<svg
  width="12"
  height="12"
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.25 4H8.25V0H3.75V4H0.75L6 8.66667L11.25 4ZM5.25 5.33333V1.33333H6.75V5.33333H7.6275L6 6.78L4.3725 5.33333H5.25ZM0.75 10H11.25V11.3333H0.75V10Z"
    fill="#111827"
  />
</svg>
