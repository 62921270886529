<script>
  import { t } from '../stores/messages';
  import { answers } from '../stores/answers';
  import { dimensions } from '../stores/dimensions';
  import { performanceLevel } from '../results/performanceLevel';
  import { totalQuestionsPerCategoryCalculable } from '../results/allDimensionsLevel';
  import CircleProgressBar from './CircleProgressBar.svelte';
  import { Doughnut } from 'svelte-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
  } from 'chart.js';
  import { get } from 'svelte/store';

  export let dimension;

  const dimensionNames = {
    S: { en: 'Social', es: 'Social' },
    A: { en: 'Environmental', es: 'Ambiental' },
    E: { en: 'Economic', es: 'Económico' },
    G: { en: 'Management', es: 'Gestión' },
  };

  const categoriesByDimension = {
    S: ['S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8'],
    A: ['A1', 'A2', 'A3', 'A5', 'A6', 'A7'], // Removed A4 because it doesn't have questions with a score
    E: ['E1', 'E2', 'E3', 'E4', 'E5', 'E6'],
    G: ['G0', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8', 'G9'],
  };

  const categoriesNames = {
    S1: { en: 'S1 Working conditions', es: 'S1 Condiciones laborales' },
    S2: {
      en: 'S2 Human and labour rights',
      es: 'S2 Derechos humanos y laborales',
    },
    S3: { en: 'S3 Social Security', es: 'S3 Seguridad social' },
    S4: { en: ' S4 Wage and opportunities', es: ' S4 Sueldos y oportunidades' },
    S5: {
      en: 'S5 Occupational safety and health',
      es: 'S5 Seguridad y Salud Ocupacional',
    },
    S6: { en: 'S6 Suppliers', es: 'S6 Proveedores' },
    S7: { en: 'S7 Community', es: 'S7 Comunidad' },
    S8: { en: 'S8 Customers', es: 'S8 Clientes' },
    A1: { en: 'A1 Resources', es: 'A1 Recursos' },
    A2: { en: 'A2 Emissions and waste', es: 'A2 Emisiones y Residuos' },
    A3: {
      en: 'A3 Environmental assessment of suppliers',
      es: 'A3 Evaluación ambiental de proveedores',
    },
    // A4: { en: 'Transport of products', es: 'Transporte de productos' },
    A5: {
      en: 'A5 Environmental risk',
      es: 'A5 Riesgo ambiental',
    },
    A6: {
      en: 'A6 Use of chemical substances',
      es: 'A6 Uso de sustancias químicas',
    },
    A7: {
      en: 'A7 Research for environmental improvement',
      es: 'A7 Investigación para la mejora ambiental',
    },
    E1: { en: 'E1 Business plan', es: 'E1 Plan de negocios' },
    E2: { en: 'E2 Production cost', es: 'E2 Costo de producción' },
    E3: { en: 'E3 Financial performance', es: 'E3 Desempeño financiero' },
    E4: { en: 'E4 Collaboration', es: 'E4 Colaboración' },
    E5: {
      en: 'E5 Vulnerability',
      es: 'E5 Vulnerabilidad',
    },
    E6: { en: 'E6 Financial indicators', es: 'E6 Indicadores Financieros' },
    G0: { en: 'G0 Company profile', es: 'G0 Perfil de la empresa' },
    G1: { en: 'G1 Automation', es: 'G1 Automatización' },
    G2: { en: 'G2 Manufacturing management', es: 'G2 Gestión y manufactura' },
    G3: { en: 'G3 Business management', es: 'G3 Gestión administrativa' },
    G4: { en: 'G4 Security', es: 'G4 Seguridad' },
    G5: {
      en: 'G5 Sustainability strategies',
      es: 'G5 Estrategias de sustentabilidad',
    },
    G6: { en: 'G6 Applicable legislation', es: 'G6 Legislación y regulación' },
    G7: { en: 'G7 Corruption prevention', es: 'G7 Prevención de corrupción' },
    G8: {
      en: 'G8 Transparency and communication',
      es: 'G8 Transparencia y comunicación',
    },
    G9: { en: 'G9 Market', es: 'G9 Mercado' },
  };

  let performanceLow = [];
  let performanceMid = [];
  let performanceHigh = [];
  let performanceIdeal = [];

  let dimensionScore = 0;
  let maxDimensionScore = 0;
  let dimensionAnswers = [];

  let chartData = (d) => {
    return {
      labels: ['Ideal %', 'High %', 'Mid %', 'Low %'],
      datasets: [
        {
          data: d,
          backgroundColor: ['#385623', '#71AD46', '#ED7C30', '#FFBF00'],
          hoverBackgroundColor: ['#385623', '#71AD46', '#ED7C30', '#FFBF00'],
        },
      ],
    };
  };

  let data = {};
  let ideal = 0;
  let high = 0;
  let mid = 0;
  let low = 0;

  const updatePerformanceLevels = (a) => {
    //Limpiar cada nivel
    performanceLow = [];
    performanceMid = [];
    performanceHigh = [];
    performanceIdeal = [];

    // Variables
    let categoryScore = 0;
    let categoryAnswers = [];
    let categoryPerformace = '';
    // Iterar las categories
    categoriesByDimension[dimension].forEach((category) => {
      categoryScore = 0;
      categoryAnswers = [];
      // Contar todas las preguntas que su question_id empiece por el nombre de la categoria y el tipo
      // de respuesta empiece con R (ej: R21, R3, etc)
      for (var key in a) {
        if (key === 'createdAt') {
          continue;
        }
        if (
          a[key].question_id.startsWith(category) &&
          a[key].answer_type.startsWith('R')
        ) {
          categoryAnswers.push(a[key]);
        }
      }
      // Quitar las preguntas que el answer_value sea NA
      categoryAnswers = categoryAnswers.filter(
        (answ) => answ.answer_value !== 'NA'
      );
      // Sumar todos los answer_values que quedan
      categoryAnswers.forEach((answ) => {
        categoryScore += Number(answ.answer_value);
      });
      // Llamar funcion performanceLevel para saber el nivel
      categoryPerformace = performanceLevel(
        categoryAnswers.length,
        categoryScore
      );
      switch (categoryPerformace) {
        case 'low':
          performanceLow.push(categoriesNames[category][$t['lang']]);
          performanceLow = performanceLow;
          break;
        case 'mid':
          performanceMid.push(categoriesNames[category][$t['lang']]);
          performanceMid = performanceMid;
          break;
        case 'high':
          performanceHigh.push(categoriesNames[category][$t['lang']]);
          performanceHigh = performanceHigh;
          break;
        case 'ideal':
          performanceIdeal.push(categoriesNames[category][$t['lang']]);
          performanceIdeal = performanceIdeal;
          break;
      }
    });

    ideal =
      (performanceIdeal.length / categoriesByDimension[dimension].length) * 100;
    high =
      (performanceHigh.length / categoriesByDimension[dimension].length) * 100;
    mid =
      (performanceMid.length / categoriesByDimension[dimension].length) * 100;
    low =
      (performanceLow.length / categoriesByDimension[dimension].length) * 100;

    data = chartData([ideal, high, mid, low]);
    dimensionScore = 0;
    maxDimensionScore = 0;
    dimensionAnswers = [];

    for (var key in a) {
      if (key === 'createdAt') {
        continue;
      }
      if (
        a[key].question_id.startsWith(dimension) &&
        a[key].answer_type.startsWith('R')
      ) {
        dimensionAnswers.push(a[key]);
      }
    }
    categoriesByDimension[dimension].forEach((c) => {
      let questionsQuantity = totalQuestionsPerCategoryCalculable(
        c,
        get(dimensions)
      );
      questionsQuantity =
        questionsQuantity -
        dimensionAnswers.filter(
          (answ) => answ.answer_value === 'NA' && answ.question_id.startsWith(c)
        ).length;
      maxDimensionScore += questionsQuantity * 3;
    });
    dimensionAnswers = dimensionAnswers.filter(
      (answ) => answ.answer_value !== 'NA'
    );

    // Sumar todos los answer_values que quedan
    dimensionAnswers.forEach((answ) => {
      if (answ.answer_value) {
        dimensionScore += Number(answ.answer_value);
      }
    });
  };

  answers.subscribe((a) => {
    updatePerformanceLevels(a);
  });
  t.subscribe((t) => {
    const a = get(answers);
    updatePerformanceLevels(a);
  });

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
</script>

<div class="mt-5 performance-top">
  <div class="performance grid grid-cols-2 gap-4">
    <div class="col-span-1">
      {#if $t['lang'] === 'en'}
        <h2 class="font-bold mb-0">
          {dimensionNames[dimension][$t['lang']]} Performance
        </h2>
        <p class="mb-2">
          Progress in the {dimensionNames[dimension][$t['lang']]} dimension is:
        </p>
      {:else}
        <h2 class="font-bold mb-0">
          Desempeño {dimensionNames[dimension][$t['lang']]}
        </h2>
        <p class="mb-2">
          La dimensión {dimensionNames[dimension][$t['lang']]} presenta un avance
          de:
        </p>
      {/if}
    </div>
    <div class="col-span-1">
      {#if maxDimensionScore > 0}
        <p class="z-10 absolute mt-28 ml-32 font-bold">
          {Math.trunc((dimensionScore / maxDimensionScore) * 100)}%
        </p>
        <div class="flex items-center font-bold pl-16 mb-12 mt-12">
          <CircleProgressBar
            progress={dimensionScore / maxDimensionScore}
            class="z-0"
          />
        </div>
      {/if}
    </div>
  </div>
  <div class="performance ml-4">
    <div>
      {#if $t['lang'] === 'en'}
        <p class="mb-2">
          Classification of {dimensionNames[dimension][$t['lang']]} impact categories
          according to the performance level:
        </p>
      {:else}
        <p class="mb-2">
          Clasificación de categorias de {dimensionNames[dimension][$t['lang']]}
          según el nivel del desempeño presentado:
        </p>
      {/if}
    </div>
  </div>
  <div class="performance grid grid-cols-2 gap-4">
    <div class="col-span-1">
      <div>
        <ul class="mb-3">
          <p class="level">
            {#if $t['lang'] === 'en'}
              1- Categories Level 1 - Ideal performance {Math.trunc(ideal)}%
            {:else}
              1- Categoría Nivel 1 - Desempeño ideal {Math.trunc(ideal)}%
            {/if}
          </p>
          {#each performanceIdeal as cat}
            <li>
              {cat}
            </li>
          {/each}
        </ul>
        <ul class="mb-3">
          <p class="level">
            {#if $t['lang'] === 'en'}
              2- Categories Level 2 - High performance {Math.trunc(high)}%
            {:else}
              2- Categoría Nivel 2 - Desempeño alto {Math.trunc(high)}%
            {/if}
          </p>
          {#each performanceHigh as cat}
            <li>
              {cat}
            </li>
          {/each}
        </ul>
        <ul class="mb-3">
          <p class="level">
            {#if $t['lang'] === 'en'}
              3- Categories Level 3 - Medium performance {Math.trunc(mid)}%
            {:else}
              3- Categoría Nivel 3 - Desempeño Medio {Math.trunc(mid)}%
            {/if}
          </p>
          {#each performanceMid as cat}
            <li>
              {cat}
            </li>
          {/each}
        </ul>
        <ul class="mb-3">
          <p class="level">
            {#if $t['lang'] === 'en'}
              4- Categories Level 4 - Low performance {Math.trunc(low)}%
            {:else}
              4- Categoría Nivel 4 - Desempeño Bajo {Math.trunc(low)}%
            {/if}
          </p>
          {#each performanceLow as cat}
            <li>
              {cat}
            </li>
          {/each}
        </ul>
      </div>
    </div>
    <div class="col-span-1 flex items-center">
      {#if maxDimensionScore > 0}
        <div class="block">
          <Doughnut {data} options={{ responsive: true }} />
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .performance {
    color: black;
    margin-left: 30px;
  }
  .level {
    color: black;
    font-weight: bold;
  }
  .block {
    height: 250px;
    width: 250px;
  }
</style>
