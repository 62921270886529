<svg
  width="42"
  height="42"
  viewBox="0 0 42 42"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="21" cy="21" r="21" fill="#FB4B00" fill-opacity="0.63" />
  <path
    d="M20.9998 11C18.8279 11 17.0511 12.7181 17.0511 14.8182C17.0511 16.9183 18.8279 18.6364 20.9998 18.6364C23.1717 18.6364 24.9486 16.9183 24.9486 14.8182C24.9486 12.7181 23.1717 11 20.9998 11ZM20.9998 12.4545C22.3588 12.4545 23.4443 13.5042 23.4443 14.8182C23.4443 16.1322 22.3588 17.1818 20.9998 17.1818C19.6409 17.1818 18.5554 16.1322 18.5554 14.8182C18.5554 13.5042 19.6409 12.4545 20.9998 12.4545ZM20.9998 19.7273C16.947 19.7273 13.6665 23.0029 13.6665 27H15.1708C15.1708 23.7672 17.7832 20.5 20.9998 20.5C24.2164 20.5 26.8289 23.7672 26.8289 27H28.3332C28.3332 23.0029 25.0526 19.7273 20.9998 19.7273Z"
    fill="white"
  />
</svg>
