<svg
  width="12"
  height="7"
  viewBox="0 0 12 7"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.59 0L6 4.32659L1.41 0L0 1.33198L6 7L12 1.33198L10.59 0Z"
    fill="#111827"
  />
</svg>
