<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { getDimensions } from '../services/satin';
  import DimensionBanner from '../components/DimensionBanner.svelte';
  import Navbar from '../components/Navbar.svelte';
  import ScrollButton from '../components/ScrollButton.svelte';
  import Sidebar from '../components/Sidebar.svelte';

  import { t } from '../stores/messages';
  import { dimensions, checkValidStoredObject } from '../stores/dimensions';
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar dimension="results" />
  <div class="w-full overflow-hidden">
    <div class="mx-auto w-11/12 mb-4 p-4 flex flex-row items-center">
      <DimensionBanner dimension="Economic" />
    </div>
    <section
      class="mx-auto w-11/12 p-4 text-gray-600 body-font bg-white shadow-xl rounded-lg"
    >
      <div class="flex-column mr-16 ml-10 text-justify">
        <div>
          {#if $t['lang'] === 'en'}
            <h3>E. Economic</h3>
            <h4 id="business-plan">E1. Business plan</h4>
            <div>
              The business plan provides valuable information for decision
              making, guiding actions towards better resources use. This
              document must compile the analysis of internal and external
              factors affecting the company's performance. It is based on a
              planning process involving all the areas and definig the most
              appropriate strategies to achieve the goals.
            </div>
            <br />
            <div>
              The internal analysis identifies strengths and weaknesses
              (resources, capacities and skills available to achieve the
              objectives). The external analysis evaluates environmental factors
              (economic, social, political, legal, technological and ecological)
              that may represent threats and opportunities for the company.
            </div>
            <br />
            <div>
              Its contents include the company profile, business model,
              organisational structure, mission, vision, values, goals and
              strategies to put them into practice. It also contains the
              definition of the product attributes or service offered, the value
              proposition, the characteristics of the target audience, the
              market barriers, and the competition analysis. It also considers
              the economic viability analysis through financial indicators such
              as the payback period and the expected profitability. Finally, it
              also articulates revenue streams and growth projections.
            </div>
          {:else}
            <h3>E. Económico</h3>
            <h4 id="business-plan">E1. Plan de negocios</h4>
            <div>
              El plan de negocios proporciona información valiosa para la toma
              de decisiones, guiando las acciones hacia un mejor aprovechamiento
              de los recursos. Es un documento que recopila el análisis de
              factores internos y externos que afectan el desempeño de la
              empresa. Se basa en un proceso de planeación integral, que
              involucra a todas las áreas de la empresa al definir las
              estrategias más adecuadas para alcanzar los objetivos.
            </div>
            <br />
            <div>
              El análisis interno identifica fortalezas y debilidades
              (disposición de recursos, capacidades y aptitudes para el logro de
              los objetivos). Mientras que el análisis externo evalúa factores
              del entorno (económicos, sociales, políticos, legales,
              tecnológicos y ambientales) que pueden representar amenazas y
              oportunidades para la empresa.
            </div>
            <br />
            <div>
              Dentro de su contenido se encuentra el perfil empresarial, modelo
              de negocios, estructura organizacional, misión, visión, valores,
              objetivos y estrategias para ponerlos en práctica. Además,
              contiene la definición de atributos del producto o servicio
              ofrecido, la propuesta de valor, las características del público
              al que va dirigido, las barreras del mercado y el análisis de la
              competencia. Así como también considera el análisis de la
              viabilidad económica, a través de indicadores financieros como son
              el periodo de retorno de la inversión y la rentabilidad esperada.
              Así mismo, articula los flujos de ingresos y las proyecciones de
              crecimiento.
            </div>
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="production-cost">E2. Production cost</h4>
            <div>
              The cost of production refers to the investments required to keep
              the production process running, including all the resources needed
              for this purpose. This will facilitate the identification of
              improvement areas and provide the information for better decision
              making. The cost of production refers to the direct and indirect
              investments corresponding to the production process, which are
              indispensable for developing a good or service. It is made up of
              the labour cost and raw materials used directly in the production
              process. It also includes indirect production costs such as the
              production hall rent. The direct and indirect components must be
              accounted for to know how the production cost is made up. This
              allows better decisions to be made during resource management and
              to identify critical points. Therefore, the company must have an
              established method for determining the total cost of goods sold
              and per production unit in a systematic and documented manner.
            </div>
            <br />
            <div>
              To determine the cost of goods and services sold should be
              considered the investments incurred in the products sold during
              the cycle to be evaluated, i.e. those disbursements that
              correspond to the products remaining in stock are excluded.
            </div>
            <br />
            <div>
              Therefore, for calculating the production cost of the goods sold,
              the value of the initial inventory of finished products plus the
              production costs of the period are added. Finally, the ending
              inventory (stocks) value is subtracted from the resulting amount
              to obtain the total cost of goods sold.
            </div>
          {:else}
            <h4 id="production-cost">E2. Costo de producción</h4>
            <div>
              El costo de producción se refiere a las inversiones requeridas
              para mantener el proceso de producción en funcionamiento,
              incluyendo todos los recursos necesarios para dicho fin. Esto
              facilitará la identificación de áreas de mejora y proporcionará la
              información necesaria para una mejor toma de decisiones. El costo
              de producción se refiere a las inversiones directas e indirectas
              que corresponden al proceso de producción, indispensables para la
              obtención de un bien o servicio determinado. Se compone del costo
              de mano de obra y materias primas utilizadas directamente en el
              proceso productivo. Así como también, incorpora los costos
              indirectos de producción como el alquiler de la nave de
              producción. Los componentes directos e indirectos se deben
              contabilizar para conocer a detalle la manera en que se encuentra
              conformado el costo de producción. Esto permite tomar mejores
              decisiones durante la gestión de recursos e identificar puntos
              críticos que deben ser atendidos. Es fundamental que la empresa
              cuente con un método establecido para determinar el costo total
              del producto vendido y por unidad de producción de manera
              sistemática y documentada.
            </div>
            <br />
            <div>
              Para la determinación del costo de los bienes o servicios
              vendidos, se deben considerar únicamente las inversiones
              incurridas en los productos vendidos durante el ciclo a evaluar,
              es decir, se excluyen aquellos desembolsos que corresponden a los
              productos que se quedan en existencia dentro de la empresa.
            </div>
            <br />
            <div>
              Por ello, para el cálculo del costo de producción de todos los
              bienes vendidos, se adiciona el valor del inventario inicial de
              productos terminados más los costos de producción del periodo. Por
              último, al monto resultante se resta el valor del inventario final
              (existencias) y de ese modo se obtiene el costo de venta total (de
              los productos vendidos).
            </div>
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="financial-performance">E3. Financial performance</h4>
            Profit refers to the gain derived from an asset or investment, so the
            presence of sustained profits over several years is one of the indicators
            of good financial performance.
          {:else}
            <h4 id="financial-performance">E3. Desempeño financiero</h4>
            Las utilidades refieren a la ganancia derivada de un bien o inversión,
            por lo cual, la presencia de utilidades sostenidas durante varios años
            es uno de los indicadores de un adecuado desempeño financiero.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="collaboration">E4. Collaboration</h4>
            The company should integrate business collaboration schemes to reduce
            costs and minimise risks in projects or expenses that the organisation
            would not carry out independently. In addition, business collaboration
            fosters dialogue and exchanging ideas, allowing companies to speed up
            the learning curve and generate growth for all stakeholders. Likewise,
            it is advisable to seek government support to generate investments in
            social and environmental programmes and thus generate a collaboration
            that benefits the community.
          {:else}
            <h4 id="collaboration">E4. Colaboración</h4>
            Es recomendable que la empresa integre esquemas de colaboración empresarial
            con la finalidad de reducir costos y minimizar riesgos en proyectos o
            gastos que la organización de forma independiente no podría llevar a
            cabo. La colaboración empresarial fomenta el diálogo y el intercambio
            de ideas, permitiendo a las empresas agilizar la curva de aprendizaje
            y generando un crecimiento para todas las partes involucradas. Así mismo,
            es conveniente buscar apoyos gubernamentales que permitan generar inversiones
            en programas de apoyo social y ambiental y así generar una colaboración
            que beneficie a la comunidad.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="vulnerability">E5. Vulnerability</h4>
            It is essential to have a plan and procedure to guide the company in
            identifying and mitigating financial risks that may threaten the business.
            In this way, it will be possible to generate actions reducing risks and
            establish procedures for proper management. In addition, it is a continuous
            monitoring process of eventualities to avoid crises or damage to the
            company's assets. It is also advisable to analyse the dependency on the
            leading supplier to assess the potential risks of not having this service
            and to generate an alternative plan providing the company with more flexibility
            and independence.
          {:else}
            <h4 id="vulnerability">E5. Vulnerabilidad</h4>
            Es esencial contar con un plan y procedimiento que guie a la empresa
            en la identificación y mitigación de riesgos financieros que puedan amenazar
            el negocio. De esta manera será posible generar acciones que reduzcan
            los riesgos y establezcan procedimientos para un adecuado manejo. Se
            trata de un proceso de monitoreo continuo que permitirá hacer frente
            ante las eventualidades, para evitar crisis o daños al patrimonio de
            la empresa. Así mismo, es conveniente contar con un análisis de dependencia
            del proveedor líder para evaluar los posibles riesgos en caso de no contar
            con este suministro y generar un plan alternativo que brinde a la empresa
            mas flexibilidad e independencia.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="financial-indicators">E6. Financial indicators</h4>
            Financial indicators are guidelines that facilitate quantifying a company's
            financial performance. They provide essential information for better
            decision making and proper business evaluation. The information provided
            by the company's indicators allows comparisons between periods or with
            other companies in the sector. These indicators generate diagnoses of
            the company's situation to identify critical points and establish actions
            to improve economic management. The measurements must be carried out
            systematically to obtain viable and reliable information. These indicators
            represent mathematical relationships between financial values, accounts
            or accounting items to evaluate a specific economic activity area.
          {:else}
            <h4 id="financial-indicators">E6. Indicadores Financieros</h4>
            Los indicadores financieros son pautas que facilitan la cuantificación
            del desempeño financiero de una empresa. Proporcionan información esencial
            para una mejor toma de decisiones y una adecuada evaluación empresarial.
            La información que proporcionan los indicadores de la empresa permite
            realizar comparaciones con periodos anteriores o con otras empresas del
            sector. A través de ellos se generan diagnósticos sobre la situación
            de la empresa, para identificar puntos críticos y establecer acciones
            de mejora durante la gestión económica. Es relevante que las mediciones
            se lleven a cabo de forma sistemática para obtener información viable
            y confiable. Estos indicadores son datos numéricos que representan relaciones
            matemáticas sobre dos o más valores financieros, cuentas o partidas contables.
            A través de las cuales se puede evaluar un área específica de la actividad
            económica.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="profitability">E61. Profitability</h5>
            <div>
              The financial profitability indicators relate the income obtained
              during the period under evaluation to net sales, total assets and
              capital. These indicators are obtained by dividing one quantity by
              another. The function of these indicators is to measure the
              capacity to generate profits. Some of the main profit margins and
              how to calculate them are described below:
            </div>
            <br />
            <li>
              a) The net profit margin is obtained by dividing net profit by net
              sales value. This result measures the company's profitability
              about net sales for the period under evaluation. It reflects the
              company's ability to convert its revenues (sales) into profits
              (after-tax profits). The higher the result, the better the
              performance.
            </li>
            <li>
              b) The operating profit margin is obtained by dividing operating
              profit by net sales. It measures the company's level of
              effectiveness in generating profits from earnings before interest
              and taxes.
            </li>
            <li>
              c) The Return on Assets (ROA) margin is obtained by dividing net
              income by the company's assets. It reflects the capacity to
              generate profits with the company's resources (total assets),
              i.e., the return on assets. It represents the company's profit for
              each dollar/euro invested in its assets.
            </li>
            <li>
              d) The Return on Equity (ROE) is obtained by dividing net income
              by capital, also known as equity. Equity is the physical and
              financial resources obtained through contributions from
              shareholders or partners. This financial indicator reflects the
              company's efficiency in generating its profits from the capital it
              owns. With this index, it is possible to analyse the return or
              profit generated by the company for each dollar/euro invested.
            </li>
            <br />
            <div>
              The company must constantly measure and compares its financial
              indicators, taking the data obtained in the different fiscal years
              to know the behaviour of its financial performance and take
              actions aimed at improving its results. There are other financial
              ratios, and the company should look for the most appropriate ones
              according to the characteristics of its operations.
            </div>
          {:else}
            <h5 id="profitability">E61. Rentabilidad</h5>
            <div>
              Los indicadores financieros de rentabilidad relacionan la utilidad
              obtenida durante el periodo evaluado respecto a las ventas netas,
              al total de activo y al capital. Estos indicadores se obtienen
              dividiendo una cantidad sobre otra. La función de estos
              indicadores es medir la capacidad para generar utilidades. A
              continuación, se describen algunos de los principales márgenes de
              utilidad y la forma de calcularlos:
            </div>
            <li>
              a) El margen de utilidad neta se obtiene dividiendo la utilidad
              neta entre el valor de las ventas netas. Ese resultado mide la
              rentabilidad de la empresa respecto a las ventas netas del período
              evaluado. Reflejando la capacidad de la empresa para convertir los
              ingresos que recibe (ventas) en beneficios (utilidades después de
              impuestos). Entre mayor sea el resultado mejor desempeño obtenido.
            </li>
            <li>
              b) El margen de utilidad operativa se obtiene de dividir la
              utilidad operativa entre las ventas netas. Mide el nivel de
              efectividad que tiene la empresa para generar sus utilidades a
              partir de la utilidad antes de impuestos e intereses.
            </li>
            <li>
              c) El margen de rentabilidad de activos (ROA) se obtiene
              dividiendo la utilidad neta entre los activos de la empresa.
              Refleja la capacidad de generar ganancias con los recursos de la
              empresa (activos totales), es decir, es el retorno sobre los
              activos. Representa la utilidad que recibe la empresa por cada
              peso/dólar/euro invertido en sus activos.
            </li>
            <li>
              d) El margen de rendimiento del capital (ROE) se obtiene
              dividiendo la utilidad neta entre el capital, también conocido
              como patrimonio. El capital son los recursos físicos y financieros
              obtenidos mediante aportaciones de accionistas o socios. Este
              indicador financiero refleja la eficiencia de la empresa al
              generar sus utilidades a partir del capital que posee. Con este
              índice es posible analizar el rendimiento o utilidad que genera la
              empresa por cada peso/dólar/euro invertido.
            </li>
            <br />
            <div>
              Es relevante que la empresa realice mediciones y comparaciones
              constantemente de sus indicadores financieros, tomando los datos
              obtenidos en los distintos ejercicios fiscales para que conozca el
              comportamiento de su desempeño financiero y realice acciones
              encaminadas a mejorar sus resultados. Existen distintos índices
              financieros y la empresa debe buscar los más adecuados de acuerdo
              a las características de sus operaciones.
            </div>
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="sales-growth">E62. Sales growth</h5>
            The sales growth rate is a financial indicator that measures the increase
            in sales revenue over a specific time. Therefore, the periods must always
            be similar, i.e. comparing one week with another, one month with another
            or one year with another. The way to calculate it is with the following
            formula: (current sales period - sales previous period)/sales last period
            *100.
          {:else}
            <h5 id="sales-growth">E62. Crecimiento en ventas</h5>

            Índice de crecimiento en ventas es un indicador financiero que
            permite medir el incremento de los ingresos por ventas en un periodo
            de tiempo específico. Es relevante que siempre se trate de periodos
            similares, es decir, comparando una semana con otra, un mes con otro
            o un año con otro. La forma de calcularlo es con la siguiente
            formula: (ventas periodo actual - ventas periodo anterior) / ventas
            periodo anterior *100.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="sustainability">E63. Sustainability investments</h5>

            The company should consider making investments aimed at sustainable
            development. This can be achieved through social programmes for the
            community, health, education, training, and environmental
            preservation such as forestry campaigns, cleaning, green areas
            maintenance, replacing virgin materials, and waste management. In
            this sense, it is recommended to have a plan establishing programmes
            and investments in sustainability to guide the company towards
            achieving its objectives. Furthermore, it is necessary to properly
            document these investments, their progress and the results achieved,
            as this is essential information for business reporting. Therefore,
            it is vital to carry out projects with systematised procedures
            through plans, actions, records, measurements and evaluations. In
            this way, the company will know the impact of sustainability
            investments. Furthermore, it will communicate more efficiently to
            stakeholders, positioning a more sustainable corporate image.
          {:else}
            <h5 id="sustainability">E63. Inversiones en Sustentabilidad</h5>

            Es conveniente que la empresa contemple realizar inversiones
            encaminadas al desarrollo sostenible. Esto se puede lograr a través
            de programas de apoyo social para la comunidad, programas de salud,
            educación y capacitación, así como programas de preservación del
            medio ambiente como campañas forestales, limpieza, mantenimiento de
            áreas verdes, remplazo de materiales vírgenes y gestión de residuos,
            entre otros. En este sentido se recomienda contar con un plan que
            establezca programas e inversiones en tema de sustentabilidad, de
            tal manera que guie a la empresa hacia el alcance de sus objetivos.
            Es necesario documentar adecuadamente estas inversiones, su progreso
            y los resultados alcanzados, ya que es información esencial en la
            elaboración de reportes empresariales. Es vital llevar a cabo los
            proyectos con procedimientos sistematizados, mediante planes,
            acciones, registros, mediciones y evaluaciones. De esa manera la
            empresa conocerá el impacto de las inversiones en materia de
            sustentabilidad y podrá comunicarlas más eficientemente a los grupos
            de interés, posicionándose con una imagen corporativa más
            sustentable.
          {/if}
        </div>
      </div>
    </section>
  </div>
  <ScrollButton />
</div>
