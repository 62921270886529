<script>
  import { get } from 'svelte/store';
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';

  export let question;

  let selectedDateStart = null;
  let selectedDateEnd = null;

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  answers.subscribe((a) => {
    const selectedDates = a[question.code]
      ? parseAnswer(a[question.code]?.user_value)
      : [null, null];

    selectedDateStart = selectedDates[0] ? new Date(selectedDates[0]) : null;
    selectedDateEnd = selectedDates[1] ? new Date(selectedDates[1]) : null;
  });
</script>

<div class="flex flex-row items-center">
  <span>
    {#if $t['lang'] === 'en'}
      From
    {:else}
      De
    {/if}
  </span>
  <div class="mb-5 w-64 mt-5 ml-5">
    <input
      on:input={async (e) => {
        selectedDateStart = new Date(e.target.value);
        const a = get(answers) || {};
        const answer = JSON.stringify([selectedDateStart, selectedDateEnd]);
        if (a[question.code]?.user_value !== answer) {
          saveAnswer(question, answer);
        }
      }}
      type="date"
      id="start"
      pattern="\d{4}-\d{2}-\d{2}"
      value={selectedDateStart ? formatDate(selectedDateStart) : ''}
    />
  </div>
  <span class="ml-2">
    {#if $t['lang'] === 'en'}
      To
    {:else}
      A
    {/if}
  </span>
  <div class="mb-5 w-64 mt-5 ml-5">
    <input
      on:input={async (e) => {
        selectedDateEnd = new Date(e.target.value);
        const a = get(answers) || {};
        const answer = JSON.stringify([selectedDateStart, selectedDateEnd]);
        if (a[question.code]?.user_value !== answer) {
          saveAnswer(question, answer);
        }
      }}
      type="date"
      id="end"
      pattern="\d{4}-\d{2}-\d{2}"
      value={selectedDateEnd ? formatDate(selectedDateEnd) : ''}
    />
  </div>
</div>
