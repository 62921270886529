<script>
  import Navbar from '../components/Navbar.svelte';
  import { t } from '../stores/messages';
</script>

<Navbar />
<div class="mt-7 w-full">
  <section class="mx-auto w-full mb-4 p-4 text-gray-600 body-font bg-white">
    <div class="container py-12">
      <div class="flex flex-wrap w-full mb-20 flex-col">
        <div class="flex justify-center items-center">
          <h1 class="text-gray-900 font-bold leading-9 mt-4 m-1.5">
            {#if $t['lang'] === 'en'}
              Get ready to start!
            {:else}
              ¡Prepárate para comenzar!
            {/if}
          </h1>
        </div>
        <div class="flex justify-center items-center mb-5">
          <h2 class="text-gray-900 font-bold text-2xl leading-9 mt-4 m-1.5">
            {#if $t['lang'] === 'en'}
              Before starting, make sure you have access to data and information
              related to the four dimensions:
            {:else}
              Antes de comenzar, asegúrese contar con acceso a datos e
              información relativos a las cuatro dimensiones a evaluar:
            {/if}
          </h2>
        </div>

        <div class="bg-white flex flex-row justify-evenly">
          <div class="flex justify-center items-center p-2">
            <img
              src="/images/environmental.png"
              alt="environmental"
              style="min-width: 150px;"
            />
          </div>
          <div class="flex flex-col right-0 p-2">
            <h6 class="mt-4 font-bold">
              {#if $t['lang'] === 'en'}
                Environmental
              {:else}
                Ambiental
              {/if}
            </h6>
            <p class="text-justify text-md">
              {#if $t['lang'] === 'en'}
                The Environmental dimension evaluates the company's
                environmental management and quantifies the impact of process
                inputs and outputs. It is recommended to access data on fibre
                use, electricity, fuels, water, waste generation and product
                shipments.
              {:else}
                La dimensión Ambiental examina la gestión ambiental de la
                empresa y cuantifica el impacto ambiental asociado a las
                entradas y salidas del proceso. Se recomienda contar con acceso
                a datos sobre uso de fibras, electricidad, combustibles, agua,
                generación de residuos y envíos de productos.
              {/if}
            </p>
          </div>
        </div>

        <div class="bg-white flex flex-row justify-evenly">
          <div class="flex justify-center items-center p-2">
            <img
              src="/images/social.png"
              alt="social"
              style="min-width: 150px;"
            />
          </div>
          <div class="flex flex-col right-0 p-2">
            <h6 class="mt-4 font-bold">
              {#if $t['lang'] === 'en'}
                Social
              {:else}
                Social
              {/if}
            </h6>
            <p class="text-justify">
              {#if $t['lang'] === 'en'}
                The Social dimension analyses the company's performance in areas
                as working conditions, human rights, occupational health and
                relationships with suppliers, customers and the community.
              {:else}
                La dimensión Social analiza el desempeño de la empresa en
                materia de condiciones laborales, derechos humanos, salud
                ocupacional y relación con proveedores, clientes y comunidad.
              {/if}
            </p>
          </div>
        </div>

        <div class="bg-white flex flex-row justify-evenly">
          <div class="flex justify-center items-center p-2">
            <img
              src="/images/economic.png"
              alt="economic"
              style="min-width: 150px;"
            />
          </div>
          <div class="flex flex-col right-0 p-2">
            <h6 class="mt-4 font-bold">
              {#if $t['lang'] === 'en'}
                Economic
              {:else}
                Económico
              {/if}
            </h6>
            <p class="text-justify">
              {#if $t['lang'] === 'en'}
                The Economic dimension evaluates the company's performance in
                generating profits, stability and capacity to maintain economic
                resources through qualitative indicators based on financial
                ratios.
              {:else}
                La dimensión Económica evalúa el desempeño de la empresa para
                generar ganancias, estabilidad y su capacidad para mantener los
                recursos económicos. Esta evaluación se realiza mediante
                indicadores cualitativos basados en razones financieras.
              {/if}
            </p>
          </div>
        </div>

        <div class="bg-white flex flex-row justify-evenly">
          <div class="flex justify-center items-center p-2">
            <img
              src="/images/management.png"
              alt="management"
              style="min-width: 150px;"
            />
          </div>
          <div class="flex flex-col right-0 p-2">
            <h6 class="mt-4 font-bold">
              {#if $t['lang'] === 'en'}
                Management
              {:else}
                Gestión
              {/if}
            </h6>
            <p class="text-justify">
              {#if $t['lang'] === 'en'}
                The Management dimension evaluates policies, protocols and
                mechanisms that facilitate proper business management. Among the
                topics to be analysed are manufacturing management, process
                automation, continuous improvement programs, transparency and
                communication.
              {:else}
                La dimensión de Gestión Empresarial evalúa la existencia de
                políticas, protocolos y mecanismos que faciliten una adecuada
                gestión empresarial. Entre los temas a analizar se encuentra
                gestión de manufactura, automatización de procesos, programas de
                mejora continua, transparencia y comunicación.
              {/if}
            </p>
          </div>
        </div>
        <div class="flex justify-center items-center">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321 2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183 17Z"
              stroke="#111827"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 class="text-gray-900 text-2xl leading-9 mt-4 m-1.5 ml-5">
            {#if $t['lang'] === 'en'}
              It is recommended to consult the User Manual available in the menu
              in the upper right corner to facilitate the use of the tool.
            {:else}
              Para facilitar el uso de la herramienta, se recomienda consultar
              el Manual de Usuario disponible en el menú de la esquina superior
              derecha.
            {/if}
          </h2>
        </div>
      </div>

      <div class="bg-white flex flex-row justify-evenly">
        <a href={'/app/main/A/A1'}>
          <button
            class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded-lg shadow-sm"
          >
            {#if $t['lang'] === 'en'}
              Start
            {:else}
              Iniciar
            {/if}
          </button>
        </a>
      </div>
    </div>
  </section>
</div>
