<script>
  import { t } from '../../stores/messages';
  export let title;
  export let materials;
  export let onAdd;
  export let onUpdate;

  $: {
    onUpdate(materials);
  }
</script>

<div class="yarn pr-6">
  <div class="mr-4 yarn-info">
    <div class="info w-full">
      <h5>{title}</h5>
      <h5 class="text-center">
        <b>
          {#if $t['lang'] === 'en'}
            Purchased raw materials
          {:else}
            Materias primas adquiridas
          {/if}
        </b>
      </h5>
      <p>
        {#if $t['lang'] === 'en'}
          Select the material and the amount of yarn purchased annually
        {:else}
          Seleccione el material y la cantidad de hilo adquirida anualmente
        {/if}
      </p>
    </div>
    <div class="inputs flex mt-5">
      <div class="flex-column w-full">
        <div class="row">
          {#each materials as material}
            <div class="flex flex-wrap flex-row w-full">
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="select">
                    <select
                      bind:value={material['fabric']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="A113K">
                        {#if $t['lang'] === 'en'}
                          Conventional cotton woven fabric
                        {:else}
                          Tejido plano de algodón convencional
                        {/if}
                      </option>
                      <option value="A113L">
                        {#if $t['lang'] === 'en'}
                          Organic cotton woven fabric
                        {:else}
                          Tejido plano de algodón orgánico
                        {/if}
                      </option>
                      <option value="A113M">
                        {#if $t['lang'] === 'en'}
                          Wool woven fabric
                        {:else}
                          Tejido plano de lana
                        {/if}
                      </option>
                      <option value="A113N">
                        {#if $t['lang'] === 'en'}
                          Cellulosic fibers woven fabric
                        {:else}
                          Tejido plano de fibras celulósicas
                        {/if}
                      </option>
                      <option value="A113O">
                        {#if $t['lang'] === 'en'}
                          Polyester woven fabric
                        {:else}
                          Tejido plano de poliéster
                        {/if}
                      </option>
                      <option value="A113P">
                        {#if $t['lang'] === 'en'}
                          Recycled polyester woven fabric
                        {:else}
                          Tejido plano de poliéster reciclado
                        {/if}
                      </option>
                      <option value="A113Q">
                        {#if $t['lang'] === 'en'}
                          Nylon woven fabric
                        {:else}
                          Tejido plano de nylon
                        {/if}
                      </option>
                      <option value="A113R">
                        {#if $t['lang'] === 'en'}
                          Elastane woven fabric
                        {:else}
                          Tejido plano de elastano
                        {/if}
                      </option>
                      <option value="A113S">
                        {#if $t['lang'] === 'en'}
                          Acrylic woven fabric
                        {:else}
                          Tejido plano de acrilico
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="flex flex-row items-center mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={material['quantity']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        {#if $t['lang'] === 'en'}
                          kg/year
                        {:else}
                          kg/año
                        {/if}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>

  <div class="mr-4 yarn-transport">
    <br />
    <div class="info w-full">
      <br />
      <h5 class="text-center">
        <b>
          {#if $t['lang'] === 'en'}
            Origin or Provenance
          {:else}
            Origen o procedencia
          {/if}
        </b>
      </h5>
      <p>
        {#if $t['lang'] === 'en'}
          Select the transport mean and the kilometres travelled (approximately)
          from the material production place to your company
        {:else}
          Selecciona el medio de transporte y kilómetros recorridos
          (aproximadamente) desde el lugar de producción del material hasta tu
          empresa
        {/if}
      </p>
    </div>
    <div class="inputs flex mt-5">
      <div class="flex-column w-full">
        <div class="row">
          {#each materials as material}
            <div class="flex flex-wrap flex-row w-full">
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="select mb-5">
                    <select
                      bind:value={material['firstTransport']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Train
                        {:else}
                          Tren
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Lorry
                        {:else}
                          Camión
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Commercial vehicle
                        {:else}
                          Vehículo comercial
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Ship
                        {:else}
                          Barco
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Aircraft
                        {:else}
                          Avión
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="flex flex-row items-center mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={material['firstTransportQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        km
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="select mb-5">
                    <select
                      bind:value={material['secondTransport']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Train
                        {:else}
                          Tren
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Lorry
                        {:else}
                          Camión
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Commercial vehicle
                        {:else}
                          Vehículo comercial
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Ship
                        {:else}
                          Barco
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Aircraft
                        {:else}
                          Avión
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="flex flex-row items-center mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={material['secondTransportQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        km
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <button
    class="button"
    type="button"
    on:click={() => {
      onAdd();
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke="#111827"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>

<style>
  .yarn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .yarn-info {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
  }
  .yarn-transport {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
    margin-top: -39px;
  }
  .form-outline {
    width: 55%;
  }
  .button {
    background-color: white;
  }
</style>
