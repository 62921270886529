<script>
  import 'chota';
  import { Tabs, Tab } from 'svelte-chota';
  import { t } from '../stores/messages';
  import Accordion from '../components/Accordion.svelte';
  import Performance from './Performance.svelte';
  import Tables from './Tables.svelte';
  import RadarPerformance from './RadarPerformance.svelte';
  import { getExcel, getResults } from '../services/satin';
  import DownloadArrow from '../components/Icons/DownloadArrow.svelte';
  import Xsl from '../components/Icons/Xsl.svelte';
  import Pdf from '../components/Icons/Pdf.svelte';

  export let activeTab = 0;

  const dimensionsNames = ['S', 'A', 'G', 'E'];
</script>

<Tabs
  full
  class="leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary mt-2 width:50px"
  bind:active={activeTab}
>
  <Tab class={activeTab === 0 ? 'activeTab' : ''}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="taboption text-center"
      on:click={() => {
        window.location.hash = '#charts';
      }}
    >
      {$t['graphs']}
    </div>
  </Tab>
  <Tab class={activeTab === 1 ? 'activeTab' : ''}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="taboption text-center"
      on:click={() => {
        window.location.hash = '#tips';
      }}
    >
      {$t['tips']}
    </div>
  </Tab>
</Tabs>

{#if activeTab == 0}
  <div class="container">
    <div class="noprint flex justify-end mt-5">
      <div
        class="block p-8 rounded-md w-64 border-2 border-solid shadow-gray-50"
      >
        <div class="flex items-center mb-2.5">
          <DownloadArrow />
          <div class="ml-5">
            {#if $t['lang'] === 'en'}
              Download
            {:else}
              Descargar
            {/if}
          </div>
        </div>
        <div class="flex items-center justify-evenly cursor-pointer">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            on:click={() => {
              window.print();
            }}
          >
            <Pdf />
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            on:click={async () => {
              const results = await getResults();
              const resultsBase64 = window.btoa(JSON.stringify(results));
              const res = await getExcel(
                resultsBase64,
                $t['lang'].toUpperCase()
              );
              window.location.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.file}`;
            }}
          >
            <Xsl />
          </div>
        </div>
      </div>
    </div>
    <div class="mb-32">
      <RadarPerformance />
    </div>
    {#each dimensionsNames as dimension}
      {#if dimension === 'A'}
        <Performance {dimension} class="mt-0" />
      {/if}
    {/each}
    <Tables style="margin-bottom: 80px;" />
    {#each dimensionsNames as dimension}
      {#if dimension === 'S'}
        <Performance {dimension} />
      {/if}
    {/each}
    {#each dimensionsNames as dimension}
      {#if dimension === 'E'}
        <Performance {dimension} />
      {/if}
    {/each}
    {#each dimensionsNames as dimension}
      {#if dimension === 'G'}
        <Performance {dimension} />
      {/if}
    {/each}
  </div>
{/if}

{#if activeTab == 1}
  <Accordion />
{/if}

<style>
  .taboption {
    color: black;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    justify-content: center;
    letter-spacing: 0.15px;
    background: #ffffff;
    margin-left: 10px;
    &:hover {
      color: black;
      font-weight: bold;
    }
    &:focus {
      color: black;
      font-weight: bold;
    }
  }
</style>
