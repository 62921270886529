<script>
  import { navigateTo } from 'svelte-router-spa';
  import { createForm } from 'svelte-forms-lib';
  import { auth } from '../stores/auth';
  import { t } from '../stores/messages';
  import Navbar from '../components/Navbar.svelte';
  import CheckCircle from '../components/CheckCircle.svelte';
  import UserLogo from '../components/UserLogo.svelte';
  import { getMe, postRegistration } from '../services/satin';
  import Sidebar from '../components/Sidebar.svelte';
  import { onMount } from 'svelte';

  let user;
  let isRegistrationDisabled = true;
  let userIsRegistering = window.location.href.includes('register');

  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      userName: user?.name,
      userEmail: user?.email,
      companyName: user?.company?.name || '',
      affiliation: user?.company?.affiliation || '',
      position: user?.department || '',
      employees: user?.company?.size || '',
      country: user?.company?.country || '',
      state: user?.company?.state || '',
    },
    validate: () => {
      validateForm();
      return {};
    },
    onSubmit: async (values) => {
      const u = (await getMe()).me;
      const registration = {
        id: u.id,
        name: values.userName,
        email: u.email,
        auth_id: u.auth_id,
        department: values.position,
        company: {
          id: u.company.id,
          name: values.companyName,
          size: values.employees,
          affiliation: values.affiliation,
          country: values.country,
          state: values.state,
        },
      };
      const r = await postRegistration(registration);
      if (r.ok) {
        const me = await getMe();
        me.createdAt = new Date().getTime();
        auth.set(me);
        if (userIsRegistering) {
          navigateTo('/app/main/D');
        }
      }
    },
  });

  const validateForm = () => {
    let valid =
      !!$form.userName &&
      !!$form.userEmail &&
      !!$form.companyName &&
      !!$form.affiliation &&
      !!$form.position &&
      !!$form.employees &&
      !!$form.country &&
      !!$form.state;

    isRegistrationDisabled = !valid;
  };

  onMount(async () => {
    const me = await getMe();
    user = me.me;
    auth.set(me);
    form.set({
      userEmail: user?.email,
      userName: user?.name,
      companyName: user?.company?.name || '',
      affiliation: user?.company?.affiliation || '',
      position: user?.department || '',
      employees: user?.company?.size || '',
      country: user?.company?.country || '',
      state: user?.company?.state || '',
    });
    if (
      user?.email &&
      user?.name &&
      user?.company?.name &&
      user?.company?.affiliation &&
      user?.department &&
      user?.company?.size &&
      user?.company?.country &&
      user?.company?.state
    ) {
      isRegistrationDisabled = false;
    }
  });
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  {#if !userIsRegistering}
    <Sidebar />
  {/if}
  <div class="w-full">
    <section
      class="mx-auto w-8/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white shadow-xl"
    >
      <div class="container py-12">
        <div class="flex flex-wrap w-full mb-20 flex-col">
          <div class="flex flex-row items-center mr-16 border-b">
            <h1 class="mb-0 mt-0 text-black">
              {#if userIsRegistering}
                {$t['profile-register']}
              {:else}
                {$t['profile-profile']}
              {/if}
            </h1>
            <div class="mt-2 ml-2">
              <CheckCircle />
            </div>
          </div>
          <div class="h-auto w-auto rounded-full mx-auto mt-5">
            <UserLogo />
          </div>
          <form on:submit={handleSubmit}>
            <div class="flex items-center">
              <div class="container mx-auto">
                <div class="mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {$t['profile-user-name']}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        bind:value={$form.userName}
                        on:keyup={handleChange}
                        placeholder={$auth.me?.name}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {$t['profile-company-email']}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        disabled
                        name="userEmail"
                        id="userEmail"
                        bind:value={$form.userEmail}
                        on:keyup={handleChange}
                        placeholder={$auth.me?.email}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="border-t mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      I subscribe as
                    {:else}
                      Tipo de afiliación
                    {/if}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <select
                        required
                        name="affiliation"
                        id="affiliation"
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        bind:value={$form.affiliation}
                        on:keyup={handleChange}
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1">
                          {#if $t['lang'] === 'en'}
                            Textile Company
                          {:else}
                            Empresa Textil
                          {/if}
                        </option>
                        <option value="2">
                          {#if $t['lang'] === 'en'}
                            Retailer
                          {:else}
                            Distribuidor de productos textiles
                          {/if}
                        </option>
                        <option value="3">
                          {#if $t['lang'] === 'en'}
                            Research and education
                          {:else}
                            Educación e investigación
                          {/if}
                        </option>
                        <option value="4">
                          {#if $t['lang'] === 'en'}
                            Public organism
                          {:else}
                            Organismo público
                          {/if}
                        </option>
                        <option value="5">
                          {#if $t['lang'] === 'en'}
                            Professional association or organisation
                          {:else}
                            Asociación u organización profesional
                          {/if}
                        </option>
                        <option value="6">
                          {#if $t['lang'] === 'en'}
                            Non-governmental organisation
                          {:else}
                            Organización no gubernamental
                          {/if}
                        </option>
                        <option value="7">
                          {#if $t['lang'] === 'en'}
                            Other
                          {:else}
                            Otro
                          {/if}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="border-t mr-16">
                  <div class="flex flex-row mb-8 font-bold ml-16 mt-6">
                    {$t['profile-company']}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        bind:value={$form.companyName}
                        on:keyup={handleChange}
                        placeholder={$auth.me?.company?.name}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="border-t mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Department / Position
                    {:else}
                      Departamento / Cargo
                    {/if}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <select
                        required
                        name="position"
                        id="position"
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        bind:value={$form.position}
                        on:change={handleChange}
                      >
                        <option selected value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="DIRECTION">
                          {#if $t['lang'] === 'en'}
                            Direction
                          {:else}
                            Dirección
                          {/if}
                        </option>
                        <option value="PRODUCTION">
                          {#if $t['lang'] === 'en'}
                            Production
                          {:else}
                            Producción
                          {/if}
                        </option>
                        <option value="HR">
                          {#if $t['lang'] === 'en'}
                            HR
                          {:else}
                            RH
                          {/if}
                        </option>
                        <option value="PURCHASING">
                          {#if $t['lang'] === 'en'}
                            Purchasing
                          {:else}
                            Compras
                          {/if}
                        </option>
                        <option value="SALES">
                          {#if $t['lang'] === 'en'}
                            Sales
                          {:else}
                            Ventas
                          {/if}
                        </option>
                        <option value="FINANCE">
                          {#if $t['lang'] === 'en'}
                            Finance
                          {:else}
                            Finanzas
                          {/if}
                        </option>
                        <option value="OTHER">
                          {#if $t['lang'] === 'en'}
                            Other
                          {:else}
                            Otro
                          {/if}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="border-t mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Number of employees
                    {:else}
                      Número de empleados
                    {/if}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <select
                        required
                        name="employees"
                        id="employees"
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        bind:value={$form.employees}
                        on:change={handleChange}
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1"> 0-9 </option>
                        <option value="2"> 10-49 </option>
                        <option value="3"> 50-250 </option>
                        <option value="4"> 250-500 </option>
                        <option value="5"> &gt; 500 </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="border-t mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Country
                    {:else}
                      País
                    {/if}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        name="country"
                        id="country"
                        bind:value={$form.country}
                        on:keyup={handleChange}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="border-t mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-16 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      State/Province/Region
                    {:else}
                      Estado/Provincia/Región
                    {/if}
                  </div>
                </div>
                <div class="max-w-md mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        name="state"
                        id="state"
                        bind:value={$form.state}
                        on:keyup={handleChange}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="mr-9 mt-12">
                  <div
                    class="flex flex-row justify-end mb-8 font-bold ml-8 mt-6 text-gray-400"
                  >
                    support@satinapp.org
                  </div>
                </div>
                <div class="terms-and-conditions rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <div>
                        <p class="block mb-2 text-md text-black">
                          {#if $t['lang'] === 'en'}
                            By registering you are accepting the <a
                              target="_blank"
                              href="/app/terms">terms and conditions</a
                            >.
                          {:else}
                            Al registrarse usted está aceptando los <a
                              target="_blank"
                              href="/app/terms">terminos y condiciones</a
                            >.
                          {/if}
                        </p>
                      </div>
                      {#if userIsRegistering}
                        <button
                          on:click={() => {
                            handleSubmit();
                          }}
                          type="button"
                          disabled={isRegistrationDisabled}
                          class="button w-full px-3 py-2 text-white placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none bg-blue-500 hover:bg-blue-700"
                          >{#if $t['lang'] === 'en'}
                            Register
                          {:else}
                            Registrar
                          {/if}</button
                        >
                      {:else}
                        <button
                          on:click={() => {
                            handleSubmit();
                          }}
                          type="button"
                          disabled={isRegistrationDisabled}
                          class="button w-full px-3 py-2 text-white placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none bg-blue-500 hover:bg-blue-700"
                        >
                          {#if $t['lang'] === 'en'}
                            Save
                          {:else}
                            Guardar
                          {/if}
                        </button>
                      {/if}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</div>

<style>
  .terms-and-conditions {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
  .button {
    background-color: #14854f;
  }
</style>
