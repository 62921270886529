import axios from 'axios';

const baseURL = '/.netlify/functions/';

const axiosAPI = axios.create({
  baseURL: baseURL,
});

const getSessionId = () => {
  const cookies = document.cookie.split('=');
  if (cookies.indexOf('confetti_session_id') !== -1) {
    return `Bearer ${cookies[cookies.indexOf('confetti_session_id') + 1]}`;
  } else {
    return false;
  }
};

export const apiRequest = async (method, url, request, headers = {}) => {
  if (getSessionId()) {
    headers = {
      ...headers,
      Authorization: `${getSessionId()}`,
    };
  }
  try {
    const res = await axiosAPI({
      method,
      url,
      data: request,
      headers,
    });
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

const get = (url, headers, request) => apiRequest('get', url, request, headers);
const deleteRequest = (url, request) => apiRequest('delete', url, request);
const post = (url, request) => apiRequest('post', url, request);
const put = (url, request) => apiRequest('put', url, request);
const patch = (url, request) => apiRequest('patch', url, request);

const Netlify = {
  get,
  delete: deleteRequest,
  post,
  put,
  patch,
};
export default Netlify;
