<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';
  export let question;

  let commercial = { tons: 0, km: 0 };
  let lorry = { tons: 0, km: 0 };
  let train = { tons: 0, km: 0 };
  let ship = { tons: 0, km: 0 };
  let aircraft = { tons: 0, km: 0 };

  $: {
    const a = JSON.stringify({
      commercial: commercial,
      lorry: lorry,
      train: train,
      ship: ship,
      aircraft: aircraft,
    });
    saveAnswer(question, a);
  }

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      let ans = parseAnswer(a[question.code].user_value);
      commercial.tons = ans.commercial.tons;
      commercial.km = ans.commercial.km;
      lorry.tons = ans.lorry.tons;
      lorry.km = ans.lorry.km;
      train.tons = ans.train.tons;
      train.km = ans.train.km;
      ship.tons = ans.ship.tons;
      ship.km = ans.ship.km;
      aircraft.tons = ans.aircraft.tons;
      aircraft.km = ans.aircraft.km;
    }
  });
</script>

<div class="answer mt-5 pr-5">
  <div class="col-right">
    <div class="select mb-5">
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Commercial vehicle
          {:else}
            Vehiculo comercial
          {/if}
        </label>
      </div>
      <div class="vehicle-inputs ml-5">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={commercial['tons']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              tons/year
            {:else}
              tn/año
            {/if}
          </label>
        </div>
        <div class="form-outline ml-5">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={commercial['km']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              km transported
            {:else}
              km transportados
            {/if}
          </label>
        </div>
      </div>
    </div>
    <div class="select mb-5">
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Lorry
          {:else}
            Camión
          {/if}
        </label>
      </div>
      <div class="vehicle-inputs ml-5">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={lorry['tons']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              tons/year
            {:else}
              tn/año
            {/if}
          </label>
        </div>
        <div class="form-outline ml-5">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={lorry['km']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              km transported
            {:else}
              km transportados
            {/if}
          </label>
        </div>
      </div>
    </div>
    <div class="select mb-5">
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Train
          {:else}
            Tren
          {/if}
        </label>
      </div>
      <div class="vehicle-inputs ml-5">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={train['tons']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              tons/year
            {:else}
              tn/año
            {/if}
          </label>
        </div>
        <div class="form-outline ml-5">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={train['km']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              km transported
            {:else}
              km transportados
            {/if}
          </label>
        </div>
      </div>
    </div>
    <div class="select mb-5">
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Ship
          {:else}
            Barco
          {/if}
        </label>
      </div>
      <div class="vehicle-inputs ml-5">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={ship['tons']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              tons/year
            {:else}
              tn/año
            {/if}
          </label>
        </div>
        <div class="form-outline ml-5">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={ship['km']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              km transported
            {:else}
              km transportados
            {/if}
          </label>
        </div>
      </div>
    </div>
    <div class="select mb-5">
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Aircraft
          {:else}
            Avión
          {/if}
        </label>
      </div>
      <div class="vehicle-inputs ml-5">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={aircraft['tons']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              tons/year
            {:else}
              tn/año
            {/if}
          </label>
        </div>
        <div class="form-outline ml-5">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={aircraft['km']}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              km transported
            {:else}
              km transportados
            {/if}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .answer {
    display: flex;
    justify-content: space-between;
  }
  .form-outline {
    width: 15%;
  }
  .select {
    align-items: center;
    display: flex;
    justify-items: center;
    justify-content: space-between;
  }
  .vehicle-inputs {
    align-items: center;
    display: flex;
    justify-items: center;
  }
  .form-label {
    align-items: center;
  }
</style>
