<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { getDimensions } from '../services/satin';
  import DimensionBanner from '../components/DimensionBanner.svelte';
  import Navbar from '../components/Navbar.svelte';
  import ScrollButton from '../components/ScrollButton.svelte';
  import Sidebar from '../components/Sidebar.svelte';

  import { t } from '../stores/messages';
  import { dimensions, checkValidStoredObject } from '../stores/dimensions';

  onMount(async () => {
    if (checkValidStoredObject(get(dimension))) {
      const d = await getDimensions();
      d.createdAt = new Date().getTime();
      dimensions.set(d);
    }
  });
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar dimension="results" />
  <div class="w-full overflow-hidden">
    <div class="mx-auto w-11/12 mb-4 p-4 flex flex-row items-center">
      <DimensionBanner dimension="Management" />
    </div>
    <section
      class="mx-auto w-11/12 p-4 text-gray-600 body-font bg-white shadow-xl rounded-lg"
    >
      <div class="flex-column mr-16 mt-5 ml-10 text-justify">
        <div>
          {#if $t['lang'] === 'en'}
            <h2>G. Business Management</h2>
            <h4 id="company-profile">G0. Company Profile</h4>
            In order to chart a path and a goal to reach, the company must have a
            clearly defined profile. This will facilitate the projection of the company's
            image to potential customers and other stakeholders. This profile describes
            how the company will contribute to its development, society, and environment.
            A clear definition of the company profile will facilitate decision making
            and provide flexibility, control and better responsiveness. It is essential
            that planning and all efforts are aligned to this profile to avoid deviations
            and contribute to the company's growth and development efficiently and
            harmoniously according to its values.
          {:else}
            <h2>G. Gestión Empresarial</h2>
            <h4 id="company-profile">G0. Perfil de la empresa</h4>
            Con la finalidad de trazar un camino y una meta que alcanzar es indispensable
            que la empresa tenga claramente definido su perfil. Esto facilitará la
            proyección de la imagen de la empresa hacia los clientes potenciales
            y a otras partes interesadas. Este perfil definirá la manera en la que
            la compañía contribuirá a su propio desarrollo, así como también al desarrollo
            de la sociedad y medio ambiente. Una clara definición del perfil de la
            empresa facilitará a la toma de decisiones y proporcionará flexibilidad,
            control y una mejor capacidad de reacción a la empresa. Es fundamental
            que la planeación y todos los esfuerzos estén alineados a este perfil
            para evitar desvíos y contribuir al crecimiento y desarrollo de la empresa
            de manera eficiente y de forma congruente con sus valores.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G01. Mission, vision and values</h5>
            The mission, vision and values give the company a purpose, a function
            and justify its existence. The mission determines the guidelines to achieve
            a goal and sets out the company's reasons. The vision defines the goal
            to be achieved, the objective for which all company members are committed
            and unite their efforts towards the same common goal. Finally, corporate
            values represent the principles that drive the company's activities.
            In this sense, all the company's employees must know and share the mission,
            vision and values, which will allow them to unify their efforts and trace
            a path towards the same goal.
          {:else}
            <h5>G01. Misión, visión y valores</h5>
            La misión visión y valores, proporcionan a la empresa un propósito, una
            función y justifican su existencia. La misión determina las pautas a
            seguir para alcanzar una meta y expone la razón de ser de la empresa.
            La visión define la meta que se quiere alcanzar, el objetivo por el cual
            todos los integrantes de la empresa se comprometen y unen esfuerzos hacia
            un mismo fin común. Por su parte, los valores empresariales representan
            los principios que impulsan las actividades. En este sentido, es necesario
            que todos los empleados de la empresa conozcan y compartan la misión,
            visión y valores, lo que permitirá unificar esfuerzos y trazar un camino
            hacia el mismo objetivo.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G02. Social and environmental objectives</h5>
            It is necessary to define the place of society, environment, and sustainability
            within the company profile. Then, specify the commitment and how to contribute
            to the development. This will facilitate the company's integration as
            a community member and strengthen ties to make it more resilient and
            permanent.
          {:else}
            <h5>G02. Objetivos sociales y ambientales</h5>
            Como miembro de una sociedad, es necesario definir en el perfil el lugar
            que la sociedad, el medio ambiente y la sustentabilidad ocupan dentro
            de la empresa. Especificar su compromiso y la manera en la cual se planea
            contribuir con su desarrollo. Esto contribuirá a la integración de la
            empresa como miembro de la comunidad, fortaleciendo lazos que le brindarán
            mayor resiliencia y permanencia.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="automation">G1. Automation</h4>
            <h5>G11. Automated processes</h5>

            Automation systems help control and monitor the performance of
            repetitive and mechanical tasks, reducing human intervention. As a
            result, automation in processes will provide the company with
            greater productivity and performance, higher quality and homogeneity
            in the activities carried out. This also can facilitate data
            collection, optimising the resources used, reducing the risk of
            accidents, improving working conditions, and reducing production
            costs. For this reason, the company should have a certain percentage
            of automated processes, depending on the possibilities and
            activities carried out.
          {:else}
            <h4 id="automation">G1. Automatización</h4>
            <h5>G11. Procesos automatizados</h5>

            Los sistemas de automatización son útiles para controlar y
            monitorear la realización de una tarea repetitiva y mecánica,
            reduciendo la intervención humana. La automatización en los procesos
            proporcionará a la empresa mayor productividad y rendimiento, mayor
            calidad y homogeneidad en las actividades realizadas, facilita la
            recopilación de datos, optimiza el uso de recursos, disminuye
            riesgos de accidentes y mejora las condiciones laborales, así como
            también reduce costos de producción. Por esta razón, es conveniente
            que la empresa cuente con un cierto porcentaje de procesos
            automatizados, según las posibilidades y las actividades realizadas.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G12. Efficient technology</h5>

            Another way to reduce the production costs and the environmental
            impact could be integrating technologies that make the resources
            such as raw materials, water, electricity, fuels more efficient.
          {:else}
            <h5>G12. Tecnología eficiente</h5>

            Es conveniente que la empresa busque integrar tecnologías que hagan
            mas eficiente el uso de recursos como materias primas, agua,
            electricidad, combustibles, etc. De esta manera se reducen costos de
            producción y se reduce el impacto ambiental.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G13. New technologies</h5>

            To gradually incorporate new technologies and automated systems, it
            is advisable to have an integration plan that establishes goals and
            periodicity in the renewal and maintenance of equipment. This will
            contribute to a better performance in the medium and long term.
          {:else}
            <h5>G13. Nuevas tecnologías</h5>

            Para la integración paulatina de nuevas tecnologías y sistemas
            automatizados es conveniente contar con un plan de integración el
            cual establezca objetivos y periodicidad en la renovación y
            mantenimiento de equipo. Esto contribuirá a un mejor desempeño
            productivo a mediano y largo plazo.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Wet Processing Guidebook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Solidaridad network, The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Certifications in the textile industry - List of
                          international suppliers of inputs and sustainable
                          technology - List of international platforms and
                          groupings - List of useful tools for environmental
                          management in the textile industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/nieuws/2020/solidaridad-guidebook-wet-processing.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/-/media/imvo/files/<br
                            />kleding/nieuws/2020/<br />solidaridad-guidebook<br
                            />-wet-processing.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="manufacturing-management">G2. Manufacturing management</h4>
            Product quality control, efficiency, and processes standardisation are
            crucial aspects of the company's development. Therefore, it is essential
            to implement policies and management systems that systematically guide
            the actions and efforts to achieve better outcomes.
          {:else}
            <h4 id="manufacturing-management">G2. Gestión y manufactura</h4>
            El control de calidad en los productos, así como la eficiencia y la estandarización
            de procesos es un aspecto crucial en el desarrollo de la empresa, por
            lo cual es importante implementar políticas y sistemas de gestión que
            guíen las acciones y los esfuerzos realizados de manera metódica para
            conseguir mejores resultados.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G21. Quality management</h5>

            Quality management is the systematisation of the company's processes
            to plan, execute, control, and evaluate its different activities to
            improve procedures constantly. To standardise quality management
            procedures, the company should have a policy defining the actions to
            be carried out to improve the quality of processes and products.
          {:else}
            <h5>G21. Gestión de calidad</h5>

            La gestión de calidad es la sistematización de los procesos de la
            empresa para planear, ejecutar, controlar y evaluar sus distintas
            actividades, con el objetivo de mejorar los procedimientos de forma
            constante. Con la finalidad de estandarizar los procedimientos de
            gestión de calidad, es conveniente que la empresa posea una política
            en la cual se definan y regulen las acciones que se deben llevar a
            cabo para mejorar la calidad de procesos y productos.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Improving working conditions and productivity in the
                          garment industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (1998)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Efficient materials storage and handling - Practical
                          workstation - Productive machine safety, maintenance
                          and environmental control - Good lighting for quality
                          products - Productivity techniques
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/wcmsp5/groups/public/---ed_protect/---protrav/---safework/documents/instructionalmaterial/wcms_228220.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/<br />wcmsp5/groups/public/<br
                            />---ed_protect/---protrav<br
                            />/---safework/documents<br
                            />/instructionalmaterial<br />/wcms_228220.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G22. Manufacturing management models</h5>

            A quality management model aims to strengthen the production process
            and increase the company's competitiveness. Different manufacturing
            management models depend on the company's problems and needs. In
            short, it is an essential tool for the good performance of any
            organisation, regardless of its business line or size. These models
            can be integrated into the production process, as in the case of ISO
            9001 certification, or as part of the business philosophy, promoting
            all activities carried out through management systems such as LEAN,
            which focuses on the continuous improvement of processes, customer
            satisfaction and market requirements.
          {:else}
            <h5>G22. Modelos de gestión de manufactura</h5>

            Un modelo de gestión de calidad busca fortalecer el proceso
            productivo y aumentar la competitividad de la empresa. Existen
            diversos modelos de gestión de manufactura en función de las
            problemáticas y necesidades de la empresa. En definitiva, es una
            herramienta esencial para el buen desempeño de cualquier
            organización, independientemente de su giro o tamaño. Estos modelos
            se pueden integrar a las actividades de la empresa como una
            herramienta puntual en el proceso productivo, tal es el caso de la
            certificación ISO 9001; o bien como parte de la filosofía
            empresarial impulsando todas las actividades realizadas mediante
            sistemas de gestión como LEAN, que se enfoca en la mejora continua
            de los procesos, la satisfacción del cliente y las exigencias del
            mercado.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Lean and Green strategy: the Lean and Green House and
                          maturity deployment model
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Verrier, B., Rose, B., & Caillaud, E. (2016)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Implementation structure to a Lean methodology based
                          on the seeking and eradication of wastes in production
                          processes.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi-org.ezproxy2.utwente.nl/10.1016/j.jclepro.2015.12.022"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi-org.ezproxy2<br
                            />.utwente.nl/10.1016/<br />j.jclepro.2015.12.022</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Performance measurement and lean maturity: congruence
                          for improvement
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Zanon, L. G., Ulhoa, T. F., & Esposto, K. F. (2021)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Adoption of Lean practices and maturity level in a
                          production system
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1080/09537287.2020.1762136"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1080<br
                            />/09537287.2020.1762136</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 9001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Shakoor, Wasim (2015)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal quality control audits
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://dx.doi.org.ezproxy2.utwente.nl/10.13140/RG.2.2.12590.20809"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://dx.doi.org.<br />ezproxy2.utwente.nl<br
                            />/10.13140/RG.2.2.<br />12590.20809</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 9001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          DNV GL
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal quality control audits
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.dnv.us/Images/ISO-90012015Checklist_tcm14-57745.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.dnv.us/<br />Images/ISO-900120<br
                            />15Checklist_tcm14<br />-57745.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 9001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          GrupoACMS
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist para auditorías internas en material de
                          control de calidad
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.grupoacms.com/descargadocumentosgratis/check_list_auditoria_9001.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.grupoacms<br
                            />.com/descargadocumentos<br />gratis/check_list<br
                            />_auditoria_9001.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 9001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Hedera
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist para auditorías internas en material de
                          control de calidad
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://hederaconsultores.com/docs/Check-list-auditoria-ISO-9001-2015.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://hederaconsultores.com<br
                            />/docs/Check-list-auditoria<br
                            />-ISO-9001-2015.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G23. Documentation of production processes</h5>

            To improve the traceability of products, it is essential to keep
            proper document control specifying the batch number, date and other
            associated details. In this way, in case of any doubt or question
            concerning the production, it will be easier to identify the
            operating conditions and document them properly.
          {:else}
            <h5>G23. Documentación de procesos productivos</h5>

            Para mejorar la trazabilidad de los productos es indispensable
            llevar un correcto control de documentos que especifiquen, el numero
            de lote, la fecha y demás detalles asociados. De esta manera, en
            caso de presentarse alguna duda o cuestión referente a la
            producción, será más fácil identificar las condiciones de operación
            y documentarlo debidamente.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G24. Product shipment</h5>

            It is essential to keep strict product shipments control, specifying
            the date, destination, shipping conditions, production conditions
            and any other information that allows product traceability.
            Furthermore, in the case of shipments abroad, it is necessary to
            keep up to date with customs requirements and comply fully with
            them.
          {:else}
            <h5>G24. Envío de productos</h5>

            Es indispensable llevar un estricto control de envío de productos,
            que especifiquen fecha, destino, condiciones de envío, condiciones
            de producción y cualquier otra información que permita la
            trazabilidad del producto. En caso de embarques al extranjero, es
            preciso mantenerse actualizado de los requisitos aduanales y dar
            total cumplimiento.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="administrative-management">G3. Business management</h4>
            It is advisable to carry out a periodic programme of audits that evaluate
            the different areas of the company. These audits can be internal or external
            inspections focused on occupational health and safety, quality management,
            inventory control, environmental management, finance and accounting and
            human rights and labour. The audits should trigger the internal analysis
            to encourage a perspective change in how activities are carried out,
            thus leading to constant process improvement.
          {:else}
            <h4 id="administrative-management">G3. Gestión administrativa</h4>
            Es conveniente realizar un programa periódico de auditorías que evalúen
            las diferentes áreas de la empresa. Estas auditorias pueden ser inspecciones
            internas o externas enfocadas a áreas como, seguridad e higiene en el
            trabajo, gestión de calidad, control de inventarios, gestión ambiental,
            financieras y contables, derechos humanos y labores, entre otras. Los
            resultados de las auditorias deben detonar al análisis interno, para
            fomentar un cambio de perspectiva sobre la forma en que se realizan las
            actividades y conducir así a la mejora constante de los procesos.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G31. Internal audits</h5>

            Staff members conduct internal audits under a systematic procedure
            established in advance. This procedure should specify the type of
            audit to be performed, the frequency and scope, and find a procedure
            and follow-up actions. This is a handy resource in implementing
            strategies on an ongoing basis. It allows assessing whether the
            procedure has been appropriately implemented and is active and
            aligned with the organisation's profile. Internal audits should also
            periodically update the applicable legislation and verify that it
            complies. The selection of auditors should ensure adequate
            competence, objectivity and process impartiality. It is also crucial
            to properly document all audits, detailing the results obtained.
            This will provide the company with quality information,
            strengthening the decision-making process.
          {:else}
            <h5>G31. Auditorías internas</h5>

            Las auditorías internas son realizadas por miembros del personal
            bajo un procedimiento sistemático establecido previamente. Este
            procedimiento debe establecer el tipo de auditoria que se debe
            realizar, la frecuencia y el alcance, así mismo, establecer un
            procedimiento y acciones de seguimiento. Este es un recurso
            sumamente útil en la implementación de estrategias de manera
            continua ya que permite evaluar si el procedimiento se ha
            implementado adecuadamente y se mantiene activo, así como también si
            se encuentra alineado con el perfil de la organización. Así mismo,
            las auditorias internas deben actualizar periódicamente la
            legislación aplicable y verificar que se cumplimiento. La selección
            de los auditores debe asegurar la adecuada competencia, objetividad
            e imparcialidad del proceso. Así mismo, es crucial documentar
            adecuadamente todas las auditorias realizadas detallando los
            resultados obtenidos. Esto permitirá que la empresa cuente con
            información de calidad que fortalecerá la toma de decisiones.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO Consultant
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal environmental management
                          audits ISO14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://isoconsultantkuwait.com/2020/10/09/iso-140012015-checklist/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://isoconsultantkuwait<br
                            />.com/2020/10/09/iso-<br />140012015-checklist/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Hedera
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist para auditorías internas en material
                          ambiental
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://hederaconsultores.com/docs/Check-list-auditor%C3%ADa-ISO-14001-2015.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://hederaconsultores<br
                            />.com/docs/Check-list-<br />auditor%C3%ADa-ISO-<br
                            />14001-2015.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Hedera
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist para auditorías internas en material
                          ambiental
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://pdfcookie.com/download/check-list-rio-auditoria-iso-14001-68v41ynr37vg"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://pdfcookie.com/<br />download/check-list-<br
                            />rio-auditoria-iso-<br />14001-68v41ynr37vg</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Shakoor, Wasim, 2018
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal occupational health and
                          safety audits ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.13140/RG.2.2.35305.95844"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.13140<br
                            />/RG.2.2.35305.95844</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Worldwide Responsible Accredited Production
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          WRAP
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Pre-audit self-assessment - Risk Assessment -
                          Working Hours Action Plan - Risk management guide -
                          Facilities' Handbook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://wrapcompliance.org/certification/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://wrapcompliance<br />.org/certification/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G32. Continuous improvement</h5>
            To implement continuous improvement strategies, it is essential to establish
            clear objectives and a procedure that leads the actions. Likewise, quantifying
            results is crucial to developing and defining key performance indicators.
            Furthermore, it is also necessary to keep a results record and a monitoring
            system to analyse the generation of preventive and corrective actions.
            In this sense, it is suggested to implement the following method. - Reduce
            production waste by 5% within nine months Establish a scale to measure
            the progress of the target.
            <div>
              {#if $t['lang'] === 'en'}
                <img src={'/images/pdca-en.png'} alt="pdca" />
              {:else}
                <img src={'/images/pdca-es.png'} alt="pdca" />
              {/if}
            </div>
            <div>Example:</div>
            <div>
              <span class="font-bold">Hotspot:</span> Impact category with low performance.
              Ex: Waste (A2)
            </div>
            <div>
              <span class="font-bold">Objective:</span> What is to be achieved. Define
              the goal precisely and realistically.
            </div>
            <div>
              <li>Develop and implement a Waste Management Plan.</li>
              <li>Waste reduction at source</li>
              <li>Waste sorting</li>
              <li>Identify usable waste and method of recovery</li>
              <br />
              <div>
                <span class="font-bold">Goal:</span> Quantification of the objective.
              </div>
              <li>Develop Management Plan in four months</li>
              <li>Operational plan in one year</li>
              <li>Sorting and storing waste correctly within six months</li>
              <li>Sale or donation of usable waste within six months</li>
              <li>Reduce production waste by 5% within nine months</li>
              <br />
              <div>
                <span class="font-bold">Indicator:</span> Unit of measurement of
                the target.
              </div>
              <li>Percentage of plan development and implementation</li>
              <li>Percentage of wastes sorted correctly</li>
              <li>Percentage of wastes sold or donated</li>
              <li>Percentage of wastes reduction</li>
            </div>
          {:else}
            <h5>G32. Mejora continua</h5>

            Para la implementación de estrategias de mejora continua es
            indispensable contar con un procedimiento y metodología a seguir,
            con la finalidad de establecer metas claras y el camino a seguir.
            Como parte de este procedimiento es crucial el desarrollo y
            establecimiento de indicadores clave de rendimiento, que faciliten
            la cuantificación de los resultados de manera periódica. Así mismo
            es necesario llevar un correcto registro de los datos y resultados
            del seguimiento realizado para facilitar el análisis y la generación
            de acciones preventivas y correctivas. En este sentido se sugiere
            implementar el siguiente método.
            <div>
              {#if $t['lang'] === 'en'}
                <img src={'/images/pdca-en.png'} alt="pdca" />
              {:else}
                <img src={'/images/pdca-es.png'} alt="pdca" />
              {/if}
            </div>
            <div>Ejemplo:</div>
            <div>
              <span class="font-bold">Punto crítico:</span> Categoría de impacto
              con bajo desempeño. Ej.:Residuos (A2)
              <div />
              <div>
                <span class="font-bold">Objetivo:</span> Lo que se busca conseguir.
                Definir el objetivo de manera específica y realista.
              </div>
              <li>Desarrollar e implementar Plan de Manejo de Residuos</li>
              <li>Reducción de residuos en fuente</li>
              <li>Clasificación de residuos</li>
              <li>
                Identificar residuos aprovechables y método de aprovechamiento
              </li>
              <br />
              <div>
                <span class="font-bold">Meta:</span> Cuantificación del objetivo.
              </div>
              <li>Desarrollar el Plan de Manejo en cuatro meses</li>
              <li>Plan operativo en un año</li>
              <li>
                Clasificación y almacenamiento de residuos correctamente en seis
                meses
              </li>
              <li>Venta o donación de residuos aprovechables en seis meses</li>
              <li>Reducir los residuos de producción en 5% nueve meses</li>
              <br />
              <div>
                <span class="font-bold">Indicador:</span> Unidad de medida del objetivo.
                Establecer una escala que permita medir el avance de la meta.
              </div>

              <li>Porcentaje de desarrollo e implementación del plan</li>
              <li>Porcentaje de residuos clasificados correctamente</li>
              <li>Porcentaje de residuos vendidos o donados</li>
              <li>Porcentaje de reducción de residuos</li>
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/investment<br
                            />/due-diligence-guidance-<br
                            />for-responsible-business<br />-conduct.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G33. Traceability</h5>

            Product traceability is an indispensable factor for any company and
            production chain. It refers to the detailed product tracking,
            including the origin and raw materials manufacture, production and
            distribution until they reach the final consumer. In addition, a
            correct traceability system must guarantee quality compliance with
            safety and hygiene standards and regulations. For this purpose, it
            is handy to implement automated systems facilitating data collection
            and making it more efficient and complete.
          {:else}
            <h5>G33. Trazabilidad</h5>

            La trazabilidad de productos es un factor indispensable para
            cualquier empresa y cadena productiva. Se refiere al seguimiento
            detallado de los productos incluyendo el origen y manufactura de
            materias primas, producción y distribución hasta su llegada al
            consumidor final. Un correcto sistema de trazabilidad debe dar
            garantía de calidad, cumplimiento de estándares de seguridad e
            higiene en el cumplimiento de la normativa. Para este fin es de suma
            utilidad la implementación de sistemas automatizados que faciliten
            la recolección de información haciéndola más eficiente y completa.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />investment/due-diligence<br
                            />-guidance-for-responsible<br
                            />-business-conduct.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G34. Document control</h5>

            It is recommended that the company implement procedures to handle
            official documents correctly. To a good change control, it is
            necessary to have a detailed system for papers and formats updating
            and approval. In this way, the company can ensure that the correct
            versions of its documents are available at the use point, avoiding
            the unintended use of obsolete versions. This will prevent errors
            and make processes more efficient. In addition, it is necessary to
            ensure that documents remain easily identifiable and to control
            their distribution.
          {:else}
            <h5>G34. Control de documentos</h5>

            Es recomendable que la empresa desarrolle e implemente
            procedimientos para el correcto manejo de documentos oficiales. Es
            necesario contar con un procedimiento detallado para la emisión,
            control de cambios, actualización y aprobación de documentos y
            formatos. De esta manera la empresa podrá asegurarse de que estén
            disponibles las versiones correctas de sus documentos en los puntos
            de uso, evitando el uso no intencionado de versiones obsoletas. Esto
            evitará errores y hará los procesos más eficientes. Es necesario
            asegurar que los documentos permanezcan fácilmente identificables y
            controlar su distribución.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="security">G4. Security</h5>
            <div>
              To ensure the well-being and security of the company, its
              employees, and its assets, it is essential to have strict access
              control to the facilities and have security systems and staff
              guaranteeing the correct implementation of security mechanisms. It
              is recommended to carry out regular assessments of actual or
              potential security risks in the company and ensure adequate
              security.
            </div>
            <br />
            <div>
              If the company contracts external security services, it must
              ensure that its personnel have the professional capacity, training
              and adequate equipment to conduct their activities correctly
              without disrespecting human rights. In this regard, it is
              advisable to have a manual that defines security personnel's
              functions according to the company's profile and values following
              international human rights standards. It is also desirable to have
              a procedure for recording incidents concerning security staff,
              including mechanisms for receiving and resolving complaints from
              employees, community members, or external individuals.
            </div>
          {:else}
            <h5 id="security">G4. Seguridad</h5>
            <div>
              Para asegurar el bienestar y seguridad de la empresa, sus
              empleados y sus bienes es fundamental tener un control estricto de
              acceso a las instalaciones, así como sistemas y personal de
              seguridad que garanticen la implementación correcta de los
              mecanismos de seguridad. Se recomienda llevar a cabo evaluaciones
              periódicas de los riesgos de seguridad reales o potenciales que se
              pueden presentar en la empresa y garantizar que las medidas de
              seguridad sean adecuadas.
            </div>
            <br />
            <div>
              Si la empresa contrata servicios de seguridad externos, es
              necesario asegurar que su personal cuente con la capacidad
              profesional, capacitación y equipos adecuados para realizar sus
              actividades correctamente sin transgredir los derechos humanos. En
              este sentido es conveniente contar con un manual que defina las
              funciones y normas de conducta del personal de seguridad según el
              perfil y valores de la empresa, así como las normas
              internacionales de derechos humanos. Así mismo, es conveniente
              contar con un procedimiento para el registro de incidentes
              referentes a la seguridad y conducta del personal de seguridad que
              incluya mecanismos para la recepción y resolución de quejas tanto
              de los empleados, miembros de la comunidad o individuos externos.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Worldwide Responsible Accredited Production
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          WRAP (2021)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Facilities' Handbook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://wrapcompliance.org/certification/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://wrapcompliance.org<br />/certification/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="sustainability-strategies">
              G5. Sustainability strategies
            </h4>
            To improve the company's sustainable performance, it is essential to
            establish strategies to generate a holistic improvement, including all
            the organisation areas and social, economic, environmental and management
            aspects. To this end, it is necessary to have an improvement plan establishing
            actions, guidelines, time frames and other factors involved in programmes.
            These efforts will contribute to the transition towards more sustainable
            production patterns.
          {:else}
            <h4 id="sustainability-strategies">
              G5. Estrategias de sustentabilidad
            </h4>
            Para mejorar el desempeño sostenible de la empresa, es fundamental el
            establecimiento de estrategias encaminadas a generar una mejora holística
            en todas las áreas de la organización, incluyendo aspectos sociales,
            económicos, ambientales y de gestión empresarial. Para este fin es necesario
            contar con un plan de mejora que establezca acciones, pautas, marco de
            tiempo y otros factores involucrados en programas que contribuyan en
            la transición hacia patrones de producción más sostenibles.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G51. Sustainability management</h5>

            Adequate sustainability management in an organisation requires
            strategies and mechanisms to identify and evaluate improvement areas
            and establish actions. In this sense, the company must generate
            strategies and activities aligned with its mission, vision and
            values. These actions may include setting goals, internal audits and
            social and environmental programmes. Thus, depending on the
            company's profile, it should seek to improve its labour practices
            and environmental performance through proper business management,
            which will be reflected positively in the economic area.
            Furthermore, all actions and procedures must be systematised and
            evaluated to know how operations impact the environment and
            stakeholders.
          {:else}
            <h5>G51. Gestión de sustentabilidad</h5>

            Para una gestión adecuada de la sustentabilidad en una organización
            es necesario contar con estrategias y mecanismos que contribuyan a
            identificar y evaluar áreas de mejora, así como a establecer
            acciones de mejora. En este sentido, la empresa debe generar
            estrategias y acciones alineadas a su misión, visión y valores.
            Estas acciones pueden incluir el establecimiento de metas,
            auditorías internas, programas sociales o programas ambientales,
            entre otros. Es así que, según el perfil de la empresa, debe buscar
            en todo momento mejorar sus prácticas laborales y su desempeño
            ambiental mediante una correcta gestión empresarial que se verá
            reflejado positivamente en el área económica. Es imprescindible que
            todas las acciones y procedimientos sean sistematizados y que los
            resultados sean evaluados, con el fin de conocer de qué manera las
            operaciones están impactando al medio ambiente y a los grupos de
            interés.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD Guideliness for Multinational Enterprises
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines establishing a code of responsible
                          business conduct, also applies to national companies -
                          Identify and mitigate risks, Human Rights,
                          Environment, Anti-corruption, Consumer interests,
                          Science and technology
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://mneguidelines.oecd.org/guidelines/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://mneguidelines.oecd<br />.org/guidelines/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />investment/due-diligence<br
                            />-guidance-for-responsible<br
                            />-business-conduct.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due diligence Guidance for Responsible supply chains
                          in the Garment and Footwear sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines to improve the supply chain in the
                          textile industry, the relationship with stakeholders
                          and labor practices. - Applicable for Small and
                          medium-sized enterprises (SMEs) - Presents the most
                          common risks in the textile industry and
                          recommendations to avoid them
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/industry/inv/mne/responsible-supply-chains-textile-garment-sector.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />industry/inv/mne/responsible<br
                            />-supply-chains-textile-<br />garment-sector.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Dutch Agreement on Sustainable Garments and Textile
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Voluntary agreement signed by Dutch textile
                          companies with productive activities in different
                          countries - It seeks to generate a commitment and
                          actions to improve human and labor rights, health and
                          safety of workers and protection of the environment. -
                          Provides useful tools for the integration of actions
                          and policies.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/en/garments-textile</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Sustainable Apparel
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Richard, T., & Stanbury, P. (2021)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Presents the key aspects for good sustainable
                          performance in the fashion industry - Includes
                          environmental and social aspects - Challenges and
                          opportunities in cotton and viscose production
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://innovationforum.s3.amazonaws.com/uploads/spina/attachment/file/1062/Sustainable_Apparel_Barometer.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://innovationforum.s3<br
                            />.amazonaws.com/uploads/spina<br
                            />/attachment/file/1062/<br
                            />Sustainable_Apparel_<br />Barometer.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Performance standars
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standards and guidelines to improve performance in:
                          risk management, working conditions, resource
                          efficiency, community, cultural heritage - Performance
                          Standards on Environmental Sustainability and Social -
                          Guidance Notes: Performance Standards on Social &
                          Environmental Sustainability
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/performance-standards"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps<br
                            />/wcm/connect/topics_ext<br
                            />_content/ifc_external_<br
                            />corporate_site/sustainability<br
                            />-at-ifc/policies-standards<br
                            />/performance-standards</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Performance Standards on Environmental and Social
                          Sustainability
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Articulates the strategic commitment of the
                          Corporation with sustainable development and risk
                          management. It comprises the IFC Policy and
                          Performance Standards on Environmental and Social
                          Sustainability, and the Access to Information Policy.
                          - Guides how to identify risks and impacts, to help
                          avoid, mitigate, and manage risks, including
                          stakeholder engagement and client disclosure
                          obligations about project-level activities.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/publications/publications_handbook_pps"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/wcm<br
                            />/connect/topics_ext_content<br
                            />/ifc_external_corporate_site<br
                            />/sustainability-at-ifc/<br
                            />publications/publications<br />_handbook_pps</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Environmental and Social Management System
                          Implementation Handbook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2015)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - The Implementation Manual is intended for companies
                          wishing to implement a system for the management of
                          risks and impacts of safety (OHS), labor and community
                          - Self-Assessment and Improvement Guide - Toolkit for
                          Environmental and Social Management System
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/publications/publications_handbook_esms-general"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/wcm<br
                            />/connect/topics_ext_content<br
                            />/ifc_external_corporate_<br
                            />site/sustainability-at-<br />ifc/publications/<br
                            />publications_handbook<br />_esms-general</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          TEXTILES & APPAREL -Environmental and Social
                          Management System Implementation Handbook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2014)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - The Implementation Manual for Textiles and Apparel
                          is intended for companies wishing to implement a
                          management system. The Manual provides step-by-step
                          instructions on developing and implementing an
                          occupational, occupational and community health and
                          safety management, risks and impacts system. - Toolkit
                          and case studies, Textile Industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/publications/esms_implementation_handbook-textiles_apparel"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps<br
                            />/wcm/connect/topics_ext<br
                            />_content/ifc_external_<br
                            />corporate_site/sustainability<br
                            />-at-ifc/publications/<br />esms_implementation_<br
                            />handbook-textiles_apparel</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Tools Remedies Towards a Better Workplace
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Tools to improve the sustainable performance of the
                          textile industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile/tools/child-labour/tools#tools"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/en/garments-textile<br
                            />/tools/child-labour<br />/tools#tools</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G52. Circular economy</h5>

            The circular economy is the future of the textile industry but also
            one of its most significant challenges. For this reason, it is
            advisable to identify the opportunities in the production process to
            integrate circularity strategies gradually. This will prepare the
            company to cope with the major changes in this area, making it more
            resilient and flexible. Furthermore, the transition to circular
            practices implies repercussions along the entire value chain, so the
            company should include circular patterns on time, avoiding the risk
            of market displacement. Possible actions are increasing the recycled
            materials use and the products' recyclability as well as extending
            producer responsibility.
          {:else}
            <h5>G52. Economía circular</h5>

            La economía circular es el futuro de la industria textil, pero
            también uno de sus mas grandes retos. Por esta razón es conveniente
            evaluar las oportunidades que presenta el proceso de producción para
            integrar estrategias de circularidad de manera paulatina. Esto
            preparará a la empresa para afrontar los grandes cambios que se
            presentan es este tema, brindándole mayor resiliencia y
            flexibilidad. La transición hacia practicas circulares implica
            repercusiones a lo largo de toda la cadena de valor, por lo cual es
            recomendable que la empresa realice esfuerzos por transitar a
            patrones circulares oportunamente, evitando riesgos de
            desplazamiento de mercado. Algunas de las posibles acciones a
            realizar es el incremento en el uso de materiales de origen
            reciclado, el aumento en la reciclabilidad de los productos
            generados y la responsabilidad extendida del productor.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Sustainability and Circularity in the Textile Value
                          Chain
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          United Nations Environment Programme (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Analyzes environmental and socioeconomic and
                          socio-economic hotspots along the entire textile value
                          chain - Sustainability and circularity in the textile
                          value chain
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oneplanetnetwork.org/new-report-launched-sustainability-and-circularity-textile-value-chain"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oneplanetnetwork<br
                            />.org/new-report-launched-<br
                            />sustainability-and-<br />circularity-textile<br
                            />-value-chain</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Fossil Fashion
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Changing Markets Foundation (2021)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Dependence on fossil fuels in the textile industry -
                          Synthetic fiber production for the textile industry -
                          Landfill, incineration and recycling - Recommendations
                          for textile producers, retailers and consumers
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://changingmarkets.org/wp-content/uploads/2021/01/FOSSIL-FASHION_Web-compressed.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://changingmarkets<br
                            />.org/wp-content/uploads<br />/2021/01/FOSSIL-<br
                            />FASHION_Web-compressed.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          A New Textiles Economy: Redesigning Fashion’s Future
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Ellen Mac Arthur Foundation (2017)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Phase out substances of concern and microfibre
                          release - Transform the way clothes are designed,
                          sold, and used - Improve recycling by transforming
                          clothing design, collection, and reprocessing - Make
                          effective use of resources and move to renewable
                          inputs
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://ellenmacarthurfoundation.org/a-new-textiles-economy"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://ellenmacarthurfoundation<br
                            />.org/a-new-textiles-economy</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Textiles and the environment in a circular economy
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          European Topic Centre Waste and Materials in a Green
                          Economy (2019)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Towards a circular textiles production and
                          consumption system - Production and consumption trends
                          - Collection, reuse, recycling and waste management
                          trends in Europe - Environmental and social impacts of
                          textiles - Circular business models and policy options
                          for textiles
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Health and Safety Committee
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www-eionet-europa-eu.ezproxy2.utwente.nl/etcs/etc-wmge/products/etc-reports/textiles-and-the-environment-in-a-circular-economy"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www-eionet-europa<br
                            />-eu.ezproxy2.utwente.nl<br
                            />/etcs/etc-wmge/products<br
                            />/etc-reports/textiles-<br
                            />and-the-environment-in<br />-a-circular-economy</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G53. Social and environmental certifications</h5>

            The global trend in fashion and textile is to increase sustainable
            performance, improve working conditions, and reduce the
            environmental impact. This is why companies are raising the
            requirements and demands on their suppliers, requesting social and
            environmental certifications that guarantee optimum performance. In
            this sense, obtaining these certifications can be an extremely
            complicated task for many small and medium-sized companies, as it
            requires significant changes and production processes
            restructuration. For this reason, the company should seek to
            gradually integrate certifications and voluntary standards to
            improve the organisation's performance. This will allow the company
            to respond to market demands, offering more flexibility and
            resilience.
          {:else}
            <h5>G53. Certificaciones sociales y ambientales</h5>

            La tendencia global de los grandes productores y distribuidores de
            moda y textiles es incrementar su desempeño sostenible, mejorando
            las condiciones laborales y disminuyendo el impacto ambiental
            generado. Es por esto que cada vez más, las empresas incrementan los
            requisitos y exigencias a sus proveedores, solicitando
            certificaciones sociales y ambientales que garanticen un cierto
            nivel de desempeño. En este sentido, para muchas pequeñas y medianas
            empresas, la obtención de estas certificaciones puede llegar a ser
            una tarea sumamente complicada, ya que exige profundos cambios y
            reestructuración en los procesos productivos. Por tal razón, es
            recomendable que la empresa busque integrar paulatinamente
            certificaciones y estándares voluntarios que contribuyan a mejorar
            el desempeño de la organización. Esto proporcionará a la empresa
            mayor capacidad de respuesta ante las exigencias del mercado,
            brindando mas flexibilidad y resiliencia.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Worldwide Responsible Accredited Production
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          WRAP
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Pre-audit self-assessment - Risk Assessment -
                          Working Hours Action Plan - Risk management guide -
                          Facilities' Handbook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://wrapcompliance.org/certification/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://wrapcompliance<br />.org/certification/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO Consultant
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal environmental management
                          audits ISO14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://isoconsultantkuwait.com/2020/10/09/iso-140012015-checklist/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://isoconsultantkuwait<br
                            />.com/2020/10/09/iso-<br />140012015-checklist/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Hedera
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist para auditorías internas en material
                          ambiental
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://hederaconsultores.com/docs/Check-list-auditor%C3%ADa-ISO-14001-2015.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://hederaconsultores<br
                            />.com/docs/Check-list-<br />auditor%C3%ADa-ISO<br
                            />-14001-2015.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 14001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Hedera
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist para auditorías internas en material
                          ambiental
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://pdfcookie.com/download/check-list-rio-auditoria-iso-14001-68v41ynr37vg"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://pdfcookie.com/<br />download/check-list-<br
                            />rio-auditoria-iso-<br />14001-68v41ynr37vg</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Shakoor, Wasim (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal occupational health and
                          safety audits ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.13140/RG.2.2.35305.95844"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.<br
                            />13140/RG.2.2.35305.95844</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G54. Reports</h5>

            One of the most effective tools for business performance
            communication is corporate sustainability reports. The company
            communicates to its customers, suppliers, and community the
            programmes developed and the improvements presented in social and
            environmental matters. To prepare these reports, it is advisable to
            establish a procedure standardising the periodical published
            information according to the company's profile and values and using
            mechanisms to quantify the goals achieved and improvements made.
          {:else}
            <h5>G54. Reportes</h5>

            Una de las herramientas más efectivas para comunicar el desempeño de
            una organización son los reportes empresariales en materia de
            sustentabilidad, mediante los cuales la empresa comunica a sus
            clientes, proveedores y comunidad los programas desarrollados y las
            mejoras presentadas en materia social y ambiental. Para la
            realización de estos reportes en conveniente establecer un
            procedimiento que estandarice la información publicada
            periódicamente, según el perfil y valores de la empresa. Así como
            también establecer mecanismos que contribuyan a la cuantificación de
            las metas alcanzadas y mejoras realizadas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Standards for sustainability reports
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Global Reporting Initiative (GRI)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standards for the preparation of sustainability
                          reports at a global level to publicly report the
                          economic, environmental and social impacts of an
                          organization
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.globalreporting.org/standards/download-the-standards/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.globalreporting<br
                            />.org/standards/download<br />-the-standards/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="legislation-regulation">G6. Legislation and regulation</h4>
            The company must know the applicable legislation on environmental, social
            and financial issues, as well international treaties and agreements.
            This includes guidelines and legislation on human and labour rights,
            occupational health and safety, environmental protection, and anti-corruption.
            It is also necessary to establish an update programme ensuring the company
            complies with the current versions of these guidelines and regulations
            and check that it has the required permits and authorisations to operate.
          {:else}
            <h4 id="legislation-regulation">G6. Legislación y regulación</h4>
            Es fundamental que la empresa conozca a detalle la legislación aplicable
            en material, ambiental, social y financiera, así como los tratados y
            acuerdos internacionales. Esto incluye lineamientos y legislación en
            materia de derechos humanos y laborales, seguridad y salud ocupacional,
            preservación de medio ambiente y lucha contra la corrupción, entre otros.
            Así mismo, es necesario establecer un programa de actualización que asegure
            que la empresa cumple con las versiones vigentes de estos lineamientos
            y nuevas regulaciones, así como corroborar que se cuente con los permisos
            y autorizaciones necesarios para operar.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="corruption-prevention">G7. Corruption prevention</h4>
            One of the goals of international organisations such as the United Nations
            Global Compact and the Organisation for Economic Co-operation and Development
            (OECD) is the prevention of corruption. Accordingly, various guidelines
            have been developed to guide companies and organisations in this process.
          {:else}
            <h4 id="corruption-prevention">G7. Prevención de corrupción</h4>
            Uno de los objetivos de los marcos internacionales como del Pacto Mundial
            de las Naciones Unidas y de la Organización para la Cooperación y el
            Desarrollo Económico (OCDE) es la prevención de corrupción, para lo cual
            se han desarrollado diversas directrices para guiar a las empresas y
            organizaciones en este proceso.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G71. Anti-corruption program</h5>
            <div>
              It is recommended that the company take an active role in the
              fight against corruption. To this end, it is necessary to
              elaborate plans and programmes identifying risks and inappropriate
              behaviour and take mitigating and preventive measures. The company
              should develop and implement a policy that clearly states its
              position against corruption according to its profile and values.
              Procedures should be established to assess the corruption risk
              associated with the company's activities and stipulate guidelines
              and actions promoting business ethics. The policy should also
              specify how to handle requests for payment, participation in
              sponsorships, giving and receiving gifts and engaging in political
              contributions. It is essential to develop indicators and
              benchmarks to determine performance and progress and communicate
              results to the community and stakeholders. In addition, the
              company should inform all employees of its commitment to this
              fight and provide regular training to workers in areas of most
              significant risk. It is advisable to generate a dialogue with
              employees obtaining feedback on the implementation of the actions,
              suspicions of corruption and other signs to detect and prevent
              corruption.
            </div>
            <br />
            <div>
              It is recommended to stimulate dialogue with different
              stakeholders, promote a fair business environment, sharing
              experiences, procedures and challenges with other organisations,
              partners, community, customers and suppliers. It is also necessary
              to establish company policy and applicable regulations in
              contracts and agreements with suppliers and business partners. In
              this sense, it is advisable compliance monitoring with policies
              and programmes through internal and external audits to identify
              irregularities, report them and provide follow-up.
            </div>
          {:else}
            <h5>G71. Programa anticorrupción</h5>
            <div>
              Es recomendable que la empresa adopte un papel activo en la lucha
              contra la corrupción, mediante planes y programas que contribuyan
              a identificar riesgos y comportamientos inadecuados, así como a
              tomar medidas de mitigación y prevención. Para este fin se debe
              desarrollar e implementar una política en la cual la empresa
              exprese claramente su posición contra la corrupción según su
              perfil y valores. Se deben establecer procedimientos para evaluar
              el riesgo de corrupción asociado a las actividades de la empresa y
              estipular las pautas y acciones que promuevan la ética
              empresarial. Así mismo, la política debe especificar la forma de
              manejar las solicitudes de pago, la participación en patrocinios,
              dar y recibir regalos y participar en contribuciones políticas. En
              este sentido es fundamental el desarrollo de indicadores y puntos
              de referencia para determinar el desempeño y avance alcanzado, así
              como también, comunicar los resultados obtenidos a la comunidad y
              partes interesadas. Adicionalmente, la empresa debe informar a
              todos sus empleados del compromiso en esta lucha y ofrecer
              capacitación periódica a los trabajadores de las áreas que
              presenten mayor riesgo. Es conveniente generar un diálogo con los
              empleados, que facilite la retroalimentación acerca de la
              implementación de acciones, sospechas de casos de corrupción y
              otros indicios que contribuyan a la detección oportuna y
              prevención de corrupción.
            </div>
            <br />
            <div>
              Se recomienda estimular el diálogo con los diferentes grupos de
              interés para promover un ambiente de negocio justo, compartir
              experiencias, procedimientos y desafíos con otras organizaciones,
              socios comerciales, comunidad, clientes, proveedores, etc. Así
              mismo es necesario que la empresa indique la política empresarial
              establecida y el reglamento aplicable en los contratos y convenios
              realizados con proveedores y socios comerciales. En este sentido,
              es conveniente supervisar de manera continua el cumplimiento de
              las políticas y programas establecidos, mediante auditorías
              internas y externas que contribuyan a la identificación de
              indicios o irregularidades, reportarlas a la administración y
              proporcionar el seguimiento pertinente.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD Guideliness for Multinational Enterprises
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines establishing a code of responsible
                          business conduct, also applies to national companies -
                          Identify and mitigate risks, Human Rights,
                          Environment, Anti-corruption, Consumer interests,
                          Science and technology
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://mneguidelines.oecd.org/guidelines/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://mneguidelines.<br />oecd.org/guidelines/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.<br />uk/simple-health-<br
                            />safety/risk/risk-<br />assessment-template<br
                            />-and-examples.<br />htm#article</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="transparency-communication">
              G8. Transparency and communication
            </h4>
            To create a more robust value chain, it is essential to promote transparency
            in its operations and establish effective communication channels. This
            will enable the participation of stakeholders, promote honest and fair
            trade and provide the company with a trustworthy image.
          {:else}
            <h4 id="transparency-communication">
              G8. Transparencia y comunicación
            </h4>
            Para generar una cadena de valor más sólida es fundamental impulsar la
            transparencia de las operaciones de la empresa y establecer conductos
            de comunicación efectivos. Esto permitirá la participación de las diferentes
            partes interesadas, fomentará un comercio honesto y justo y proporcionará
            a la empresa una imagen confiable.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G81. Transparency</h5>

            At the beginning of a business relationship, it is essential to
            generate an environment of trust between stakeholders. For this, all
            information about the company must be available for consultation in
            a truthful, valuable and verifiable way. To this end, it is
            advisable to have a corporate transparency policy according to the
            organisation's profile and values. Increasing transparency by going
            beyond the applicable regulations improves competitiveness,
            generating attraction and loyalty from business partners, investors
            and customers. It also enhances the company's stability by
            positively influencing relations with customers and suppliers,
            increasing employee confidence and the retention of skilled staff.
          {:else}
            <h5>G81. Transparencia</h5>

            Al comenzar una relación empresarial es fundamental generar un
            ambiente de confianza entre todas las partes involucradas, para esto
            es necesario que toda la información acerca de la empresa este
            disponible para su consulta, de forma veraz, útil y verificable.
            Para tal efecto, es recomendable contar con una política de
            transparencia empresarial según el perfil y valores de la
            organización. Aumentar la trasparencia de la empresa yendo más allá
            de la normativa aplicable mejora la competitividad ya que genera
            atracción y lealtad de socios comerciales, inversores y clientes.
            Así mismo, favorece la estabilidad de la empresa pues influye
            positivamente en las relaciones con clientes y proveedores, aumenta
            la confianza de los empleados y la permanencia de personal
            capacitado.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due diligence Guidance for Responsible supply chains
                          in the Garment and Footwear sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines to improve the supply chain in the
                          textile industry, the relationship with stakeholders
                          and labor practices. - Applicable for Small and
                          medium-sized enterprises (SMEs) - Presents the most
                          common risks in the textile industry and
                          recommendations to avoid them - Traceability,
                          transparency and communication
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/industry/inv/mne/responsible-supply-chains-textile-garment-sector.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />industry/inv/mne/responsible<br
                            />-supply-chains-textile<br />-garment-sector.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G82. Communication</h5>
            <div>
              To generate reliable and stable relationships with stakeholders,
              it is essential to provide clear, relevant and truthful
              information describing the company's performance. This will dispel
              uncertainties and build trust and loyalty. For this reason, it is
              crucial to create effective internal and external communication
              contributing to the consolidation of the processes and activities
              carried out by the company.
            </div>
            <br />
            <div>
              Internal communication has a considerable influence on the working
              environment, so it is important to generate communication
              mechanisms between all levels and areas of the company, seeking to
              support, motivate and obtain feedback from all employees. On the
              other hand, external communication refers to the interaction with
              other groups or organisations outside the company and contributes
              to establishing solid and productive relationships between the
              stakeholders. Therefore, it is advisable to publish corporate
              reports through electronic or printed media, sharing the company's
              performance, social programmes, and other relevant information.
            </div>
          {:else}
            <h5>G82. Comunicación</h5>
            <div>
              Para generar relaciones confiables y estables con proveedores,
              socios comerciales, clientes y comunidad es indispensable
              proporcionar información clara, relevante y veraz que describa el
              desempeño social y ambiental de la empresa, disipando
              incertidumbres y generando confianza y lealtad. Por esta razón es
              fundamental generar una comunicación interna y externa de manera
              efectiva y que contribuya a la consolidación de los procesos y
              actividades realizados por la empresa.
            </div>
            <br />
            <div>
              La comunicación interna influye considerablemente en el ambiente
              de trabajo de cualquier organización, por lo cual es esencial
              generar mecanismos de comunicación entre todos los niveles y áreas
              de la empresa, buscando apoyar, motivar y obtener
              retroalimentación de todos los empleados. La comunicación externa
              se refiere a la interacción con otros grupos u organismos ajenos a
              la empresa y contribuye al establecimiento de relaciones solidas y
              productivas entre los actores involucrados. Para mejorar la
              comunicación externa es recomendable la publicación periódica de
              informes de desempeño, programas e información relevante mediante
              reportes empresariales, medios electrónicos o impresos.
            </div>
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>G83. Stakeholders</h5>
            <div>
              For a proper engagement of the company with its environment, it is
              essential to have a participation plan involving the entire
              community. This plan should establish mechanisms to identify all
              stakeholders and encourage their participation. It is also
              necessary to identify potential barriers to participation,
              generate conflict resolution strategies, and consider these
              factors in decision-making. It is recommended to implement
              programmes to promote dialogue with stakeholders and know their
              needs and expectations in detail.
            </div>
            <br />
            <div>
              Likewise, the company should establish a procedure for receiving,
              processing and resolving internal and external claims, concerns
              and complaints. This procedure should establish the guidelines for
              follow-up and solve the complaints received. Information about
              this procedure should be clear and accessible to anyone
              interested. It should specify how to report complaints, process
              and resolve them. All stakeholders should be free to lodge
              complaints about the company's activities without being subject to
              adverse action. It is recommended that a committee responsible for
              hearing, processing and resolving complaints be established. This
              Committee should allow for open participation and report the
              resolution.
            </div>
          {:else}
            <h5>G83. Grupos de interés</h5>
            <div>
              Para una correcta vinculación de la empresa con su entorno es
              fundamental contar con un plan de vinculación y participación que
              incluya a los diferentes grupos involucrados con las actividades
              comerciales. Este plan debe establecer mecanismos para identificar
              a todas las partes interesadas y fomentar su participación. Así
              mismo, es necesario identificar los posibles obstáculos de
              participación, generar estrategias de resolución de conflictos y
              considerar el impacto que estos factores pueden generar en la toma
              de decisiones. Se recomienda implementar programas encaminados a
              impulsar el diálogo con los grupos de interés y conocer a detalle
              sus necesidades y expectativas.
            </div>
            <br />
            <div>
              Así mismo, le empresa debe establecer un procedimiento para
              recibir, procesar y dar solución a problemas, inquietudes y quejas
              internas y externas. Este procedimiento debe establecer las pautas
              para dar adecuado seguimiento y solución a todas las quejas
              recibidas. La información acerca de este procedimiento debe ser
              clara y estar accesible a cualquier persona que esté interesada.
              Debe especificar como reportar las quejas y la forma en que son
              procesadas y solucionadas. Todo grupo de interés debe ser libre de
              presentar cualquier tipo de queja referente a las actividades de
              la empresa sin ser objeto de represalias o acciones negativas. Se
              recomienda establecer un comité responsable de la audiencia,
              procesamiento y resolución de quejas. Este comité debe permitir la
              participación abierta e informar acerca de los resultados
              obtenidos en el proceso de resolución.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />investment/due-diligence<br
                            />-guidance-for-responsible<br
                            />-business-conduct.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4>G84. Public opinion</h4>

            To know the stakeholders' opinion about the company, it is advisable
            to have mechanisms facilitating the data collection, analysis, and
            inclusion in decision- making. In this way, it will be possible to
            know how the different stakeholders perceive business operations and
            generate actions to increase ethics and transparency.
          {:else}
            <h4>G84. Opinión publica</h4>

            Para conocer la opinión que la comunidad, clientes, proveedores y
            socios comerciales tienen acerca de la empresa, es recomendable
            contar con mecanismos que faciliten recabar información, analizarla
            e incluirla en la toma de decisiones. De esta manera, será posible
            conocer la forma en que las operaciones comerciales son percibidas
            por los diferentes grupos de interés y generar acciones para
            incrementar la percepción de ética y transparencia de la empresa.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4>G85. Product Labelling</h4>

            Another important communication channel is product labelling. It is
            essential to take care of label specifications and introduce
            eco-labels to contribute to the positive positioning of the
            products. Furthermore, such measures will facilitate the
            introduction of products on the international market and make them
            more attractive to different stakeholders.
          {:else}
            <h4>G85. Etiquetado de productos</h4>

            Otro importante medio de comunicación es el etiquetado de productos.
            Por esta razón, es fundamental cuidar las especificaciones que
            conforman la etiqueta de los productos generados, así como también
            introducir ecoetiquetas que contribuyan al posicionamiento positivo
            de los productos en el mercado. Este tipo de medidas facilitaran la
            introducción de productos en el mercado internacional, así como
            también los hará mas atractivos para los diferentes grupos de
            interés.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Eco-labeling, Product Category Rules and Certification
                          Procedures Based on ISO 14025 Requirements
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Magerholm Fet, A., & Skaar, C. (2006)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Elaboration of environmental product declarations
                          (EPD) based on the product category rules
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://dx.doi.org/10.1065/lca2006.01.237 "
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://dx.doi.org/10.<br />1065/lca2006.01.237
                          </a>
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Eco-labeling for textiles and apparel
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Moore, S. B., & Wentz, M. (2009)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Eco-label for textile industry. Types, roles and
                          reasons for eco-labels and future developments
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1533/9781845696948.2.214"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1533<br />/9781845696948.2.214
                          </a>
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Eco-Labeling Criteria for Textile Products with the
                          Support of Textile Flows : A Case Study of the
                          Vietnamese Textile Industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Thai, V. N., Tokai, A., Yamamoto, Y. & Nguyen, D. T.
                          (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Difficulties in implementing eco-labeling schemes
                          for developing countries
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://www.jseejournal.com/media/97/attachment/Eco-labelling%20pp.%20105-115.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://www.jseejournal.<br
                            />com/media/97/attachment<br
                            />/Eco-labelling%20pp.<br />%20105-115.pdf
                          </a>
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Eco-labeling and sustainability: A case of textile
                          industry in Pakistan
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Hayat, N., Hussain, A., & Lohano, H. D. (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Effect of eco-labels on the environmental and
                          financial performance of textile companies
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="10.1016/j.jclepro.2019.119807"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >10.1016/j.jclepro.<br />2019.119807
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="market">G9. Market</h4>
            The company should have a written procedure for pricing, taking into
            account all the stakeholders. It is also advisable to identify customers'
            expectations to offer products or services that meet their needs. In
            addition, it is desirable to have mechanisms in place to identify market
            risks based on historical data review and analysis of trends, including
            a mitigation plan. This will provide the company with better responsiveness,
            flexibility and resilience.
          {:else}
            <h4 id="market">G9. Mercado</h4>
            Es recomendable que la empresa cuente con un procedimiento escrito acerca
            de los factores incluidos en el establecimiento de precios, tomando en
            cuenta a los diferentes actores involucrados. Así como también, identificar
            las expectativas de los clientes de manera oportuna, con el fin de ofrecer
            productos o servicios que satisfagan sus necesidades. Además, es conveniente
            contar con mecanismos de identificación de riesgos de mercado basados
            en la revisión de datos históricos y análisis de las tendencias emergentes,
            así como desarrollar un plan de mitigación. Esto proporcionará a la empresa
            mayor capacidad de reacción, mayor flexibilidad y resiliencia.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br
                            />/simple-health-safety<br
                            />/risk/risk-assessment-<br
                            />template-and-examples.<br />htm#article
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <ScrollButton />
</div>
