<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';

  export let question;
  let notes = '';

  let country = { name: '', value: 0 };

  function save() {
    const a = JSON.stringify({
      country: country,
    });
    saveAnswer(question, a, notes);
  }

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      let ans = parseAnswer(a[question.code].user_value);
      country.name = ans.country.name;
      country.value = ans.country.value;
      notes = a[question.code].notes;
    }
  });
</script>

<div class="answer mt-5 pr-5">
  <div class="flex mr-4">
    <div class="col-right">
      <div class="select">
        <select
          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          bind:value={country['name']}
          on:change={() => {
            save();
          }}
        >
          <option value="" selected>
            {#if $t['lang'] === 'en'}
              Select
            {:else}
              Seleccionar
            {/if}
          </option>
          <option value="112"> Africa </option>
          <option value="113"> Asia </option>
          <option value="115"> Europe </option>
          <option value="114"> Latin America and the Caribbean </option>
          <option value="116"> Australia </option>
          <option value="117"> Austria </option>
          <option value="119"> Belgium </option>
          <option value="120"> Bulgaria </option>
          <option value="122"> Canada </option>
          <option value="123"> China </option>
          <option value="124"> Croatia </option>
          <option value="125"> Czech Republic </option>
          <option value="126"> Denmark </option>
          <option value="128"> El Salvador </option>
          <option value="129"> France </option>
          <option value="130"> Germany </option>
          <option value="131"> Greece </option>
          <option value="132"> Guatemala </option>
          <option value="133"> Haiti </option>
          <option value="134"> Honduras </option>
          <option value="137"> Italy </option>
          <option value="145"> Mexico </option>
          <option value="147"> Netherlands </option>
          <option value="148"> Nicaragua </option>
          <option value="149"> Pakistan </option>
          <option value="150"> Panama </option>
          <option value="151"> Peru </option>
          <option value="153"> Poland </option>
          <option value="154"> Portugal </option>
          <option value="155"> Romania </option>
          <option value="156"> Russia </option>
          <option value="158"> Slovakia </option>
          <option value="160"> Spain </option>
          <option value="162"> Sweden </option>
          <option value="163"> Switzerland </option>
          <option value="170"> United States </option>
          <option value="115.1"> Other </option>
        </select>
      </div>
    </div>
    <div class="col-left">
      <div class="quantity">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={country['value']}
            on:change={() => {
              save();
            }}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              kWh/year
            {:else}
              kWh/año
            {/if}
          </label>
        </div>
      </div>
    </div>
  </div>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(
        question,
        JSON.stringify({
          country: country,
        }),
        notes
      );
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>

<style>
  .answer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .quantity {
    align-items: center;
    display: flex;
  }

  .select {
    align-items: center;
    display: flex;
    justify-items: center;
  }
</style>
