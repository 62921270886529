<script>
  import { logo } from '../results/images';
</script>

<svg
  width="102"
  height="43"
  viewBox="0 0 102 43"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    cx="14.4996"
    cy="9.18333"
    r="9.18333"
    fill="#3B82F6"
    fill-opacity="0.9"
  />
  <circle cx="14.5" cy="27.5499" r="14.5" fill="#34D399" fill-opacity="0.9" />
  <path
    d="M47.3239 18.7102H49.6818C49.6477 16.6364 47.9432 15.2045 45.3523 15.2045C42.8011 15.2045 40.9318 16.6136 40.9432 18.7273C40.9375 20.4432 42.1477 21.4261 44.1136 21.8977L45.3807 22.2159C46.6477 22.5227 47.3523 22.8864 47.358 23.6705C47.3523 24.5227 46.5455 25.1023 45.2955 25.1023C44.017 25.1023 43.0966 24.5114 43.017 23.3466H40.6364C40.6989 25.8636 42.5 27.1648 45.3239 27.1648C48.1648 27.1648 49.8352 25.8068 49.8409 23.6761C49.8352 21.7386 48.375 20.7102 46.3523 20.2557L45.3068 20.0057C44.2955 19.7727 43.4489 19.3977 43.4659 18.5625C43.4659 17.8125 44.1307 17.2614 45.3352 17.2614C46.5114 17.2614 47.233 17.7955 47.3239 18.7102ZM53.2358 27L54.0994 24.3466H58.2983L59.1619 27H61.7983L57.7869 15.3636H54.6165L50.5994 27H53.2358ZM54.7244 22.4261L56.1562 18.0227H56.2472L57.679 22.4261H54.7244ZM61.3182 17.392H64.8807V27H67.3125V17.392H70.875V15.3636H61.3182V17.392ZM74.9091 15.3636H72.4489V27H74.9091V15.3636ZM86.6662 15.3636H84.2173V22.6818H84.1151L79.0923 15.3636H76.9332V27H79.3935V19.6761H79.4787L84.5412 27H86.6662V15.3636Z"
    fill="#111827"
  />
  <path d="M101 10V31.5" stroke="#9CA3AF" />
</svg>

<div>
  <img src={logo} alt="logo" class="logo" />
</div>

<style>
  .logo {
    width: 137px;
  }
</style>
