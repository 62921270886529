<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = 0;
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
      notes = a[question.code].notes;
    }
  });

  function save() {
    saveAnswer(question, answer.toString(), notes);
  }
</script>

<div class="answer mt-5 pr-5">
  <div class="col-left">
    <div class="quantity">
      <div class="">
        <input
          type="number"
          id="typeNumber"
          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          min="0"
          size="0.5"
          step=".001"
          bind:value={answer}
          on:change={() => {
            save();
          }}
        />
      </div>
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            m3/year
          {:else}
            m3/año
          {/if}
        </label>
      </div>
    </div>
  </div>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, answer.toString(), notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>

<style>
  .answer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .quantity {
    align-items: center;
    display: flex;
  }
</style>
