<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';

  import Navbar from '../components/Navbar.svelte';
  import Sidebar from '../components/Sidebar.svelte';
  import ScrollButton from '../components/ScrollButton.svelte';
  import Category from './Category.svelte';
  import DimensionBanner from '../components/DimensionBanner.svelte';

  import { getDimensions, getAnswers, deleteAnswers } from '../services/satin';
  import { t } from '../stores/messages';
  import { dimensions, checkValidStoredObject } from '../stores/dimensions';
  import { answers, companyProcesses } from '../stores/answers';

  const processesQuestionId = 'D421A';

  export let currentRoute;
  export let params;
  let dimension = currentRoute.namedParams.dimension || params.dimension;
  let categoryId = null;

  let category;
  let categories;
  let isDataMissing = true;
  let showSidebar = dimension !== 'D';

  $: {
    dimension = currentRoute.namedParams.dimension || params.dimension;
    categoryId = currentRoute.namedParams['categoryId?'] || null;
    categories = $dimensions[dimension]?.['categories']?.data || [];
    if (Object.keys($dimensions).length !== 0) {
      if (categoryId === null) {
        category = categories[0];
      } else {
        category = categories.filter((c) => c.code === categoryId)[0];
      }
    }
  }

  onMount(async () => {
    if (checkValidStoredObject(get(dimensions))) {
      const d = await getDimensions();
      d.createdAt = new Date().getTime();
      dimensions.set(d);
    }
    if (checkValidStoredObject(get(answers))) {
      const ans = (await getAnswers()) || [];
      const rA = Object.fromEntries(
        ans.map((a) => {
          if (a.question_id === processesQuestionId) {
            companyProcesses.set(a.user_value.split(','));
          }
          return [a.question_id, a];
        })
      );
      rA.createdAt = new Date().getTime();
      answers.set(rA);
    }
  });

  answers.subscribe((a) => {
    let dataAnswers = [];
    for (const key in a) {
      if (key.startsWith('D')) {
        dataAnswers.push(a[key].user_value);
      }
    }
    isDataMissing =
      dataAnswers.length < 3 ||
      dataAnswers.some((dataAnswer) => {
        return (
          dataAnswer === undefined ||
          (typeof dataAnswer === 'string' &&
            (dataAnswer === '' ||
              dataAnswer?.includes('[null') ||
              dataAnswer?.includes('null]')))
        );
      });
    showSidebar = dimension !== 'D' || (!isDataMissing && a.length > 3);
  });

  async function myAlert() {
    let message;
    if (get(t)['lang'] === 'es') {
      message =
        '¿Seguro que desea borrar? Se eliminarán permanentemente todos los datos ingresados, asi como los resultados generados.';
    } else {
      message =
        'Are you sure you want to reset it? All entered data will be permanently deleted, as well as the generated results.';
    }
    let accepted = confirm(message);
    if (accepted) {
      answers.set({});
      await deleteAnswers();
    }
  }
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  {#if showSidebar}
    <Sidebar {dimension} {categoryId} />
  {/if}
  <div class="w-full">
    {#if dimension !== 'D'}
      <div class="mx-auto w-11/12 mb-4 p-4 flex flex-row items-center">
        <DimensionBanner dimension={$dimensions[dimension]?.['en']} />
      </div>
    {/if}
    <section
      class="mx-auto w-11/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white shadow-xl"
    >
      <form>
        <div class="flex items-center">
          <div class="container mx-auto">
            {#if dimension === 'D'}
              {#if $t['lang'] === 'en'}
                <h3>General Data</h3>
              {:else}
                <h3>Datos Generales</h3>
              {/if}
              {#each categories as c}
                <Category category={c} />
              {/each}
            {:else if category}
              <h1>{category[$t['lang']]}</h1>
              <Category {category} />
            {/if}
          </div>
        </div>
      </form>
      {#if dimension === 'D'}
        <div class="mx-auto w-11/12 bg-white flex flex-row justify-evenly">
          <a href="/app/transition">
            <button
              disabled={isDataMissing}
              class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
            >
              {#if $t['lang'] === 'en'}
                Next
              {:else}
                Siguiente
              {/if}
            </button>
          </a>
        </div>
        <hr class="mx-auto w-11/12 mt-24" />
        <div
          class=" mx-auto w-11/12 flex flex-row justify-center bg-white flex flex-row mb-5"
        >
          <p class="mr-5">
            {#if $t['lang'] === 'en'}
              If you want to delete all the answers press the button
            {:else}
              Si desea eliminar todas las respuestas presione el botón
            {/if}
          </p>
          <button
            on:click={() => {
              myAlert();
            }}
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
          >
            Reset
          </button>
        </div>
      {/if}
      <ScrollButton />
    </section>
  </div>
</div>
