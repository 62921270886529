<svg
  class="menu-icon"
  width="19"
  height="17"
  viewBox="0 0 19 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1.91629 14V13.35C1.91629 13.01 2.06959 12.69 2.30913 12.54C3.92839 11.53 5.77761 11 7.66515 11C7.6939 11 7.71306 11 7.74181 11.01C7.83762 10.31 8.02925 9.64 8.30711 9.03C8.09632 9.01 7.88553 9 7.66515 9C5.34644 9 3.18104 9.67 1.33182 10.82C0.488654 11.34 0 12.32 0 13.35V16H8.87242C8.46999 15.4 8.15381 14.72 7.94302 14H1.91629Z"
    fill="#111827"
  />
  <path
    d="M7.6651 8C9.7826 8 11.4977 6.21 11.4977 4C11.4977 1.79 9.7826 0 7.6651 0C5.5476 0 3.83252 1.79 3.83252 4C3.83252 6.21 5.5476 8 7.6651 8ZM7.6651 2C8.71906 2 9.58139 2.9 9.58139 4C9.58139 5.1 8.71906 6 7.6651 6C6.61114 6 5.74881 5.1 5.74881 4C5.74881 2.9 6.61114 2 7.6651 2Z"
    fill="#111827"
  />
  <path
    d="M17.9653 12C17.9653 11.78 17.9365 11.58 17.9078 11.37L19.0001 10.36L18.0419 8.63L16.6526 9.12C16.346 8.85 16.0011 8.64 15.6178 8.49L15.3304 7H13.4141L13.1266 8.49C12.7434 8.64 12.3984 8.85 12.0918 9.12L10.7025 8.63L9.74438 10.36L10.8367 11.37C10.8079 11.58 10.7792 11.78 10.7792 12C10.7792 12.22 10.8079 12.42 10.8367 12.63L9.74438 13.64L10.7025 15.37L12.0918 14.88C12.3984 15.15 12.7434 15.36 13.1266 15.51L13.4141 17H15.3304L15.6178 15.51C16.0011 15.36 16.346 15.15 16.6526 14.88L18.0419 15.37L19.0001 13.64L17.9078 12.63C17.9365 12.42 17.9653 12.22 17.9653 12ZM14.3722 14C13.3183 14 12.4559 13.1 12.4559 12C12.4559 10.9 13.3183 10 14.3722 10C15.4262 10 16.2885 10.9 16.2885 12C16.2885 13.1 15.4262 14 14.3722 14Z"
    fill="#111827"
  />
</svg>
