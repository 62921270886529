<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = '';
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
      notes = a[question.code].notes;
    }
  });
</script>

<div class="mt-5 flex justify-between items-center">
  <ul class="w-2/4">
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'a', notes);
          }}
          bind:group={answer}
          value={'a'}
        />
        {#if $t['lang'] === 'en'}
          a) Internationally recognized certifications or programs that
          integrate the economic, social and environmental dimensions involving
          all areas of the organization. E.g. Worldwide Responsible Accredited
          Production Certification (WRAP), Global Compact, or related
          sustainability programs.
        {:else}
          a) Certificaciones o programas de reconocimiento internacional que
          integren la dimensión económica, social y ambiental involucrando todas
          las áreas de la organización. Ej. Wordlwide Responsible Accredited
          Production Certification (WRAP), Global Compact, o programas de
          sustentabilidad afines.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'b', notes);
          }}
          bind:group={answer}
          value={'b'}
        />
        {#if $t['lang'] === 'en'}
          b) Internationally recognized certifications or programs focused on
          one or two dimensions, involving different areas of the organization.
          E.g. ISO 14001, ISO 45001, ISO 26000, AA1000, SA8000, AMFORI,
          BlueSing, SAC Higg Index or related social or environmental programs.
        {:else}
          b) Certificaciones o programas de reconocimiento internacional
          enfocados en una o dos dimensiones, involucrando diferentes áreas de
          la organización. Ej. ISO 14001, ISO 45001, ISO 26000, AA1000, SA8000,
          AMFORI, BlueSing, SAC Higg Index o programas sociales o ambientales
          afines.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'c', notes);
          }}
          bind:group={answer}
          value={'c'}
        />
        {#if $t['lang'] === 'en'}
          c) Certifications or programs of international or national recognition
          focused mainly on a specific product or area. E.g. Cradle to cradle,
          Global Organic Textile Standard (GOTS), OEKO-TEX, Global Recycled
          Standard (GRS), ZDHC (Zero Discharge of Hazardous Chemicals),
          Corporate Social Responsibility, Clean Industry or similar.
        {:else}
          c) Certificaciones o programas de reconocimiento internacional o
          nacional enfocadas principalmente a un producto o área específica. Ej.
          Cradle to cradle, Global Organic Textile Standard (GOTS), OEKO-TEX,
          Global Recycled Standard (GRS), ZDHC (Zero Discharge of Hazardous
          Chemicals), Responsabilidad Social Empresarial, Industria limpia o
          afines.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'd', notes);
          }}
          bind:group={answer}
          value={'d'}
        />
        {#if $t['lang'] === 'en'}
          d) None
        {:else}
          d) Ninguna
        {/if}
      </label>
    </li>
  </ul>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, answer, notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>
