<script>
  import { onMount } from 'svelte';
  import { t } from '../stores/messages';

  onMount(async () => {
    document.body.scrollIntoView();
  });
</script>

<div class="mt-7 flex flex-row">
  <div class="w-full">
    <section
      class="mx-auto w-8/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white shadow-xl"
    >
      <div class="container py-12">
        <div class="flex flex-wrap w-full mb-20 flex-col">
          <div id="top" class="flex flex-row items-center mb-8 mr-16">
            <h1 class="text-black border-b w-full">
              {#if $t['lang'] === 'en'}
                Terms and conditions
              {:else}
                Términos y condiciones
              {/if}
            </h1>
          </div>
          <!-- Derechos -->
          <div>
            <div>
              <h1
                class="sm:text-3xl text-2xl font-medium title-font mr-2 text-gray-900"
              >
                {#if $t['lang'] === 'en'}
                  Intellectual Property Rights
                {:else}
                  Derechos de propiedad intelectual
                {/if}
              </h1>
            </div>
            <div>
              {#if $t['lang'] === 'en'}
                All information published on https://www.satinapp.org/ (content,
                features and functionality, software, design, text, images,
                figures and tables, etc.) is protected by copyright. All rights
                are reserved. Any use of the information prohibited by copyright
                law is subject to the prior explicit consent of the author and
                the University of Twente. This applies to copying, processing,
                translating, entering, altering or reproducing the information
                in databases or other electronic media and systems. SATIN
                (Sustainability Assessment of Textile Industry) may not be sold
                or distributed in whole or part for commercial use or
                incorporated into other print or digital products. These Terms
                of Use permit you to use the website solely for your personal,
                non-commercial use. You may not reproduce, distribute, modify or
                create derivative works. SATIN is an academic, free access,
                non-profit project.
              {:else}
                Toda la información publicada en este sitio web (contenido,
                características y funcionalidad, software, diseño, textos,
                imágenes, figuras y tablas, etc.) está protegida por derechos de
                autor. Todos los derechos están reservados. Cualquier uso de la
                información prohibido por las leyes de derechos de autor está
                sujeto al consentimiento explícito previo del autor y de la
                Universidad de Twente. Esto se aplica en particular a la copia,
                el procesamiento, la traducción, la introducción, la alteración
                o la reproducción de la información en bases de datos u otros
                medios y sistemas electrónicos. SATIN (Sustainability Assessment
                of Textile Industry) no puede ser vendida o distribuida total o
                parcialmente, para uso comercial ni incorporada a otros
                productos impresos o digitales. Estos términos de uso le
                permiten utilizar el sitio web únicamente para su uso personal y
                no comercial. No se debe reproducir, distribuir, modificar o
                crear trabajos derivados. SATIN es un proyecto académico, de
                acceso gratuito, sin fines de lucro.
              {/if}
            </div>
            <br />
            <div>
              {#if $t['lang'] === 'en'}
                Author: Claudia Cristina Lujan Ornelas University of Twente
              {:else}
                Autor: Claudia Cristina Lujan Ornelas University of Twente
              {/if}
            </div>
            <br />
            <div>
              {#if $t['lang'] === 'en'}
                ©Copyright University of Twente 2023. All rights reserved.
              {:else}
                ©Copyright University of Twente 2023. Derechos reservados.
              {/if}
            </div>
          </div>
          <!-- Aviso -->
          <div class="mt-5">
            <div>
              <h1
                class="sm:text-3xl text-2xl font-medium title-font mr-2 text-gray-900"
              >
                {#if $t['lang'] === 'en'}
                  Notice
                {:else}
                  Aviso
                {/if}
              </h1>
            </div>
            <div>
              {#if $t['lang'] === 'en'}
                The information presented here and the results obtained should
                be taken as a guide in the improvement process and should not be
                considered as certification, statute or official guideline. The
                user is responsible for the correct use of the tool, taking into
                account that the quality of the results obtained will depend on
                the quality of the data entered, its accuracy and transparency.
                The University of Twente is not responsible for the improper use
                of the tool or the information obtained. The additional material
                presented here is for informational purposes only and
                corresponds to links to other sites and resources provided by
                third parties. We have no control over the content of these
                sites and, therefore, cannot be held responsible if the material
                is removed from the Internet.
              {:else}
                La información aquí presentada y los resultados obtenidos deben
                ser tomados como una guía en el proceso de mejora, no debe ser
                considerada como certificación, estatuto o lineamiento oficial.
                El usuario es responsable del uso correcto de la herramienta,
                tomando en cuenta que la calidad de los resultados obtenidos
                estará en función de la calidad de los datos ingresados, su
                veracidad y transparencia. La Universidad de Twente no se hace
                responsable del uso inadecuado de la herramienta o de la
                información obtenida. El material adicional aquí presentado es
                sólo para fines informativos y corresponde a enlaces de otros
                sitios y recursos proporcionados por terceros. No tenemos
                control sobre el contenido de esos sitios, por lo cual no nos
                hacemos responsables si el material se retira de internet.
              {/if}
            </div>
          </div>
          <!-- Manejo de Datos -->
          <div class="mt-5">
            <div>
              <h1
                class="sm:text-3xl text-2xl font-medium title-font mr-2 text-gray-900"
              >
                {#if $t['lang'] === 'en'}
                  Data Management
                {:else}
                  Manejo de Datos
                {/if}
              </h1>
            </div>
            <div>
              <div>
                {#if $t['lang'] === 'en'}
                  Thank you for choosing SATIN as your improvement tool
                  ("SATIN", "we", "us", "our"). At SATIN, we protect your
                  information and your right to privacy. This section explains
                  what information is collected and how it is used. If you have
                  any questions about data handling, contact us at
                  support@satinapp.org.
                {:else}
                  Gracias por elegir SATIN como herramienta de mejora (“SATIN”,
                  “nosotros”, “nos”, “nuestro”). En SATIN nos comprometemos a
                  proteger su información y su derecho de privacidad. En esta
                  sección se explica qué información se recopila y cómo es
                  usada. Si tiene alguna pregunta sobre el manejo de datos
                  contáctenos en support@satinapp.org
                {/if}
              </div>
              <br />
              <div>
                {#if $t['lang'] === 'en'}
                  To access the website, registration information is requested.
                  SATIN respects your privacy and is committed to protecting
                  your personal data. Adequate security measures have been
                  implemented to prevent unauthorized or accidental leakage,
                  disclosure or use of your data. Data handling is based on the
                  <a
                    href="https://commission.europa.eu/law/law-topic/data-protection/data-protection-eu_en"
                    target="_blank"
                    class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                  >
                    European Union's General Data Protection Regulation (GDPR)</a
                  >, regardless of the company's headquarters country,
                  nationality or place of residence. Data entered into the
                  system will be kept under strict confidentiality at all times
                  by virtue of the fact that the data belongs exclusively to the
                  company. At no time or under any circumstances will personal
                  data or data associated with the company's performance be
                  published, shared or used.
                {:else}
                  Para acceder al sitio web se solicita cierta información de
                  registro. SATIN respeta su privacidad y se compromete a
                  proteger sus datos personales. Se han implementado medidas de
                  seguridad adecuadas para evitar que sus datos personales se
                  filtren, divulguen o utilicen de manera no autorizada o
                  accidental. El manejo de datos se basa en el
                  <a
                    href="https://commission.europa.eu/law/law-topic/data-protection/data-protection-eu_en"
                    target="_blank"
                    class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                  >
                    Reglamento General de Protección de Datos de la Unión
                    Europea (GDPR)</a
                  >, independientemente del país sede de la empresa,
                  nacionalidad o lugar de residencia. Los datos ingresados al
                  sistema se mantendrán bajo estricta confidencialidad en todo
                  momento, en virtud de que los datos pertenecen exclusivamente
                  a la empresa. En ningún momento o bajo ninguna circunstancia
                  se publicarán, compartirán o usarán datos personales o
                  asociados al desempeño de la empresa.
                {/if}
              </div>
              <br />
              <div>
                {#if $t['lang'] === 'en'}
                  The data will be temporarily stored in the cloud until the
                  filling process is completed. Once the data filling is
                  completed and the results are generated, the tool offers the
                  option to delete the data permanently.
                {:else}
                  Los datos se guardarán temporalmente en la nube hasta que el
                  proceso de llenado sea completado. Una vez completado el
                  proceso y generados los resultados, la app presenta la opción
                  de borrar los datos ingresados de manera permanente.
                {/if}
              </div>
              <br />
              <div>
                {#if $t['lang'] === 'en'}
                  Technical security measures have been implemented to protect
                  the security of your information. Data is stored in the cloud
                  using AES encryption, one of the world's most widely used
                  security standards. AES consists of encrypting the data once
                  it is stored and decrypting it only when the user accesses the
                  information. This way, even if someone can access the data, it
                  cannot be decrypted.
                {:else}
                  Se han implementado medidas de seguridad técnicas para
                  proteger la seguridad de su información. Los datos se
                  almacenan en la nube utilizando cifrado AES, uno de los
                  estándares de seguridad más usados en el mundo. AES consiste
                  en cifrar los datos una vez que son guardados y estos solo se
                  descifran al momento en que el usuario accede a la
                  información. De esta manera, aunque alguien tuviera acceso a
                  los datos, estos no podrán ser descifrados.
                {/if}
              </div>
              <br />
              <div>
                {#if $t['lang'] === 'en'}
                  Your account information is password protected. Protecting
                  against unauthorized access to your account is important by
                  setting a strong password. Your password and computer should
                  be kept secure by logging off after logging on to the website.
                  You should use caution when accessing your account from public
                  or shared computers.
                {:else}
                  La información de su cuenta está protegida por una contraseña.
                  Es importante que se proteja contra el acceso no autorizado a
                  su cuenta estableciendo una contraseña segura. Su contraseña y
                  computadora se deben mantener seguras cerrando sesión después
                  de ingresar al sitio web. Debe tener cuidado al ingresar a su
                  cuenta desde computadoras públicas o compartidas.
                {/if}
              </div>
              <br />
              <div>
                {#if $t['lang'] === 'en'}
                  SATIN collects company and performance data through a web
                  application. The data collected and its use is shown below:
                {:else}
                  SATIN recopila datos de la empresa y su desempeño a través de
                  una aplicación web. Los datos recolectados y su uso se
                  muestran a continuación:
                {/if}
              </div>
            </div>
          </div>
          <!-- Tabla -->
          <div class="mt-5">
            <div class="border">
              <table class="border">
                <tr class="border-b">
                  <th class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Data type
                      {:else}
                        Tipo de datos
                      {/if}
                    </span>
                  </th>
                  <th class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Description
                      {:else}
                        Descripción
                      {/if}
                    </span>
                  </th>
                  <th class="border-r">
                    {#if $t['lang'] === 'en'}
                      Use
                    {:else}
                      Uso
                    {/if}
                  </th>
                </tr>
                <tr class="border-b">
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Identity data
                      {:else}
                        Datos de Identidad
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    {#if $t['lang'] === 'en'}
                      Include company name, location, and size of the company
                    {:else}
                      Incluyen nombre de la empresa, ubicación, tamaño de la
                      empresa
                    {/if}
                  </td>
                  <td class="border-r" rowspan="3">
                    {#if $t['lang'] === 'en'}
                      Register the user on the platform, create an account and
                      maintain it in operation.
                    {:else}
                      Dar de alta al usuario en la plataforma, crear una cuenta
                      y mantenerla en funcionamiento.
                    {/if}
                  </td>
                </tr>
                <tr class="border-b">
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Contact data
                      {:else}
                        Datos de contacto
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        First name, last name, position, e-mail
                      {:else}
                        Nombre, apellido, puesto, correo electrónico
                      {/if}
                    </span>
                  </td>
                </tr>
                <tr class="border-b">
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Profile data
                      {:else}
                        Datos de perfil
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Username and password
                      {:else}
                        Nombre de usuario y contraseña
                      {/if}
                    </span>
                  </td>
                </tr>
                <tr class="border-b">
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Usage Data
                      {:else}
                        Datos de uso
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Information about how you use the website and services
                      {:else}
                        Información acerca de cómo utiliza el sitio web y
                        servicios
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Website administration (troubleshooting, system
                        maintenance and support)
                      {:else}
                        Administrar el sitio web (resolución de problemas,
                        mantenimiento del sistema y soporte)
                      {/if}
                    </span>
                  </td>
                </tr>
                <tr class="border-b">
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Data on business activity
                      {:else}
                        Datos de actividad empresarial
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Inputs and outputs of the production process.
                        Environmental, social, economic and administrative
                        management performance data.
                      {:else}
                        Cantidad de entradas y salidas del proceso de
                        producción. Datos de desempeño ambiental, social,
                        económico y gestión administrativa.
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Perform sustainability performance calculation. They are
                        stored for 90 days and then disposed of.
                      {:else}
                        Realizar el cálculo del desempeño en sustentabilidad.
                        Son almacenados durante 90 días y posteriormente son
                        eliminados.
                      {/if}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Aggregate data
                      {:else}
                        Datos agregados
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        Anonymous statistical data that does not reveal your
                        identity directly or indirectly.
                      {:else}
                        Datos estadísticos anónimos que no revelan su identidad
                        directa o indirectamente.
                      {/if}
                    </span>
                  </td>
                  <td class="border-r">
                    <span>
                      {#if $t['lang'] === 'en'}
                        To develop statistics and research anonymously and
                        entirely unlinked to the company's identity directly or
                        indirectly.
                      {:else}
                        Desarrollo de estadísticas e investigación de manera
                        anónima y completamente desvinculado a la identidad de
                        la empresa directa o indirectamente.
                      {/if}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div>
            {#if $t['lang'] === 'en'}
              <div>
                <h4>CRediT taxonomy</h4>
                <span class="font-bold mt-2">Conceptualization:</span> Cristina
                Luján.<br />
                <span class="font-bold mt-2">Methodology:</span> Cristina Luján.
                <br /><span class="font-bold mt-2">Investigation:</span>
                Cristina Luján. <br /><span class="font-bold mt-2"
                  >Supervision:</span
                >
                Laura Franco, Michiel Heldeweg.<br />
                <span class="font-bold mt-2">Software development:</span>
                Ricardo González Echavarria, Victoria Molina Pineda.<br />
                <span class="font-bold mt-2">Graphic design:</span>
                Javier Yañez Yapor, Mariana Yañez Yapor.<br />
                <span class="font-bold mt-2">Validation:</span> Cristina Luján.
                <br /><span class="font-bold mt-2">Data source:</span> The
                development of qualitative indicators was based on international
                certifications and frameworks such as ISO, International Labor
                Organization (ILO), United Nations, Global Compact, OECD, and
                Global Reporting Initiative (GRI).<br />
                The environmental impact assessment is carried out according to ISO
                14040/44 (ISO, 2006). The data used to model this section were obtained
                from fieldwork in Mexican Companies and free access databases such
                as Agribalyse (ADEME, 2021), ELCD III (JRC-IES, 2012) and OzLCI 2019
                (The Evah Institute, 2019) via OpenLCA.
                <br /><span class="font-bold mt-2">Resources: </span>University
                of Twente. <br /><span class="font-bold mt-2"
                  >Funding:
                </span>University of Twente, CONACyT.<br />
                <span class="font-bold mt-2">References:</span>
                ADEME (2021) Agribalyse Database v3.01. Agence de l’environnement
                et de la maitrise de l’energie. Available at: https://agribalyse.ademe.fr/
                (Accessed: 30 January 2023). ISO (2006) ‘ISO 14040/44 Environmental
                Management--Life cycle assessment’, International Organization for
                Standardization. International Standard.
                <br /> JRC-IES (2012) ‘European Life Cycle Database (ELCD)
                version 3.0’. European Commission - Joint Research Centre -
                Institute for Environment and Sustainability. Available at:
                https://eplca.jrc.ec.europa.eu/ELCD3/.<br />
                The Evah Institute (2019) OzLCI2019. Available at: https://nexus.openlca.org/
                (Accessed: 30 January 2023).
              </div>
            {:else}
              <div>
                <h4>Taxonomia CRediT</h4>
                <span class="font-bold mt-2">Conceptualización:</span> Cristina
                Luján.<br />
                <span class="font-bold mt-2">Metodología:</span> Cristina Luján.
                <br /><span class="font-bold mt-2">Investigación:</span>
                Cristina Luján. <br /><span class="font-bold mt-2"
                  >Supervisión:</span
                >
                Laura Franco, Michiel Heldeweg.<br />
                <span class="font-bold mt-2">Desarrollo de software:</span>
                Ricardo González Echavarria, Victoria Molina Pineda.<br />
                <span class="font-bold mt-2">Diseño gráfico:</span>
                Javier Yañez Yapor, Mariana Yañez Yapor.<br />
                <span class="font-bold mt-2">Validación:</span> Cristina Luján.
                <br /><span class="font-bold mt-2">Fuente de datos:</span> La
                elaboración de los indicadores cualitativos se ha basado en
                certificaciones y marcos internacionales como ISO, Organización
                Internacional del Trabajo (OIT), Naciones Unidas, Pacto Mundial,
                OCDE y Global Reporting Initiative (GRI).<br />
                La evaluación del impacto ambiental se realiza según la norma ISO
                14040/44 (ISO, 2006). Los datos utilizados para modelar esta sección
                se obtuvieron a partir de trabajo de campo en empresas mexicanas
                y bases de datos de libre acceso como Agribalyse (ADEME, 2021), ELCD
                III (JRC-IES, 2012) and OzLCI 2019 (The Evah Institute, 2019) via
                OpenLCA.
                <br /><span class="font-bold mt-2">Recursos: </span>University
                of Twente. <br /><span class="font-bold mt-2"
                  >Financiación:</span
                >
                University of Twente, CONACyT.<br />
                <span class="font-bold mt-2">Referencias:</span>
                ADEME (2021) Agribalyse Database v3.01. Agence de l’environnement
                et de la maitrise de l’energie. Available at: https://agribalyse.ademe.fr/
                (Accessed: 30 January 2023). ISO (2006) ‘ISO 14040/44 Environmental
                Management--Life cycle assessment’, International Organization for
                Standardization. International Standard.<br /> JRC-IES (2012)
                ‘European Life Cycle Database (ELCD) version 3.0’. European
                Commission - Joint Research Centre - Institute for Environment
                and Sustainability. Available at:
                https://eplca.jrc.ec.europa.eu/ELCD3/.<br />
                The Evah Institute (2019) OzLCI2019. Available at: https://nexus.openlca.org/
                (Accessed: 30 January 2023).
              </div>
            {/if}
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<style>
  table {
    table-layout: fixed;
    width: 100%;
  }
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    width: 33%;
  }
</style>
