const MAX_SCORE_PER_QUESTION = 3;
const NUMBER_OF_DIFFERENT_SCORES = 4;
// @params
// - total de preguntas (si hubo preguntas con NA entonces no deben ser tomadas en cuenta)
// - score (suma de todas los answer_values de las preguntas)
export const performanceLevel = (totalQuestions, score) => {
  // console.log('totalQuestions', totalQuestions, 'score', score);
  const interval =
    (MAX_SCORE_PER_QUESTION * totalQuestions) / NUMBER_OF_DIFFERENT_SCORES;
  if (score <= interval) {
    return 'low';
  } else if (score > interval && score <= interval * 2) {
    return 'mid';
  } else if (score > interval * 2 && score <= interval * 3) {
    return 'high';
  } else if (score > interval * 3) {
    return 'ideal';
  }
};
