import { get } from 'svelte/store';
import { postAnswer } from './satin';
import { answers } from '../stores/answers';

const DEBOUNCE_TIMEOUT = 500;
const answerTimers = {};

export async function saveAnswer(question, answer, notes = '', timeout = null) {
  const a = get(answers);
  if (
    a[question.code]?.user_value !== answer ||
    a[question.code]?.notes !== notes
  ) {
    a[question.code] = {
      ...a[question.code],
      question_id: question.code,
      user_value: answer,
      answer_type: question.answerType,
      notes: notes,
    };
    answers.set(a);
    if (answerTimers[question.code]) {
      clearTimeout(answerTimers[question.code]);
    }
    let timer = setTimeout(async () => {
      await postAnswer({
        question_id: question.code,
        answer: answer,
        answer_type: question.answerType,
        notes: notes,
      });
    }, timeout || DEBOUNCE_TIMEOUT);
    answerTimers[question.code] = timer;
  }
}

export function parseAnswer(answer) {
  let ans = answer.replaceAll('""', '"').replaceAll(':",', ':"",');
  // console.log(ans);
  return JSON.parse(ans);
}
