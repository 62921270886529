<script>
  import { t } from '../../stores/messages';
  import { answers, companyProcesses } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let processes = [];
  answers.subscribe((a) => {
    processes = a[question.code] ? a[question.code].user_value.split(',') : [];
  });
  const toggleProcess = (e, p) => {
    if (e.target.checked) {
      processes.push(p);
    } else {
      processes = processes.filter((proc) => proc !== p);
    }
    processes = processes.filter((p) => p !== '');
    saveAnswer(question, processes.join(','), '', 100);
    companyProcesses.set(processes);
  };
</script>

<div class="ml-5 mt-5">
  <ul>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M1')}
          on:change={(e) => {
            toggleProcess(e, 'M1');
          }}
        />
        {#if $t['lang'] === 'en'}
          Yarn manufacturing
        {:else}
          Producción de hilo
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M2')}
          on:change={(e) => {
            toggleProcess(e, 'M2');
          }}
        />
        {#if $t['lang'] === 'en'}
          Fabric manufacturing
        {:else}
          Producción de tejido
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M3')}
          on:change={(e) => {
            toggleProcess(e, 'M3');
          }}
        />
        {#if $t['lang'] === 'en'}
          Finished knitted product manufacturing
        {:else}
          Fabricación de productos confeccionados de tejido de punto
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M4')}
          on:change={(e) => {
            toggleProcess(e, 'M4');
          }}
        />
        {#if $t['lang'] === 'en'}
          Finished woven product manufacturing
        {:else}
          Fabricación de productos confeccionados de tejido plano
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M5')}
          on:change={(e) => {
            toggleProcess(e, 'M5');
          }}
        />
        {#if $t['lang'] === 'en'}
          Trimmings manufacturing
        {:else}
          Producción de pasamanería
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M6')}
          on:change={(e) => {
            toggleProcess(e, 'M6');
          }}
        />
        {#if $t['lang'] === 'en'}
          Nonwoven fabrics manufacturing
        {:else}
          Producción de telas no tejidas
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="checkbox"
          checked={processes.includes('M7')}
          on:change={(e) => {
            toggleProcess(e, 'M7');
          }}
        />
        {#if $t['lang'] === 'en'}
          Nonwoven finished product manufacturing
        {:else}
          Fabricación de productos confeccionados de telas No tejidas
        {/if}
      </label>
    </li>
  </ul>
</div>
