<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = '';
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
      notes = a[question.code].notes;
    }
  });
</script>

<div class="mt-5 flex justify-between items-center">
  <ul class="w-2/4">
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'a', notes);
          }}
          bind:group={answer}
          value={'a'}
        />
        {#if $t['lang'] === 'en'}
          a) Support for: Scholarships, major medical health insurance,
          eyeglasses, life insurance, saving fund, purchase of private vehicles,
          automobile insurance, vehicle maintenance.
        {:else}
          a) Apoyo para: Becas, seguro de gastos médicos mayores, anteojos,
          seguro de vida, establecimiento de caja de ahorro, compra de vehículos
          particulares, seguros automóviles, mantenimiento a vehículos.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'b', notes);
          }}
          bind:group={answer}
          value={'b'}
        />
        {#if $t['lang'] === 'en'}
          b) -Food vouchers, savings fund, productivity, performance,
          punctuality, attendance or seniority bonuses. -Personnel services
          (e.g. parking, transportation, cafeteria, nursery, laundry, showers,
          sports facilities, medical exams, uniforms).
        {:else}
          b) -Vales de despensa, fondo de ahorro, bonos de productividad,
          desempeño, puntualidad, asistencia, antigüedad.-Servicios al personal
          (estacionamiento, transporte, comedor, guardería, lavandería, duchas,
          instalaciones deportivas exámenes médicos, uniformes).
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'c', notes);
          }}
          bind:group={answer}
          value={'c'}
        />
        {#if $t['lang'] === 'en'}
          c) Social security (e.g. medical care, dental service, housing,
          disability insurance, sickness, maternity, family, unemployment,
          old-age, employment injury, invalidity or survivors benefits).
          -Benefits (e.g. vacation, vacation bonus, Sunday bonus, Christmas
          bonus, seniority bonus, profit sharing.
        {:else}
          c) -Seguridad social (seguro médico, servicio dental, baja por
          maternidad, seguro por incapacidad, pensión por vejez, cesantía por
          edad avanzada, invalidez y vida, retiro, orfandad, guardería IMSS,
          acceso a vivienda). -Prestaciones (Vacaciones, prima vacacional, prima
          dominical, aguinaldo, prima de antigüedad, reparto de utilidades).
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'd', notes);
          }}
          bind:group={answer}
          value={'d'}
        />
        {#if $t['lang'] === 'en'}
          d) None
        {:else}
          d) Ninguno
        {/if}
      </label>
    </li>
  </ul>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, answer, notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>
