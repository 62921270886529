<script>
  import { t } from '../stores/messages';
  import { apiRequest } from '../services/api';
  import { b64ToUtf8 } from '../services/satin';
  export let checked = false;
</script>

<label class="switch">
  <input
    type="checkbox"
    bind:checked
    on:change={() => {
      const lang = checked ? 'en' : 'es';
      apiRequest('get', '/messages', {}, { 'Accept-Language': lang })
        .then((messages) => {
          t.set(JSON.parse(b64ToUtf8(messages)));
        })
        .catch((err) => {
          console.log(err);
        });
    }}
  />
  <span class="slider" />
</label>

<style>
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f59e0b;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:checked + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
</style>
