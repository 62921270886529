<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = '';
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
      notes = a[question.code].notes;
    }
  });
</script>

<div class="mt-5 flex justify-between items-center">
  <ul class="w-2/4">
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'a', notes);
          }}
          bind:group={answer}
          value={'a'}
        />
        {#if $t['lang'] === 'en'}
          a) Sustainability reporting system according to international
          standards. E.g. Global Reporting Initiative (GRI), Sustainability
          Accounting Standards Board (SASB), Guidelines of International
          Integrated Reporting Council (IIRC), United Nations Guiding Principles
          (UNGP) Reporting Framework, or similar.
        {:else}
          a) Sistema de reporte de sustentabilidad de acuerdo a estándares
          internacionales. Ej. Global Reporting Initiative (GRI), Sustainability
          Accounting Standards Board (SASB), Guidelines of International
          Integrated Reporting Council (IIRC), United Nations Guiding Principles
          (UNGP) Reporting Framework o afines.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'b', notes);
          }}
          bind:group={answer}
          value={'b'}
        />
        {#if $t['lang'] === 'en'}
          b) Corporate reporting system for environmental performance and/or
          Corporate Social Responsibility (CSR), or similar.
        {:else}
          b) Sistema de reporte empresarial de desempeño ambiental y/o
          Responsabilidad Social Empresarial (RSE) o afines.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'c', notes);
          }}
          bind:group={answer}
          value={'c'}
        />
        {#if $t['lang'] === 'en'}
          c) Sporadic reports on specific projects and/or community programs.
        {:else}
          c) Reportes esporádicos sobre proyectos específicos y/o programas
          comunitarios.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'd', notes);
          }}
          bind:group={answer}
          value={'d'}
        />
        {#if $t['lang'] === 'en'}
          d) None or there is no evidence
        {:else}
          d) Ninguna o no existe evidencia
        {/if}
      </label>
    </li>
  </ul>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, answer, notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>
