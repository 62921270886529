<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = '';
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
      notes = a[question.code].notes;
    }
  });
</script>

<div class="mt-5 flex justify-between items-center">
  <ul>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'a', notes);
          }}
          bind:group={answer}
          value={'a'}
        />
        {#if $t['lang'] === 'en'}
          a) Yes, a culture of strategic intervention is implemented that
          involves all areas, and employees of the organization. It is part of
          the daily work philosophy and all departments are aligned with the
          objectives set, including all Human Resources activities.
        {:else}
          a) Si, se implementa una cultura de intervención estratégica que
          involucra a todas las áreas y empleados de la organización. Forma
          parte de la filosofía del trabajo diario y todos los departamentos
          están alineados con los objetivos planteados, incluyendo todas las
          actividades de Recursos Humanos.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'b', notes);
          }}
          bind:group={answer}
          value={'b'}
        />
        {#if $t['lang'] === 'en'}
          b) Yes, a proactive culture is implemented in which the model is part
          of the organization's strategies. Pilot projects and planned
          activities are carried out based on established goals and objectives,
          including some Human Resources activities. Performance improvements
          are reported, and progress is measured.
        {:else}
          b) Si, se implementa una cultura proactiva en la que el modelo forma
          parte de las estrategias de la organización. Se realizan proyectos
          piloto y actividades planeados con base en las metas y objetivos
          establecidos, incluyendo algunas actividades de Recursos Humanos. Se
          presentan mejoras en el desempeño y se mide el progreso.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'c', notes);
          }}
          bind:group={answer}
          value={'c'}
        />
        {#if $t['lang'] === 'en'}
          c) Yes, a basic implementation of the model or tool is performed in
          isolated processes or areas. Resource optimization is sought; however,
          there is no organizational culture or strategic planning based on the
          model.
        {:else}
          c) Si, se realiza una implementación básica del modelo o herramienta
          en procesos o áreas aislados. Se busca la optimización de recursos,
          sin embargo, no se cuenta con una cultura organizacional ni planeación
          estratégica en función al modelo.
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'd', notes);
          }}
          bind:group={answer}
          value={'d'}
        />
        {#if $t['lang'] === 'en'}
          d) No
        {:else}
          d) No
        {/if}
      </label>
    </li>
  </ul>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, answer, notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>
