<script>
  import { Tabs, Tab } from 'svelte-chota';
  import { t } from '../stores/messages';
  import { answers } from '../stores/answers';
  import { selectedDepartment } from '../stores/answers';
  import {
    totalAnsweredPerCategory,
    totalQuestionsPerSubcategory,
  } from '../results/allDimensionsLevel';

  export let category;
  export let setSubCategoryId;

  let department = $selectedDepartment;

  const fullSubcateogryList = [
    'A1',
    'A2',
    'A3',
    'A4',
    'A5',
    'A6',
    'A7',
    'S1',
    'S2',
    'S3',
    'S4',
    'S5',
    'S6',
    'S7',
    'S8',
    'E1',
    'E2',
    'E3',
    'E4',
    'E5',
    'E6',
    'G0',
    'G1',
    'G2',
    'G3',
    'G4',
    'G5',
    'G6',
    'G7',
    'G8',
    'G9',
  ];

  let active_tab = 0;
  let init_tab_index = 0;
  let end_tab_index = 4;

  let prevSubcategoryPath = '';
  let nextSubcategoryPath = '';

  function next() {
    active_tab++;
    if (active_tab === end_tab_index) {
      init_tab_index = init_tab_index + 4;
      end_tab_index = end_tab_index + 4;
      active_tab = init_tab_index;
    }
  }

  function prev() {
    active_tab--;
    if (active_tab === init_tab_index - 1) {
      init_tab_index = init_tab_index - 4;
      end_tab_index = end_tab_index - 4;
      active_tab = end_tab_index - 1;
    }
  }

  $: {
    let currentSubCategory = window.location.href.slice(-2);
    let currentSubCategoryIndex = fullSubcateogryList.findIndex(
      (sc) => sc === currentSubCategory
    );
    prevSubcategoryPath = `/app/main/${
      fullSubcateogryList[currentSubCategoryIndex - 1]?.[0]
    }/${fullSubcateogryList[currentSubCategoryIndex - 1]}`;
    nextSubcategoryPath = `/app/main/${
      fullSubcateogryList[currentSubCategoryIndex + 1]?.[0]
    }/${fullSubcateogryList[currentSubCategoryIndex + 1]}`;

    (category.subCategories?.data || []).forEach((subCategory, index) => {
      if (active_tab === index) {
        setSubCategoryId(subCategory.code);
      }
    });
  }
</script>

{#if !category.code.startsWith('D')}
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <div class=" mr-5">
        {#if $t['lang'] === 'en'}
          Area filter
        {:else}
          Filtro por departamento
        {/if}
      </div>
      <div>
        <select
          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          bind:value={department}
          on:change={() => {
            $selectedDepartment = department;
          }}
        >
          <option selected value="">
            {#if $t['lang'] === 'en'}
              All
            {:else}
              Todos
            {/if}
          </option>
          <option value="DIRECTION">
            {#if $t['lang'] === 'en'}
              Direction
            {:else}
              Dirección
            {/if}
          </option>
          <option value="PRODUCTION">
            {#if $t['lang'] === 'en'}
              Production
            {:else}
              Producción
            {/if}
          </option>
          <option value="HR">
            {#if $t['lang'] === 'en'}
              HR
            {:else}
              RH
            {/if}
          </option>
          <option value="PURCHASING">
            {#if $t['lang'] === 'en'}
              Purchasing
            {:else}
              Compras
            {/if}
          </option>
          <option value="SALES">
            {#if $t['lang'] === 'en'}
              Sales
            {:else}
              Ventas
            {/if}
          </option>
          <option value="FINANCE">
            {#if $t['lang'] === 'en'}
              Finance
            {:else}
              Finanzas
            {/if}
          </option>
        </select>
      </div>
    </div>
    <div class="buttons mt-5 mb-5">
      {#if active_tab !== 0}
        <button
          type="button"
          class="button-style mr-5"
          on:click={() => {
            prev();
          }}
        >
          {#if $t['lang'] === 'en'}
            Previous
          {:else}
            Anterior
          {/if}
          <svg
            class="arrow-icon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.2541 9.7774L2.47919 7.0048L5.25179 4.2299L5.25221 5.25365L12.2522 5.25073L12.2537 8.75073L5.25367 8.75365L5.2541 9.7774ZM7.00586 14.0029L7.0044 10.5029L14.0044 10.5L14.0015 3.5L7.00149 3.50292L7.00003 0.00291666L0.00294414 7.00583L7.00586 14.0029Z"
              fill="#BDBDBD"
            />
          </svg>
        </button>
      {:else if !prevSubcategoryPath.endsWith('undefined')}
        <button
          type="button"
          class="button-style"
          on:click={() => {
            window.location.href = prevSubcategoryPath;
          }}
        >
          {#if $t['lang'] === 'en'}
            Previous
          {:else}
            Anterior
          {/if}
          <svg
            class="arrow-icon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.2541 9.7774L2.47919 7.0048L5.25179 4.2299L5.25221 5.25365L12.2522 5.25073L12.2537 8.75073L5.25367 8.75365L5.2541 9.7774ZM7.00586 14.0029L7.0044 10.5029L14.0044 10.5L14.0015 3.5L7.00149 3.50292L7.00003 0.00291666L0.00294414 7.00583L7.00586 14.0029Z"
              fill="#BDBDBD"
            />
          </svg>
        </button>
      {/if}
      {#if active_tab < category.subCategories?.data.length - 1}
        <button
          type="button"
          class="button-style"
          on:click={() => {
            next();
          }}
        >
          <svg
            class="arrow-icon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.73867 4.24964L12.505 7.0308L9.72383 9.79712L9.72657 8.77337L2.72659 8.75465L2.73595 5.25466L9.73593 5.27338L9.73867 4.24964ZM7.99998 0.0187229L7.99061 3.51871L0.990639 3.49999L0.971916 10.5L7.97189 10.5187L7.96253 14.0187L14.9812 7.03742L7.99998 0.0187229Z"
              fill="#BDBDBD"
            />
          </svg>
          {#if $t['lang'] === 'en'}
            Next
          {:else}
            Siguiente
          {/if}
        </button>
      {:else if !nextSubcategoryPath.endsWith('undefined')}
        <button
          type="button"
          class="button-style"
          on:click={() => {
            window.location.href = nextSubcategoryPath;
          }}
        >
          <svg
            class="arrow-icon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.73867 4.24964L12.505 7.0308L9.72383 9.79712L9.72657 8.77337L2.72659 8.75465L2.73595 5.25466L9.73593 5.27338L9.73867 4.24964ZM7.99998 0.0187229L7.99061 3.51871L0.990639 3.49999L0.971916 10.5L7.97189 10.5187L7.96253 14.0187L14.9812 7.03742L7.99998 0.0187229Z"
              fill="#BDBDBD"
            />
          </svg>
          {#if $t['lang'] === 'en'}
            Next
          {:else}
            Siguiente
          {/if}
        </button>
      {:else}
        <button type="button" class="button-style">
          <a href="/app/results#charts">
            <svg
              class="arrow-icon"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.73867 4.24964L12.505 7.0308L9.72383 9.79712L9.72657 8.77337L2.72659 8.75465L2.73595 5.25466L9.73593 5.27338L9.73867 4.24964ZM7.99998 0.0187229L7.99061 3.51871L0.990639 3.49999L0.971916 10.5L7.97189 10.5187L7.96253 14.0187L14.9812 7.03742L7.99998 0.0187229Z"
                fill="#BDBDBD"
              />
            </svg>
            {#if $t['lang'] === 'en'}
              Results
            {:else}
              Resultados
            {/if}
          </a>
        </button>
      {/if}
    </div>
  </div>
  <Tabs
    full
    class="leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary mt-2 width:50px"
    bind:active={active_tab}
  >
    {#each category.subCategories?.data || [] as subCategory, index}
      {#if category.subCategories?.data.length <= 4}
        <Tab
          class={`${active_tab === index ? 'activeTab' : ''} ${
            totalAnsweredPerCategory(subCategory.code, $answers) ===
            totalQuestionsPerSubcategory(subCategory)
              ? 'completedSubcategory'
              : ''
          }`}
        >
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            on:click={() => {
              setSubCategoryId(subCategory.code);
              active_tab = index;
            }}
            class="cursor-pointer tabs"
          >
            {subCategory[$t['lang']]}
          </div>
        </Tab>
      {:else if index >= init_tab_index && index < end_tab_index}
        <Tab
          class={`${active_tab === index ? 'activeTab' : ''} ${
            totalAnsweredPerCategory(subCategory.code, $answers) ===
            totalQuestionsPerSubcategory(subCategory)
              ? 'completedSubcategory'
              : ''
          }`}
        >
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            on:click={() => {
              setSubCategoryId(subCategory.code);
              active_tab = index;
            }}
            class="cursor-pointer tabs"
          >
            {subCategory[$t['lang']]}
          </div>
        </Tab>
      {/if}
    {/each}
  </Tabs>
{/if}

<slot />

<style>
  .buttons {
    align-items: center;
    display: flex;
    justify-content: right;
  }
  .button-style {
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #14854f;
    background: #ffffff;
    border: 1px solid #14854f;
    border-radius: 6px;
    margin-left: 10px;
  }
  .arrow-icon {
    align-items: center;
    display: inline;
  }
  .tabs {
    color: #9ca3af;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    justify-content: center;
    &:hover {
      color: black;
      font-weight: bold;
    }
    &:active {
      color: black;
      font-weight: bold;
    }
  }

  .department {
    margin-right: 300px;
  }
</style>
