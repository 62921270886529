<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { getDimensions } from '../services/satin';
  import DimensionBanner from '../components/DimensionBanner.svelte';
  import Navbar from '../components/Navbar.svelte';
  import ScrollButton from '../components/ScrollButton.svelte';
  import Sidebar from '../components/Sidebar.svelte';

  import { t } from '../stores/messages';
  import { dimensions, checkValidStoredObject } from '../stores/dimensions';

  onMount(async () => {
    if (checkValidStoredObject(get(dimensions))) {
      const d = await getDimensions();
      d.createdAt = new Date().getTime();
      dimensions.set(d);
    }
  });
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar dimension="results" />
  <div class="w-full overflow-hidden">
    <div class="mx-auto w-11/12 mb-4 p-4 flex flex-row items-center">
      <DimensionBanner dimension="Social" />
    </div>
    <section
      class="mx-auto w-11/12 p-4 text-gray-600 body-font bg-white shadow-xl rounded-lg"
    >
      <div class="flex-column mr-16 mt-5 ml-10 text-justify">
        <h3 id="working-conditions">S. Social</h3>
        <h4 id="working-conditions">S1. {$t['working-conditions']}</h4>
        <div>
          {#if $t['lang'] === 'en'}
            The global trend shows an increasing interest in social justice
            issues for the company's customers and employees, but also for all
            workers along the value chain. For that reason, providing good
            working conditions and ensuring respect for human rights will always
            translate into benefits for the organisation. It will create an
            environment of safety and trust where everyone can carry out their
            activities correctly and positively. Furthermore, it also helps
            create a solid corporate image as a socially responsible company,
            which gives it a better position in the market. For this reason, it
            is crucial to put the needs of employees first, ensure their safety
            and well-being, and avoid problems that could jeopardise
            productivity and stability. In this sense, the International Labour
            Organisation (ILO) establishes standards, protocols and
            recommendations on the different issues involved. Likewise, it is
            essential to keep national and local legislation up to date, in full
            compliance with the provisions.
          {:else}
            La tendencia global, muestra un aumento del interés en temas de
            justicia social no solo para los clientes y empleados de la empresa,
            sino para todos los trabajadores a lo largo de la cadena de valor.
            Por ese motivo, proporcionar buenas a condiciones laborales y
            asegurar el respeto de los derechos humanos para todos los empleados
            siempre se traducirá en beneficios para la organización, ya que se
            creará un ambiente de seguridad y confianza en el cual todos puedan
            desarrollar sus actividades correcta y positivamente. Además de
            favorecer la creación de una imagen corporativa sana, como empresa
            socialmente responsable, que la identifique y le brinde un mejor
            posicionamiento en el mercado. Por esta razón es importante
            anteponerse a las necesidades de los empleados, velar por su
            seguridad y bienestar evitando problemas que puedan poner en riesgo
            la productibilidad y estabilidad de la empresa. En este sentido, la
            Organización Internacional del Trabajo (OIT) establece estándares,
            protocolos y recomendaciones en los diferentes temas involucrados.
            De igual manera, es indispensable mantener actualizadas la
            legislación nacional y local, dando entero cumplimiento a lo
            establecido.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Standards
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - International System on International Standards:
                          Forced labour, freedom association, equal
                          remuneration, discrimination, child labour, work
                          hours, maternity protection, sickness insurance,
                          old-age insurance, invalidity insurance, indigenous
                          workers, holidays, social security, wages.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/dyn/normlex/en/f?p=1000:12000:::NO:::"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/dyn/normlex/<br
                            />en/f?p=1000:12000:::NO:::</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="gender-equity">S11. Gender equity</h5>
            Equity refers to the fair distribution of resources and rights of individuals
            within a society. In this sense, gender equity refers to the proper treatment
            of men and women according to their needs, providing the same opportunities
            without neglecting the particularities of each individual. For this reason,
            the company must promote equality and equity among its employees by adopting
            labour policies that promote an adequate distribution of roles and opportunities.
          {:else}
            <h5 id="gender-equity">S11. Equidad de género</h5>

            El termino equidad hace referencia a la distribución justa de los
            recursos y derechos de los individuos dentro de una sociedad. En
            este sentido, equidad de genero alude al tratamiento justo de
            hombres y mujeres según sus necesidades, otorgando las mismas
            oportunidades sin dejar de lado las particularidades de cada
            individuo. Por esta razón es crucial que la empresa fomente la
            igualdad y equidad entre sus empleados mediante la adopción de
            políticas laborales que promuevan una adecuada distribución de roles
            y oportunidades.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Gender Equality in the Workplace
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          UNDP (2010)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - What is a certification Programme for gender
                          Equality Management Systems? - How to start your own
                          Programme
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.americalatinagenera.org/es/documentos/Gender-Equality-in-the-Workplace.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.americalatinagenera.<br
                            />org/es/documentos/Gender-Equality-<br
                            />in-the-Workplace.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="employment-contracts">S12. Employment contracts</h5>

            Employment contracts protect employers and employees by setting out
            the rights and obligations of both parties. For this reason, a
            company must ensure that all employees work under employment
            contracts that are fair and clear to all parties involved. The
            contracts should specify employment rights and obligations and
            describe the activities to be performed, salaries, working hours,
            overtime, benefits, and notice periods for significant changes,
            among others. For this reason, employment contracts must be
            appropriately drafted and include all aspects regulated by local and
            national legislation and international conventions.
          {:else}
            <h5 id="employment-contracts">S12. Contratos laborales</h5>

            Los contratos laborales brindan protección a patrones y trabajadores
            mediante el establecimiento de los derechos y obligaciones de ambas
            partes. Por ese motivo, es indispensable que la empresa se asegure
            que todos sus empleados laboran bajo contratos de trabajo justos y
            claros para todas las partes involucradas. Los contratos laborales
            deben especificar los derechos y obligaciones de empleo, así como
            describir las actividades a realizar, sueldos, jornada laboral,
            tiempo extra, prestaciones y periodos de aviso de cambios
            significativos, entre otros. Por esta razón es necesario que los
            contratos laborales estén correctamente redactados e incluyan todos
            los aspectos regulados por la legislación local y nacional, así como
            por las iniciativas internacionales correspondientes.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="job-descriptions">S13. Job Descriptions</h5>

            It is recommended that the company have detailed job descriptions to
            document the activities performed. In addition, each job should have
            a document indicating the salary level and qualifications required.
            All employees and their immediate superior should be aware of and
            have access to their job descriptions. This will avoid confusion in
            activities, make processes more efficient and ensure that employees
            concentrate on their work effectively. Job descriptions also help
            reduce the risk of conflicts and efforts duplication, thus improving
            the work environment and employee satisfaction.
          {:else}
            <h5 id="job-descriptions">S13. Descripción de puestos</h5>

            Es recomendable que la empresa cuente con la descripción detallada
            de los puestos de trabajo, de tal manera que las actividades a
            realizar queden documentadas. Cada puesto de trabajo debe contar con
            un documento que indique el nivel de salario y las cualificaciones
            requeridas. Todos los empleados deben tener conocimiento y acceso a
            la descripción de su puesto, así como también su superior inmediato.
            Esto evitará confusiones en las actividades, hará mas eficientes los
            procesos y asegurará que los empleados se concentren en sus labores
            de manera efectiva. Así mismo la descripción de puestos contribuye a
            reducir el riesgo de conflictos y la duplicidad de esfuerzos,
            favoreciendo el clima laboral y la satisfacción de los empleados.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="working-overtime">S14. Working hours and overtime</h5>

            National laws, international conventions and industry standards
            provide 48 hours per week or less in regular working hours.
            Likewise, overtime should not exceed 12 hours per week or 36 hours
            per month and should be reimbursed at a rate higher than the typical
            working day. In this sense, the company must have a system for
            planning, recording and monitoring the hours worked per employee,
            taking care not to exceed these limits. This will avoid penalties
            and ensure the well-being of its workers. In this way, it will be
            possible to assess whether there are enough workers to meet
            production targets without resorting to overtime or whether it is
            necessary to increase the workforce. Similarly, workers must have at
            least 24 consecutive hours of rest per week, 30 minutes for every
            four working hours and the freedom to use the sanitary facilities at
            any time, not only during the breaks.
          {:else}
            <h5 id="working-overtime">S14. Jornada laboral y tiempo extra</h5>

            Las leyes nacionales, convenciones internacionales y estándares
            industriales establecen un horario laboral de 48 horas semanales o
            menos en jornada normal. Así mismo, el tiempo extra no debe exceder
            de 12 horas por semana o 36 horas al mes y deben ser remuneradas con
            una tasa superior a la jornada normal. En este sentido, es necesario
            que la empresa lleve un sistema de planeación, registro y
            seguimiento de horas trabajadas por empleado, cuidando no exceder
            estos límites, evitando sanciones y velando por el bienestar de sus
            trabajadores. De esta manera se podrá evaluar si se cuenta con los
            trabajadores suficientes para cumplir con los objetivos de
            producción sin tener que recurrir a horas extras o si es necesario
            aumentar su fuerza de trabajo. De igual manera, los trabajadores
            deben contar con al menos 24 horas de descanso consecutivas
            semanalmente, 30 minutos por cada cuatro horas de trabajo y la
            libertad de usar las instalaciones sanitarias en cualquier momento,
            no solo durante las pausas establecidas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Working Hours Action Plan
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Worldwide Responsible Accredited Production (WRAP)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Working Hours Action Plan
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://wrapcompliance.org/certification/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://wrapcompliance.org<br />/certification/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="operational-changes">S15. Operational changes</h5>

            Employees should be given notice before significant operational
            changes that may affect their working conditions are implemented.
            This will avoid negatively impacting employees, transparency, and a
            good working environment. It also avoids creating instability and
            putting the company's activities at risk. It is recommended to
            present the changes positively, looking for team collaboration and
            commitment. It is also necessary to ensure that all employees
            understand the changes that will be made, their role, and its
            benefitsto their activities and the organisation. This will actively
            engage staff and foster collective commitment, reducing resistance
            to change, generating favourable synergies and increasing
            organisational resilience.
          {:else}
            <h5 id="operational-changes">S15. Cambios operativos</h5>

            Es necesario dar aviso a los trabajadores antes de poner en practica
            cambios operativos significativos que puedan afectar sus condiciones
            laborales. Esto evitará generar un impacto negativo en los
            empleados, propicia la transparencia y un adecuado ambiente de
            trabajo. De igual manera también evita generar inestabilidad y poner
            en riesgo las actividades de la empresa. Se recomienda plantear los
            cambios exponiendo los beneficios que esto traerá consigo,
            presentándolo de manera positiva, como un reto para el cual es
            necesaria la colaboración y compromiso del equipo. Así mismo, es
            necesario asegurarse de que todos los empleados comprendan a detalle
            los cambios que se realizarán, el papel que desempeñan y los
            beneficios que traerá a sus actividades y a la organización. Esto
            involucrará al personal activamente y propiciará un compromiso
            colectivo, reduciendo la resistencia al cambio, generando sinergias
            favorables y aumentando la capacidad de resiliencia organizacional.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="human-and-labour-rights">S2. Human and labour rights</h4>
            The company must know, respect and uphold the human and labour rights
            set out in local regulations and international conventions. For this
            reason, the existence and implementation of a policy setting out the
            company's position on this issue are crucial. This policy should establish
            guidelines and standards to enforce the rights of employees on matters
            such as child labour, freedom of association, free labour, inclusion
            and gender equality. Similarly, it is necessary to establish mechanisms
            for workers to freely file complaints, ensuring follow-up and resolution.
          {:else}
            <h4 id="human-and-labour-rights">
              S2. Derechos humanos y laborales
            </h4>

            La empresa tiene la obligación de conocer, respetar y hacer valer
            los derechos humanos y laborales establecidos en las regulaciones
            locales y en convenios internacionales. Por tal motivo, es crucial
            la existencia e implementación de una política que establezca la
            posición de la empresa frente a este tema. Esta política debe
            establecer lineamientos y pautas para hacer cumplir los derechos de
            los empleados ante temas como trabajo infantil, libertad de
            asociación, trabajo libre, inclusión y equidad de género. De igual
            manera, es necesario establecer mecanismos para que los trabajadores
            puedan presentar quejas libremente en caso de incumplimiento,
            asegurando un seguimiento y resolución.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Standards
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - International System on International Standards:
                          Forced labour, freedom association, equal
                          remuneration, discrimination, child labour, work
                          hours, maternity protection, sickness insurance,
                          old-age insurance, invalidity insurance, indigenous
                          workers, holidays, social security, wages.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/dyn/normlex/en/f?p=1000:12000:::NO:::"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/dyn/normlex/<br
                            />en/f?p=1000:12000:::NO:::</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="child-labour">S21. Child labour</h5>
            It is defined by the International Labour Organisation (ILO) as "any
            work that deprives children of their childhood, their potential and their
            dignity, and is harmful to their physical and psychological development".
            For this reason, it is desirable that the company systematically regulates
            the personnel recruitment and refrain from employing workers under 16
            years of age on a full-time basis; or assigning children under 18 years
            of age to heavy, dangerous or interfere with their development and education.
          {:else}
            <h5 id="child-labour">S21. Trabajo infantil</h5>

            Esta definido por la Organización Internacional del Trabajo (OIT)
            como “todo trabajo que priva a los niños de su niñez, su potencial y
            su dignidad, y que es perjudicial para su desarrollo físico y
            psicológico”. Por esta razón, es conveniente que la empresa regule
            sistemáticamente la contratación de personal y se abstenga de
            emplear trabajadores menores de 16 años en jornada laboral completa
            o asignar a menores de 18 años actividades pesadas, peligrosas o que
            intervengan en su desarrollo y educación.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guidelines for improving policies and practices on
                          child labour and forced labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Elements to include in a Child Labour Policy -
                          Express and communicate commitment - Risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile/tools/child-labour/-/media/7453487819F44F3F883C7E1C9C917423.ashx"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.nl/<br
                            />en/garments-textile/tools/child-labour/-/media/<br
                            />7453487819F44F3F883C7E1<br />C9C917423.ashx</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Legal framework and indicators on Child Labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - International Conventions and frameworks -
                          Definitions - Root causes - Indicators for child
                          labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/child-labour/8-framework-indicators-child-labour.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.<br
                            />nl/-/media/imvo/files/kleding/<br
                            />child-labour/8-framework-<br
                            />indicators-child-labour.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Tools Remedies Towards a Better Workplace
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Recommendations for age verification
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/child-labour/7-age-verification.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.nl<br
                            />/-/media/imvo/files/kleding/<br
                            />child-labour/7-age-<br />verification.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="freedom-of-association">S22. Freedom of association</h5>
            The company should recognise the right to freedom of association and
            collective bargaining of its employees and respect the right to join
            trade unions. Therefore, it is advisable to establish procedures ensuring
            collective bargaining through designated representatives on any labour
            issues. Also, it is necessary to hold regular meetings to present petitions
            and discuss concerns or grievances that may arise. The company should
            refrain from any adverse action against workers who engage in legitimate
            trade union activities.
          {:else}
            <h5 id="freedom-of-association">S22. Libertad de asociación</h5>

            La empresa debe reconocer el derecho a la libertad de asociación y
            negociación colectiva de sus empleados, así como respetar la
            afiliación a sindicatos independientes. Es recomendable establecer
            procedimientos para garantizar la negociación colectiva a través de
            los representantes designados sobre cualquier aspecto laboral, así
            como realizar reuniones periódicas para la exposición de peticiones
            y discusión de preocupaciones o quejas que puedan presentarse. Así
            mismo, la empresa debe abstenerse de cualquier acción adversa o
            represalia contra aquellos trabajadores que participen en
            actividades sindicales legitimas.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="forced-labour">S23. Forced Labour</h5>
            It is essential to create mechanisms ensuring free work within the company
            and document the procedures. In this regard, the employee's freedom to
            leave their employment with prior notice within a reasonable time should
            be ensured. Therefore, the company should refrain from withholding identity
            documents or personal belongings to prevent employees from leaving their
            jobs. Similarly, the company should ensure that it does not withhold
            wages, bonuses or make deductions for disciplinary measures or other
            activities not authorised by national legislation and international conventions.
            The company should also ensure that it does not employ labour from agencies
            or companies involved in human trafficking or other forms of forced labour.
            In addition, the company should promptly issue letters of release or
            other appropriate documents, if required.
          {:else}
            <h5 id="forced-labour">S23. Trabajo forzoso</h5>
            Es importante generar mecanismos para asegurar el trabajo libre dentro
            de la empresa, así como documentar los procedimientos que se llevan a
            cabo para tal fin. En este sentido, se debe asegurar la libertad de los
            empleados para dejar su empleo con previo aviso y dentro de un periodo
            razonable. La empresa debe abstenerse de retener documentos de identidad
            u objetos personales que le impidan al empleado abandonar su puesto de
            trabajo. De igual manera la empresa debe asegurarse de no retener salarios,
            bonos o realizar deducciones por medidas disciplinarias u otras actividades
            no autorizadas por la legislación nacional y convenios internacionales.
            Así mismo, la empresa debe asegurarse de no emplear mano de obra de agencias
            o empresas involucradas en trata de personas u otra forma de trabajo
            forzado. Adicionalmente, la empresa debe expedir sin demora cartas de
            liberación o documentos a fines, en caso de ser requerido.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guidelines for improving policies and practices on
                          child labour and forced labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Code of conduct - Elements to include in a forced
                          Labour Policy - Express and communicate commitment -
                          Risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile/tools/child-labour/-/media/7453487819F44F3F883C7E1C9C917423.ashx"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.<br
                            />nl/en/garments-textile/tools<br
                            />/child-labour/-/media/<br
                            />7453487819F44F3F883C7<br />E1C9C917423.ashx</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Legal framework and indicators on Forced Labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - International Conventions and frameworks -
                          Definitions - Indicators for foced labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/child-labour/9-framework-indicators-forced-labour.pdf"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.<br
                            />nl/-/media/imvo/files/kleding/<br
                            />child-labour/9-framework-<br
                            />indicators-forced-labour.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="discrimination-in-employment">
              S24. Discrimination in employment
            </h5>
            The company should develop and implement policies and mechanisms to identify,
            address and remedy different types of discrimination, including those
            rooted in cultural traditions. These policies should ensure that hiring,
            promotion, wages and training are impartial and not linked to discriminatory
            actions. For example, job advertisements do not refer to discriminatory
            criteria such as gender, age, or race unless part of an equal opportunity
            programme. Likewise, when hiring personnel, do not ask for information
            regarding marital status, pregnancy, number of dependents or other information
            that could lead to discriminatory hiring. For this purpose, it is necessary
            to provide appropriate training according to its policies to hiring managers.
            The company should provide channels to report incidents or complaints
            about discriminatory actions in the work environment without adverse
            action. In addition, appropriate communication channels need to be established
            to manage interpersonal relationships and foster the adoption of organisational
            values among its members. For this reason, it is essential to check that
            the rules, policies, values and operating procedures are aligned towards
            a corporate culture free of discrimination.
          {:else}
            <h5 id="discrimination-in-employment">
              S24. Discriminación laboral
            </h5>
            La empresa debe desarrollar e implementar políticas y mecanismos para
            identificar, atender y dar solución a diferentes tipos de discriminación,
            incluyendo aquellos arraigados en tradiciones culturales. Estas políticas
            deben asegurar que aspectos relativos a contratación, promoción, salarios
            y capacitación sean completamente imparciales y no se vinculen con acciones
            discriminatorias. Es conveniente asegurarse de que los anuncios de empleo
            no hagan referencia a criterios discriminatorios como género, edad o
            raza, a menos de que sea parte de algún programa de igualdad de oportunidades.
            Así mismo, al momento de la contratación de personal no se debe solicitar
            información referente al estado civil, embarazo, número de dependientes
            u otra información que radique en contratación discriminatoria. Para
            este fin es necesario proporcionar la capacitación correspondiente según
            las políticas de la empresa a los encargados de contratación. Se deben
            facilitar medios y conductos a través de los cuales los empleados puedan
            reportar incidentes o quejas acerca de acciones discriminatorias en el
            entorno laboral, sin riesgo a represalias o acciones adversas. Es necesario
            establecer canales de comunicación adecuada, que gestionen las relaciones
            interpersonales y fomenten la adopción de valores organizacionales entre
            sus miembros; hasta lograr que cada uno de ellos se identifique con dichos
            valores. Por esta razón es importante revisar que las normas, políticas,
            valores y procedimientos operativos se encuentran alineados hacia una
            cultura organizacional libre de discriminación.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Equality at work: The continuing challenge
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          Capacidad institucional para velar por la no
                          discriminacion, medios para reducir la brecha
                          salarial, relativo a la paternidad y paternidad,
                          medidas para evitar la discriminacion
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/ilc/ILCSessions/previous-sessions/100thSession/reports/reports-submitted/WCMS_154779/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/ilc/<br
                            />ILCSessions/previous-sessions<br
                            />/100thSession/reports/reports<br
                            />-submitted/WCMS_154779/lang-<br />-en/index.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="equal-pay">S25. Equal pay</h5>
            It is desirable to establish policies and procedures to ensure that men
            and women working in the enterprise under similar conditions of position
            have equal opportunities for remuneration, training and promotion. The
            company should be developed mechanisms to regulate and prevent the wage
            gap between men and women and establish a method for establishing equitable
            wages.
          {:else}
            <h5 id="equal-pay">S25. Salario equitativo</h5>
            Es conveniente establecer políticas y procedimientos que aseguren a hombres
            y mujeres laborando en la empresa bajo condiciones similares de puesto
            y antigüedad, tener las mismas oportunidades de remuneración, capacitación
            y promoción. Se deben desarrollar mecanismos que regulen y eviten la
            diferencia salarial entre hombres y mujeres y establezcan un método para
            el establecimiento de salarios equitativos.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          An introductory guide Equal Pay
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2013)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Why equal remuneration for work of equal value
                          matters - The gender pay gap - Comparing jobs and
                          determining equal value - Wage fixing and equal pay
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/publications/WCMS_216695/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global<br
                            />/publications/WCMS_216695/<br
                            />lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="inclusion">S26. Inclusion</h5>
            The company should be committed to enabling people with physical limitations
            or adverse health conditions to obtain employment within the organisation
            and establish working conditions according to their needs.
          {:else}
            <h5 id="inclusion">S26. Inclusión</h5>
            La empresa debe comprometerse a generar acciones para permitir a personas
            con limitaciones físicas o condiciones de salud adversas obtener empleo
            dentro de la organización, así como también establecer condiciones laborales
            según sus necesidades.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Equal Opportunities in Recruitment A Guide for
                          Employers
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          S Welland, Work Experience Manager, The University of
                          Birmingham (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Age, Disability gender reassignment, Marriage and
                          civil partnership, Pregnancy and maternity, Race,
                          Religion or belief, Sex, Sexual orientation
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.birmingham.ac.uk/Documents/partners/employers/equal-opps.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.birmingham.<br
                            />ac.uk/Documents/partners<br
                            />/employers/equal-opps.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="human-risks">S27. Human and labour rights risks</h5>
            The company must establish mechanisms to identify and assess human rights
            and labour rights risks. These procedures should be based on reliable
            information and a thorough understanding of trends. These mechanisms
            should prioritise the most significant risks, timely follow-up and response.
            In addition, the company should monitor that its procedures and activities
            are aligned with company policies and values. One way to monitor these
            processes is through internal audits assessing human rights and labour
            rights. The company should commit to preventing workplace harassment
            by providing training to managers on how to deal with cases of workplace
            harassment. Likewise, it is necessary to communicate to employees the
            obligation to refrain from violent or abusive behaviour towards colleagues.
            In addition, the company should be thoroughly investigated any issue
            or complaint of workplace harassment and take preventive, corrective
            and disciplinary measures to prevent a recurrence. Written procedures
            should be developed for the submission, follow-up and resolution of employee
            complaints or concerns regarding human or labour rights without any retaliation.
            In addition, corporate values need to be fostered among all employees
            to create a stable work environment and provide safe and dignified workplaces.
          {:else}
            <h5 id="human-risks">
              S27. Riesgos en derechos humanos y laborales
            </h5>
            <div>
              Es indispensable que la empresa establezca mecanismos para
              identificar y evaluar riesgos, oportunidades y efectos en materia
              de derechos humanos y laborales. Estos procedimientos deben
              basarse en información confiable y profundo entendimiento de las
              tendencias emergentes. Estos mecanismos deben permitir priorizar
              los riesgos más significativos y dar seguimiento y respuesta
              oportuna. La empresa debe monitorear que sus procedimientos y
              actividades se encuentren alineados con las políticas y valores
              empresariales. Una forma de monitorear estos procesos es mediante
              auditorías internas que evalúen el desempeño en materia de
              derechos humanos y laborales. La empresa debe comprometerse a
              evitar el acoso laboral proporcionando capacitación a sus
              directivos sobre el manejo de casos de acoso laboral, así como
              también comunicar a sus empleados la obligación de abstenerse de
              conductas violentas o abusivas hacia sus compañeros. Así mismo, se
              debe investigar a fondo cualquier caso o denuncia de acoso laboral
              y adoptar medidas preventivas, correctivas y disciplinarias que
              eviten la nueva manifestación de conductas similares.
            </div>
            <br />
            <div>
              Se deben desarrollar procedimientos escritos para presentar, dar
              seguimiento y solucionar quejas o preocupaciones de los empleados
              en materia de violación de derechos humanos o laborales sin riesgo
              a represalias de ningún tipo. Es necesario fomentar los valores
              empresariales entre todos los empleados para generar clima laboral
              sano y proporcionar espacios de trabajo seguros y dignos.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Assessment and Management of Environmental and Social
                          Risks and Impacts
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standards for risk management: Labor and Working
                          Conditions, Resource - Efficiency and Pollution
                          Prevention, Community Health, Safety, and Security,
                          Cultural Heritage.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/performance-standards/ps1"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps<br
                            />/wcm/connect/topics_ext_<br
                            />content/ifc_external_<br
                            />corporate_site/sustainability<br
                            />-at-ifc/policies-standards<br
                            />/performance-standards/ps1</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Safe and healthy working environments free from
                          violence and harassment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Violence and harassment in the world of work -
                          Action at the workplace level - regulatory frameworks
                          addressing violence and harassment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/topics/safety-and-health-at-work/resources-library/publications/WCMS_751832/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global<br
                            />/topics/safety-and-health<br
                            />-at-work/resources-library<br
                            />/publications/WCMS_751832/<br
                            />lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD Guideliness for Multinational Enterprises
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines establishing a code of responsible
                          business conduct also applies to national companies -
                          Identify and mitigate risks, Human Rights,
                          Environment, Anti-corruption, Consumer interests,
                          Science and technology
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://ellenmacarthurfoundation.org/a-new-textiles-economy"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://ellenmacarthur<br
                            />foundation.org/a-new-<br />textiles-economy</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due diligence Guidance for Responsible supply chains
                          in the Garment and Footwear sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines to improve the supply chain in the
                          textile industry, the relationship with stakeholders
                          and labor practices. - Applicable for Small and
                          medium-sized enterprises (SMEs) - Presents the most
                          common risks in the textile industry and
                          recommendations to avoid them
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/industry/inv/mne/responsible-supply-chains-textile-garment-sector.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />industry/inv/mne/responsible<br
                            />-supply-chains-textile-<br />garment-sector.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Preventing Workplace Harassment and Violence
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE et al. (2017)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Definition - Preventing, identifying and maneging -
                          Implementation and follow-up
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/violence/preventing-workplace-harassment.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br
                            />/violence/preventing-<br
                            />workplace-harassment.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br
                            />simple-health-safety/risk<br
                            />/risk-assessment-template-<br
                            />and-examples.htm#article</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="human-policy">S28. Human and labour rights policy</h5>
            <div>
              For the timely identification and proper handling of human and
              labour rights misuses, it is essential to have a policy
              establishing its position on this issue. Furthermore, the policy
              should establish guidelines for the enforcement of employees'
              rights and the procedure to be followed in each case. In addition,
              the policy should set out the company's commitment to comply with
              local legal requirements and international guidelines. Finally,
              senior management should approve this document and make it known
              to all employees.
            </div>
            <br />
            <div>
              The company should investigate and document the general human
              rights situation in its areas, avoid systematic misuses, identify
              solutions, and reduce the risk of contributing to these actions.
              To this end, it is advisable to generate dialogue with civil
              society organisations, authorities and other stakeholders. It is
              also advisable for the company to designate one or more persons
              with experience in this area to be responsible for the policies
              and plans that make up the organisation and ensure the procedures
              are correctly applied.
            </div>
          {:else}
            <h5 id="human-policy">
              S28. Política de derechos humanos y laborales
            </h5>
            <div>
              Para la identificación oportuna y buen manejo de casos de
              violación a derechos humanos y laborales es indispensable contar
              con una política que establezca la posición de la empresa frente a
              este tema. Esta política debe establecer lineamientos y pautas
              para hacer cumplir los derechos de los empleados, así como el
              procedimiento a seguir según cada caso. La política debe
              establecer el compromiso de la empresa por cumplir los requisitos
              legales locales y los lineamientos internacionales. Este documento
              debe estar aprobada por la alta gerencia y ser del conocimiento de
              todos los empleados.
            </div>
            <br />
            <div>
              La empresa debe investigar y documentarse acerca de la situación
              general de los derechos humanos en las áreas donde opera, evitando
              violaciones sistemáticas, identificando soluciones y reduciendo el
              riesgo de contribuir en estas acciones. Para este fin es
              recomendable generar el diálogo con organizaciones civiles,
              autoridades y otras instancias interesadas Así mismo, es
              conveniente que la empresa designe a una o mas personas con
              experiencia en el tema, como responsables de las políticas y
              planes que conforman la organización y monitorear de manera
              periódica que los procedimientos se apliquen correctamente.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due diligence Guidance for Responsible supply chains
                          in the Garment and Footwear sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines to improve the supply chain in the
                          textile industry, the relationship with stakeholders
                          and labor practices. - Applicable for Small and
                          medium-sized enterprises (SMEs) - Presents the most
                          common risks in the textile industry and
                          recommendations to avoid them
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/industry/inv/mne/responsible-supply-chains-textile-garment-sector.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/industry<br
                            />/inv/mne/responsible-supply<br
                            />-chains-textile-garment-<br />sector.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org<br
                            />/investment/due-diligence<br
                            />-guidance-for-responsible<br
                            />-business-conduct.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          How to Develop a Human Rights Policy
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          United Nations Global Compact (2015)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - What is a human rights policy? - Why develop a human
                          rights policy? - What are the key components of a
                          human rights policy?
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.unglobalcompact.org/library/22"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.unglobalcompact<br />.org/library/22</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Tools Remedies Towards a Better Workplace
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Tools to improve the sustainable performance and
                          labour conditions of the textile industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.<br
                            />nl/en/garments-textile</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guidelines for improving policies and practices on
                          child labour and forced labour
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Code of conduct - Elements to include in a child
                          Labour Policy - Elements to include in a forced Labour
                          Policy - Express and communicate commitment - Risk
                          assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile/tools/child-labour/-/media/7453487819F44F3F883C7E1C9C917423.ashx"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.<br
                            />nl/en/garments-textile/tools<br
                            />/child-labour/-/media/7453487<br
                            />819F44F3F883C7E1C9C917423<br />.ashx</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="employee-benefits">S3. Social Security</h4>
            Workers have the right to holidays, health coverage and other employment
            benefits to ensure decent working conditions. For this reason, the company
            must be aware of local, national legislation and international guidelines
            on this issue. An adequate employment benefits package is also conducive
            to employee well- being and satisfaction, positively impacting productivity
            and commitment. The company must ensure its employees' physical, mental
            and social well-being, as the conditions of its human capital are directly
            linked to productivity and efficiency. One of the significant causes
            of absenteeism is accidents and occupational diseases caused by unsafe
            conditions, stress and fatigue, among other factors that affect workers'
            performance. For this reason, it is crucial to address these circumstances
            through health campaigns, medical care, ergonomics programmes and actions
            promoting the employees' health and well-being.
          {:else}
            <h4 id="employee-benefits">S3. Seguridad social</h4>
            Los trabajadores tienen derecho a contar con vacaciones, cobertura de
            salud y otras prestaciones laborales que aseguren condiciones de trabajo
            dignas. Por tal razón es indispensable que la empresa este al tanto de
            la legislación local y nacional, así como los lineamientos internacionales
            en este tema. Así mismo, un paquete adecuado de prestaciones laborales
            propicia el bienestar y satisfacción del trabajador, impactando positivamente
            en la productividad y compromiso de los empleados hacia la empresa. La
            empresa debe velar por el bienestar físico, mental y social de sus empleados
            ya que las condiciones de su capital humano están directamente ligadas
            con la productividad y eficiencia. Una de las mayores causas de ausentismo
            laboral son los accidentes y enfermedades ocupacionales causados por
            condiciones inseguras, estrés y cansancio entre otros factores que afectan
            el rendimiento de los trabajadores. Por esta razón, es crucial anteponerse
            a estas circunstancias mediante campañas de salud, atención médica adecuada,
            programas de ergonomía y diversas acciones encaminadas a fomentar la
            salud y bienestar de los empleados.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Standards
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - International System on International Standards:
                          Forced labour, freedom association, equal
                          remuneration, discrimination, child labour, hours of
                          work, maternity protection, sickness insurance,
                          old-age insurance, invalidity insurance, indigenous
                          workers, holidays, social security, wages.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/dyn/normlex/en/f?p=1000:12000:::NO:::"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/dyn<br
                            />/normlex/en/f?p=1000:12000<br />:::NO:::</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="wages-and-opportunities">S4. Wages and opportunities</h4>
            <h5>S41. Wages</h5>

            The company must ensure that it provides its employees with a fair
            and sufficient wage to cover basic needs such as housing, food,
            clothing for themselves and their dependents. Wages should be paid
            on time and in the amount stated in labour contracts, without
            deductions not authorised by national legislation. The company must
            be aware of the legal minimum wage where it operates and comply with
            it. In the absence of a national minimum wage, the company should
            calculate the cost of living in its operation area and ensure that
            the basic needs of employees and their dependents are covered.
            Likewise, part-time workers should receive wages and benefits
            commensurate with full-time workers.
          {:else}
            <h4 id="wages-and-opportunities">S4. Sueldos y oportunidades</h4>
            <h5>S41. Salarios</h5>

            La empresa debe asegurarse de proporcionar a sus empleados un
            salario justo y suficiente para cubrir necesidades básicas como
            vivienda, alimentación, vestido para ellos y sus dependientes. El
            salario debe ser pagado en tiempo y forma de acuerdo al día y monto
            establecidos en los contratos laborales, sin deducciones no
            autorizadas por la legislación nacional. La empresa debe estar al
            tanto del salario mínimo legal en el lugar donde opera y dar
            cumplimiento. En caso de no existir un salario mínimo nacional, la
            empresa debe calcular el costo de vida en su zona de operaciones y
            garantizar la cobertura de las necesidades básicas de los empleados
            y sus dependientes. Así mismo, los trabajadores de tiempo parcial
            deben recibir salarios y beneficios proporcionales a los
            trabajadores de tiempo completo.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Standards
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - International System on International Standards:
                          Forced labour, freedom association, equal
                          remuneration, discrimination, child labour, hours of
                          work, maternity protection, sickness insurance,
                          old-age insurance, invalidity insurance, indigenous
                          workers, holidays, social security, wages.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/dyn/normlex/en/f?p=1000:12000:::NO:::"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/dyn<br
                            />/normlex/en/f?p=1000:12000<br />:::NO:::</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Minimum wage policy guide
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - How to define a minimun wage? - Setting and
                          adjusting minimun wage levels - Monitoring the effects
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/topics/wages/minimum-wages/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global<br
                            />/topics/wages/minimum-wages<br
                            />/lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="recruitment-and-promotion">
              S42. Recruitment and promotion
            </h5>
            <div>
              The company should advertise vacancies so that any eligible
              candidate has an equal chance of getting the job. However,
              according to the University of Birmingham, it is possible to take
              simple steps to ensure proper recruitment (Welland, 2012).<br />
              <li>
                Job advertisements and job descriptions: Avoid referencing
                skills or characteristics that apply directly or indirectly to a
                particular type of person. Focus on the actual requirements
                needed to perform the role, analyse the specific job, and
                consider the position's roles, responsibilities, and
                competencies to define the training and experience required.
              </li>
              <li>
                Pre-selection: Avoid bias and stereotyping of candidates and
                ensure consistency. Have at least two people carry out the
                pre-selection against a set of pre-determined criteria.
              </li>
              <li>
                Interviews: Again, avoid candidate bias and stereotyping and
                ensure consistency by having at least two people conduct the
                interviews. Make reasonable accommodations when interviewing a
                person with a disability. Do not make assumptions based on
                appearance and avoid asking discriminatory questions about
                pregnancy, children or childcare, working on holy days,
                relationships or marriage, which are considered illegal.
              </li>
            </div>
          {:else}
            <h5 id="recruitment-and-promotion">
              S42. Reclutamiento y promoción
            </h5>
            <div>
              La empresa debe publicar las convocatorias de puestos vacantes
              dando oportunidad a que cualquier candidato que cumpla los
              requisitos tenga las mismas oportunidades de obtener el empleo.
              Según la Universidad de Birmingham, hay algunas medidas sencillas
              que puede tomar para asegurarse de realizar una adecuada selección
              de personal (Welland, 2012).
              <br />
              <li>
                Anuncios y descripciones de puestos de trabajo: evite las
                referencias por aptitudes o características que se apliquen
                directa o indirectamente a un tipo concreto de persona. Céntrese
                en los requisitos reales necesarios para desempeñar la función,
                analizando el puesto específico y teniendo en cuenta las
                funciones, responsabilidades y competencias del puesto para
                definir la formación y la experiencia requeridas.
              </li>
              <li>
                Preselección: evite los prejuicios y los estereotipos de los
                candidatos y asegúrese de que haya coherencia. Haga que al menos
                dos personas lleven a cabo la preselección en función de una
                serie de criterios predeterminados.
              </li>
              <li>
                Entrevistas: Una vez más, evite los prejuicios y los
                estereotipos de los candidatos y garantice la coherencia
                haciendo que al menos dos personas realicen las entrevistas.
                Haga ajustes razonables cuando entreviste a una persona
                discapacitada. No haga suposiciones basadas en la apariencia y
                evite hacer preguntas discriminatorias, como por ejemplo sobre
                el embarazo, los hijos o el cuidado de los niños, el trabajo en
                días religiosos, las relaciones o el matrimonio, que se
                consideran ilegales.
              </li>
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          General principles and operational guidelines for fair
                          recruitment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2019)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - General principles and operational guidelines for
                          fair recruitment and the definition of recruitment
                          fees and related costs
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/topics/labour-migration/publications/WCMS_536755/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global<br
                            />/topics/labour-migration/<br
                            />publications/WCMS_536755/<br
                            />lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Fair Recruitment Initiative Strategy 2021-2025 -
                          Taking stock, moving forward
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2021)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Strategy for national and international recruitment
                          under international labour standards
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/topics/fair-recruitment/WCMS_817166/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global<br
                            />/topics/fair-recruitment/<br
                            />WCMS_817166/lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Establishing Fair Recruitment Processes
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2021)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Training course with five modules covering different
                          aspects of fair recruitment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/topics/labour-migration/publications/WCMS_682737/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global/<br
                            />topics/labour-migration/<br
                            />publications/WCMS_682737/<br
                            />lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Equal Opportunities in Recruitment A Guide for
                          Employers
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Welland S., University of Birmingham (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Age, Disability gender reassignment, Marriage and
                          civil partnership, Pregnancy and maternity, Race,
                          Religion or belief, Sex, Sexual orientation
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.birmingham.ac.uk/Documents/partners/employers/equal-opps.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.birmingham.ac.uk<br
                            />/Documents/partners/<br
                            />employers/equal-opps.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="job-satisfaction">S43. Job Satisfaction</h5>

            The company should develop mechanisms to determine its employees'
            degree of job satisfaction. This will provide information to decide
            whether or not it is necessary to change or implement new strategies
            to improve the work environment and reduce turnover. Measuring job
            satisfaction can be done through questionnaires, which employees can
            answer privately, but which do not provide 100% honesty. A baseline
            can track changes and calculate average satisfaction per employee if
            job satisfaction is measured regularly. Follow-up questionnaires can
            include interviews and discussions. These personal checks take time
            and effort but create a deeper relationship with the employee.
            Always manage the responses responsibly and confidentially.
          {:else}
            <h5 id="job-satisfaction">S43. Satisfacción laboral</h5>

            Es recomendable que la empresa genere mecanismos para determinar el
            grado de satisfacción laboral de sus empleados. Esto proporcionará
            información para determinar si es necesario cambiar o implementar
            nuevas estrategias para mejorar el ambiente de trabajo y reducir la
            tasa de rotación. La medición de la satisfacción en el trabajo puede
            realizarse mediante cuestionarios, que pueden ser contestados por
            los empleados de forma privada, pero que no proporcionan un 100% de
            honestidad. Si se mide la satisfacción en el trabajo con
            regularidad, se puede utilizar una medición de referencia para
            seguir los cambios y calcular la satisfacción media por empleado.
            Los cuestionarios de seguimiento pueden incluir entrevistas y
            discusiones, estas comprobaciones personales requieren tiempo y
            esfuerzo, pero crean una relación más profunda con el empleado.
            Garantice siempre un tratamiento responsable y confidencial de las
            respuestas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Human Stress, Work and Job Satisfaction. a Critical
                          Approach
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (1983)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Psychophysiology of human stress -The needs and
                          satisfactions of work - Stress mechanisms and
                          manifestations in work - Remedial suggestions
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/global/topics/safety-and-health-at-work/resources-library/publications/WCMS_250134/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/global/<br
                            />topics/safety-and-health-at<br
                            />-work/resources-library/<br
                            />publications/WCMS_250134/<br
                            />lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Measuring Job Satisfaction: 7 Questionnaires, Surveys,
                          & Scales
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          positivepsychology.com
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Questionnaires and surveys to measure job
                          satisfaction
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://positivepsychology.com/job-satisfaction-questionnaires-surveys/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://positivepsychology.<br
                            />com/job-satisfaction-<br
                            />questionnaires-surveys/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="vocational-education">
              S44. Continuing vocational education and training (CVET)
            </h5>
            It is desirable to ensure that employees have adequate job development,
            providing the essential training to perform their duties and opportunities
            to advance their skills and knowledge. It is essential to include social
            and environmental training and education in this regard. A well-developed
            training plan is based on employee input and assessing the competencies
            required for a specific role.
          {:else}
            <h5 id="vocational-education">S44. Desarrollo Profesional</h5>

            Es conveniente asegurarse que los empleados cuenten con un
            desarrollo laboral adecuado, proporcionando no solo la capacitación
            básica para realizar sus funciones, si no también generando
            oportunidades para que aumenten sus habilidades y conocimientos para
            avanzar dentro de la empresa. En este sentido es importante incluir
            formación y educación en materia social y ambiental. Un plan de
            formación bien desarrollado es elaborado por los directivos y se
            basa en las aportaciones de los empleados y en la evaluación de las
            competencias necesarias para una función específica.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Skills Development and Lifelong Learning
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Building capacity within workers - Success cases
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/skills/areas/skills-policies-and-systems/WCMS_761035/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/skills<br
                            />/areas/skills-policies-and<br
                            />-systems/WCMS_761035/lang-<br />-en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Skills Development and Training in SMEs
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2013)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Types of training - Assessing skills - Motivation
                          and collaboration for training.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://read.oecd-ilibrary.org/industry-and-services/skills-development-and-training-in-smes_9789264169425-en#page1"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://read.oecd-ilibrary.org<br
                            />/industry-and-services/skills-<br
                            />development-and-training-in-smes<br
                            />_9789264169425-en#page1</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="occupational-health-and-safety">
              S5. Occupational Health and Safety
            </h4>
            The company must provide a clean, safe and healthy environment for all
            its employees. To this end, it is necessary to have a health and safety
            committee, develop policies, establish actions and awareness-raising
            mechanisms to reinforce safety as organisational culture. Some of the
            essential activities to be carried out are integrating monitoring systems
            for production processes, machinery and equipment, guaranteeing their
            correct functioning and personnel safety. Likewise, workers and managers
            must be appropriately trained to respond to emergencies. The company
            should have a first aid kit and trained personnel to provide the required
            attention. In addition, mechanisms should be in place to maintain clean
            and comfortable conditions in work areas, including appropriate ventilation,
            lighting, temperature and sanitary facilities for all employees.
          {:else}
            <h4 id="occupational-health-and-safety">
              S5. Seguridad y salud ocupacional
            </h4>
            La empresa debe proporcionar un entorno limpio, seguro y saludable para
            todos sus empleados. Para dicho fin es necesario contar con una comisión
            de seguridad e higiene, así como desarrollar las políticas necesarias
            para establecer las acciones correspondientes y establecer mecanismos
            de concientización que fortalezcan la seguridad como cultura organizacional.
            Algunas de las actividades básicas que realizar, es integrar sistemas
            de monitoreo de los procesos de producción, maquinaria y equipo, garantizando
            su correcto funcionamiento y la seguridad del personal. Así mismo, los
            trabajadores y gerentes deben contar con la capacitación suficiente para
            responder ante cualquier emergencia. La empresa debe contar con botiquín
            de primeros auxilios y personal capacitado para brindar la atención requerida.
            Se deben establecer mecanismos para mantener condiciones limpias y confortables
            en las áreas de trabajo, incluyendo ventilación, iluminación, temperatura
            e instalaciones sanitarias apropiadas para todos los empleados.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="occupational-health-policy">
              S51. Occupational health and safety policy
            </h5>
            <div>
              It is advisable to have a written policy on occupational health
              and safety aligned with the company's values. This policy should
              establish actions and guidelines to create safe work areas for all
              employees. The top management must approve this policy with a
              commitment to implement programmes and strategies for continuous
              improvement and must be made known to all company employees. It is
              also necessary to carry out efforts to adopt occupational health
              and safety values among the organisation members. The policy
              should clearly state the safety and health responsibilities tasks
              to be carried out and define responsibilities, targets, and
              period. It is also advisable for the company to designate staff
              members with expertise in the subject as responsible for the
              policies and plans and regularly monitor the correctly applied
              procedures.
            </div>
            <br />
            <div>
              The International Labour Organisation (ILO) states that the
              employer, in consultation with workers and their representatives,
              should establish a written Occupational Safety and Health (OSH)
              policy that is:
              <li>
                Specific to the organisation and appropriate to its size and the
                nature of its activities;
              </li>
              <li>
                Concise, clearly written, dated and made effective by the
                signature or endorsement of the employer or the most senior
                responsible person in the organisation;
              </li>
              <li>
                Communicated and easily accessible to all people in the
                workplace;
              </li>
              <li>Reviewed for continuing suitability; and</li>
              <li>Be made available to external stakeholders.</li>
            </div>
            <br />
            <div>
              The OSH management system shall be compatible with or integrated
              into other organisations' management systems. In addition, the OSH
              policy shall include, as a minimum, the following fundamental
              principles and objectives to which the organisation is committed:
              <li>
                Protect the safety and health of all members of the organisation
                by preventing work-related injuries, ill-health, diseases and
                incidents;
              </li>
              <li>
                Comply with relevant national OSH laws and regulations,
                voluntary programmes, OSH collective agreements and other
                requirements to which the organisation subscribes;
              </li>
              <li>
                Ensure that workers and their representatives are consulted and
                encouraged to participate in all elements of the OSH management
                system actively; and
              </li>
              <li>
                Continuously improve the performance of the OSH management
                system.
              </li>
            </div>
            <br />
            <div>
              Also, according to EU directive 89/654/EEC (EU, 1989), workplaces
              must comply with the following general obligations:
              <li>
                Circulation routes to the emergency exits and the exits
                themselves are kept clear at all times.
              </li>
              <li>
                Technical maintenance of the workplace, equipment and devices is
                carried out as quickly as possible.
              </li>
              <li>
                The workplace, equipment and devices are regularly cleaned to an
                adequate level of hygiene.
              </li>
              <li>
                Safety equipment and devices are regularly maintained and
                checked to prevent or eliminate hazards.
              </li>
              <li>
                The temperature of working premises should be suitable for human
                beings, considering the working methods used and the physical
                demands placed on workers.
              </li>
              <li>
                Working premises shall be of sufficient area, height and
                ventilation to enable workers to carry out their work without
                risk to their safety, health or well- being.
              </li>
            </div>
          {:else}
            <h5 id="occupational-health-policy">
              S51. Política de seguridad y salud ocupacional
            </h5>
            <div>
              Es conveniente contar con una política escrita en materia de
              seguridad y salud ocupacional que vaya alineada con los valores de
              la empresa, estableciendo acciones y lineamientos para generar
              áreas de trabajo seguras para todos los empleados. Así mismo es
              necesario realizar acciones encaminadas a la adopción de valores
              de seguridad y salud ocupacional entre sus miembros. Esta política
              debe estar aprobada por la alta gerencia manifestando el
              compromiso de implementar programas y estrategias de mejora
              continua y debe ser del conocimiento de todos los empleados de la
              empresa. La política debe expresar claramente las
              responsabilidades y tareas en materia de seguridad y salud que se
              deben de llevar a cabo, así como también, definir encargados,
              metas y establecer un marco de tiempo. Así mismo, es conveniente
              que la empresa designe a una o más personas con experiencia en el
              tema, como responsables de las políticas y planes que conforman la
              organización y monitorear de manera periódica que los
              procedimientos se apliquen correctamente.
            </div>
            <br />
            <div>
              La Organización Internacional del Trabajo (OIT) establece que el
              empleador, en consulta con los trabajadores y sus representantes,
              debe establecer por escrito una política de Seguridad y Salud en
              el Trabajo (SST) que sea:
              <li>
                Específica para la organización y adecuada a su tamaño y a la
                naturaleza de sus actividades;
              </li>
              <li>
                Concisa, claramente redactada, fechada y hecha efectiva mediante
                la firma o el aval del empleador o de la persona responsable de
                mayor rango en la organización;
              </li>
              <li>
                Comunicada y fácilmente accesible a todas las personas en su
                lugar de trabajo; Revisado para comprobar su idoneidad
                permanente; y
              </li>
              <li>
                Ponerse a disposición de las partes interesadas externas
                pertinentes, según proceda.
              </li>
            </div>
            <br />
            <div>
              El sistema de gestión de la SST debe ser compatible con otros
              sistemas de gestión de la organización o integrarse en ellos. Así
              mismo, la política de SST debe incluir, como mínimo, los
              siguientes principios y objetivos clave con los que la
              organización se compromete:
            </div>
            <br />
            <div>
              <li>
                Proteger la seguridad y la salud de todos los miembros de la
                organización mediante la prevención de lesiones, mala salud,
                enfermedades e incidentes relacionados con el trabajo;
              </li>
              <li>
                Cumplir con las leyes y reglamentos nacionales de SST
                pertinentes, los programas voluntarios, los convenios colectivos
                sobre SST y otros requisitos que la organización suscriba;
              </li>
              <li>
                Garantizar que los trabajadores y sus representantes sean
                consultados y animados a participar activamente en todos los
                elementos del sistema de gestión de la SST; y
              </li>
              <li>
                Mejorar continuamente el rendimiento del sistema de gestión de
                la SST.
              </li>
            </div>
            <br />
            <div>
              Así mismo, de acuerdo con la directiva de la UE 89/654/CEE (EU,
              1989), los lugares de trabajo deben cumplir con las siguientes
              obligaciones generales:
              <li>
                Las vías de circulación hacia las salidas de emergencia y las
                propias salidas se mantienen libres en todo momento.
              </li>
              <li>
                El mantenimiento técnico del lugar de trabajo, equipos y
                dispositivos se realiza con la mayor rapidez posible.
              </li>
              <li>
                El lugar de trabajo, equipos y dispositivos se limpian
                periódicamente hasta alcanzar un nivel de higiene adecuado.
              </li>
              <li>
                Los equipos y dispositivos de seguridad se mantienen y
                comprueban periódicamente para prevenir o eliminar riesgos.
              </li>
              <li>
                La temperatura de los locales de trabajo deberá ser adecuada
                para el ser humano, teniendo en cuenta los métodos de trabajo
                que se utilicen y las exigencias físicas que se impongan a los
                trabajadores.
              </li>
              <li>
                Los locales de trabajo deberán tener una superficie, altura y
                ventilación suficiente para que los trabajadores puedan realizar
                su trabajo sin riesgo para su seguridad, salud o bienestar.
              </li>
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Environmental, Health, and Safety Guideline
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2007)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - The Environmental, Health, and Safety (EHS)
                          Guidelines are technical reference documents with
                          general and industry-specific examples of Good
                          International Industry Practice - Physical hazards,
                          Personal protective equipment, disease prevention,
                          emergencies
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/ehs-guidelines/ehsguidelines"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/wcm/<br
                            />connect/topics_ext_content/ifc<br
                            />_external_corporate_site/<br
                            />sustainability-at-ifc/policies<br
                            />-standards/ehs-guidelines/ehsguidelines</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Worldwide Responsible Accredited Production
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Worldwide Responsible Accredited Production (WRAP)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Health and safety risk assessment - Risk management
                          guide
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://wrapcompliance.org/certification/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://wrapcompliance.org<br />/certification/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guidelines on occupational safety and health
                          management systems
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2001)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Framework for occupational safety and health
                          management systems - The occupational safety and
                          health management system in the organization -
                          Planning and implementation
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/safework/info/standards-and-instruments/WCMS_107727/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/safework<br
                            />/info/standards-and-instruments<br
                            />/WCMS_107727/lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Prepare a health and safety policy
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - How to write your policy
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/policy/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk/<br
                            />simple-health-safety/<br />policy/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Shakoor, Wasim (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Checklist for internal occupational health and
                          safety audits ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://repository.ucc.edu.co/bitstream/20.500.12494/20193/7/2020_FORMULACION_MEJORAMIENTO_ISO_AnexoB.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://repository.ucc.edu.co<br
                            />/bitstream/20.500.12494/20193<br
                            />/7/2020_FORMULACION_<br />MEJORAMIENTO<br
                            />_ISO_AnexoB.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ISO 45001
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Universidad Cooperativa de Colombia
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Lista de verificación para auditorías internas en
                          materia de seguridad y salud ocupacional
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://repository.ucc.edu.co/bitstream/20.500.12494/20193/7/2020_FORMULACION_MEJORAMIENTO_ISO_AnexoB.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://repository.ucc.edu.co<br
                            />/bitstream/20.500.12494/20193<br
                            />/7/2020_FORMULACION<br />_MEJORAMIENT<br
                            />_ISO_AnexoB.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="safety-committee">S52. Health and Safety Committee</h5>
            A health and safety committee comprising workers and management representatives
            should be established. This Committee should periodically inspect and
            monitor tidiness, cleanliness, potential hazards and safety in the work
            area. Once risks have been identified, the Committee should propose preventive
            measures and monitor compliance. The Committee should integrate visual
            warnings, understandable to all employees, to preserve order, health
            and safety. In addition, it must monitor noise, odour, air quality, lighting
            and vibrations in working areas, ensuring compliance with the relevant
            standards. Another of its responsibilities is to provide information
            to employees on safety, hygiene and occupational health. In addition,
            it provides advice to employees on how to identify risks in their work
            areas. All the Committee's activities must be duly documented following
            the applicable regulations.
          {:else}
            <h5>S52. Comisión de seguridad e higiene</h5>
            Se debe establecer una Comisión de seguridad e higiene integrada por
            trabajadores y representantes de la administración. Esta Comisión debe
            inspeccionar y monitorear periódicamente aspectos de orden, limpieza,
            situaciones de riesgo potencial y seguridad en el área de trabajo. Una
            vez identificados los riesgos, La Comisión debe encargarse de proponer
            medidas de prevención y vigilar su cumplimiento. La Comisión debe integrar
            advertencias visuales comprensibles para todos los empleados, que contribuyan
            a preservar el orden, la salud y la seguridad. Debe monitorizar el ruido,
            olor, calidad del aire, iluminación y vibraciones en las áreas de trabajo,
            asegurándose de cumplir con las normas correspondientes. Otra de sus
            responsabilidades es proporcionar información a los empleados en materia
            de seguridad, higiene y salud ocupacional. Adicionalmente, brinda asesoría
            a los empleados para que ellos mismos identifiquen riesgos en sus áreas
            de trabajo. Todas las actividades de la Comisión deberán ser debidamente
            documentadas, de acuerdo con la normatividad aplicable.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Roles of Health and Safety Committees
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          COSH, USA
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Hazard Identification, Evaluation, and Control -
                          Information and Education - Making Health and Safety
                          Committees Effective
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.coshnetwork.org/sites/default/files/supp_healthsafety_hndt.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.coshnetwork.org/<br
                            />sites/default/files/supp_<br
                            />healthsafety_hndt.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International first aid and resuscitation guidelines
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Federation of Red Cross and Red Crescent
                          Societies (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Chain of survival behaviours - Principles of first
                          aid education - Context: Disaster, Water, Pandemic,
                          Workplace - Breathing problems, trauma, medical
                          conditions, mental distress
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.globalfirstaidcentre.org/resource/international-first-aid-resuscitation-and-education-guidelines-2020-2/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.globalfirstaidcentre<br
                            />.org/resource/international-<br
                            />first-aid-resuscitation-and-<br
                            />education-guidelines-2020-2/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="personal-protection">
              S53. Personal protection equipment (PPE)
            </h5>
            It is the company's responsibility to conduct a job analysis and determine
            which activities require personal protection equipment to ensure the
            employees' safety. The company should provide PPE to employees at no
            cost or requirement, train them on its correct use and make them aware
            of the possible consequences of not using the equipment correctly. A
            detailed record of personnel trained for specific tasks should be kept
            to avoid risks when integrating new personnel into risky activities without
            prior training. The company should keep itself informed about new health
            and safety risks and protective equipment technologies. In the case of
            the use of chemicals, the company must ensure that it has the necessary
            permits and complies with the requirements. It is also crucial to provide
            these substances' proper storage, use and adequate personnel training.
            In addition, it is required to record and monitor substances in production
            and maintenance areas.
          {:else}
            <h5 id="personal-protection">S53. Equipo de seguridad</h5>
            Es responsabilidad de la empresa realizar un análisis de puestos y determinar
            que actividades requieren equipo de protección u otras que garanticen
            la seguridad de los empleados. La empresa debe proporcionar sin ningún
            costo o requisito el equipo de seguridad a los empleados, capacitarlos
            acerca de su correcto uso y concientizarlos sobre las posibles consecuencias
            en caso de no utilizar el equipo correctamente. Se debe mantener un registro
            detallado del personal que ha sido capacitado para ciertas tareas evitando
            riesgos al integrar a nuevo personal en actividades riesgosas sin previa
            capacitación. Es conveniente que la empresa se mantenga informada acerca
            de nuevas tecnologías sobre riesgos de seguridad y salud y equipos de
            protección. En caso de uso de sustancias químicas, la empresa debe asegurarse
            de cumplir con los permisos necesarios y cumplir con los requisitos establecidos.
            De igual manera, es crucial asegurar el correcto almacenamiento y uso
            de estas sustancias, así como la adecuada capacitación del personal.
            Es necesario registrar y monitorizar el uso de sustancias en áreas de
            producción y mantenimiento. Esto proporcionará la información suficiente
            para hacer un mejor manejo de ellas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Personal protective equipment (PPE) at work
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Health and Safety Executive, UK
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - What is PPE? - What do the Regulations require? -
                          Selection and use - The hazards and types of PPE
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/pubns/indg174.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br />/pubns/indg174.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Personal Protective Equipment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Health and Safety Authority, Ireland (2007)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Provision of Personal Protective Equipment -
                          Assessment of Personal Protective Equipment -
                          Maintenance and Replacement
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hsa.ie/eng/Publications_and_Forms/Publications/Retail/Gen_Apps_PPE.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hsa.ie/eng/<br
                            />Publications_and_Forms/<br
                            />Publications/Retail/Gen_<br />Apps_PPE.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="work-accidents">
              S54. Accidents at work and occupational diseases
            </h5>
            One of the Health and Safety Committee's functions is to investigate,
            record, and report accidents relating to health and safety and ensure
            that corrective action is taken through standardised procedures. Measures
            should be taken to eliminate possible causes of accidents, such as unsafe
            designs, defects, or situations that cause secondary effects or harm
            or threaten employees' health. To this end, it is advisable to have a
            risk identification plan that helps to reduce hazardous conditions systematically.
            It is also necessary to correctly record accidents, occupational illnesses
            and the resulting incapacity hours as part of personnel management.
          {:else}
            <h5 id="work-accidents">
              S54. Accidentes laborales y enfermedades profesionales
            </h5>
            Una de las funciones de la Comisión de seguridad e higiene es investigar,
            registrar y reportar los accidentes referentes a seguridad y salud y
            asegurarse de que se tomen acciones correctivas, a través de procedimientos
            estandarizados. Se deben tomar medidas para eliminar posibles causas
            de accidentes, como diseños inseguros, defectos, o situaciones que causen
            efectos secundarios o que puedan dañar o amenazar la salud de los empleados.
            Para este fin, es recomendable contar con un plan de identificación de
            riesgos que contribuya a reducir las condiciones peligrosas de manera
            sistemática. Así mismo, es necesario realizar el debido registro de los
            accidentes presentados, las enfermedades laborales y las horas de incapacidad
            derivadas de los hechos como parte de la administración de personal.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Recording and notification of occupational accidents
                          and diseases
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (1996)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Policy on recording, notification and investigation
                          of occupational accidents, occupational diseases and
                          dangerous occurrences, and related statistics -
                          Arrangements for recording - Arrangements for
                          notification
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/safework/info/standards-and-instruments/codes/WCMS_107800/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/safework<br
                            />/info/standards-and-instruments<br
                            />/codes/WCMS_107800/lang-<br />-en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Investigating accidents and incidents
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Health and Safety Executive, UK (2004)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Reducing risks and protecting people - The causes of
                          adverse events - A step by step guide to health and
                          safety investigations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/pubns/hsg245.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk/<br />pubns/hsg245.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Risk Assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2014)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Identify the hazards - Who might be harmed? - Record
                          your significant findings
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/pubns/indg163.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk/<br />pubns/indg163.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="ergonomics">S55. Ergonomics</h5>
            Any repetitive activity generates adverse effects on people's physical
            and mental health. For this reason, it is advisable to analyse each job
            in detail according to the physical requirements it demands. In this
            sense, according to the International Ergonomics Association, this concept
            refers to the body of scientific knowledge applied to the adaptation
            of work, systems and the environment according to the capacities, physical
            and mental limitations of its users, optimising efficiency, safety and
            well-being. For this reason, it is the company's responsibility to provide
            optimal working conditions adapted to the worker's specific needs. This
            will contribute to the well-being of the staff, reduce work-related accidents
            and occupational illnesses, and positively impact the company's productivity
            and job satisfaction.
          {:else}
            <h5 id="ergonomics">S55. Ergonomía</h5>
            Toda actividad repetitiva genera efectos adversos en la salud física
            y mental de las personas. Por esta razón es conveniente analizar a detalle
            cada puesto de trabajo según los requerimientos físicos que demanda.
            En este sentido, de acuerdo a la Asociación Internacional de Ergonomía,
            este concepto hace referencia al conjunto de conocimientos científicos
            aplicados a la adecuación del trabajo, los sistemas y el ambiente según
            las capacidades, limitaciones físicas y mentales de sus usuarios, optimizando
            la eficacia, seguridad y bienestar. Por tal motivo, es responsabilidad
            de la empresa brindar condiciones de trabajo optimas y adecuadas a las
            necesidades específicas del trabajador. Esto contribuirá al bienestar
            del personal, disminuirá accidentes de trabajo y enfermedades profesionales
            impactando positivamente en la productividad de la empresa y la satisfacción
            laboral.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Ergonomic Checkpoints
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2010)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Ergonomic checklist - Materials storage and handling
                          - Machine safety - Workstation design - Hazardous
                          substances and agents
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/safework/info/instr/WCMS_178593/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/safework<br
                            />/info/instr/WCMS_178593/<br
                            />lang--en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Improving working conditions and productivity in the
                          garment industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (1998)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Practical workstation - Ergonomics - Productive
                          machine safety, maintenance and environmental control
                          - Good lighting for quality products - Productivity
                          techniques
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/wcmsp5/groups/public/---ed_protect/---protrav/---safework/documents/instructionalmaterial/wcms_228220.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/wcmsp5<br
                            />/groups/public/---ed_protect<br
                            />/---protrav/---safework/<br
                            />documents/instructionalmaterial<br />/wcms_<br
                            />228<br />220.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Estudio de las condiciones ergonómicas del trabajo en
                          el sector textil
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Castelló Mercé, P. et al., Instituto de Biomecánica de
                          Valencia, España (2004)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Descripción de puestos en la industria textil -
                          Análisis de carga física por actividad -
                          Recomendaciones de mejora por puesto
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://gestion.ibv.org/gestoribv/index.php/productos/descargables/106-estudio-de-las-condiciones-ergonomicas-del-trabajo-en-el-sector-textil/file"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://gestion.ibv.org/<br
                            />gestoribv/index.php/productos<br
                            />/descargables/106-estudio-de-<br
                            />las-condiciones-ergonomicas-del<br
                            />-trabajo-en-el-sector-textil/file</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Ergonomía en el Sector Textil
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Fundación para la prevención de riesgos laborales,
                          España
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Herramientas para la evaluación de riesgos
                          ergonómicos - Conceptos basicos de ergonomía -
                          Principales riesgos ergonómicos en los diferentes
                          puestos de trabajo - Acciones para prevenir riesgos -
                          Metodología Quick Exposure Checklist (QEC) - Ejemplos
                          de aplicación
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://textil.ibv.org/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://textil.ibv.org/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="facilities">S56. Facilities</h5>
            With the support and participation of the Health and Safety Committee,
            the company must ensure that all employees are provided with adequate
            facilities to carry out their activities. In this sense, it is necessary
            to provide good lighting according to the performance activities, monitor
            noise levels in production areas, and have a regular maintenance programme.
            Also is needed to provide clean and sufficient sanitary facilities, drinking
            water and a canteen area separate from the production area. In addition,
            it is also advisable to carry out periodic audits or reviews to ensure
            that the aspects mentioned above are in good working order.
          {:else}
            <h5 id="facilities">S56. Instalaciones</h5>
            La empresa mediante el apoyo y participación de la Comisión de seguridad
            e higiene debe asegurarse de proporcionar a todos los empleados instalaciones
            adecuadas para realizar sus actividades. En este sentido es necesario
            evaluar y proporcionar la iluminación adecuada según la tarea a desarrollar,
            monitorizar el nivel de ruido en áreas de producción, contar con un programa
            de mantenimiento periódico, facilitar instalaciones sanitarias limpias
            y suficientes para todos los empleados, así como agua potable y un área
            de comedor independiente al área de producción. Así mismo, es conveniente
            realizar auditorías o revisiones periódicas para asegurar del buen estado
            y funcionamiento de los aspectos antes mencionados.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Improving working conditions and productivity in the
                          garment industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (1998)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Practical workstation - Productive machine safety,
                          maintenance and environmental control - Good lighting
                          for quality products - Productivity techniques
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/wcmsp5/groups/public/---ed_protect/---protrav/---safework/documents/instructionalmaterial/wcms_228220.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/wcmsp5<br
                            />/groups/public/---ed_protect<br
                            />/---protrav/---safework/documents<br
                            />/instructionalmaterial/<br />wcms_228220.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guide to the Safety, Health and Welfare at Work
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Health and Safety Authority, Ireland (2007)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Ventilation, room temperature, natural and
                          artificial lighting - Floors, walls, ceilings and
                          roofs of rooms - Fire Detection and Fire Fighting -
                          Sanitary and Washing Facilities
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hsa.ie/eng/Publications_and_Forms/Publications/General_Application_Regulations/Guide_to_the_Safety,_Health_and_Welfare_at_Work_General_Application_Regulations_2007.html"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hsa.ie/eng/<br
                            />Publications_and_Forms/<br
                            />Publications/General_<br
                            />Application_Regulations<br
                            />/Guide_to_the_Safety,_<br
                            />Health_and_Welfare_at_<br
                            />Work_General_Application<br
                            />_Regulations_2007.html</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Workplace amenities and work environment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          WorkSafe Victoria (2008)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Identifying and maintaining amenities and facilities
                          - Amenities - The work environment - Responding to
                          emergencies
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.worksafe.vic.gov.au/resources/compliance-code-workplace-amenities-and-work-environment"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.worksafe.vic<br
                            />.gov.au/resources/compliance<br
                            />-code-workplace-amenities<br
                            />-and-work-environment
                          </a></td
                        >
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Iluminación en el puesto de trabajo
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Instituto Nacional de Seguridad e Higiene en el
                          Trabajo, España (2015)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Magnitudes lumínicas - Propiedades ópticas -
                          Percepcion del color - Iluminación natural y
                          artificial
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.insst.es/documents/94886/96076/Iluminacion+en+el+puesto+de+trabajo/9f9299b8-ec3c-449e-81af-2f178848fd0a"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.insst.es/<br />documents/94886/96076<br
                            />/Iluminacion+en+el+<br />puesto+de+trabajo/<br
                            />9f9299b8-ec3c-449e-<br />81af-2f178848fd0a
                          </a></td
                        >
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Ergonomic Checkpoints
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2010)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Materials storage and handling - Workstation design
                          - Lighting
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/safework/info/instr/WCMS_178593/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org<br />/safework/info/instr<br
                            />/WCMS_178593/lang-<br />-en/index.htm
                          </a></td
                        >
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Design codes - buildings / structures
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Health and Safety Executive
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Control room design - Fire protection - Desingn
                          codes - Roadways
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/comah/sragtech/techmeasbuilding.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br />/comah/sragtech/<br
                            />techmeasbuilding.htm
                          </a></td
                        >
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Workplace health, safety and welfare
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guide for managers - Practice and guidance - The
                          health and safety toolbox - Involving your workforce
                          in health and safety - Consulting workers on health
                          and safety
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/pubns/indg244.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.<br />uk/pubns/indg244.htm
                          </a></td
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="fire-prevention">S57. Fire prevention</h5>
            It is essential to have a fire prevention plan and system in place. This
            plan should provide clear indications of what to do in the event of a
            fire. In this sense, it is necessary to have adequate systems and equipment
            and train personnel to activate them if necessary. It is also advisable
            to carry out periodic tests to ensure the correct functioning of these
            systems.
          {:else}
            <h5 id="fire-prevention">
              S57. Sistema de prevención de incendios
            </h5>
            Es indispensable contar con plan y sistema de prevención de incendios.
            Este plan debe proporcionar indicaciones claras de qué hacer en caso
            de generarse algún tipo de incendio. En este sentido es necesario contar
            con sistemas y equipo adecuado, así como capacitar al personal para accionarlo
            en caso de ser necesario. Así mismo es conveniente realizar pruebas periódicas
            que aseguren el correcto funcionamiento de dichos sistemas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Fire Risk Management
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Labour Organization (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Common causes of major fire disasters - Fire risk
                          reduction and control - Fire management systems
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ilo.org/safework/info/publications/WCMS_194781/lang--en/index.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ilo.org/<br
                            />safework/info/publications<br
                            />/WCMS_194781/lang-<br />-en/index.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guía básica sobre prevención de incendios
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          FREMAP Mutua Colaboradora con la Seguridad Social,
                          España (2015)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Naturaleza del fuego, Productos de la combustión,
                          Tipos de fuego, Métodos de extinción, Agentes
                          extintores, Sistemas de prevención, Plan de emergencia
                          en caso de incendio, Normas básicas, Instalación y
                          mantenimiento de equipos, Primeros auxilios
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.icv.csic.es/prevencion/Documentos/manuales/Guia_basica_sobre_Prevencion_de_Incendios.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.icv.csic.es<br
                            />/prevencion/Documentos<br
                            />/manuales/Guia_basica<br />_sobre_Prevencion_<br
                            />de_Incendios.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="emergency-plan">S58. Emergency plan</h5>
            The company should design and implement an emergency plan that defines
            an evacuation route and provides clear indications of floods, earthquakes,
            hurricanes, toxic gas emissions, chemical spills, fires, explosions and
            other phenomena that may occur in and around the working area. In addition,
            it is necessary to identify the hazardous activities carried out in the
            company and their potential consequences on the well-being of employees,
            the community and the environment. In this regard, the plan must specify
            detailed procedures, equipment, and training programmes to prevent accidents,
            deal with emergencies, and establish actions to avoid or mitigate the
            consequences. Periodic drills should be conducted at least once a year
            to show personnel how to react in certain circumstances and provide information
            about the company's emergency response capability. In the event of significant
            risk to the community, the company should provide a procedure to notify
            affected areas immediately and provide information on response actions
            and evacuation plans.
          {:else}
            <h5 id="emergency-plan">S58. Plan de emergencias</h5>
            La empresa debe diseñar e implementar un plan de emergencias que defina
            una ruta de evacuación y proporciones indicaciones claras en caso de
            inundaciones, terremotos, huracanes, emisión de gases tóxicos, vertidos
            químicos, incendios, explosiones y otros fenómenos que puedan llegar
            a presentarse en el área de trabajo y sus inmediaciones. Es necesario
            identificar las actividades peligrosas realizadas en la empresa y sus
            potenciales consecuencias sobre el bienestar de los empleados, comunidad
            y medio ambiente. En este sentido, el plan debe especificar los procedimientos
            detallados, equipos y programas de capacitación para prevenir accidentes
            y atender emergencias, así como también, debe establecer acciones para
            prevenir o mitigar las consecuencias. Así mismo, se deben realizar simulacros
            periódicos, al menos una vez al año, que muestren al personal como reaccionar
            en determinadas circunstancias y proporcione información acerca de la
            capacidad de respuesta de la empresa ante emergencias. En caso de existir
            un riesgo significativo sobre la comunidad, la empresa debe proporcionar
            un procedimiento que le permita notificar a las zonas afectadas de manera
            inmediata e informar sobre acciones de respuesta y planes de evacuación.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          How to Plan for Workplace Emergencies and Evacuations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OSHA, USA (2001)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - What is an emergency action plan? - How do you
                          protect yourself, your employees, and your business? -
                          How do you develop an evacuation policy and
                          procedures?
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.osha.gov/sites/default/files/publications/osha3088.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.osha.gov<br />/sites/default/files<br
                            />/publications/<br />osha3088.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="suppliers">S6. Suppliers</h4>
            To ensure a stable and reliable supply chain, it is advisable to establish
            good relationships with suppliers and business partners. In such a way,
            there is adequate communication, commitment, and trust between the stakeholders.
            For this purpose, it is necessary to have a thorough knowledge of the
            suppliers, their labour practices, production conditions, the origin
            of raw materials and their relations with stakeholders, among other aspects
            to consider.
          {:else}
            <h4 id="suppliers">S6. Proveedores</h4>
            Para garantizar una cadena de suministro estable y confiable es recomendable
            entablar buenas relaciones con los proveedores y socios comerciales de
            la empresa. De tal manera que exista una adecuada comunicación, compromiso
            y confianza entre las partes involucradas. Para este fin es necesario
            conocer a fondo a los proveedores, sus practicas laborales, sus condiciones
            de producción, el origen de materias primas y sus relaciones con grupos
            de interés entre otros aspectos a considerar.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="fair-agreements">S62. Fair agreements with suppliers</h5>
            It is recommended that the company has a supplier contracting procedure,
            which establishes the requirements and conditions and the rights and
            obligations of both parties. This procedure should detail the steps to
            evaluate suppliers before hiring, the aspects to consider, and the expected
            results. It should also integrate the guidelines to establish prices,
            delivery times and payment conditions. The company should establish this
            procedure according to its profile and values, generating fair agreements
            and guaranteeing fair competition.
          {:else}
            <h5 id="fair-agreements">S62. Contratación de proveedores</h5>
            Es recomendable que la empresa cuente con un procedimiento de contratación
            de proveedores, el cual establezca los requisitos y condiciones de la
            empresa, así como los derechos y obligaciones de ambas partes. Este procedimiento
            debe detallar los pasos a seguir para la evaluación de proveedores antes
            de su contratación, los aspectos deben ser evaluados y los resultados
            esperados. De igual manera debe integrar las pautas utilizadas para el
            establecimiento de precios, tiempos de entrega y condiciones de pago.
            La empresa debe establecer este procedimiento en función a su perfil
            y valores, generando acuerdos justos y garantizando la competencia leal.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="fair-competition">S63. Fair competition</h5>
            To establish a good relationship with suppliers and business partners,
            it is essential to have good communication driving to agreements that
            benefit all stakeholders. For this reason, it is crucial to stipulate
            in writing the collaboration conditions, such as delivery times and payments
            for services. In addition, the company must guarantee the price of services
            in due time and form, and the supplier must ensure the correct delivery
            of goods as agreed. To this end, it is advisable to develop mechanisms
            to establish good relations with suppliers and strengthen the supply
            chain.
          {:else}
            <h5 id="fair-competition">S63. Relación con proveedores</h5>
            Para establecer una buena relación con proveedores y socios comerciales
            es indispensable contar con una buena comunicación que propicie acuerdos
            que beneficien a todas las partes involucradas y dar cumplimiento a lo
            pactado. Por esta razón, es muy importante que se estipulen por escrito
            las condiciones de colaboración, como tiempos de entrega y pagos de servicios.
            La empresa debe garantizar el pago de servicios en tiempo y forma, así
            como el proveedor debe asegurar la entrega correcta de mercancía de acuerdo
            a lo pactado. Para este fin es conveniente desarrollar mecanismos que
            contribuyan al establecimiento de buenas de relaciones con los proveedores
            y fortalezcan la cadena de suministro.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Assessment and Management of Environmental and Social
                          Risks and Impacts
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standards for risk management: Labor and Working
                          Conditions, Resource - Efficiency and Pollution
                          Prevention, Community Health, Safety, and Security,
                          Cultural Heritage.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/performance-standards/ps1"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps<br
                            />/wcm/connect/topics_<br />ext_content/ifc_<br
                            />external_corporate<br />_site/sustainability<br
                            />-at-ifc/policies-<br />standards/<br
                            />performance-standards/ps1</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org<br />/investment/due-<br
                            />diligence-guidance<br />-for-responsible<br
                            />-business-conduct.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Recommendations for dialogue with factory management
                          about supply chain mapping
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Suggestions to dialogue with suppliers and improve
                          the relationship - Establishment of roles and
                          responsibilities
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/child-labour/1-dialogue-suppliers.pdf?la=en&hash=504F0FB559E68E8C0AFEC994FDA19F2F"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/-/media/imvo/files<br
                            />/kleding/child-labour/<br
                            />1-dialogue-suppliers.pdf<br
                            />?la=en&hash=504F0FB559<br
                            />E68E8C0AFEC994FDA19F2F</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="supplier-evaluation">S64. Supplier evaluation</h5>
            To ensure product quality and traceability, the company must be aware
            of its suppliers' and business partners' working conditions and environmental
            performance. Therefore, it is necessary to have evaluation mechanisms
            in place both at hiring and for continuous monitoring. These mechanisms
            can be contracts, agreements, surveys or audits through which both parties
            establish the operating conditions. In addition, an assessment is required
            to identify those suppliers presenting a risk or negative impact and
            encourage the improvement through agreements.
          {:else}
            <h5 id="supplier-evaluation">S64. Evaluación de proveedores</h5>
            Para asegurar la calidad y trazabilidad de los productos es indispensable
            que la empresa conozca las condiciones laborales y el desempeño ambiental
            que presentan sus proveedores y socios comerciales. Para esto es necesario
            contar con mecanismos de evaluación tanto al momento de contratación
            como de monitoreo periódico. Estos mecanismos pueden ser contratos, acuerdos,
            encuestas o auditorías mediante las cuales ambas partes establezcan las
            condiciones de operación necesarias. Es necesario realizar una evaluación
            para identificar a aquellos proveedores que presentan un riesgo o impactos
            negativos y fomentar una mejora de los estándares de desempeño de proveedores
            mediante acuerdos.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due diligence Guidance for Responsible supply chains
                          in the Garment and Footwear sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines to improve the supply chain in the
                          textile industry, the relationship with stakeholders
                          and labor practices. - Applicable for Small and
                          medium-sized enterprises (SMEs) - Presents the most
                          common risks in the textile industry and
                          recommendations to avoid them
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/industry/inv/mne/responsible-supply-chains-textile-garment-sector.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br />industry/inv/mne/<br
                            />responsible-supply<br />-chains-textile-<br
                            />garment-sector.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Supply Chain Mapping
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Supply chain mapping - Supplier data collection -
                          Recommendatios for dialogue
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile/tools/child-labour/3#tools"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br />.nl/en/<br
                            />garments-textile/tools/<br
                            />child-labour/3#tools</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="identification-of-risks">
              S65. Identification of risks with suppliers
            </h5>
            To establish a reliable supply chain, the company must develop and implement
            a risk identification procedure to assess conditions, opportunities,
            impacts and generate corrective and mitigating actions. In this regard,
            it is essential to consider human and labour rights, health and safety,
            environment and anti-corruption. It is also necessary to prioritise the
            main risks, taking into account the people most affected. Once the risk
            assessment has been carried out, the supplier must be notified of the
            results obtained, and agreements for improvement must be reached to reduce
            the impact of its operations. It is also necessary to establish a written
            procedure for submitting, receiving, following up and resolving complaints
            and concerns from suppliers and business partners. Information on this
            procedure should be clear and accessible to all stakeholders.
          {:else}
            <h5 id="identification-of-risks">
              S65. Identificación de riesgos con proveedores
            </h5>
            Para establecer una cadena de suministro confiable, la empresa debe desarrollar
            e implementar un procedimiento de identificación de riesgos que le permita
            evaluar las condiciones, oportunidades y efectos, así como generar acciones
            correctivas y de mitigación. En este sentido es indispensable tomar en
            cuenta aspectos como derechos humanos y laborales, seguridad y salud,
            medio ambiente y lucha contra la corrupción. Así mismo es necesario priorizar
            los principales riesgos tomando en cuenta las personas mayormente afectadas.
            Una vez realizada la evaluación de riesgos se debe notificar al proveedor
            los resultados obtenidos y generar acuerdos de mejora que permitan reducir
            los impactos de sus operaciones. Así mismo, es necesario establecer un
            procedimiento escrito para presentar, recibir y dar seguimiento y resolución
            a quejas y preocupaciones provenientes de proveedores y socios comerciales.
            La información sobre este procedimiento debe ser clara y accesible para
            todas las partes involucradas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due diligence Guidance for Responsible supply chains
                          in the Garment and Footwear sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines to improve the supply chain in the
                          textile industry, the relationship with stakeholders
                          and labor practices. - Applicable for Small and
                          medium-sized enterprises (SMEs) - Presents the most
                          common risks in the textile industry and
                          recommendations to avoid them
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/industry/inv/mne/responsible-supply-chains-textile-garment-sector.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/<br
                            />industry/inv/mne/responsible<br
                            />-supply-chains-textile<br />-garment-sector.htm</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD Guideliness for Multinational Enterprises
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines establishing a code of responsible
                          business conduct also applies to national companies -
                          Identify and mitigate risks, Human Rights,
                          Environment, Anti-corruption, Consumer interests,
                          Science and technology
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://mneguidelines.oecd.org/guidelines/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://mneguidelines.oecd.<br />org/guidelines/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Supply Chain Mapping
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Agreement on Sustainable Garments and Textiles, SER,
                          The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Supply chain mapping - Recommendatios for improving
                          supply chain management
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/child-labour/b-supply-chain-mapping.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/-/media/imvo/files/<br
                            />kleding/child-labour/<br
                            />b-supply-chain-mapping<br />.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br
                            />/simple-health-safety/<br
                            />risk/risk-assessment-<br
                            />template-and-examples<br />.htm#article</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="local-suppliers">S66. Local suppliers</h5>
            As a commitment to the community in which the company operates, it is
            desirable to encourage the participation of local suppliers as far as
            possible. In this way, the company can actively involve the community
            and influence its well-being. Furthermore, this will build trust and
            establish communication channels between society and the company, strengthening
            ties of reciprocity.
          {:else}
            <h5 id="local-suppliers">S66. Proveedores locales</h5>
            Como compromiso hacia la comunidad en donde la empresa opera, es conveniente
            fomentar la participación de proveedores locales a medida de las posibilidades.
            De esta manera la empresa puede involucrar activamente a la comunidad
            e influir en su bienestar. Esto generará confianza y establecerá conductos
            de comunicación entre la sociedad y la empresa, fortaleciendo lazos de
            reciprocidad.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="community">S7. Community</h4>
            As a community member, the company is committed to actively improving
            the natural and social environment. To this end, according to its capabilities
            and resources, the company can integrate social programmes that benefit
            the community members, particularly the most vulnerable groups.
          {:else}
            <h4 id="community">S7. Comunidad</h4>
            Como miembro de una comunidad la empresa tiene el compromiso de contribuir
            activamente al mejoramiento del entorno natural y social. Para este fin,
            la empresa puede integrar, en función a sus capacidades y recursos, programas
            sociales que favorezcan a los miembros de la comunidad, particularmente
            a los grupos más vulnerables.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD Guideliness for Multinational Enterprises
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2011)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines establishing a code of responsible
                          business conduct also applies to national companies -
                          Identify and mitigate risks, Human Rights,
                          Environment, Anti-corruption, Consumer interests,
                          Science and technology
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="http://mneguidelines.oecd.org/guidelines/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >http://mneguidelines<br />.oecd.org/guidelines/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="community-engagement">S71. Community engagement</h5>
            To strengthen the company's ties with the community, it is advisable
            to generate an action plan that guides the company in implementing social
            programmes and alliances. For example, health and training programmes
            involving civil organisations, educational and community centres, among
            others, can be carried out. It is also crucial that the company generates
            good communication with the community through local consultations, media,
            and stakeholder engagement. This will help the company prevent, reduce
            and mitigate risks by being aware of local concerns and how the community
            influences the company's activities and vice versa.
          {:else}
            <h5 id="community-engagement">S71. Compromiso con la comunidad</h5>
            Con la finalidad de fortalecer los lazos de la empresa con la comunidad
            es conveniente generar un plan de acción que guie a la empresa en la
            implementación de programas sociales y alianzas con grupos de interés
            de la comunidad. Se pueden llevar a cabo programas de salud y de capacitación
            que involucren organizaciones civiles, centros educativos y centros comunitarios,
            entre otros. Así mismo, es crucial que la empresa genere una buena comunicación
            con la comunidad, puede ser mediante consultas locales, medios informativos
            e inclusión de grupos de interés. Esto ayudará a la empresa a prevenir,
            reducir y mitigar riesgos estando al tanto de las inquietudes locales
            y de cómo la comunidad influye en las actividades de la empresa y viceversa.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="vulnerable-groups">S72. Vulnerable groups</h5>
            One of how the company can support community development is by providing
            support to vulnerable groups. In this sense, it is advisable to promote
            integration and growth programmes, providing knowledge, training, or
            other means to improve the entire community's well-being
          {:else}
            <h5 id="vulnerable-groups">S72. Grupos vulnerables</h5>
            Una de las maneras en que la empresa puede apoyar en el desarrollo de
            la comunidad es proporcionando apoyo a grupos vulnerables. En este sentido
            es conveniente impulsar programas de integración y crecimiento, aportando
            conocimiento, capacitación o cualquier otro medio que mejore el bienestar
            de toda la comunidad.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="risk-detection">S73. Risk detection</h5>
            <div>
              Through a good relationship that fosters communication with the
              community, the identification, assessment and risks prevention can
              be more transparent and accurate. It is necessary to assess
              regularly the risks associated with the activities carried out by
              the company before implementing any changes or integrating new
              operations. The risk assessment should consider community health
              and safety, human rights, and environmental conservation. A method
              should also be in place to identify risks and prioritise affected
              groups by updating the assessment periodically, based on
              historical data and trend analysis.
            </div>
            <br />
            <div>
              Once the potential risks have been identified, it is necessary to
              generate an action plan describing the activities to be carried
              out, establishing a time frame, how to deal with these impacts,
              and the improvements to be achieved. Likewise, the company must
              monitor the established procedures, guaranteeing their correct
              application. In addition, information about identified risks and
              potential impacts arising from the company's operations should be
              duly published and accessible to potentially affected community
              members.
            </div>
          {:else}
            <h5 id="risk-detection">S73. Detección de riesgos</h5>
            <div>
              A través de una buena relación que fomente la comunicación con la
              comunidad, la identificación, evaluación y prevención de riesgos
              puede ser mas clara y acertada. Es necesario evaluar los riesgos
              asociados a las actividades realizadas por la empresa de manera
              regular, así como también, antes de implementar algún cambio o
              integración de nuevas operaciones. La evaluación de riesgos debe
              tomar en cuenta la seguridad y salud de la comunidad, los derechos
              humanos y la conservación de medio ambiente, entre otros. Así
              mismo, se debe contar con un método que facilite la jerarquización
              de riesgos y la identificación de grupos afectados actualizando la
              evaluación de manera periódica, según datos históricos y análisis
              de tendencias.
            </div>
            <br />
            <div>
              Una vez identificados los riesgos potenciales, es necesario
              generar un plan de acción que describa las actividades a realizar,
              que establezca un marco de tiempo, los medios a utilizar para
              hacer frente a estos impactos y las mejoras que se desean
              alcanzar. Así mismo, la empresa debe asegurarse de monitorear los
              procedimientos establecidos, garantizando su correcta aplicación.
              Adicionalmente es necesario que la información acerca de los
              riesgos identificados y los posibles impactos derivados de las
              operaciones de la empresa sean debidamente publicados y estén
              accesibles a los miembros de la comunidad potencialmente
              afectados.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br
                            />/simple-health-safety<br
                            />/risk/risk-assessment<br
                            />-template-and-examples<br />.htm#article</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5 id="local-jobs">S74. Local jobs</h5>
            Another way to contribute positively to the community's well-being is
            to generate employment. First, however, the company must encourage hiring
            local employees by providing adequate training and job growth opportunities.
          {:else}
            <h5 id="local-jobs">S74. Puestos de trabajo locales</h5>
            Otra manera de contribuir positivamente al bienestar de la comunidad
            es la generación de empleos. Sin embargo, es necesario que la empresa
            fomente la contratación de empleados de la localidad, brindando adecuada
            capacitación y oportunidades de crecimiento laboral.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="customers">S8. Customer</h4>
            <div>
              A key stakeholder in any organisation is the customer, so the
              company needs to create efficient communication channels. By
              obtaining detailed information about customer needs, the company
              will improve its services and make more informed decisions. To
              this end, it is essential to create mechanisms that allow the
              company to collect data regularly and up to date.
            </div>
            <br />
            <div>
              Often the company's focus is on manufacturing quality products and
              delivering on time. However, it is essential to have mechanisms to
              understand and quantify customer satisfaction, such as surveys and
              interviews. This information will facilitate the design of
              products according to customer demand and requirements.
            </div>
            <br />
            <div>
              It is also necessary to have detailed information about products
              representing an actual or potential risk to customers. In
              addition, the company should be aware of and comply with
              legislation or voluntary codes concerning product health and
              safety. In this sense, it is advisable to create a procedure for
              the reception, follow-up and resolution of customer complaints.
              The information regarding this procedure must be clear and easily
              accessible.
            </div>
          {:else}
            <h4 id="customers">S8. Clientes</h4>
            <div>
              Un grupo de interés fundamental en cualquier organización son los
              clientes, por tal motivo es esencial que la empresa genere canales
              de comunicación eficientes. Obteniendo información detallada
              acerca de las necesidades del cliente la empresa podrá mejorar los
              servicios que oferta, así como también podrá realizar una toma de
              decisiones más informada. Para este fin, es esencial generar
              mecanismos que permitan a la empresa recolectar datos confiables
              de manera actualizada y periódica.
            </div>
            <br />
            <div>
              Frecuentemente la empresa se centra en generar productos de
              calidad y realizar entregas en tiempo y forma. Sin embargo, es
              fundamental contar con mecanismos que contribuyan a conocer y
              cuantificar la satisfacción del cliente, como lo son encuestas y
              entrevistas. Esta información facilitará el diseño de productos en
              función a la demanda y requerimientos del cliente.
            </div>
            <br />
            <div>
              Así mismo, es necesario contar con información detallada acerca de
              aquellos productos que represen un riesgo real o potencial a los
              clientes, estar al tanto y dar cumplimiento a legislaciones o
              códigos voluntarios referentes a seguridad y salud de productos.
              En este sentido, es recomendable generar un procedimiento para el
              recibimiento, seguimiento y resolución de quejas o inconformidades
              de los clientes y que la información referente a este
              procedimiento sea clara y fácilmente accesible.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border-b border-t">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border-b border-t">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            >Font Awesome Pro 5.15.4 by @fontawesome -
                            https://fontawesome.com License -
                            https://fontawesome.com/license (Commercial License)<path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <ScrollButton />
</div>
