<script>
  import { LayerCake, Svg } from 'layercake';
  import { t } from '../stores/messages';
  import { answers } from '../stores/answers';
  import {
    allScoresPerCategory,
    categoriesNames,
  } from '../results/allDimensionsLevel';

  import Radar from './radar/Radar.svelte';
  import AxisRadial from './radar/AxisRadial.svelte';

  let scores;
  let xKey;
  let data;
  answers.subscribe((currentAnswers) => {
    scores = allScoresPerCategory(currentAnswers);
    // Make the default radar size the same
    scores['R0'] = 4;
    xKey = Object.keys(scores).map((k) => k);
    data = [scores];
  });
</script>

<div style="max-width: 900px; margin-left: auto; margin-right: auto;">
  <h4 class="text-black">
    {#if $t['lang'] === 'en'}
      Sustainable Performance
    {:else}
      Desempeño Sustentable
    {/if}
  </h4>
  <p class="text-black">
    {#if $t['lang'] === 'en'}
      <p>
        <span class="font-bold"> Graph 1: </span>
        Progress is presented in the categories that comprise the four dimensions
        analyzed, according to four performance levels.
      </p>
    {:else}
      <p>
        <span class="font-bold"> Gráfico 1: </span>
        Avance presentado en las categorías que conforman las cuatro dimensiones
        analizadas, en función a cuatro niveles de desempeño.
      </p>
    {/if}
  </p>
  <div class="flex items-center pr-56">
    <div class="chart-container radar" style="height: 400px;">
      <LayerCake
        padding={{ top: 30, right: 0, bottom: 7, left: 0 }}
        x={xKey}
        xDomain={[0, 5]}
        xRange={({ height }) => [0, height / 2]}
        {data}
      >
        <Svg>
          <AxisRadial />
          <Radar />
        </Svg>
      </LayerCake>
    </div>
    <div class="w-96 radarimage">
      {#if $t['lang'] === 'en'}
        <img src={'/images/performance-en.png'} alt="performance" />
      {:else}
        <img src={'/images/performance-es.png'} alt="performance" />
      {/if}
    </div>
  </div>
  <div
    class="grid grid-cols-4 gap-4 text-black"
    style="max-width: 900px; margin-left: auto; margin-right: auto;"
  >
    <div>
      <h4>Social</h4>
      <p>
        {#if $t['lang'] === 'en'}
          S1 Working conditions
        {:else}
          S1 Condiciones laborales
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S2 Human and labour rights
        {:else}
          S2 Derechos humanos y laborales
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S3 Social Security
        {:else}
          S3 Seguridad social
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S4 Wages and opportunities
        {:else}
          S4 Sueldos y oportunidades
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S5 Occupational Safety and Health
        {:else}
          S5 Salud y seguridad ocupacional
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S6 Suppliers
        {:else}
          S6 Proveedores
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S7 Community
        {:else}
          S7 Comunidad
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          S8 Customers
        {:else}
          S8 Clientes
        {/if}
      </p>
      <p class="mt-5">
        {#if $t['lang'] === 'en'}
          R0 - Ideal reference value
        {:else}
          R0 - Valor de referencia ideal
        {/if}
      </p>
    </div>
    <div>
      <h4>
        {#if $t['lang'] === 'en'}
          Environmental
        {:else}
          Ambiental
        {/if}
      </h4>
      <p>
        {#if $t['lang'] === 'en'}
          A1 Resources
        {:else}
          A1 Recursos
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          A2 Emissions and waste
        {:else}
          A2 Emisiones y residuos
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          A3 Suppliers environmental assessment
        {:else}
          A3 Evaluación ambiental de proveedores
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          A4 Transport of products
        {:else}
          A4 Transporte de productos
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          A5 Environmental risk
        {:else}
          A5 Riesgo Ambiental
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          A6 Use of chemical substances
        {:else}
          A6 Uso de sustancias quimicas
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          A7 Research for environmental improvement
        {:else}
          A7 Investigación para la mejora ambiental
        {/if}
      </p>
    </div>
    <div>
      <h4>
        {#if $t['lang'] === 'en'}
          Economic
        {:else}
          Económico
        {/if}
      </h4>
      <p>
        {#if $t['lang'] === 'en'}
          E1 Business Plan
        {:else}
          E1 Plan de negocios
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          E2 Production cost
        {:else}
          E2 Costo de producción
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          E3 Financial performance
        {:else}
          E3 Desempeño financiero
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          E4 Collaboration
        {:else}
          E4 Colaboración
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          E5 Vulnerability
        {:else}
          E5 Vulnerabilidad
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          E6 Financial indicators
        {:else}
          E6 Indicadores financieros
        {/if}
      </p>
    </div>
    <div>
      <h4>
        {#if $t['lang'] === 'en'}
          Management
        {:else}
          Gestión
        {/if}
      </h4>
      <p>
        {#if $t['lang'] === 'en'}
          G0 Company profile
        {:else}
          G0 Perfil de la empresa
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G1 Automation
        {:else}
          G1 Automatización
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G2 Manufacturing Management
        {:else}
          G2 Gestión y manufactura
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G3 Business management
        {:else}
          G3 Gestión administrativa
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G4 Security
        {:else}
          G4 Seguridad
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G5 Sustainability strategies
        {:else}
          G5 Estrategias de sustentabilidad
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G6 Applicable Legislation
        {:else}
          G6 Legislación y regulación
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G7 Corruption prevention
        {:else}
          G7 Prevención de corrupción
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G8 Transparency and communication
        {:else}
          G8 Transparencia y comunicación
        {/if}
      </p>
      <p>
        {#if $t['lang'] === 'en'}
          G9 Market
        {:else}
          G9 Mercado
        {/if}
      </p>
    </div>
  </div>
</div>

<style>
  /*
    The wrapper div needs to have an explicit width and height in CSS.
    It can also be a flexbox child or CSS grid element.
    The point being it needs dimensions since the <LayerCake> element will
    expand to fill it.
  */
  .chart-container {
    width: 100%;
    height: 500px;
  }
</style>
