<script>
  import { t } from '../stores/messages';
  import Delete from '../components/Icons/Delete.svelte';

  export let name;
  export let email;
  export let trash;
  export let department;
  export let defaultDepartment;
  export let onSelectDepartment = () => null;
  export let onDelete = () => null;
  let selectedDepartment = defaultDepartment ? defaultDepartment : '';
</script>

<div class="card mt-4" style="width: 346px;">
  <div class="card-body">
    <div class="flex justify-between flex items-center">
      <h5 class="card-title mb-0 mt-0 text-black">
        {name}
      </h5>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="icono flex items-center cursor-pointer"
        on:click={() => onDelete()}
      >
        <div class="edit mr-2.5" />
        <div class="delete">
          {#if trash === true}
            <Delete />
          {/if}
        </div>
      </div>
    </div>
    <h6 class="card-subtitle mb-2 text-muted text-black">
      {email}
    </h6>
    <div class="flex items-center">
      {#if department}
        <h6 class="card-subtitle mb-2 text-muted text-blue-500">
          {#if $t['lang'] === 'en'}
            Departament:
          {:else}
            Departamento:
          {/if}
        </h6>
        <select
          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary ml-5"
          bind:value={selectedDepartment}
          on:change={() => {
            onSelectDepartment(selectedDepartment);
          }}
        >
          <option selected value="">
            {#if $t['lang'] === 'en'}
              Select
            {:else}
              Seleccionar
            {/if}
          </option>
          <option value="DIRECTION">
            {#if $t['lang'] === 'en'}
              Direction
            {:else}
              Dirección
            {/if}
          </option>
          <option value="PRODUCTION">
            {#if $t['lang'] === 'en'}
              Production
            {:else}
              Producción
            {/if}
          </option>
          <option value="HR">
            {#if $t['lang'] === 'en'}
              HR
            {:else}
              RH
            {/if}
          </option>
          <option value="PURCHASING">
            {#if $t['lang'] === 'en'}
              Purchasing
            {:else}
              Compras
            {/if}
          </option>
          <option value="SALES">
            {#if $t['lang'] === 'en'}
              Sales
            {:else}
              Ventas
            {/if}
          </option>
          <option value="FINANCE">
            {#if $t['lang'] === 'en'}
              Finance
            {:else}
              Finanzas
            {/if}
          </option>
          <option value="OTHER">
            {#if $t['lang'] === 'en'}
              Other
            {:else}
              Otro
            {/if}
          </option>
        </select>
      {/if}
    </div>
  </div>
</div>
