<script>
  import { createForm } from 'svelte-forms-lib';
  import { get } from 'svelte/store';
  import { auth } from '../stores/auth';
  import { t } from '../stores/messages';
  import Navbar from '../components/Navbar.svelte';
  import Sidebar from '../components/Sidebar.svelte';
  import { postContactMessage } from '../services/satin';

  let isContactDisabled = true;

  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      name: '',
      subject: '',
      message: '',
      email: $auth.me.email,
    },
    validate: (formChanges) => {
      if (
        Object.values(formChanges).some((v) => {
          return v === '';
        })
      ) {
        isContactDisabled = true;
      } else {
        isContactDisabled = false;
      }
      return {};
    },
    onSubmit: async (values) => {
      const r = await postContactMessage(values);
      if (r.ok) {
        if (get(t)['lang'] === 'es') {
          alert(
            '¡Gracias por contactarnos!, en breve responderemos tu mensaje'
          );
        } else {
          alert(
            'Thank you for contacting us! We will reply to your message shortly'
          );
        }
      }
    },
  });
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar />
  <div class="w-full">
    <section
      class="mx-auto w-8/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white shadow-xl"
    >
      <div class="container py-12">
        <div class="flex flex-wrap w-full mb-20 flex-col">
          <div class="flex flex-row items-center ml-16 mr-16 border-b">
            <h1 class="text-black mb-0 mt-0">
              {#if $t['lang'] === 'en'}
                Contact Us
              {:else}
                Contáctanos
              {/if}
            </h1>
          </div>
          <form on:submit={handleSubmit}>
            <div class="flex items-center">
              <div class="container mx-auto">
                <div class="mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-8 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Your Name
                    {:else}
                      Tu nombre
                    {/if}
                  </div>
                </div>
                <div class="mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        bind:value={$form.name}
                        on:keyup={handleChange}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-8 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Subject
                    {:else}
                      Asunto
                    {/if}
                  </div>
                </div>
                <div class="mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        bind:value={$form.subject}
                        on:keyup={handleChange}
                        required
                        class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
                <div class="mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-8 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Message
                    {:else}
                      Mensaje
                    {/if}
                  </div>
                </div>
                <div class="mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <div class="block p-2.5 w-full">
                        <textarea
                          bind:value={$form.message}
                          placeholder={$t['lang'] === 'en'
                            ? 'Message...'
                            : 'Mensaje...'}
                          class="block p-2.5 w-full h-64"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-row justify-end mb-8 font-bold mr-8 mt-6 text-gray-400"
                >
                  support@satinapp.org
                </div>
              </div>
            </div>
          </form>
          <div class="mx-auto w-11/12 bg-white flex flex-row justify-evenly">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a href="">
              <button
                class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
                disabled={isContactDisabled}
                on:click={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {#if $t['lang'] === 'en'}
                  Send
                {:else}
                  Enviar
                {/if}
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
