<script>
  import { onMount } from 'svelte';
  import { Bar } from 'svelte-chartjs';
  import {
    Chart,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from 'chart.js';
  import { t } from '../stores/messages';
  import { getResults } from '../services/satin';

  let percentData = {};
  let percentTitle = '';
  let results = null;

  Chart.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  );

  const formatNumber = (n) => {
    if (n > 0 && n < 0.01) {
      return n.toLocaleString('es-MX', { notation: 'scientific' });
    } else {
      return n.toLocaleString('es-MX', {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      });
    }
  };

  const formatNumberPercentage = (n) => {
    return n.toLocaleString('es-MX', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  onMount(async () => {
    results = await getResults();
    t.subscribe((translations) => {
      const lang = translations['lang'];
      const labels =
        lang === 'en'
          ? [
              'Climate change',
              'Fossil depletion',
              'Freshwater ecotoxicity',
              'Human toxicity',
              'Water depletion',
            ]
          : [
              'Cambio Climatico',
              'Agotamiento de recursos fosiles',
              'Ecotoxicidad de agua',
              'Toxicidad Humana',
              'Agotamiento de Agua',
            ];
      percentData = {
        labels: labels,
        datasets: [
          {
            label:
              lang === 'en'
                ? 'Fibres, yarn, fabric'
                : 'Fibras, hilo, tejido, polimero',
            data: [
              results?.['fibers_thread_polymer_percent']?.[0] || 0,
              results?.['fibers_thread_polymer_percent']?.[1] || 0,
              results?.['fibers_thread_polymer_percent']?.[2] || 0,
              results?.['fibers_thread_polymer_percent']?.[3] || 0,
              results?.['fibers_thread_polymer_percent']?.[4] || 0,
            ],
            backgroundColor: '#43B9C3',
          },
          {
            label:
              lang === 'en'
                ? 'Transport of fibres, yarn and fabric'
                : 'Transporte de polimero, fibras, hilo y tejido',
            data: [
              results?.['fibers_thread_polymer_transportation_percent']?.[0] ||
                0,
              results?.['fibers_thread_polymer_transportation_percent']?.[1] ||
                0,
              results?.['fibers_thread_polymer_transportation_percent']?.[2] ||
                0,
              results?.['fibers_thread_polymer_transportation_percent']?.[3] ||
                0,
              results?.['fibers_thread_polymer_transportation_percent']?.[4] ||
                0,
            ],
            backgroundColor: '#0140FE',
          },
          {
            label:
              lang === 'en' ? 'Use of chemicals' : 'Uso de sustancias químicas',
            data: [
              results?.['substances_percent']?.[0] || 0,
              results?.['substances_percent']?.[1] || 0,
              results?.['substances_percent']?.[2] || 0,
              results?.['substances_percent']?.[3] || 0,
              results?.['substances_percent']?.[4] || 0,
            ],
            backgroundColor: '#EA6108',
          },
          {
            label: lang === 'en' ? 'Packaging' : 'Empaques',
            data: [
              results?.['packaging_percent']?.[0] || 0,
              results?.['packaging_percent']?.[1] || 0,
              results?.['packaging_percent']?.[2] || 0,
              results?.['packaging_percent']?.[3] || 0,
              results?.['packaging_percent']?.[4] || 0,
            ],
            backgroundColor: '#E0197D',
          },
          {
            label: lang === 'en' ? 'Electricity use' : 'Uso de electricidad',
            data: [
              results?.['electricity_percent']?.[0] || 0,
              results?.['electricity_percent']?.[1] || 0,
              results?.['electricity_percent']?.[2] || 0,
              results?.['electricity_percent']?.[3] || 0,
              results?.['electricity_percent']?.[4] || 0,
            ],
            backgroundColor: '#F4BE14',
          },
          {
            label: lang === 'en' ? 'Fuel use' : 'Uso de combustibles',
            data: [
              results?.['fuels_percent']?.[0] || 0,
              results?.['fuels_percent']?.[1] || 0,
              results?.['fuels_percent']?.[2] || 0,
              results?.['fuels_percent']?.[3] || 0,
              results?.['fuels_percent']?.[4] || 0,
            ],
            backgroundColor: '#CD2EFB',
          },
          {
            label: lang === 'en' ? 'Water use' : 'Uso de Agua',
            data: [
              results?.['water_use_percent']?.[0] || 0,
              results?.['water_use_percent']?.[1] || 0,
              results?.['water_use_percent']?.[2] || 0,
              results?.['water_use_percent']?.[3] || 0,
              results?.['water_use_percent']?.[4] || 0,
            ],
            backgroundColor: '#541E77',
          },
          {
            label: lang === 'en' ? 'Waste water' : 'Agua residual',
            data: [
              results?.['residual_water_percent']?.[0] || 0,
              results?.['residual_water_percent']?.[1] || 0,
              results?.['residual_water_percent']?.[2] || 0,
              results?.['residual_water_percent']?.[3] || 0,
              results?.['residual_water_percent']?.[4] || 0,
            ],
            backgroundColor: '#86B505',
          },
          {
            label:
              lang === 'en' ? 'Waste generation' : 'Generación de residuos',
            data: [
              results?.['waste_generation_percent']?.[0] || 0,
              results?.['waste_generation_percent']?.[1] || 0,
              results?.['waste_generation_percent']?.[2] || 0,
              results?.['waste_generation_percent']?.[3] || 0,
              results?.['waste_generation_percent']?.[4] || 0,
            ],
            backgroundColor: '#8A6A4E',
          },
          {
            label:
              lang === 'en'
                ? 'Transport of products'
                : 'Transporte de productos',
            data: [
              results?.['product_transportation_percent']?.[0] || 0,
              results?.['product_transportation_percent']?.[1] || 0,
              results?.['product_transportation_percent']?.[2] || 0,
              results?.['product_transportation_percent']?.[3] || 0,
              results?.['product_transportation_percent']?.[4] || 0,
            ],
            backgroundColor: '#BFCCCD',
          },
        ],
      };
      percentTitle =
        lang === 'en' ? 'Environmental Impact %' : 'Impacto Ambiental %';
    });
  });
</script>

<div
  class="row mt-32"
  style="max-width: 900px; margin-left: auto; margin-right: auto;"
>
  <p class="text-black">
    {#if $t['lang'] === 'en'}
      <span class="font-bold"> Table 1: </span>
      Environmental impact per process stage expressed according to the unit corresponding
      to each impact category.
    {:else}
      <span class="font-bold"> Tabla 1: </span>
      Impacto ambiental por etapa de proceso expresado según la unidad correspondiente
      a cada una de las categorías de impacto.
    {/if}
  </p>
  <h4 class="mb-1 text-black">
    {#if $t['lang'] === 'en'}
      Environmental Impact (Real values)
    {:else}
      Impacto Ambiental (Valores reales)
    {/if}
  </h4>
  <table class="table-fixed text-md border-solid text-black">
    <thead class="border-b border-black">
      <tr class="border-b border-black text-center">
        <th />
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Impact Category
          {:else}
            Categoria de Impacto
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Climate change
          {:else}
            Cambio Climatico
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Fossil depletion
          {:else}
            Agotamiento de recursos fosiles
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Freshwater ecotoxicity
          {:else}
            Ecotoxicidad de agua
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Human toxicity
          {:else}
            Toxicidad Humana
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Water depletion
          {:else}
            Agotamiento de Agua
          {/if}
        </th>
      </tr>
      <tr class="text-center">
        <th />
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Units
          {:else}
            Unidades
          {/if}
        </th>
        <th class="border-r border-black p-0"> kg CO2-Eq </th>
        <th class="border-r border-black p-0"> kg oil-Eq </th>
        <th class="border-r border-black p-0"> kg 1,4-DC </th>
        <th class="border-r border-black p-0"> kg 1,4-DC </th>
        <th class="border-r border-black p-0"> m3 water </th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0" rowspan="3">
          {#if $t['lang'] === 'en'}
            Raw Materials (RM)
          {:else}
            Materias Primas (MP)
          {/if}
        </td>
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Fibres, yarn, fabric
          {:else}
            Fibras, hilo, tejido, polimero
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fibers_thread_polymer']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fibers_thread_polymer']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fibers_thread_polymer']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fibers_thread_polymer']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fibers_thread_polymer']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Transport of fibres, yarn and fabric
          {:else}
            Transporte de polimero, fibras, hilo y tejido
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(
            results?.['fibers_thread_polymer_transportation']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(
            results?.['fibers_thread_polymer_transportation']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(
            results?.['fibers_thread_polymer_transportation']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(
            results?.['fibers_thread_polymer_transportation']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(
            results?.['fibers_thread_polymer_transportation']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0" rowspan="9">
          {#if $t['lang'] === 'en'}
            Company activities (CA)
          {:else}
            Actividades de la empresa (AE)
          {/if}
        </td>
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Use of chemicals
          {:else}
            Uso de sustancias químicas
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['substances']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['substances']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['substances']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['substances']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['substances']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Packaging
          {:else}
            Empaques
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['packaging']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['packaging']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['packaging']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['packaging']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['packaging']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Electricity use
          {:else}
            Uso de electricidad
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['electricity']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['electricity']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['electricity']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['electricity']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['electricity']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Fuel use
          {:else}
            Uso de combustibles
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fuels']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fuels']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fuels']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fuels']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['fuels']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Water use
          {:else}
            Uso de agua
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['water_use']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['water_use']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['water_use']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['water_use']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['water_use']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Waste water
          {:else}
            Agua residual
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['residual_water']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['residual_water']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['residual_water']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['residual_water']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['residual_water']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Waste generation
          {:else}
            Generación de residuos
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['waste_generation']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['waste_generation']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['waste_generation']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['waste_generation']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['waste_generation']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-r border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Transport of products
          {:else}
            Transporte de productos
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['product_transportation']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['product_transportation']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['product_transportation']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['product_transportation']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['product_transportation']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td />
        <td class="border-r border-black text-center p-0"
          ><strong>Total</strong></td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['totals']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['totals']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['totals']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['totals']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumber(results?.['totals']?.[4] || 0)}</td
        >
      </tr>
    </tbody>
  </table>
</div>
<div
  class=" row mt-32 mb-32"
  style="max-width: 900px; margin-left: auto; margin-right: auto; margin-bottom: 200px"
>
  <div class="block">
    <p class="text-black">
      {#if $t['lang'] === 'en'}
        <span class="font-bold"> Table 2: </span>
        Percentage contribution by impact category at each process stage.
      {:else}
        <span class="font-bold"> Tabla 2: </span>
        Contribución porcentual por categoría de impacto en cada etapa del proceso.
      {/if}
    </p>
    <h4 class="mb-1 text-black">
      {#if $t['lang'] === 'en'}
        Environmental Impact (Percent)
      {:else}
        Impacto Ambiental (Porcentual)
      {/if}
    </h4>
  </div>
  <table class="table-fixed text-md text-black border-solid mt-2">
    <thead class="border-b border-black p-0">
      <tr class="border-b border-black text-center p-0">
        <th />
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Impact Category
          {:else}
            Categoria de Impacto
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Climate change
          {:else}
            Cambio Climatico
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Fossil depletion
          {:else}
            Agotamiento de recursos fosiles
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Freshwater ecotoxicity
          {:else}
            Ecotoxicidad de agua
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Human toxicity
          {:else}
            Toxicidad Humana
          {/if}
        </th>
        <th class="border-r border-black p-0">
          {#if $t['lang'] === 'en'}
            Water depletion
          {:else}
            Agotamiento de Agua
          {/if}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0" rowspan="3">
          {#if $t['lang'] === 'en'}
            Raw Materials (RM)
          {:else}
            Materias Primas (MP)
          {/if}
        </td>
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Fibres, yarn, fabric
          {:else}
            Fibras, hilo, tejido, polimero
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Transport of fibres, yarn and fabric
          {:else}
            Transporte de polimero, fibras, hilo y tejido
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_transportation_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_transportation_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_transportation_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_transportation_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['fibers_thread_polymer_transportation_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0" rowspan="9">
          {#if $t['lang'] === 'en'}
            Company activities (CA)
          {:else}
            Actividades de la empresa (AE)
          {/if}
        </td>
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Use of chemicals
          {:else}
            Uso de sustancias químicas
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['substances_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['substances_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['substances_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['substances_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['substances_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Packaging
          {:else}
            Empaques
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['packaging_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['packaging_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['packaging_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['packaging_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['packaging_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Electricity use
          {:else}
            Uso de electricidad
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['electricity_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['electricity_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['electricity_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['electricity_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['electricity_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Fuel use
          {:else}
            Uso de combustibles
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['fuels_percent']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['fuels_percent']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['fuels_percent']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['fuels_percent']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['fuels_percent']?.[4] || 0)}</td
        >
      </tr>
      <tr class="border-b border-black">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Water use
          {:else}
            Uso de agua
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['water_use_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['water_use_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['water_use_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['water_use_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['water_use_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Waste water
          {:else}
            Agua residual
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['residual_water_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['residual_water_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['residual_water_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['residual_water_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['residual_water_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Waste generation
          {:else}
            Generación de residuos
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['waste_generation_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['waste_generation_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['waste_generation_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['waste_generation_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['waste_generation_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-r border-black p-0">
        <td class="border-r border-black text-center p-0">
          {#if $t['lang'] === 'en'}
            Transport of products
          {:else}
            Transporte de productos
          {/if}
        </td>
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['product_transportation_percent']?.[0] || 0
          )}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['product_transportation_percent']?.[1] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['product_transportation_percent']?.[2] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['product_transportation_percent']?.[3] || 0
          )}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(
            results?.['product_transportation_percent']?.[4] || 0
          )}</td
        >
      </tr>
      <tr class="border-b border-black p-0">
        <td />
        <td class="border-r border-black text-center p-0"
          ><strong>Total</strong></td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['totals_percent']?.[0] || 0)}</td
        >
        <td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['totals_percent']?.[1] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['totals_percent']?.[2] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['totals_percent']?.[3] || 0)}</td
        ><td class="border-r border-black text-center p-0"
          >{formatNumberPercentage(results?.['totals_percent']?.[4] || 0)}</td
        >
      </tr>
    </tbody>
  </table>
</div>
<div
  class="row mb-24 mt-24"
  style="max-width: 900px; margin-left: auto; margin-right: auto; margin-top: 200px"
>
  <p class="text-black">
    {#if $t['lang'] === 'en'}
      <span class="font-bold"> Graph 1: </span>
      Percentage contribution by impact category.
    {:else}
      <span class="font-bold"> Gráfico 1: </span>
      Contribución porcentual por categoría de impacto.
    {/if}
  </p>
  <Bar
    data={percentData}
    options={{
      plugins: {
        title: {
          display: true,
          text: percentTitle,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          beginAtZero: true,
          suggestedMax: 100,
        },
      },
    }}
  />
</div>
