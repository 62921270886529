import Api from './api';
import Netlify from './netlify';

export function b64ToUtf8(data) {
  if (typeof data === 'object' && data !== null) {
    return JSON.stringify(data);
  } else {
    return decodeURIComponent(escape(window.atob(data)));
  }
}

export const getMessages = async () => {
  try {
    const response = await Api.get('/messages');
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return {};
  }
};

export const getMe = async () => {
  try {
    const response = await Api.get('/me');
    const me = JSON.parse(b64ToUtf8(response));
    return { loggedIn: true, me: me };
  } catch (error) {
    return { loggedIn: false };
  }
};

export const getDimensions = async () => {
  try {
    const response = await Api.get(`/dimensions`);
    const d = JSON.parse(b64ToUtf8(response));
    const dimensions = Object.fromEntries(
      d.data.allDimensions.data.map((d) => {
        return [d.code, d];
      })
    );
    return dimensions;
  } catch (error) {
    return {};
  }
};

export const getResults = async () => {
  try {
    const response = await Api.get('/results');
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { result: false };
  }
};

export const getExcel = async (results, language) => {
  try {
    const response = await Netlify.get('/xlsx', {
      results: results,
      lang: language,
    });
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { result: false };
  }
};

export const postRegistration = async (values) => {
  try {
    const response = await Api.post('/user_profile', values);
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { error: "Couldn't update profile" };
  }
};

export const postContactMessage = async (values) => {
  try {
    const response = await Api.post('/contact', values);
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { error: "Couldn't send message" };
  }
};

export const getAnswers = async () => {
  try {
    const response = await Api.get('/answers');
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return {};
  }
};

export const deleteInvitation = async (email) => {
  try {
    const response = await Api.delete('/invitations', email);
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { error: "Couldn't delete invitation" };
  }
};

export const postInvitation = async (values) => {
  try {
    const response = await Api.post('/invitations', values);
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { error: "Couldn't send invitation" };
  }
};

export const getInvitations = async () => {
  try {
    const response = await Api.get('/invitations');
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return [];
  }
};

export const getUsers = async () => {
  try {
    const response = await Api.get('/users');
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return [];
  }
};

export const postAnswer = async (values) => {
  try {
    const response = await Api.post('/answers', values);
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { error: "Couldn't save answer" };
  }
};

export const deleteAnswers = async () => {
  try {
    const response = await Api.delete('/answers', {});
    return JSON.parse(b64ToUtf8(response));
  } catch (error) {
    return { error: "Couldn't save answer" };
  }
};
