<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
    }
  });

  $: {
    saveAnswer(question, answer.toString(), '');
  }
</script>

<div class="answer mt-5 pr-5">
  <div class="quantity block w-full">
    <div class="block w-full">
      <textarea
        placeholder={$t['lang'] === 'en'
          ? 'Enter the list of products...'
          : 'Escriba la lista de productos...'}
        bind:value={answer}
        class="block w-full h-64"
      />
    </div>
  </div>
</div>

<style>
  .answer {
    display: flex;
    justify-content: left;
  }
  .quantity {
    align-items: center;
    display: flex;
  }
</style>
