<script>
  import { t } from '../../stores/messages';
  export let title;
  export let materials;
  export let onAdd;
  export let onUpdate;

  $: {
    onUpdate(materials);
  }
</script>

<div class="mix-yarn mt-5 pr-6">
  <div class="mr-4 yarn-info">
    <div class="info w-full">
      <h5>{title}</h5>
      <h5 class="text-center">
        <b>
          {#if $t['lang'] === 'en'}
            Purchased raw materials
          {:else}
            Materias primas adquiridas
          {/if}
        </b>
      </h5>
      <p>
        {#if $t['lang'] === 'en'}
          In this section, you can customize the purchased yarn according to its
          composition. <br />1) Enter the amount of yarn purchased annually
          <br />2) Select its components and the percentage
        {:else}
          En esta sección puedes personalizar el hilo adquirido según su
          composición. 1) Ingresa la cantidad de hilo adquirido anualmente 2)
          Selecciona sus componentes y el porcentaje
        {/if}
      </p>
    </div>
    <div class="inputs w-full flex mt-5">
      <div class="flex-column w-full">
        <div class="row">
          {#each materials as knitted, index}
            <div class="flex flex-wrap flex-row w-full">
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="items-center text-center mb-12">
                    <label class="form-label ml-5" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        Fabric {index + 1}
                      {:else}
                        Tejido {index + 1}
                      {/if}
                    </label>
                  </div>
                  <div class="select mb-5">
                    <select
                      bind:value={knitted['firstComponent']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Conventional cotton
                        {:else}
                          Algodón convencional
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Organic cotton
                        {:else}
                          Algodón orgánico
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Wool
                        {:else}
                          Lana
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Cellulosic fiber
                        {:else}
                          Fibras celulosicas
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Polyester
                        {:else}
                          Poliéster
                        {/if}
                      </option>
                      <option value="6">
                        {#if $t['lang'] === 'en'}
                          Recycled polyester
                        {:else}
                          Poliéster reciclado
                        {/if}
                      </option>
                      <option value="7">
                        {#if $t['lang'] === 'en'}
                          Nylon
                        {:else}
                          Nylon
                        {/if}
                      </option>
                      <option value="8">
                        {#if $t['lang'] === 'en'}
                          Elastane
                        {:else}
                          Elastano
                        {/if}
                      </option>
                      <option value="9">
                        {#if $t['lang'] === 'en'}
                          Acrylic
                        {:else}
                          Acrílico
                        {/if}
                      </option>
                    </select>
                  </div>
                  <div class="select mb-5">
                    <select
                      bind:value={knitted['secondComponent']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Conventional cotton
                        {:else}
                          Algodón convencional
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Organic cotton
                        {:else}
                          Algodón orgánico
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Wool
                        {:else}
                          Lana
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Cellulosic fiber
                        {:else}
                          Fibras celulosicas
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Polyester
                        {:else}
                          Poliéster
                        {/if}
                      </option>
                      <option value="6">
                        {#if $t['lang'] === 'en'}
                          Recycled polyester
                        {:else}
                          Poliéster reciclado
                        {/if}
                      </option>
                      <option value="7">
                        {#if $t['lang'] === 'en'}
                          Nylon
                        {:else}
                          Nylon
                        {/if}
                      </option>
                      <option value="8">
                        {#if $t['lang'] === 'en'}
                          Elastane
                        {:else}
                          Elastano
                        {/if}
                      </option>
                      <option value="9">
                        {#if $t['lang'] === 'en'}
                          Acrylic
                        {:else}
                          Acrílico
                        {/if}
                      </option>
                    </select>
                  </div>
                  <div class="select mb-5">
                    <select
                      bind:value={knitted['thirdComponent']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Conventional cotton
                        {:else}
                          Algodón convencional
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Organic cotton
                        {:else}
                          Algodón orgánico
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Wool
                        {:else}
                          Lana
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Cellulosic fiber
                        {:else}
                          Fibras celulosicas
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Polyester
                        {:else}
                          Poliéster
                        {/if}
                      </option>
                      <option value="6">
                        {#if $t['lang'] === 'en'}
                          Recycled polyester
                        {:else}
                          Poliéster reciclado
                        {/if}
                      </option>
                      <option value="7">
                        {#if $t['lang'] === 'en'}
                          Nylon
                        {:else}
                          Nylon
                        {/if}
                      </option>
                      <option value="8">
                        {#if $t['lang'] === 'en'}
                          Elastane
                        {:else}
                          Elastano
                        {/if}
                      </option>
                      <option value="9">
                        {#if $t['lang'] === 'en'}
                          Acrylic
                        {:else}
                          Acrílico
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={knitted['quantity']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        {#if $t['lang'] === 'en'}
                          kg/year
                        {:else}
                          kg/año
                        {/if}
                      </label>
                    </div>
                  </div>
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={knitted['firstComponentQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        %
                      </label>
                    </div>
                  </div>
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={knitted['secondComponentQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        %
                      </label>
                    </div>
                  </div>
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={knitted['thirdComponentQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        %
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
  <div class="mr-4 mixed-yarn-transport">
    <div class="info w-full">
      <br />
      <h5 class="text-center">
        <b>
          {#if $t['lang'] === 'en'}
            Origin or Provenance
          {:else}
            Origen o procedencia
          {/if}
        </b>
      </h5>
      <p>
        {#if $t['lang'] === 'en'}
          Select the transport mean and the kilometres travelled (approximately)
          from the material production place to your company
        {:else}
          Selecciona el medio de transporte y kilómetros recorridos
          (aproximadamente) desde el lugar de producción del material hasta tu
          empresa
        {/if}
      </p>
    </div>
    <div class="inputs-transport w-full flex mt-5">
      <div class="flex-column w-full">
        <div class="row">
          {#each materials as knitted, index}
            <div class="flex flex-wrap flex-row w-full">
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="select mb-5">
                    <select
                      bind:value={knitted['firstTransport']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Train
                        {:else}
                          Tren
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Lorry
                        {:else}
                          Camión
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Commercial vehicle
                        {:else}
                          Vehículo comercial
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Ship
                        {:else}
                          Barco
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Aircraft
                        {:else}
                          Avión
                        {/if}
                      </option>
                    </select>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                </div>
                <div class="col-left">
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={knitted['firstTransportQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        km
                      </label>
                    </div>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="select mb-5">
                    <select
                      bind:value={knitted['secondTransport']}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Train
                        {:else}
                          Tren
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Lorry
                        {:else}
                          Camión
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Commercial vehicle
                        {:else}
                          Vehículo comercial
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Ship
                        {:else}
                          Barco
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Aircraft
                        {:else}
                          Avión
                        {/if}
                      </option>
                    </select>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                </div>
                <div class="col-left">
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={knitted['secondTransportQty']}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        km
                      </label>
                    </div>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                  <div class="false items-center text-center mb-12">
                    <label class="form-label ml-5 text-white" for="typeNumber">
                      {#if $t['lang'] === 'en'}
                        .
                      {:else}
                        .
                      {/if}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <button class="button" type="button" on:click={onAdd}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke="#111827"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>

<style>
  .mix-yarn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .yarn-info {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
  }

  .mixed-yarn-transport {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
    margin-top: 83px;
  }
  .inputs {
    align-items: center;
    align-content: center;
    justify-content: right;
  }

  .quantity {
    align-items: center;
    display: flex;
  }

  .inputs {
    align-items: center;
    align-content: center;
    justify-content: right;
  }
  .button {
    background-color: white;
  }
</style>
