<script>
  import { Accordion, AccordionItem } from 'svelte-accessible-accordion';
  import { t } from '../stores/messages';
</script>

<Accordion multiselect>
  <div class="flex-column mr-16 mt-5 ml-10 text-justify">
    <h4 class="text-black">
      {#if $t['lang'] === 'en'}
        Additional Material
      {:else}
        Material Adicional
      {/if}
    </h4>
    <div class="mt-5 mb-5 text-black">
      <p>
        {#if $t['lang'] === 'en'}
          This section will find general suggestions for improving performance
          according to the assessed dimensions. This information can serve as a
          guide on aspects to improve and general actions to implement. In
          addition, documents are provided in-depth on topics of interest for
          sustainability. Among the documents are various resources to
          facilitate the establishment of continuous improvement strategies.
          <p>The documents are classified into:</p>
        {:else}
          En este apartado encontrará sugerencias generales para mejorar el
          desempeño de acuerdo a cada una de las dimensiones evaluadas. Esta
          información puede servir de guía sobre los aspectos a mejorar y
          acciones generales para poner en práctica. Adicionalmente encontrará
          documentos que le permitirán profundizar en temas de interés para la
          sustentabilidad de su empresa. Entre los documentos encontrará
          diversos recursos para facilitar el establecimiento de estrategias de
          mejora continua. <p>Los documentos están clasificados en:</p>
        {/if}
      </p>
      <br />
      <div class="flex items-center mb-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="w-6 h-6"
          ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
            d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
          /></svg
        >
        <p class="ml-5">
          {#if $t['lang'] === 'en'}
            <div class="flex items-center">
              <p class="font-bold mr-5">Normativity:</p>
              <p>
                International regulatory frameworks and certifications in
                different areas.
              </p>
            </div>
          {:else}
            <div class="flex items-center">
              <p class="font-bold mr-5">Normatividad:</p>
              <p>
                Marcos normativos internacionales y certificaciones en
                diferentes áreas.
              </p>
            </div>
          {/if}
        </p>
      </div>
      <div class="flex items-center mb-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          class="w-6 h-6"
          ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
            d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
          /></svg
        >

        <p class="ml-5">
          {#if $t['lang'] === 'en'}
            <div class="flex items-center">
              <p class="font-bold mr-5">Guidelines:</p>
              <p>
                Documents and success stories to facilitate the integration of
                good practices in different areas.
              </p>
            </div>
          {:else}
            <div class="flex items-center">
              <p class="font-bold mr-5">Guías:</p>
              <p>
                Documentos que facilitan la integración de buenas prácticas en
                diferentes áreas y casos de éxito.
              </p>
            </div>
          {/if}
        </p>
      </div>
      <div class="flex items-center mb-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="w-6 h-6"
          ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
            d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
          /></svg
        >

        <p class="ml-5">
          {#if $t['lang'] === 'en'}
            <div class="flex items-center">
              <p class="font-bold mr-5">Analysis tools:</p>
              <p>
                Checklists, websites and other resources to integrate standards
                and certifications.
              </p>
            </div>
          {:else}
            <div class="flex items-center">
              <p class="font-bold mr-5">Herramientas de análisis:</p>
              <p>
                Listas de verificación, sitios web y otros medios que facilitan
                la integración de normativas y certificaciones.
              </p>
            </div>
          {/if}
        </p>
      </div>

      <div class="flex-column mr-16 mt-5 ml-10 mb-5">
        {#if $t['lang'] === 'en'}
          <div>
            Note: It is suggested to use a Continuous Improvement Strategic
            Planning model to implement any enhancement process.
          </div>
          <br />
          <div>
            <span class="font-bold">Strategic planning</span> is a management process
            that facilitates companies' decision-making so that results are constantly
            evaluated throughout the process and timely actions are taken to improve
            continuously. It requires establishing the steps to achieve the goals
            (planning), integrating procedures to measure results through key indicators,
            analysing outcomes, identifying strengths to be maintained and areas
            of improvement.
          </div>
        {:else}
          <div>
            Nota: Se sugiere utilizar un modelo de Planeación Estratégica de
            Mejora Continua, para cualquier proceso de mejora continua que se
            desee implementar.
          </div>
          <br />
          <div>
            La <span class="font-bold"> planeación estratégica</span> es un proceso
            de gestión que facilita la toma de decisiones de las empresas, de manera
            que constantemente se evalúen los resultados durante todo el proceso
            y se realicen acciones oportunas para mejorar continuamente. Se requiere
            establecer los pasos a seguir para lograr los objetivos planteados (planeación);
            integrar los procedimientos para medir los resultados mediante indicadores
            clave; analizar resultados e identificar puntos fuertes a mantener, así
            como áreas de mejora para dirigir las acciones constantemente.
          </div>
        {/if}
      </div>
      <div>
        {#if $t['lang'] === 'en'}
          <img src={'/images/pdca-en.png'} alt="pdca" />
        {:else}
          <img src={'/images/pdca-es.png'} alt="pdca" />
        {/if}
      </div>
      <div>
        <AccordionItem
          class="example leading-none rounded-lg shadow-md focus:outline-none focus:shadow-outline border border-secondary mt-2"
          title={$t['environmental']}
        >
          <ul>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-resources"
                ><span>{$t['environmental-resources']}</span></a
              >
            </li>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-emissions"
                ><span>{$t['environmental-emissions']}</span></a
              >
            </li>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-suppliers"
                ><span>{$t['environmental-suppliers']}</span></a
              >
            </li>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-transport"
                ><span>
                  {$t['environmental-transport']}
                </span></a
              >
            </li>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-risk"
                ><span>{$t['environmental-risk']}</span></a
              >
            </li>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-substances"
                ><span>{$t['environmental-substances']}</span></a
              >
            </li>
            <li class="mt-3">
              <a
                class="category-a"
                href="/app/tipsenvironmental/#environmental-research"
                ><span>{$t['environmental-research']}</span></a
              >
            </li>
            <li class="mt-3">
              <a class="category-a" href="/app/tipsenvironmental/#life-cycle"
                ><span
                  >{#if $t['lang'] === 'en'}
                    Life Cycle Assessment
                  {:else}
                    Análisis de Ciclo de Vida
                  {/if}</span
                ></a
              >
            </li>
          </ul>
        </AccordionItem>
      </div>
      <AccordionItem
        class="leading-none rounded-lg shadow-md focus:outline-none focus:shadow-outline border border-secondary mt-2"
        title={$t['social']}
      >
        <ul>
          <li class="mt-3">
            <a class="category-a" href="/app/tipssocial/#working-conditions"
              ><span>{$t['working-conditions']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipssocial/#human-and-labour-rights"
              ><span>{$t['human-and-labour-rights']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipssocial/#employee-benefits"
              ><span>{$t['employee-benefits']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipssocial/#wages-and-opportunities"
              ><span>{$t['wages-and-opportunities']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipssocial/#occupational-health-and-safety"
              ><span>{$t['occupational-health-and-safety']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipssocial/#suppliers"
              ><span>{$t['suppliers']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipssocial/#community"
              ><span>{$t['community']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipssocial/#customers">
              <span
                >{#if $t['lang'] === 'en'}
                  Customers
                {:else}
                  Clientes
                {/if}
              </span>
            </a>
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem
        class="leading-none rounded-lg shadow-md focus:outline-none focus:shadow-outline border border-secondary mt-2"
        title={$t['economic']}
      >
        <ul>
          <li class="mt-3">
            <a class="category-a" href="/app/tipseconomic/#business-plan"
              ><span>{$t['business-plan']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipseconomic/#production-cost"
              ><span>{$t['production-cost']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipseconomic/#financial-performance"
              ><span>{$t['financial-performance']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipseconomic/#collaboration"
              ><span>{$t['collaboration']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipseconomic/#vulnerability"
              ><span>{$t['vulnerability']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipseconomic/#financial-indicators"
              ><span>{$t['financial-indicators']}</span></a
            >
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem
        class="leading-none rounded-lg shadow-md focus:outline-none focus:shadow-outline border border-secondary mt-2"
        title={$t['management']}
      >
        <ul class=" text-content">
          <li class="mt-3">
            <a class="category-a" href="/app/tipsmanagement/#company-profile"
              ><span>{$t['company-profile']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipsmanagement/#automation"
              ><span>{$t['automation']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipsmanagement/#manufacturing-management"
              ><span>{$t['manufacturing-management']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipsmanagement/#administrative-management"
              ><span>{$t['administrative-management']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipsmanagement/#security"
              ><span>{$t['security']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipsmanagement/#sustainability-strategies"
              ><span>{$t['sustainability-strategies']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipsmanagement/#legislation-regulation"
              ><span>{$t['legislation-regulation']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipsmanagement/#corruption-prevention"
              ><span>{$t['corruption-prevention']}</span></a
            >
          </li>
          <li class="mt-3">
            <a
              class="category-a"
              href="/app/tipsmanagement/#transparency-communication"
              ><span>{$t['transparency-communication']}</span></a
            >
          </li>
          <li class="mt-3">
            <a class="category-a" href="/app/tipsmanagement/#market"
              ><span>{$t['market']}</span></a
            >
          </li>
        </ul>
      </AccordionItem>
    </div>
  </div></Accordion
>

<style>
  :global([data-accordion-item] [role='region']) {
    padding: 1rem;
  }

  a {
    color: #111827;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  a.category-a::before {
    content: '•';
    padding-right: 13px;
  }
</style>
