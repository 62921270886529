<script>
  import { t } from '../stores/messages';
  import { auth } from '../stores/auth';
  import Colaborators from './Icons/Colaborators.svelte';
  import Information from './Icons/Information.svelte';
  import Profile from './Icons/Profile.svelte';
  import Download from './Icons/Download.svelte';
  import Logout from './Icons/Logout.svelte';
  import ContactUs from './Icons/ContactUs.svelte';

  let isOpen = false;
  let linkClicked = '';
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  id="menu-wrapper"
  class="menu-wrapper"
  class:is-active={isOpen === true}
  on:click={() => {
    if (
      $auth.loggedIn &&
      $auth.me?.company?.name &&
      !window.location.href.includes('main/D')
    ) {
      isOpen = !isOpen;
    }
  }}
>
  <div class="menu-label flex items-center justify-between">
    <slot />
  </div>
  {#if $auth.loggedIn && $auth.me?.company?.name}
    <ul class="menu-list shadow-xl z-10">
      <li>
        <a
          class="menu-item"
          class:is-clicked={linkClicked === 'profile'}
          on:click={() => (linkClicked = 'profile')}
          href="/app/profile"
        >
          <div class="menu-icon">
            <Profile />
          </div>
          {#if $t['lang'] === 'en'}
            Profile
          {:else}
            Perfil
          {/if}
        </a>
      </li>
      <li>
        <a
          class="menu-item"
          class:is-clicked={linkClicked === 'colabs'}
          on:click={() => (linkClicked = 'colabs')}
          href="/app/colaborators"
        >
          <div class="menu-icon">
            <Colaborators />
          </div>
          {#if $t['lang'] === 'en'}
            Collaboration Network
          {:else}
            Colaboradores
          {/if}
        </a>
      </li>
      <li>
        <a
          class="menu-item"
          class:is-clicked={linkClicked === 'info'}
          on:click={() => (linkClicked = 'info')}
          href="/app/us"
        >
          <div class="menu-icon">
            <Information />
          </div>
          {#if $t['lang'] === 'en'}
            About us
          {:else}
            Nosotros
          {/if}
        </a>
      </li>
      <li>
        {#if $t['lang'] === 'en'}
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class="menu-item"
            class:is-clicked={linkClicked === 'manual'}
            on:click={() => {
              linkClicked = 'manual';
              window.open('/files/SATIN_User Manual_v1.1_EN.pdf', '_blank') ||
                window.location.replace('/files/SATIN_User Manual_v1.1_EN.pdf');
            }}
            href="#"
            download="SATIN_User Manual_v1_EN.pdf"
          >
            <div class="menu-icon">
              <Download />
            </div>
            Download Manual
          </a>
        {:else}
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class="menu-item"
            class:is-clicked={linkClicked === 'manual'}
            on:click={() => {
              linkClicked = 'manual';
              window.open(
                '/files/SATIN_Manual de Usuario_v1.1_ES.pdf',
                '_blank'
              ) ||
                window.location.replace(
                  '/files/SATIN_Manual de Usuario_v1.1_ES.pdf'
                );
            }}
            href="#"
            download="SATIN_Manual de Usuario_v1_ES.pdf"
          >
            <div class="menu-icon">
              <Download />
            </div>
            Descargar Manual
          </a>
        {/if}
      </li>
      <li class="mb-6">
        <a
          class="menu-item"
          class:is-clicked={linkClicked === 'contact'}
          on:click={() => (linkClicked = 'contact')}
          href="/app/contact"
        >
          <div class="menu-icon">
            <ContactUs />
          </div>
          {#if $t['lang'] === 'en'}
            Contact us
          {:else}
            Contáctanos
          {/if}
        </a>
      </li>
      <li>
        <a
          class="menu-item"
          class:is-clicked={linkClicked === 'logout'}
          on:click={() => {
            auth.set({ loggedIn: false });
            linkClicked = 'logout';
          }}
          href="/logout"
        >
          <div class="menu-icon">
            <Logout />
          </div>
          {#if $t['lang'] === 'en'}
            Logout
          {:else}
            Cerrar Sesión
          {/if}
        </a>
      </li>
    </ul>
  {/if}
</div>

<style>
  .menu-wrapper {
    margin-top: 8px;
    margin-right: 25px;
    position: relative;
    background: #fff;
    cursor: pointer;
    transition: all 0.3s ease-out;
    border-bottom: none;
  }
  .menu-label {
    min-width: 200px;
    height: 56px;
  }
  .menu-wrapper.is-active {
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    color: white;
  }
  .menu-wrapper.is-active .menu-list {
    max-height: 400px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);  */
  }

  .menu-wrapper.is-active .menu-label {
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .menu-list {
    /* Size & position */
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    /* Styles */
    background: #fff;
    border-radius: 0 0 2px 2px;
    transition: all 0.3s ease-out;
    /* Hiding */
    max-height: 0;
    overflow: hidden;
  }
  .menu-item {
    display: flex;
    align-items: center;
  }

  .menu-icon {
    margin-right: 5px;
    margin-left: 5px;
  }
  .menu-list li:hover a {
    background-color: #dbeafe;
  }
  .menu-list li a.is-clicked {
    background-color: #93c5fd;
  }
  .menu-list li:last-of-type a {
    border-bottom: none;
    border-top: 1px solid #f3f4f6;
  }
  .menu-list a {
    font-size: 14px;
    text-decoration: none;
    color: #111827e5;
    padding: 10px 30px;
    transition: all 0.3s ease-out;
  }

  .switch {
    font-size: 14px;
    text-decoration: none;
    color: #111827e5;
    margin-left: 5px;
    transition: all 0.3s ease-out;
  }
</style>
