import { writable } from 'svelte-local-storage-store';

export const dimensions = writable('satin-dimensions', {});

const TTL = 10 * 1000;

export const checkValidStoredObject = (o) => {
  if (Object.keys(o).length === 0) {
    return true;
  }
  const validDate = new Date().getTime() - TTL;
  if (o?.createdAt === undefined || o?.createdAt < validDate) {
    return true;
  } else {
    return false;
  }
  return true;
};
