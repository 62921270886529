<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';
  export let question;
  let answer;
  let notes = '';
  let substance1 = { name: '', value: 0 };
  let substance2 = { name: '', value: 0 };
  let substance3 = { name: '', value: 0 };
  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      let ans = parseAnswer(a[question.code].user_value);
      substance1.name = ans.substance1.name;
      substance1.value = ans.substance1.value;
      substance2.name = ans.substance2.name;
      substance2.value = ans.substance2.value;
      substance3.name = ans.substance3.name;
      substance3.value = ans.substance3.value;
      notes = a[question.code].notes;
    }
  });

  function save() {
    answer = {
      substance1: substance1,
      substance2: substance2,
      substance3: substance3,
    };
    saveAnswer(question, JSON.stringify(answer), notes);
  }
</script>

<div class="answer mt-5 pr-5">
  <div class="flex-column w-full">
    <div class="row mb-5">
      <div class="flex flex-wrap flex-row w-full">
        <div class="flex flex-column basis-1 flex-1">
          <div class="substance">
            <div>
              <label class="form-label mr-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  Substance
                {:else}
                  Sustancia
                {/if}
              </label>
            </div>
            <div class="form-outline">
              <input
                type="text"
                id="typeText"
                class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                bind:value={substance1['name']}
              />
            </div>
          </div>
          <div class="quantity">
            <div>
              <label class="form-label mr-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  Quantity
                {:else}
                  Cantidad
                {/if}
              </label>
            </div>
            <div class="form-outline mr-5">
              <input
                type="number"
                id="typeText"
                class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                min="0"
                step=".001"
                bind:value={substance1['value']}
                on:change={() => {
                  save();
                }}
              />
            </div>
            <div>
              <label class="form-label ml-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  kg/year
                {:else}
                  kg/año
                {/if}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="flex flex-wrap flex-row w-full">
        <div class="flex flex-column basis-1 flex-1">
          <div class="substance">
            <div>
              <label class="form-label mr-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  Substance
                {:else}
                  Sustancia
                {/if}
              </label>
            </div>
            <div class="form-outline">
              <input
                type="text"
                id="typeText"
                class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                bind:value={substance2['name']}
                on:change={() => {
                  save();
                }}
              />
            </div>
          </div>
          <div class="quantity">
            <div>
              <label class="form-label mr-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  Quantity
                {:else}
                  Cantidad
                {/if}
              </label>
            </div>
            <div class="form-outline mr-5">
              <input
                type="number"
                id="typeText"
                class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                min="0"
                step=".001"
                bind:value={substance2['value']}
                on:change={() => {
                  save();
                }}
              />
            </div>
            <div>
              <label class="form-label ml-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  kg/year
                {:else}
                  kg/año
                {/if}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="flex flex-wrap flex-row w-full">
        <div class="flex flex-column basis-1 flex-1">
          <div class="substance">
            <div>
              <label class="form-label mr-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  Substance
                {:else}
                  Sustancia
                {/if}
              </label>
            </div>
            <div class="form-outline">
              <input
                type="text"
                id="typeText"
                class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                bind:value={substance3['name']}
                on:change={() => {
                  save();
                }}
              />
            </div>
          </div>
          <div class="quantity">
            <div>
              <label class="form-label mr-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  Quantity
                {:else}
                  Cantidad
                {/if}
              </label>
            </div>
            <div class="form-outline mr-5">
              <input
                type="number"
                id="typeText"
                class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                min="0"
                step=".001"
                bind:value={substance3['value']}
                on:change={() => {
                  save();
                }}
              />
            </div>
            <div>
              <label class="form-label ml-5" for="typeNumber">
                {#if $t['lang'] === 'en'}
                  kg/year
                {:else}
                  kg/año
                {/if}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, JSON.stringify(answer), notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>

<style>
  .answer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .row {
    display: flex;
    margin-left: 3px;
  }
  .substance {
    align-items: center;
    display: flex;
    justify-items: center;
  }
  .form-outline {
    width: 50%;
  }
  .quantity {
    align-items: center;
    display: flex;
    justify-items: end;
  }
</style>
