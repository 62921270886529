<script>
  import { get } from 'svelte/store';
  import { t } from '../../stores/messages';
  import { answers, companyProcesses } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';
  import V13Knitted from './V13Knitted.svelte';
  import V13KnittedMixed from './V13KnittedMixed.svelte';
  import V13Woven from './V13Woven.svelte';
  import V13WovenMixed from './V13WovenMixed.svelte';

  const questionCode = 'A113';

  let answer = {};
  let knitted = [newMaterial(), newMaterial(), newMaterial()];
  let mixedKnitted = [
    newMixedMaterial(),
    newMixedMaterial(),
    newMixedMaterial(),
  ];
  let woven = [newMaterial(), newMaterial(), newMaterial()];
  let mixedWoven = [newMixedMaterial(), newMixedMaterial(), newMixedMaterial()];

  answers.subscribe((a) => {
    if (a[questionCode] !== undefined) {
      answer = parseAnswer(a[questionCode]?.user_value || '{}');
      knitted = answer.knitted || [newMaterial(), newMaterial(), newMaterial()];
      woven = answer.woven || [newMaterial(), newMaterial(), newMaterial()];
      mixedKnitted = answer.mixedKnitted || [
        newMixedMaterial(),
        newMixedMaterial(),
        newMixedMaterial(),
      ];
      mixedWoven = answer.mixedWoven || [
        newMixedMaterial(),
        newMixedMaterial(),
        newMixedMaterial(),
      ];
    }
  });

  function newMaterial() {
    return {
      fabric: '',
      quantity: 0,
      firstTransport: '',
      firstTransportQty: 0,
      secondTransport: '',
      secondTransportQty: 0,
    };
  }

  function newMixedMaterial() {
    return {
      quantity: 0,
      firstComponent: '',
      secondComponent: '',
      thirdComponent: '',
      firstComponentQty: 0,
      secondComponentQty: 0,
      thirdComponentQty: 0,
      firstTransport: '',
      firstTransportQty: 0,
      secondTransport: '',
      secondTransportQty: 0,
    };
  }
</script>

{#if $companyProcesses.some((p) => 'M3'.indexOf(p) >= 0)}
  <V13Knitted
    title={$t['lang'] === 'en' ? 'Knitted Fabric 100%' : 'Tejido de punto 100%'}
    materials={knitted}
    onAdd={() => {
      knitted = [...knitted, newMaterial()];
    }}
    onUpdate={(k) => {
      const a = get(answers);
      const oldAnswer = parseAnswer(a[questionCode]?.user_value || '{}');
      if (JSON.stringify(k) !== JSON.stringify(oldAnswer.knitted)) {
        knitted = k;
        answer.knitted = knitted;
        saveAnswer(
          { code: questionCode, answerType: 'V13' },
          JSON.stringify(answer)
        );
      }
    }}
  />

  <!-- svelte-ignore missing-declaration -->
  <V13KnittedMixed
    title={$t['lang'] === 'en'
      ? 'Mixed Knitted Fabric'
      : 'Tejido de punto mixto'}
    materials={mixedKnitted}
    onAdd={() => {
      mixedKnitted = [...mixedKnitted, newMixedMaterial()];
    }}
    onUpdate={(k) => {
      const a = get(answers);
      const oldAnswer = parseAnswer(a[questionCode]?.user_value || '{}');
      if (JSON.stringify(k) !== JSON.stringify(oldAnswer.mixedKnitted)) {
        mixedKnitted = k;
        answer.mixedKnitted = mixedKnitted;
        saveAnswer(
          { code: questionCode, answerType: 'V13' },
          JSON.stringify(answer)
        );
      }
    }}
  />
{/if}

{#if $companyProcesses.some((p) => 'M4'.indexOf(p) >= 0)}
  <V13Woven
    title={$t['lang'] === 'en' ? 'Woven Fabric 100%' : 'Tejido plano 100%'}
    materials={woven}
    onAdd={() => {
      woven = [...woven, newMaterial()];
    }}
    onUpdate={(w) => {
      const a = get(answers);
      const oldAnswer = parseAnswer(a[questionCode]?.user_value || '{}');
      if (JSON.stringify(w) !== JSON.stringify(oldAnswer.woven)) {
        woven = w;
        answer.woven = woven;
        saveAnswer(
          { code: questionCode, answerType: 'V13' },
          JSON.stringify(answer)
        );
      }
    }}
  />

  <V13WovenMixed
    title={$t['lang'] === 'en' ? 'Mixed Woven Fabric' : 'Tejido plano mixto'}
    materials={mixedWoven}
    onAdd={() => {
      mixedWoven = [...mixedWoven, newMixedMaterial()];
    }}
    onUpdate={(k) => {
      const a = get(answers);
      const oldAnswer = parseAnswer(a[questionCode]?.user_value || '{}');
      if (JSON.stringify(k) !== JSON.stringify(oldAnswer.mixedWoven)) {
        mixedWoven = k;
        answer.mixedWoven = mixedWoven;
        saveAnswer(
          { code: questionCode, answerType: 'V13' },
          JSON.stringify(answer)
        );
      }
    }}
  />
{/if}

<style>
</style>
