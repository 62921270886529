<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;
  let answer = '';
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = a[question.code].user_value;
      notes = a[question.code].notes;
    }
  });
</script>

<div class="mt-5 flex justify-between items-center">
  <ul>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'a', notes);
          }}
          bind:group={answer}
          value={'a'}
        />
        {#if $t['lang'] === 'en'}
          a) Have not been presented. Mechanisms or procedures are in place
        {:else}
          a) No se han presentado. Se cuenta con mecanismos o procedimientos
          implementados
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'b', notes);
          }}
          bind:group={answer}
          value={'b'}
        />
        {#if $t['lang'] === 'en'}
          b) Yes, there are mechanisms or procedures in place
        {:else}
          b) Si, se cuenta con mecanismos o procedimientos implementados
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'c', notes);
          }}
          bind:group={answer}
          value={'c'}
        />
        {#if $t['lang'] === 'en'}
          c) Yes, with mechanisms or procedures being developed
        {:else}
          c) Si, con mecanismos o procedimientos en desarrollo
        {/if}
      </label>
    </li>
    <li>
      <label>
        <input
          type="radio"
          on:change={async () => {
            await saveAnswer(question, 'd', notes);
          }}
          bind:group={answer}
          value={'d'}
        />
        {#if $t['lang'] === 'en'}
          d) There are no mechanisms in place to detect it
        {:else}
          d) No se cuenta con mecanismos para detectarlo
        {/if}
      </label>
    </li>
  </ul>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, answer, notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>
