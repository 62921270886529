<script>
  import { Router } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { get } from "svelte/store";
  import { routes } from "./routes";
  import { getMessages, getMe } from "./services/satin";
  import { t } from "./stores/messages";
  import { auth } from "./stores/auth";
  import { checkValidAuthObject } from "./stores/auth";

  onMount(async () => {
    if (Object.keys(get(t)).length === 0) {
      const m = await getMessages();
      t.set(m);
    }
    if (checkValidAuthObject(get(auth))) {
      const me = await getMe();
      let pathname = window.location.pathname;

      if (
        me.loggedIn === false &&
        !["/", "/app/about", "/app/transition", "/app/main/D"].includes(
          pathname
        )
      ) {
        window.location.href = "/";
      } else {
        me.createdAt = new Date().getTime();
        auth.set(me);
      }
    }
  });
</script>

<svelte:head>
</svelte:head>

<div class="bg-gray-50 h-full min-h-screen">
  <Router {routes} />
</div>

<style global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
