<script>
  import AppLogo from '../components/AppLogo.svelte';
  import { t } from '../stores/messages';
</script>

<div class="bg-white h-screen flex flex-row justify-evenly">
  <div class="flex justify-center items-center">
    <AppLogo />
  </div>
  <div class="flex justify-center flex-col right-0">
    <h1 class="text-gray-900 font-bold text-2xl leading-9 mt-4 m-1.5">
      {$t['home-title']}
    </h1>
    <h6 class="mt-4">{$t['home-message']}</h6>
    <div class="mt-4 m-1.5">
      <a href={'/app/about'}>
        <button
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
        >
          {$t['home-access']}
        </button>
      </a>
    </div>
  </div>
</div>
