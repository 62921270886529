<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';
  export let question;
  let notes = '';
  let answer = {
    national: 0,
    uscan: 0,
    europe: 0,
    other: 0,
  };

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      answer = parseAnswer(a[question.code].user_value);
      notes = a[question.code].notes;
    }
  });

  const savePercentage = (e) => {
    const total = Object.values(answer).reduce((a, b) => a + b, 0);
    if (total <= 100) {
      saveAnswer(question, JSON.stringify(answer), notes);
    } else {
      e.target.value = 0;
    }
  };
</script>

<div class="answer mt-5 pr-5">
  <div class="col-left">
    <div class="national mb-5">
      <div>
        <label class="form-label mr-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            National
          {:else}
            Nacional
          {/if}
        </label>
      </div>
      <div class="form-outline mr-5 national-inputs">
        <input
          type="number"
          id="typeText"
          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          min="0"
          max="100"
          step=".001"
          placeholder="%"
          bind:value={answer['national']}
          on:change={(e) => {
            savePercentage(e);
          }}
        />
      </div>
      <div>
        <label class="form-label ml-5" for="typeNumber"> % </label>
      </div>
    </div>
    <div class="united-states mb-5">
      <div>
        <label class="form-label mr-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            United States and Canada
          {:else}
            Estados Unidos y Canadá
          {/if}
        </label>
      </div>
      <div class="form-outline mr-5">
        <input
          type="number"
          id="typeText"
          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          min="0"
          max="100"
          step=".001"
          placeholder="%"
          bind:value={answer['uscan']}
          on:change={(e) => {
            savePercentage(e);
          }}
        />
      </div>
      <div>
        <label class="form-label ml-5" for="typeNumber"> % </label>
      </div>
    </div>
    <div class="europe mb-5">
      <div>
        <label class="form-label mr-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Europe
          {:else}
            Europa
          {/if}
        </label>
      </div>
      <div class="form-outline mr-5 europe-inputs">
        <input
          type="number"
          id="typeText"
          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          min="0"
          max="100"
          step=".001"
          placeholder="%"
          bind:value={answer['europe']}
          on:change={(e) => {
            savePercentage(e);
          }}
        />
      </div>
      <div>
        <label class="form-label ml-5" for="typeNumber"> % </label>
      </div>
    </div>
    <div class="others mb-5">
      <div>
        <label class="form-label mr-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            Others
          {:else}
            Otros
          {/if}
        </label>
      </div>
      <div class="form-outline mr-5 others-inputs">
        <input
          type="number"
          id="typeText"
          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          min="0"
          max="100"
          step=".001"
          placeholder="%"
          bind:value={answer['other']}
          on:change={(e) => {
            savePercentage(e);
          }}
        />
      </div>
      <div>
        <label class="form-label ml-5" for="typeNumber"> % </label>
      </div>
    </div>
  </div>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(question, JSON.stringify(answer), notes);
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>

<style>
  .answer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .national {
    align-items: center;
    display: flex;
    justify-items: center;
  }
  .national-inputs {
    margin-left: 113px;
  }
  .united-states {
    align-items: center;
    display: flex;
    justify-items: end;
  }
  .europe {
    align-items: center;
    display: flex;
    justify-items: center;
  }
  .europe-inputs {
    margin-left: 122px;
  }
  .others {
    align-items: center;
    display: flex;
    justify-items: end;
  }
  .others-inputs {
    margin-left: 124px;
  }
  .form-outline {
    width: 30%;
  }
</style>
