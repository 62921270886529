<script>
  import { Accordion, AccordionItem } from 'svelte-accessible-accordion';
  import { answers } from '../stores/answers';
  import { dimensions } from '../stores/dimensions';
  import { t } from '../stores/messages';
  import SidebarGeneral from '../components/SidebarGeneral.svelte';
  import Environmental from './Icons/Environmental.svelte';
  import DownArrow from '../components/Icons/DownArrow.svelte';
  import Social from '../components/Icons/Social.svelte';
  import Economic from '../components/Icons/Economic.svelte';
  import Management from '../components/Icons/Management.svelte';
  import Results from '../components/Icons/Results.svelte';
  import {
    totalQuestionsPerCategory,
    totalAnsweredPerCategory,
  } from '../results/allDimensionsLevel';

  export let dimension = '';
  export let categoryId = '';

  let categoryAnsweredClass = (categoryCode, allAnswers, allDimensions) => {
    let classes = '';
    if (categoryCode === categoryId) {
      classes = 'category-active ';
    }
    if (
      allAnswers &&
      allDimensions &&
      totalAnsweredPerCategory(categoryCode, allAnswers) >=
        totalQuestionsPerCategory(categoryCode, allDimensions)
    ) {
      classes = classes + 'category-finished';
    } else {
      classes = classes + '';
    }
    return classes;
  };
</script>

<Accordion multiselect class="noprint">
  <div class="general-content rounded-lg">
    <AccordionItem expanded={dimension === 'D'}>
      <a href="/app/main/D" slot="title">
        <div class="pl-10 pt-10 w-full flex flex-row">
          <SidebarGeneral />
          <div class="general ml-4 text-base leading-6">General</div>
        </div>
      </a>
    </AccordionItem>

    <AccordionItem expanded={dimension === 'A'}>
      <div class="pl-4 pt-10 w-full flex flex-row" slot="title">
        <div
          class="
        {dimension === 'A' ? 'subtitle-selected' : null}
        subtitle ml-4 text-base leading-6
        "
        >
          <div class="icon">
            <Environmental />
          </div>
          <span>
            {$t['lang'] === 'en' ? 'Environmental' : 'Ambiental'}
          </span>
          <div class="arrow-icon">
            <DownArrow />
          </div>
        </div>
      </div>

      <ul
        class="accordion-collapse collapse category-list list-disc"
        id="collapseElementA"
        data-bs-parent="#sidebarElementA"
      >
        {#each $dimensions['A']?.['categories']?.data || [] as category}
          <li class="cursor-pointer category-li marker:text-red-800">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              class={`category-a ml-12 ${categoryAnsweredClass(
                category.code,
                $answers,
                $dimensions
              )}`}
              on:click={() => {
                window.location.href = `/app/main/A/${category.code}`;
              }}
              href={category.code}
            >
              {category[$t['lang']]}
            </a>
          </li>
        {/each}
      </ul>
    </AccordionItem>

    <AccordionItem expanded={dimension === 'S'}>
      <div class="pl-4 pt-10 w-full flex flex-row" slot="title">
        <div
          class="
            {dimension === 'S' ? 'subtitle-selected' : null}
            subtitle ml-4 text-base leading-6
          "
        >
          <div class="icon">
            <Social />
          </div>
          <span>
            {$t['lang'] === 'en' ? 'Social' : 'Social'}
          </span>
          <div class="arrow-icon">
            <DownArrow />
          </div>
        </div>
      </div>
      <ul
        class="accordion-collapse collapse category-list"
        id="collapseElementS"
        data-bs-parent="#sidebarElementS"
      >
        {#each $dimensions['S']?.['categories']?.data || [] as category}
          <li class="cursor-pointer category-li">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              class={`category-a ml-12 ${categoryAnsweredClass(
                category.code,
                $answers,
                $dimensions
              )}`}
              on:click={() => {
                window.location.href = `/app/main/S/${category.code}`;
              }}
              href={category.code}
            >
              {category[$t['lang']]}
            </a>
          </li>
        {/each}
      </ul>
    </AccordionItem>

    <AccordionItem expanded={dimension === 'E'}>
      <div class="pl-4 pt-10 w-full flex flex-row" slot="title">
        <div
          class="
            {dimension === 'E' ? 'subtitle-selected' : null}
            subtitle ml-4 text-base leading-6
          "
        >
          <div class="icon">
            <Economic />
          </div>
          <span>
            {$t['lang'] === 'en' ? 'Economic' : 'Económico'}
          </span>
          <div class="arrow-icon">
            <DownArrow />
          </div>
        </div>
      </div>
      <ul
        class="accordion-collapse collapse category-list"
        id="collapseElementE"
        data-bs-parent="#sidebarElementE"
      >
        {#each $dimensions['E']?.['categories']?.data || [] as category}
          <li class="cursor-pointer category-li">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              class={`category-a ml-12 ${categoryAnsweredClass(
                category.code,
                $answers,
                $dimensions
              )}`}
              on:click={() => {
                window.location.href = `/app/main/E/${category.code}`;
              }}
              href={category.code}
            >
              {category[$t['lang']]}
            </a>
          </li>
        {/each}
      </ul>
    </AccordionItem>

    <AccordionItem expanded={dimension === 'G'}>
      <div class="pl-4 pt-10 w-full flex flex-row" slot="title">
        <div
          class="
            {dimension === 'G' ? 'subtitle-selected' : null}
            subtitle ml-4 text-base leading-6
          "
        >
          <div class="icon">
            <Management />
          </div>
          <span>
            {$t['lang'] === 'en' ? 'Management' : 'Gestión'}
          </span>
          <div class="arrow-icon">
            <DownArrow />
          </div>
        </div>
      </div>
      <ul
        class="accordion-collapse collapse category-list"
        id="collapseElementG"
        data-bs-parent="#sidebarElementG"
      >
        {#each $dimensions['G']?.['categories']?.data || [] as category}
          <li class="cursor-pointer category-li">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              class={`category-a ml-12 ${categoryAnsweredClass(
                category.code,
                $answers,
                $dimensions
              )}`}
              on:click={() => {
                window.location.href = `/app/main/G/${category.code}`;
              }}
              href={category.code}
            >
              {category[$t['lang']]}
            </a>
          </li>
        {/each}
      </ul>
    </AccordionItem>

    <AccordionItem expanded={dimension === 'results'}>
      <div class="pl-4 pt-10 w-full flex flex-row" slot="title">
        <div
          class="
        {dimension === 'results' ? 'subtitle-selected' : null}
        subtitle ml-4 text-base leading-6
        "
        >
          <div class="icon">
            <Results />
          </div>
          {$t['lang'] === 'en' ? 'Results' : 'Resultados'}
          <div class="arrow-icon">
            <DownArrow />
          </div>
        </div>
      </div>

      <ul
        class="accordion-collapse collapse category-list"
        id="collapseElementR"
        data-bs-parent="#sidebarElementR"
      >
        <li class="cursor-pointer category-li">
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class={`category-a ml-12 ${
              window.location.search.includes('charts') ? 'category-active' : ''
            }`}
            href="/app/results#charts"
            on:click={() => {
              if (window.location.href.includes('results')) {
                window.location.hash = '#charts';
                window.location.reload();
              }
            }}
          >
            {$t['lang'] === 'en' ? 'Graphs and Tables' : 'Gráficas y Tablas'}
          </a>
        </li>
        <li class="cursor-pointer category-li">
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class={`category-a ml-12 ${
              window.location.search.includes('tips') ? 'category-active' : ''
            }`}
            href="/app/results#tips"
            on:click={() => {
              if (window.location.href.includes('results')) {
                window.location.hash = '#tips';
                window.location.reload();
              }
            }}
          >
            {$t['lang'] === 'en'
              ? 'Tips & additional material'
              : 'Tips de mejora y material adicional'}
          </a>
        </li>
      </ul>
    </AccordionItem>
  </div>
</Accordion>

<style>
  :global([data-accordion-item] button) {
    background: #ffffff !important;
    font: inherit;
    line-height: inherit;
    color: inherit;
    cursor: pointer;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
  }
  .general-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 227px;
    min-height: 100%;
  }
  .general {
    color: #111827;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .subtitle {
    color: #111827;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .subtitle-selected {
    padding: 4px;
    background: #93c5fd;
    border-radius: 8px;
  }
  ul.category-list {
    white-space: nowrap;
  }
  li.category-li {
    white-space: nowrap;
    padding: 6px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.15px;
    align-items: center;
    display: flex;
  }
  a.category-finished::before {
    color: #34d399;
  }
  a.category-a:hover {
    background: #dbeafe;
    border-radius: 8px;
    padding: 6px;
  }
  .category-active {
    background: #93c5fd;
    border-radius: 8px;
    padding: 6px;
  }
  a.category-a:active {
    background: #93c5fd;
    border-radius: 8px;
    padding: 6px;
  }
  a.category-a {
    display: flex;
    color: #111827;
    white-space: initial;
    word-wrap: break-word;
    hyphens: auto;
  }
  a.category-a::before {
    content: '•';
    padding-right: 13px;
    font-size: 32px;
  }
  .icon {
    align-items: center;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  .arrow-icon {
    align-items: center;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
  }
</style>
