<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { getDimensions } from '../services/satin';
  import DimensionBanner from '../components/DimensionBanner.svelte';
  import Navbar from '../components/Navbar.svelte';
  import ScrollButton from '../components/ScrollButton.svelte';
  import Sidebar from '../components/Sidebar.svelte';

  import { t } from '../stores/messages';
  import { dimensions, checkValidStoredObject } from '../stores/dimensions';

  onMount(async () => {
    if (checkValidStoredObject(get(dimensions))) {
      const d = await getDimensions();
      d.createdAt = new Date().getTime();
      dimensions.set(d);
    }
  });
</script>

<Navbar />
<div class="mt-7 flex flex-row w-full">
  <Sidebar dimension="results" />
  <div class="w-full overflow-hidden">
    <div class="mx-auto w-11/12 mb-4 p-4 flex flex-row items-center">
      <DimensionBanner dimension="Environmental" />
    </div>
    <section
      class="mx-auto w-11/12 p-4 text-gray-600 body-font bg-white shadow-xl rounded-lg scroll-p-0"
    >
      <div class="flex-column mr-16 mt-5 ml-10 text-justify">
        <div>
          <h2>
            A. {$t['environmental']}
          </h2>
          <h4>
            A1. {$t['environmental-resources']}
          </h4>
          {#if $t['lang'] === 'en'}
            The use of resources such as fibres, chemicals, dyes, electricity,
            water, and fuels is crucial to a company's environmental and
            economic performance. For this reason, it is of utmost importance to
            record and analyse the use of these resources in a systematic way.
            This section will find information on the efficient use of resources
            and tools to facilitate their control.
          {:else}
            El uso de recursos como fibras, químicos, colorantes, electricidad,
            agua y combustibles son cruciales en el desempeño ambiental y
            económico de una empresa. Por esta razón es de suma importancia
            registrar y analizar el uso de estos recursos de manera sistemática.
            En esta sección podrá encontrar información relativa al uso
            eficiente de los recursos, así como también, algunas herramientas
            para facilitar su control.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>

                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Cleaner Production Guide for Textile Sector
                          {:else}
                            Cleaner Production Guide for Textile Sector
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            WWF/H&M, Turkey, 2018
                          {:else}
                            WWF/H&M, Turkey, 2018
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            <li>Clean Production in Turkey's textile sector</li>
                            <li>
                              Replacement of virgin materials with recycled
                              materials.
                            </li>
                            <li>Integration of safe chemicals</li>
                            <li>Reduction of water and energy use</li>
                            <li>
                              Actions to be taken to integrate clean production
                              according to each textile process
                            </li>
                          {:else}
                            <li>Clean Production in Turkey's textile sector</li>
                            <li>
                              Replacement of virgin materials with recycled
                              materials.
                            </li>
                            <li>Integration of safe chemicals</li>
                            <li>Reduction of water and energy use</li>
                            <li>
                              Actions to be taken to integrate clean production
                              according to each textile process
                            </li>
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            EN
                          {:else}
                            EN
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://d2ouvy59p0dg6k.cloudfront.net/downloads/wwf_guideline_cleaner_production_textile_2018_2.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://d2ouvy59p0dg6k.cloud<br
                            />front.net/downloads/wwf_guide<br
                            />line_cleaner_production_<br />textile_2018_2</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-center border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Wet Processing Guidebook
                          {:else}
                            Wet Processing Guidebook
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Solidaridad network, The Netherlands,
                          {:else}
                            Solidaridad network, The Netherlands,
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            <li>
                              Main problems of the textile industry according to
                              the different wet processes carried out.
                            </li>
                            <li>
                              Guide to improve the environmental performance of
                              textile companies.
                            </li>
                            <li>
                              Use of chemicals and dyes with low environmental
                              impact
                            </li>
                            <li>Use of low environmental impact fibres</li>
                            <li>Efficient use of water and energy</li>
                          {:else}
                            <li>
                              Main problems of the textile industry according to
                              the different wet processes carried out.
                            </li>
                            <li>
                              Guide to improve the environmental performance of
                              textile companies.
                            </li>
                            <li>
                              Use of chemicals and dyes with low environmental
                              impact
                            </li>
                            <li>Use of low environmental impact fibres</li>
                            <li>Efficient use of water and energy</li>
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            EN
                          {:else}
                            EN
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/nieuws/2020/solidaridad-guidebook-wet-processing.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten.nl<br
                            />/-/media/imvo/files/kleding/nieuws<br
                            />/2020/solidaridad-guidebook-wet<br
                            />-processing.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <h5>A11. {$t['environmental-material-use']}</h5>
          {#if $t['lang'] === 'en'}
            <div>
              A Materials Management and Control Plan and good inventory control
              are essential to keep proper records of material used. This will
              provide the information needed to improve the company's
              environmental performance and reduce costs. A materials use plan
              should contain guidelines to prevent, minimise and remediate
              adverse impacts on natural resources through sustainable practices
              and the use of alternative resources.The plan should consider
              acquisition frequency, annual quantity, materials origin, and
              specific characteristics. It is also essential to implement
              mechanisms to measure the consumption of these materials within
              the process. For example, knowing how much material is required
              per product, how much waste is generated and at what point in the
              process will help locate leakage points or waste. It is essential
              to keep records with detailed information for material use
              analysis. It is also recommended that employees are trained in the
              sustainable use of resources to raise awareness of natural
              resources.
            </div>
            <br />
            <div class="mt-5">
              It is important to emphasise that the environmental impact of
              materials directly impacts the company's environmental
              performance. Therefore, if the goal is to reduce the environmental
              impact or carbon footprint, it is necessary to consume materials
              with a low environmental impact without neglecting their quality.
              In addition, efforts should be increased to replace non-renewable
              resources with renewable resources and recycled materials.
            </div>
            <br />
            <div>
              Regarding <span class="font-bold">fibres, yarns and fabrics</span>
              , it is necessary to know the characteristics of each material its
              strengths and weaknesses; this will provide the required information
              to choose alternative materials with better environmental performance.
              For example, it is convenient to know the process of obtaining fibre,
              considering where they come from and how much water and chemical substances
              are involved. If the fibres or other materials are sourced from a distant
              location, the associated carbon footprint will increase significantly
              instead of local resources.
            </div>
            <br />
            <div>
              Also, including fibres or materials with recycled content
              decreases the environmental impact and contributes to a better
              performance of the company.
            </div>
            <br />
            <div>
              Regarding <span class="font-bold">packaging material</span>, it is
              necessary to analyse each material's function, eliminate those
              that are not indispensable, include a significant percentage of
              recycled supplies, and avoid implementing single-use materials.
              Additionally, suppose it is necessary to know the product's
              environmental impact in-depth; it is advisable to conduct a Life
              Cycle Assessment to evaluate the production stages and materials.
              This study will provide valuable information for choosing
              materials according to their environmental impact.
            </div>
          {:else}
            <div>
              Para llevar un registro adecuado del uso de materiales es esencial
              contar con un Plan de manejo y control de materiales, así como
              también, un buen control de inventarios. Esto proporcionará la
              información necesaria para tomar decisiones encaminadas a mejorar
              el desempeño ambiental de la empresa y reducir costos. Un plan de
              uso de materiales debe contener las pautas para prevenir,
              minimizar y remediar impactos adversos en materia de recursos
              naturales a través de prácticas sustentables y uso de recursos
              alternativos. Para la elaboración de este plan se deben tomar en
              cuenta aspectos como el tiempo o frecuencia de adquisición, la
              cantidad anual adquirida, el origen de los materiales adquiridos y
              conocer sus características detalladamente. De igual manera, es
              importante implementar mecanismos encaminados a medir el consumo
              de estos materiales dentro del proceso. Por ejemplo, conocer
              cuanto material es requerido por producto, que cantidad de
              residuos se generan y en qué punto del proceso; esto ayudará a
              localizar puntos de fuga o desperdicios. Es indispensable llevar
              registro o bitácoras con toda la información necesaria para
              realizar un análisis detallado del uso de los materiales. Así
              mismo, es recomendable que los empleados cuenten con la
              capacitación adecuada acerca de uso de recursos de manera
              sustentable, con la finalidad de crear conciencia del uso de
              materiales y recursos naturales.
            </div>
            <br />
            <div class="mt-5">
              Es importante resaltar que el impacto ambiental que los materiales
              tengan asociado, repercute directamente en el desempeño ambiental
              de la empresa. Por lo cual, si se desea disminuir el impacto
              ambiental o la huella de carbono de la empresa, es necesario
              consumir aquellos materiales que presenten un impacto ambiental
              bajo, sin descuidar la calidad de los mismos. Se deben incrementar
              esfuerzos para sustituir el uso de materiales derivados de
              recursos no renovables por recursos renovables y materiales
              reciclados.
            </div>
            <br />
            <div class="mt-5">
              En lo referente a <span class="font-bold"
                >fibras, hilos y tejidos</span
              > es necesario conocer las características de cada material, conocer
              sus fortalezas y debilidades; esto proporcionará la información necesaria
              para realizar una selección adecuada o bien buscar materiales alternativos
              que presenten un mejor desempeño ambiental. Por ejemplo, es conveniente
              conocer el proceso de obtención de las fibras, de donde provienen,
              que cantidad de agua o sustancias químicas son necesarias para su obtención.
              Si las fibras o algún otro material provienen de un lugar lejano, la
              huella de carbono asociada se incrementará significativamente, contrario
              a utilizar recursos locales. Así mismo, incluir fibras o materiales
              con contenido reciclado disminuye el impacto ambiental y contribuye
              al mejor desempeño de la empresa.
            </div>
            <br />
            <div class="mt-5">
              En lo referente a <span class="font-bold"
                >material de empaque</span
              >
              es necesario analizar la función de cada material, para eliminar aquellos
              que no sean indispensables, así como también incluir el mayor porcentaje
              de materiales reciclados y evitar la implementación de materiales de
              un solo uso. Adicionalmente, si se desea conocer a profundidad el impacto
              ambiental asociado a un producto, es conveniente realizar un Análisis
              de Ciclo de Vida, para evaluar cada una de las etapas del producto
              y sus materiales. Este estudio proporcionará información de gran valor
              para la elección de materiales según su impacto ambiental.
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Raw materials and environment
                          {:else}
                            Raw materials and environment
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Agreement on Sustainable Garments and Textiles, SER,
                            The Netherlands
                          {:else}
                            Agreement on Sustainable Garments and Textiles, SER,
                            The Netherlands
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Form to collect information on raw materials used in
                            the textile industry
                          {:else}
                            Form to collect information on raw materials used in
                            the textile industry
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            EN
                          {:else}
                            EN
                          {/if}
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/en/garments-textile/tools"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/en/garments-textile<br />/tools</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A12. Electricity use</h5>
            The generation and consumption of electricity lead to greenhouse gases
            emission, which contributes directly to Climate Change. For this reason,
            the company must take measures to quantify electricity consumption and
            generate savings alternatives. To this end, it is recommended to develop
            an electricity use and reduction plan. During the development of this
            plan, it is recommended to install monitoring, measurement and electricity
            saving devices in the main areas and equipment. These devices can be,
            for instance, presence detectors, timers, and solar panels. The electricity
            use and reduction plan should contribute to identifying possible savings
            points and facilitate the establishment of reduction targets. It is also
            essential to carry out periodic maintenance of electrical installations
            to eliminate increased consumption faults. It is also advisable to train
            employees on electricity-saving measures.
          {:else}
            <h5>A12. Uso de electricidad</h5>

            La generación y consumo de electricidad lleva consigo la emisión de
            gases efecto invernadero, los cuales contribuyen directamente con el
            Cambio Climático. Por tal motivo, la empresa debe tomar medidas para
            medir el consumo de electricidad y generar alternativas de ahorro.
            Para este fin, se recomienda desarrollar un plan de reducción y uso
            de electricidad. Para el desarrollo de este plan se recomienda
            instalar dispositivos de monitoreo, medición y ahorro de
            electricidad en las principales áreas y equipos, tales como
            detectores de presencia, temporizadores y paneles solares, entre
            otros. El plan de reducción y uso de electricidad debe contribuir en
            la identificación de posibles puntos de ahorro y facilitar el
            establecimiento de metas de reducción. Así mismo, es importante
            realizar un mantenimiento periódico de las instalaciones eléctricas
            con la finalidad de eliminar fallas que incrementen el consumo. De
            igual manera es recomendable proporcionar información y capacitar a
            los empleados acerca de medidas de ahorro de electricidad.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A13. Use of fuels</h5>
            The use of fuels derived from non-renewable resources has a strong environmental
            impact, so it is necessary to reduce the consumption of these fuels and
            implement renewable energy sources. To this end, it is recommended to
            generate a plan to analyse the use of fuels and establish goals and follow-up
            actions in their reduction and substitution.
          {:else}
            <h5>A13. Uso de Combustibles</h5>

            El uso de combustibles derivados de recursos no renovables ejerce un
            fuerte impacto ambiental, por lo cual es necesario reducir el
            consumo de estos combustibles e implementar fuentes de energía
            renovables. Para este fin, se recomienda generar un plan que analice
            el uso de combustibles y establezca acciones, metas y seguimiento en
            su disminución y sustitución.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A14. Water use</h5>
            The textile industry has a significant international water consumption,
            so it is crucial to make good use of this resource. For this, it is necessary
            to keep strict control of water consumption in the main areas and processes
            of the company. Therefore, it is advisable to have water meters and water-saving
            systems in the shared facilities and the production area. For this reason,
            a water consumption and reduction plan is an essential element in any
            organisation. This plan should include a detailed control of water use
            by areas and processes; this will help identify possible reduction points
            and leaks. Similarly, the plan should be established clear water-saving
            goals and actions to achieve them. In this aspect, it is advisable to
            implement equipment and devices guaranteeing resource-efficient use,
            water recirculation and reuse systems. It is also necessary to carry
            out periodic maintenance of the plumbing system to detect leaks. Likewise,
            the company must have the necessary permits to extract or obtain water
            from the public water supply and national water bodies.
          {:else}
            <h5>A14. Uso de agua</h5>

            La industria textil presenta un importante consumo de agua a nivel
            internacional, por lo cual es crucial hacer un uso adecuado de este
            recurso. Para esto, es necesario llevar un control estricto del
            consumo de agua en las principales áreas y procesos de la empresa.
            Es recomendable contar con medidores y sistemas de ahorro de agua
            tanto en las instalaciones de uso común como en el área de
            producción Por esta razón un plan de reducción y consumo de agua es
            un elemento imprescindible en cualquier organización. Este plan debe
            incluir un control detallado del uso de agua por áreas y procesos;
            esto ayudará a identificar los posibles puntos de reducción y fugas.
            De igual manera se deben establecer metas claras de ahorro de agua y
            las acciones que se realizarán para alcanzarlas. En este aspecto es
            conveniente la implementación de equipos y dispositivos que
            garanticen un uso eficiente del recurso, así como sistemas de
            recirculación y reutilización de agua. También es necesario realizar
            mantenimiento periódico al sistema de fontanería para detectar
            fugas. Así mismo, la empresa debe contar con los permisos necesarios
            para extraer u obtener agua del suministro publico y/o cuerpos de
            agua nacionales.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="environmental-emissions">A2. Emissions and waste</h4>
            The generation of emissions and waste generates adverse environmental
            and economic consequences. All waste generation entails disposal, treatment
            or remediation costs that in many cases can be avoided. For this reason,
            it is of utmost importance to keep track of the waste quantity and emissions
            or discharges, including rate, generation point, handling and disposal.
            This will provide the necessary information for decision making and improved
            waste management.
          {:else}
            <h4 id="environmental-emissions">A2. Emisiones y Residuos</h4>

            La generación de emisiones y residuos no solo genera consecuencias
            ambientales adversas, sino que también repercute económicamente.
            Toda generación de residuos conlleva gastos de disposición,
            tratamiento o remediación que en muchos casos pueden ser evitados.
            Por esta razón es de suma importancia llevar un control de cantidad
            de residuos, emisiones o descargas, que incluya tasa y punto de
            generación, manejo y disposición. Esto proporcionará la información
            necesaria para la toma de decisiones y mejorar el manejo de
            residuos.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A21. Wastewater</h5>
            The company should record wastewater discharges, including type, source,
            limit values and amounts of pollutants in effluents. It is essential
            to be aware of applicable legislation and ensure that effluents meet
            the stipulated values. For a wastewater management plan, it is advisable
            to improve its control by recording parameters such as volume of generated
            sewage, treated water, recirculation, discharge to national water bodies
            or sewage network and the Physico-chemical characteristics effluents.
            In addition, it is also advisable to generate actions aimed at reducing
            wastewater, such as reducing consumption and recirculation. In the case
            of effluent treatment, it is essential to have information about the
            inlet and outlet characteristics and maintain a periodic sampling system.
          {:else}
            <h5>A21. Agua Residual</h5>

            La empresa debe contar con registro de descargas de aguas
            residuales, incluyendo tipo, fuente, valores de límite y cantidades
            de contaminantes en efluentes. Es imprescindible estar al tanto de
            la legislación aplicable y asegurar que los efluentes reúnan los
            valores estipulados. Para llevar un mejor control y manejo del agua
            residual es conveniente contar con un plan de manejo de aguas
            residuales, el cual debe marcar las pautas para llevar el registro
            de los siguientes parámetros: Volumen de agua residual generada
            anualmente, volumen de agua tratada, volumen de agua recirculada,
            volumen de agua descargada a cuerpos de agua nacionales, volumen de
            agua descargada a la red de alcantarillado y características
            fisicoquímicas de los efluentes y descargas. De igual manera es
            conveniente generar acciones encaminadas a la disminución de aguas
            residuales, como reducción de consumo y recirculación. En caso de
            realizar un tratamiento a los efluentes es indispensable contar con
            información acerca de las características de entrada y salida, así
            como mantener un sistema de muestreo periódico.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Gestión de efluentes de la industria textil
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          López Grimau, V., & Crespi Rosell, M. (2015)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          Presenta los principales parámetros para caracterizar
                          las aguas residuales de la industria textil,
                          reducción, prevención, medidas de control y procesos
                          de depuración
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://uploads-ssl.webflow.com/5c4065f2d6b53e08a1b03de7/5db70334bd2f007e2fbc8577_ZDHC_WastewaterGuidelines_V1.1_JUL19_compressed%20(1).pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://uploads-ssl<br />.webflow.com/5c406<br
                            />5f2d6b53e08a1b03de7/<br />5db70334bd2f007e2fbc8<br
                            />577_ZDHC_WastewaterGu<br />idelines_V1.1_JUL19_<br
                            />compressed%20(1).pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-center border-r border-l"
                        >
                          ZDHC Wastewater Guidelines
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          The Roadmap To Zero Programme, 2019
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          Permissible parameters in water and sewage sludge.
                          Recommended sampling methods according to
                          international standards.
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/ehs-guidelines/ehsguidelines"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/<br
                            />wcm/connect/topics_ext_content<br
                            />/ifc_external_corporate_site<br
                            />/sustainability-at-ifc/policies<br
                            />-standards/ehs-guidelines/<br />ehsguidelines</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A22. Emissions</h5>
            Greenhouse gas (GHG) emissions such as carbon dioxide (CO2), nitrous
            oxide (N2O), methane CH4, ozone O3, sulphur hexafluoride (SF6), hydrofluorocarbons
            (HFCs), and perfluorocarbons (PFCs) are substances that contribute directly
            to Climate Change. For this reason, it is essential to monitor sources
            and minimise their generation. To this end, it is advisable to implement
            the GHG Protocol to identify sources, quantify emissions and obtain the
            necessary information to execute improvement actions. It is also advisable
            to have a plan for monitoring and quantifying emissions according to
            the company's activities. Control of oil and acid mists, solvent vapours,
            odours and fibre dust is required. For this purpose, it is worthwhile
            to implement equipment such as filters, air scrubbers, deposition chambers,
            scrubbers and electrostatic precipitators, among others. It is also essential
            that the company has the necessary permits according to the generated
            emissions.
          {:else}
            <h5>A22. Emisiones</h5>

            Las emisiones de Gases Efecto Invernadero (GEI) como el Dióxido de
            Carbono (CO2), óxido nitroso (N2O), metano CH4 y ozono O3 así como
            el hexafluoruro de azufre (SF6), los hidrofluorocarbonos (HFC), y
            los perfluorocarbonos (PFC) son sustancias que contribuyen
            directamente al Cambio Climático. Por esta razón es importante
            monitorizar las fuentes y minimizar su generación. Para tal efecto
            es recomendable implementar el Protocolo de GEI para identificar las
            fuentes, cuantificar las emisiones y contar con la información
            necesaria para implementar acciones de mejora. Así mismo es
            recomendable contar con un plan de monitoreo y cuantificación de
            emisiones de acuerdo a las actividades de la empresa. Se requiere
            realizar un control de neblinas de aceites y ácidos, vapores de
            disolventes, olores y polvo de fibras. Para este fin es recomendable
            implementar equipos como filtros, depuradores de aire, cámaras de
            deposición, scrubbers y precipitadores electroestáticos entre otros.
            De igual manera es indispensable que la empresa cuente con los
            permisos necesarios de acuerdo al tipo de emisiones generadas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          GHG Protocol Corporate Accounting and Reporting
                          Standard
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Greenhouse Gas Protocol
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standard for the quantification of Greenhouse Gases
                          (GHG)
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://ghgprotocol.org/corporate-standard"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://ghgprotocol<br />.org/corporate-standard</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-center border-r border-l"
                        >
                          Calculation tools
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Greenhouse Gas Protocol
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Tools for calculating Greenhouse Gas Generation
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://ghgprotocol.org/calculation-tools"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://ghgprotocol.org/<br />calculation-tools</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-center border-r border-l"
                        >
                          Emisiones atmosféricas generados por la industria
                          textil
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Gutiérrez , M. C., Droguet, M., & Crespi, M. (2003)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Presenta las emisiones atmosféricas en la industria
                          textil, las principales fuentes de generacón y los
                          sistemas de reducción
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://upcommons.upc.edu/bitstream/handle/2099/2753/7EMISIONESATMOSFERICAS.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://upcommons.<br />upc.edu/bitstream/handle<br
                            />/2099/2753/7EMISIONES<br />ATMOSFERICAS.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 text-center border-r border-l"
                        >
                          Environmental, Health, and Safety Guideline
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2007)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - The Environmental, Health, and Safety (EHS)
                          Guidelines are technical reference documents with
                          general and industry-specific examples of Good
                          International Industry Practice - Air Emissions,
                          Energy conservation, Wastewater, Water conservation,
                          Hazardous materials management, Waste management,
                          Noise, Contaminated land
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/ehs-guidelines/ehsguidelines"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/wcm<br
                            />/connect/topics_ext_content/<br
                            />ifc_external_corporate_site<br
                            />/sustainability-at-ifc/policies-standards<br
                            />/ehs-guidelines/ehsguidelines</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A23. Discharges to ground</h5>
            To prevent pollution and ground quality damage, it is necessary to avoid
            wastewater discharges and deposition of solid waste into the soil, as
            it can leach or seep into the ground. For this purpose, it is necessary
            to use adequate gutters, drainage systems and implement waste containers,
            covers, and anti-filtration strategies.
          {:else}
            <h5>A23. Descargas al suelo</h5>

            Para prevenir contaminación y daños en la calidad del suelo, es
            necesario evitar descargas de aguas residuales con sustancias
            químicas en suelo, así como la deposición de residuos sólidos que
            puedan ser objeto de lixiviados o filtración. Para tal efecto es
            necesario el uso de canaletas y sistemas de drenaje adecuados, así
            como la implementación de recipientes adecuados para contener
            residuos, cubiertas y sistemas anti filtración.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Environmental, Health, and Safety Guideline
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2007)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - The Environmental, Health, and Safety (EHS)
                          Guidelines are technical reference documents with
                          general and industry-specific examples of Good
                          International Industry Practice - Air Emissions,
                          Energy conservation, Wastewater, Water conservation,
                          Hazardous materials management, Waste management,
                          Noise, Contaminated land
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/ehs-guidelines/ehsguidelines"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/wcm/<br
                            />connect/topics_ext_content/ifc_<br
                            />external_corporate_site/<br />sustainability<br
                            />-at-ifc/policies-standards/<br
                            />ehs-guidelines/ehsguidelines</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A24. Waste generation</h5>
            It is essential to have a Waste Management Plan, which identifies all
            waste generated, type, generation rate, source, storage, treatment, recycling,
            and disposal. This information is necessary to minimise waste production
            and establish strategies for waste recovery. This will reduce the environmental
            impact and reduce production costs. Likewise, the company must ensure
            that it has the necessary permits for handling, storage, recycling and
            waste disposal, depending on the generated waste type. In this sense,
            waste is classified into municipal solid, hazardous, and special waste.
            Each of these has different characteristics and requires specific management
            according to local legislation. In addition, the company must train its
            employees for the correct handling, storage, transport and disposal of
            waste, according to its type.
          {:else}
            <h5>A24. Generación de residuos</h5>

            Para un buen manejo de residuos es indispensable contar con un Plan
            de gestión de residuos, mediante el cual se identifiquen todos los
            residuos generados, tipo, tasa de generación, fuente,
            almacenamiento, tratamiento, reciclaje y disposición. Esta
            información es necesaria para minimizar la producción de residuos y
            establecer estrategias de aprovechamiento. De esta manera se podrá
            disminuir no solo el impacto ambiental asociado a la empresa, sino
            que también disminuirán los costos de producción. Así mismo, la
            empresa debe asegurarse de contar con los permisos necesarios para
            el manejo, almacenamiento, reciclaje y eliminación de residuos,
            según el tipo de residuos que genere. En este sentido, los residuos
            se clasifican en: Residuos sólidos urbanos, residuos peligrosos y
            residuos de manejo especial. Cada uno de ellos posee características
            diferentes y requieren un manejo determinado. Adicionalmente, la
            empresa debe proporcionar la capacitación adecuada a sus empleados
            para el correcto manejo, almacenaje, transporte y disposición de los
            residuos, de acuerdo a su tipo.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Waste minimization in Textile Industry
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Uddin, Z., Nayab-Ul-Hossain, & Sela, S. K. (2017)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Key aspects to minimize waste - Efficient use of
                          water, energy and chemicals
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.iosrjournals.org/iosr-jpte/papers/Vol4-Issue5/C04051518.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.iosrjournals.org/iosr-jpte/papers/Vol4-Issue5/C04051518.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Minimización de residuos textiles
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          European Union, 2007
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Alternativas de minimización de residuos en la
                          industria textil
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://docplayer.es/18005677-Minimizacion-de-residuos-textiles.html"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://docplayer.es/18005677-Minimizacion-de-residuos-textiles.html</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Guía para el Manejo Integral de Residuos en la
                          Industria Textil
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Universidad Pontificia Bolivariana, Colombia (2008)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Plan de gestión integral de residuos para el sector
                          textil - Generación de residuos por proceso -
                          Reducción en fuente - Tratamiento y aprovechamiento de
                          residuos
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.sabaneta.gov.co/files/doc_varios/Gu%C3%ADa%20para%20el%20Manejo%20Integral%20de%20Residuos%20-%20Subsector%20confeccion,%20telas%20y%20vestuario.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.sabaneta.gov.co/<br
                            />files/doc_varios/Gu%C3<br
                            />%ADa%20para%20el%20Manejo<br
                            />%20Integral%20de%20Residuos%<br
                            />20-%20Subsector%20confeccion,<br
                            />%20telas%20y%20vestuario.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="environmental-suppliers">
              A3. Environmental assessment of suppliers
            </h4>
            To achieve adequate traceability and build a sustainable value chain,
            it is essential to know the practices carried out by suppliers as they
            indirectly affect the organisation's performance. In other words, if
            the company is interested in generating sustainable or environmentally
            friendly products, good performance within the company is not enough;
            also, good performance throughout the product value chain must be guaranteed.
            For this, it is essential to know the environmental practices of suppliers.
            That is, to see the origin of their raw materials, to ensure that they
            make good resources use and adequate waste management. For this reason,
            it is suggested to implement the procedures presented below.
          {:else}
            <h4 id="environmental-suppliers">
              A3. Evaluación ambiental de proveedores
            </h4>
            Para lograr una adecuada trazabilidad y construir una cadena de valor
            sostenible es indispensable conocer las prácticas realizadas por los
            proveedores ya que están afectan indirectamente el desempeño de la organización.
            En otras palabras, si la empresa está interesada en generar productos
            sustentables o ambientalmente amigables, no basta con un buen desempeño
            dentro de la empresa, si no que se debe de garantizar el buen desempeño
            a lo largo de la cadena del valor del producto. Para esto, es indispensable
            conocer las prácticas de los proveedores en materia ambiental. Esto es,
            conocer el origen de sus materias primas, asegurar que hagan un buen
            uso de recursos y un adecuado manejo de residuos. Por esta razón se sugiere
            implementar los procedimientos presentados a continuación.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Assessment and Management of Environmental and Social
                          Risks and Impacts
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standards for risk management: Labor and Working
                          Conditions, Resource - Efficiency and Pollution
                          Prevention, Community Health, Safety, and Security,
                          Cultural Heritage
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/performance-standards/ps1"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps/wcm<br
                            />/connect/topics_ext_content/<br
                            />ifc_external_corporate<br
                            />_site/sustainability-at-ifc/<br
                            />policies-standards/<br
                            />performance-standards/ps1</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Due Diligence Guidance for Responsible
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Guidelines for implementing continuous improvement
                          strategies to increase efficiency, transparency,
                          traceability and improve stakeholder relations
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/investment/due-diligence-guidance-for-responsible-business-conduct.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/investment/<br
                            />due-diligence-guidance-for-<br
                            />responsible-business-conduct.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A31. Supplier Environmental Assessment Protocol</h5>
            It is highly recommended to generate a protocol to evaluate suppliers'
            environmental performance, preferably at the time of contracting but
            also at any other time. This procedure requires a transparent and open
            dialogue with the supplier, explaining the company's needs and the guidelines
            to be followed. In this way, not only can the desired goal be achieved,
            but also the customer-supplier relationship and the value chain will
            be strengthened. The protocol should establish a systematised procedure
            for the environmental assessment and the circumstances under which it
            will be carried out.
          {:else}
            <h5>A31. Protocolo de evaluación ambiental de proveedores</h5>
            Generar un protocolo que establezca las acciones para evaluar el desempeño
            ambiental de los proveedores, ya sea preferentemente en el momento de
            su contratación o bien en cualquier otro momento. Para realizar este
            procedimiento se requiere un diálogo claro y abierto con el proveedor,
            exponiendo las necesidades de la empresa y las pautas a seguir. De esta
            manera no solo se puede alcanzar la meta deseada, si no que se fortalecerá
            la relación cliente-proveedor, así como la cadena de valor. El protocolo
            debe establecer claramente el procedimiento a seguir en la evaluación
            ambiental y las circunstancias en las que se realizará. De tal manera
            que se pueda generar una práctica sistematizada dentro de las actividades
            de la empresa.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Environmental assessment of suppliers</h5>
            To generate the environmental assessment of suppliers, it is important
            to establish the objective of the evaluation, what is to be achieved,
            what are the aspects of interest and how best to obtain the information.
            Must be taken into account the given conditions and available resources.
          {:else}
            <h5>A32. Evaluación ambiental de proveedores</h5>
            Para generar la evaluación ambiental de proveedores es importante establecer
            el objetivo de la evaluación, qué se busca lograr, cuáles son los aspectos
            de interés y cuál es la mejor manera de obtener la información. Tomando
            en cuenta las condiciones dadas y los recursos disponibles.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A33. Improvement agreement with suppliers</h5>
            When the evaluation has been carried out, the results should be analysed,
            and the areas that need improvement should be determined. Once the aspects
            are improved and the goals are clear, it is necessary to reach an agreement
            with the supplier for both parties to benefit.
          {:else}
            <h5>A33. Acuerdo de mejora con proveedores</h5>
            Una vez realizada la evaluación se deben analizar los resultados y determinar
            que aspectos se requiere mejorar. Una vez claros los aspectos de mejora
            y las metas deseadas es necesario llegar a un acuerdo con el proveedor.
            De tal manera que las dos partes se vean beneficiadas con la estrategia
            a seguir.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="environmental-transport">A4. Transport of products</h4>
            <h5>A41. Product shipments</h5>
            The burning of any fuel generates GHGs, so transporting products plays
            a relevant role in the environmental organisation's performance. For
            this reason, it is important to keep a record of the products shipped,
            the destination or approximate distance travelled, vehicle type and the
            fuel used. In this way, the company will quantify the carbon footprint
            associated with the transportation of its products. Likewise, both in
            the environmental and economic aspects, it is essential to have good
            distribution logistics that allow for more efficient journeys and therefore
            reduce the impact.
          {:else}
            <h4 id="environmental-transport">A4. Transporte de productos</h4>
            <h5>A41. Envío de productos</h5>
            La quema de cualquier combustible genera GEI, por lo cual el transporte
            de productos juega un relevante papel en el desempeño ambiental de una
            organización. Por esta razón es importante llevar un registro de la cantidad
            de productos enviados, el destino o distancia aproximada recorrida, tipo
            de vehículo y combustible empleado. De esta manera la empresa podrá cuantificar
            la huella de carbono asociada al transporte de sus productos. Así mismo,
            tanto en el aspecto ambiental como en el económico, es esencial contar
            con una buena logística de distribución que permita hacer más eficientes
            los viajes y por lo tanto disminuir el impacto ambiental
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="environmental-risk">A5. Environmental risk</h4>
            To avoid risks or setbacks and know how to act and mitigate their effects,
            it is necessary to understand those aspects that represent a risk for
            the organisation. In this way, if the risk is known and studied, the
            company will avoid and anticipate through preventive actions. As a result,
            it minimises the risky circumstances and the material or economic costs.
          {:else}
            <h4 id="environmental-risk">A5. Riesgo ambiental</h4>

            Para evitar riesgos o contratiempos y para saber actuar y mitigar
            sus efectos es necesario conocer detalladamente aquellos aspectos
            que representan un riesgo para la organización. De esta manera si se
            conocen y estudian los riesgos, la empresa podrá evitar y
            anticiparse mediante acciones preventivas. Minimizando las
            circunstancias riesgosas y los costos materiales o económicos que
            estos conlleven.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A51. Environmental Risk Assessment (ERA)</h5>
            An environmental risk assessment identifies and evaluates potential ecological
            and health risks of human activity. It consists of quantifying and qualifying
            the associated effects with the use of hazardous substances or equipment
            that can generate an explosion, such as boilers and pressure tanks, among
            others. This process will provide the necessary information to establish
            prevention and mitigation measures. It is essential to carry out a risk
            assessment when designing new operations or integrating new activities.
            The company should ensure that information regarding potential or actual
            risks and impacts of its functions is accessible to workers, communities
            and potentially affected stakeholders. In addition, the company should
            identify, assess, and remediate any soil, air or water pollution, noise
            or vibration that may negatively impact the environment or health.
          {:else}
            <h5>A51. Evaluación de riesgo ambiental (ERA)</h5>

            La evaluación de riesgo ambiental es un proceso mediante el cual se
            identifican y evalúan los posibles riesgos para el medio ambiente y
            la salud ejercido por una actividad humana determinada. Consiste en
            cuantificar y cualificar los efectos asociados al uso de sustancias
            peligrosas o equipos que puedan generar alguna explosión, como
            calderas y tanques de presión entre otros. Este proceso
            proporcionará la información necesaria para establecer medidas de
            prevención y mitigación. Es importante realizar una evaluación de
            riesgo al momento de diseñar nuevas operaciones o integrar nuevas
            actividades a la organización. La empresa debe asegurarse que la
            información referente a los riesgos potenciales o reales y los
            impactos de sus operaciones estén accesibles para los trabajadores,
            comunidades y grupos de interés potencialmente afectados. La empresa
            debe identificar, evaluar y remediar cualquier contaminación a
            suelo, aire o agua, así como ruido o vibraciones que pueda generar
            un impacto negativo en el ambiente o en la salud.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-center">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l border-l text-left"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          Environmental Risk Assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          Tellus, Australia
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Basic concepts, mitigation measures, discussion of
                          risks and cumulative impacts
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          <a
                            href="https://tellusholdings.com/wp-content/uploads/2019/07/ch-eis_chapter-06_environmental-risk-assessment.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://tellusholdings.com/wp-content/uploads/2019/07/ch-eis_chapter-06_environmental-risk-assessment.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          Guía para la presentacion del Estudio de Riesgo
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          SEMARNAT, Mexico
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Conceptos básicos, diagrama de flujo del
                          procedimiento, identificación de escenarios de riesgo,
                          descripción de zonas de protección y medidas de
                          seguridad preventivas
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          ES
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l border-l"
                        >
                          <a
                            href="https://www.gob.mx/cms/uploads/attachment/file/120998/Guia_Estudio_de_Riesgo__Analisis_de_Riesgo_.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.gob.mx/cms/uploads<br
                            />/attachment/file/120998/Guia_<br
                            />Estudio_de_Riesgo__Analisis<br
                            />_de_Riesgo_.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A52. Identification of environmental risks</h5>
            Identifying environmental risks involves analysing activities or circumstances
            that may cause adverse effects and designing solutions that minimise
            them. This requires processes to ensure the identification and periodic
            evaluation of the company's operations and activities based on past data
            and future trends. This process should prioritise risks, opportunities
            and impacts, including all the stakeholders. The company should record,
            investigate and remediate any extraordinary accidents or effects. It
            should also establish actions, timelines, follow-up, and means to address
            potential impacts and achieve goals. Similarly, the company must have
            mechanisms to ensure that procedures are correctly applied. It is also
            essential to perform regular maintenance in production processes, ventilation
            systems, boilers, and other equipment involved. Furthermore, it is recommended
            to ensure stakeholder participation by facilitating transparency on environmental
            issues of common interest.
          {:else}
            <h5>A52. Identificación de riesgos ambientales</h5>

            La identificación de riesgos ambientales consiste en determinar y
            evaluar los riesgos, oportunidades y efectos en materia ambiental.
            Para esto es necesario contar con procesos para garantizar la
            identificación y evaluación periódica de las operaciones y
            actividades de la empresa, con base en datos e información de los
            hechos ocurridos en el pasado y las tendencias futuras. Este proceso
            debe basarse en un método para priorizar riesgos, oportunidades e
            impactos, incluyendo a todas las personas y grupos de interés
            relacionados. La empresa debe registrar, investigar y remediar los
            accidentes o impactos extraordinarios que se presenten. Así mismo,
            debe establecer acciones, plazos, seguimiento y medios utilizados
            para hacer frente a los potenciales impactos y alcanzar las metas
            establecidas. De igual manera, es importante que la empresa cuente
            con mecanismos para asegurar que los procedimientos sean aplicados
            correctamente. Así como también realizar mantenimientos periódicos a
            los procesos de producción y sistemas de ventilación, calidad del
            aire, tratamiento de aguas, etc. Adicionalmente se recomienda
            garantizar la transparencia y participación de los grupos
            involucrados con referencia a las cuestiones ambientales de interés
            común.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Assessment and Management of Environmental and Social
                          Risks and Impacts
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          International Finance Corporation (IFC), World Bank
                          Group (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Standards for risk management: Labor and Working
                          Conditions, Resource Efficiency and Pollution
                          Prevention, Community Health, Safety, and Security,
                          Cultural Heritage
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/sustainability-at-ifc/policies-standards/performance-standards/ps1"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.ifc.org/wps<br
                            />/wcm/connect/topics_ext_content/<br
                            />ifc_external_corporate<br
                            />_site/sustainability-at-ifc/<br
                            />policies-standards/<br
                            />performance-standards/ps1</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Managing risks and risk assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          HSE (2022)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Risk assessment template and examples
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.hse.gov.uk/simple-health-safety/risk/risk-assessment-template-and-examples.htm#article"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.hse.gov.uk<br
                            />/simple-health-safety<br
                            />/risk/risk-assessment-<br
                            />template-and-examples.<br />htm#article</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A53. Environmental permits</h5>
            The company must ensure that it has the necessary permits and complies
            with the applicable legal requirements according to the activities carried
            out and the existing environmental risks. Therefore, it is advisable
            to establish a system for updating permits following current legislation
            and mechanisms to ensure monitoring and compliance.
          {:else}
            <h5>A53. Permisos ambientales</h5>
            La empresa debe asegurarse de contar con los permisos necesarios y de
            cumplir con los requisitos legales aplicables según las actividades realizadas
            y los riesgos ambientales existentes. Es conveniente establecer un sistema
            de actualización de permisos según la legislación vigente, así como mecanismos
            que garanticen el seguimiento y cumplimiento de los mismos.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A54. Penalties for noncompliance</h5>
            It is necessary to deal with and resolve any sanctions for environmental
            noncompliance that may occur. To this end, it is advisable to establish
            a procedure for dealing with and resolving sanctions that prevent inconveniences,
            establish actions, ensure correct management, and avoid future incidents.
          {:else}
            <h5>A54. Sanciones por incumplimiento ambiental</h5>
            Es necesario atender y dar solución a las sanciones por incumplimiento
            ambiental que se lleguen a presentar. Para lo cual es conveniente establecer
            un procedimiento de atención y solución de sanciones que registre los
            inconvenientes, establezca acciones, asegure el correcto manejo y evite
            futuras sanciones.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="environmental-substances">
              A6. Use of chemical substances
            </h4>
            The handling, storage, use and chemicals disposal is an issue that directly
            affects the company, workers and customers environmentally, socially
            and economically. For this reason, it is of utmost importance to have
            strict control and management of them and mechanisms that contribute
            to safeguarding the well-being of all stakeholders. These prevention
            activities also must include training and awareness-raising actions.
          {:else}
            <h4 id="environmental-substances">
              A6. Uso de sustancias químicas
            </h4>

            El manejo, almacenamiento, uso y disposición de sustancias químicas
            es un tema que afecta directamente a la empresa, trabajadores,
            clientes y consumidores en el ámbito ambiental, social y económico.
            Por esta razón es de suma importancia tener un estricto control y
            manejo de ellas, así como mecanismos que contribuyan a salvaguardar
            la seguridad y salud de todas las partes interesadas, incluyendo
            capacitación y acciones de concientización.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A61. Inventory of Chemical Substances</h5>
            To make a correct handle of chemical substances, it is essential to have
            a complete inventory of the substances dealt with, their chemical characteristics,
            function, and safety measures. Therefore, this inventory must be updated
            regularly.
          {:else}
            <h5>A61. Inventario de Sustancias químicas</h5>
            Para un correcto manejo de sustancias químicas es indispensable contar
            con un inventario completo de las sustancias que se manejan, sus características
            químicas, función y medidas de seguridad. Es importante que este inventario
            sea actualizado de manera periódica.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          The Roadmap To Zero Programme
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          The Roadmap To Zero Programme
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Information and documentation about the use of
                          chemical substances
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.roadmaptozero.com/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.roadmaptozero.com/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A62. Restricted Substances List</h5>
            <div>
              Concerning the management of chemical substances, two lists
              restrict the use of substances during the manufacturing process
              and in the finished product. Both lists are used to control
              hazardous substances and their effects on health. However, the use
              and handling of these substances can have adverse consequences, so
              it is necessary to be aware of them and eliminate their use.
            </div>
            <br />
            <div>
              <span class="font-bold">
                Manufacturing Restricted Substance List (MRSL):</span
              >
              This is the list of substances restricted during the manufacture of
              clothing, textiles and footwear. It provides manufacturers and suppliers
              with acceptable limits in chemical formulations used in production.
              This regulatory tool protects workers, consumers and the environment.
            </div>
            <br />
            <div>
              <span class="font-bold"> Restricted Substances List (RSL):</span> This
              list is a control tool including substances that may be used in manufacturing
              but must not be present in the finished product. This tool contributes
              to compliance with international safety standards.
            </div>
          {:else}
            <h5>A62. Inventario de Sustancias químicas</h5>
            <div>
              Referente a la gestión de sustancias químicas, existen dos listas
              que restringen el uso de sustancias durante el proceso de
              fabricación y en producto terminado. Ambas listas se utilizan como
              medio de control en función a la peligrosidad de las sustancias y
              sus efectos en la salud. El uso y manejo de estas sustancias puede
              generar consecuencias adversas, por lo cual es necesario
              conocerlas y eliminar su uso.
            </div>
            <br />
            <div>
              <span class="font-bold"
                >Manufacturing Restricted Substance List (MRSL):</span
              > Es la lista de sustancias restringidas durante la fabricación de
              prendas de vestir, productos textiles y calzado. Proporciona a los
              fabricantes y proveedores los límites aceptables de estas sustancias
              en las formulaciones químicas empleadas en la producción. Esta herramienta
              de regulación protege a los trabajadores, a los consumidores y al medio
              ambiente.
            </div>
            <br />
            <div>
              <span class="font-bold">Restricted Substances List (RSL):</span> Esta
              lista es una herramienta de control que incluye sustancias que pueden
              ser utilizadas en la fabricación, pero no deben estar presentes en
              el producto terminado. Esta herramienta contribuye al cumplimiento
              de las normas de seguridad internacionales
            </div>
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          List of Restricted Substances (LSR)
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Afirm Group
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - List of Restricted Substances in Textiles, Apparel
                          and Footwear Products
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.afirm-group.com/afirm-rsl/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.afirm-group.com/afirm-rsl/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Manufacturing Restricted Substance List (MRSL)
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          The Roadmap To Zero Programme
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - List of substances restricted during the manufacture
                          of garments, textile products and footwear
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://mrsl.roadmaptozero.com/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://mrsl.roadmaptozero.com/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A63. Reduction of use of hazardous chemicals</h5>
            It is important to assess the characteristics and functions of the chemicals
            and dyes used. This will provide the information necessary to identify
            high hazard substances and seek the possibility of replacing them with
            more environmentally and worker-friendly products—for example, organic
            products or low-hazardous chemicals.
          {:else}
            <h5>A63. Reducción de uso de sustancias químicas peligrosas</h5>
            Es importante evaluar las características y funciones de las sustancias
            químicas y colorantes empleados, esto proporcionará la información necesaria
            para identificar las sustancias de alto índice de peligrosidad y buscar
            la posibilidad de reemplazarlas por productos más amigables con el medio
            ambiente y con los trabajadores de la empresa. Por ejemplo, productos
            orgánicos o sustancias químicas con bajo índice de peligrosidad.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          ZDHC Chemical Management System Framework
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          The Roadmap To Zero Programme (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Principles and guidelines for implementing the ZDHC
                          program
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://uploads-ssl.webflow.com/5c4065f2d6b53e08a1b03de7/5ec4fce8cc2b044b520491d5_ZDHC%20CMS%20Framework_MAY2020.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://uploads-ssl.webflow.com<br
                            />/5c4065f2d6b53e08a1b03de7<br
                            />/5ec4fce8cc2b044b520491d5<br
                            />_ZDHC%20CMS%20Framework<br />_MAY2020.pdf</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A64. Use of substances with low environmental impact</h5>
            Implementing substances with better environmental performance is recommended,
            such as low water consumption and lower hazard index. It is crucial to
            know the chemicals used and identify new products that can replace them.
          {:else}
            <h5>A64. Uso de sustancias de bajo impacto ambiental</h5>
            Se recomienda implementar aquellas sustancias que presenten un mejor
            desempeño ambiental, como bajo consumo de agua y menor índice de peligrosidad.
            Para esto es importante conocer detalladamente las sustancias químicas
            utilizadas e identificar nuevos productos que puedan remplazarlas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Safe Chemical Substances
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          The Roadmap To Zero Programme
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Database of safe chemicals
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.roadmaptozero.com/input#Gateway-Chemical-Module"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.roadmaptozero.<br
                            />com/input#Gateway-Chemical-Module</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Alternatives for hazardous chemicals
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Marketplace Chemsec
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Platform to identify alternatives to chemicals by
                          function
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://marketplace.chemsec.org/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://marketplace.chemsec.org/</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          List of low impact chemicals
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          bluesign®
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - List of bluesing approved chemical products
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.bluesign.com/en/business/finder"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.bluesign.com<br
                            />/en/business/finder</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A65. Storage of chemical substances</h5>
            The company must own a programme for the safe storage of chemicals complying
            with applicable regulations. This programme should include proper storage
            according to the characteristics of the products, use of correct containers,
            suitable substances identification, appropriate facilities, constant
            inventory updating, order and cleanliness.
          {:else}
            <h5>A65. Almacenamiento de sustancias químicas</h5>
            La empresa debe asegurarse de contar con un programa de almacenamiento
            seguro de sustancias químicas cumpliendo con la regulación aplicable.
            Este programa debe incluir almacenamiento adecuado según las características
            de los productos, uso de contenedores correctos, identificación adecuada
            de sustancias, instalaciones apropiadas, orden y limpieza constantes
            y actualización de inventario constante.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Good Laboratory Practice
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Good Laboratory Practices - Laboratory management
                          and chemical use - Quality management and compliance
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/chemicalsafety/testing/oecdseriesonprinciplesofgoodlaboratorypracticeglpandcompliancemonitoring.htm"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org/chemical<br
                            />safety/testing/oecdseries<br
                            />onprinciplesofgoodlaboratory<br
                            />practiceglpandcompliance<br />monitoring.htm</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A66. Chemical handling training</h5>
            Workers who come into contact with chemicals must be adequately trained
            in handling and storage. The company is responsible for training its
            workers on these substances' risks and potential effects. It is also
            responsible for providing the appropriate equipment and ensuring its
            correct use. Emergency equipment such as eyewashes and showers must also
            be installed.
          {:else}
            <h5>A66. Capacitación para el manejo de sustancias químicas</h5>
            Los trabajadores que estén en contacto con productos químicos deben contar
            con la capacitación adecuada para su manejo y almacenamiento. La empresa
            es responsable de capacitar a sus trabajadores acerca de los riesgos
            y efectos potenciales derivados del eso de estas sustancias. Así como
            también proporcionar el equipo adecuado y asegurarse de su uso correcto.
            Así mismo es necesario instalar equipo en caso de emergencias como lavaojos
            y regaderas.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Joint Meeting of the Chemicals Committee and the
                          Working Party on Chemicals, Pesticides and
                          Biotechnology
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          OECD (2017)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Chemical and dye roles in the textile industry -
                          Chemical exposure - Methods for determining the
                          effluent concentration and occupational exposure
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.oecd.org/officialdocuments/publicdisplaydocumentpdf/?cote=ENV/JM/MONO(2015)50&doclanguage=en"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.oecd.org<br
                            />/officialdocuments/publicdisplay<br
                            />documentpdf/?cote=ENV/JM/MONO<br
                            />(2015)50&doclanguage=en</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A67. Chemical substances in the finished product</h5>
            Depending on the handling of chemicals during the manufacturing process,
            testing them in the finished product is advisable. This will certify
            the product quality and protect customer safety and the company's reputation.
          {:else}
            <h5>A67. Sustancias químicas en producto terminado</h5>
            Según el manejo de sustancias químicas durante el proceso de manufactura,
            es recomendable realizar pruebas para detectar la presencia de estos
            químicos en el producto terminado. Esto certificará la calidad del producto
            y protegerá la seguridad del cliente y el prestigio de la empresa.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Exposure of consumers to chemicals in clothing,
                          textiles and footware
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          European Chemical Agency (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Chemical exposure limits for consumers of textile
                          and other apparel products
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://news.wko.at/news/oesterreich/REACH-A-XVII_ECHA_Exposure-of-consumers-to-chemicals-in-text.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://news.wko.at/news<br
                            />/oesterreich/REACH-A-XVII_ECHA_<br
                            />Exposure-of-consumers-to-<br
                            />chemicals-in-text.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          List of Restricted Substances (LSR)
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Afirm Group
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - List of Restricted Substances in Textiles, Apparel
                          and Footwear Products
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Various
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.afirm-group.com/afirm-rsl/"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.afirm-group.com/afirm-rsl/</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="environmental-research">
              A7. Research for environmental improvement
            </h4>
            It is convenient to be aware and develop new products helping make efficient
            resources used. An example of this could be products with lower water
            or energy consumption, fibres from renewable resources, and high-quality
            materials that prolong the life span of textiles. It is essential to
            periodically evaluate the company's processes and technologies in search
            of more efficient and environmentally friendly alternatives. It is also
            advisable to consider these criteria when planning new investments in
            equipment and technology. It is also advisable that the company supports
            scientific research on environmental issues related to processes and
            products of interest.
          {:else}
            <h4 id="environmental-research">
              A7. Investigación para la mejora ambiental
            </h4>
            Para un buen desempeño ambiental de la empresa es conveniente estar al
            tanto o desarrollar nuevos productos que ayude a eficientar el uso de
            los recursos, por ejemplo, productos de menor consumo de agua o energía,
            fibras provenientes de recursos renovables, con bajo uso de agua y sustancias
            químicas y materiales de alta calidad que prolonguen la vida útil de
            los textiles. Es importante evaluar de forma periódica los procesos y
            tecnologías con los que cuenta la empresa, en busca de alternativas mas
            eficiente y ambientalmente amigables. Así mismo, es conveniente considerar
            estos criterios al momento de planear nuevas inversiones en equipo y
            tecnología. De igual manera es conveniente a medida de sus posibilidades,
            que la empresa apoye la investigación científica en materia ambiental
            relativa a procesos y productos de interés.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A71. New materials</h5>
            It is recommended that the company research and implement new materials
            that increase the product's useful life and decrease its environmental
            impact, whether at the materials procurement, product manufacturing,
            use or final disposal stage.
          {:else}
            <h5>A71. Nuevos materiales</h5>
            Se recomienda que la empresa investigue e implemente nuevos materiales
            que incrementen la vida útil del producto y disminuyan su impacto ambiental,
            ya sea en la etapa de obtención de materiales, manufactura de producto,
            uso o disposición final.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Wet Processing Guidebook
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Solidaridad network, The Netherlands
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Use of chemicals and dyes with low environmental
                          impact. - Use of low environmental impact fibers
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.imvoconvenanten.nl/-/media/imvo/files/kleding/nieuws/2020/solidaridad-guidebook-wet-processing.pdf"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.imvoconvenanten<br
                            />.nl/-/media/imvo/files/kleding<br
                            />/nieuws/2020/solidaridad<br
                            />-guidebook-wet-processing.pdf</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Environmental impact assessment of man-made cellulose
                          fibre
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Shen, L., et al. (2010)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - To assess the environmental impact of man-made
                          cellulose fibres - Life cycle assessment (LCA) was
                          conducted for three types of fibres (i.e. Viscose,
                          Modal and Tencel)
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1016/j.resconrec.2010.10.001"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1016/j.resconrec<br
                            />.2010.10.001</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Life cycle assessment of cotton textile products in
                          Turkey
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Baydar, G., et al. (2015).
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Environmental impacts of Eco T-shirts produced from
                          organically grown cotton and processed with green
                          dyeing recipe were compared to that of conventional
                          T-shirts
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1016/j.resconrec.2015.08.007"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1016/j.<br
                            />resconrec.2015.08.007</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Improved design for textile production process based
                          on life cycle assessment
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Zhang, Y., et al. (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - A system-oriented improved design framework based on
                          life cycle assessment (LCA) for an existing
                          polyester–cotton production system to reduce the
                          environmental impacts of the production process
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1007/s10098-018-1572-9"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1007/s10098-018-1572-9</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>A72. Environmental impact of products</h5>
            The company needsto know, quantify and analyse the environmental impact
            associated with its products. This will allow it to identify strengths
            and weaknesses to improve the quality of its products and production
            processes, make production costs more efficient, and obtain the necessary
            information for better decision- making. For this purpose, the Life Cycle
            Assessment (LCA) methodology standardised by ISO 14040 is a tool to measure
            the impact associated with the production of goods and services. This
            methodology analyses the effects of inputs and outputs at each stage
            of the process, from the extraction of raw materials, manufacture of
            the product, marketing, use and final disposal.
          {:else}
            <h5>A72. Impacto ambiental de productos</h5>
            Es importante que la empresa conozca, cuantifique y analice el impacto
            ambiental asociado a sus productos. Esto le permitirá identificar fortalezas
            y debilidades con el objetivo de mejorar la calidad de sus productos,
            de los procesos productivos, eficientar costos de producción y obtener
            la información necesaria para una mejor toma de decisiones. Para tal
            efecto la metodología de Análisis de Ciclo de Vida (ACV) normado por
            la ISO 14040 es una herramienta que permite medir el impacto asociado
            a la producción de bienes y servicios. Esta metodología analiza el impacto
            de entradas y salidas en cada etapa del proceso, desde la extracción
            de materias primas, manufactura del producto, comercialización, uso y
            disposición final.
          {/if}
          <div class="flex-column">
            <div class="overflow-x-auto">
              <div class="py-2 inline-block min-w-full">
                <div class="">
                  <table class="min-w-full border text-left">
                    <thead class="border">
                      <tr>
                        <th
                          scope="col"
                          colspan="7"
                          class="text font-medium text-gray-900 px-6 py-4 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Additional Material
                          {:else}
                            Material Adicional
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Document type
                          {:else}
                            Tipo de documento
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Subject
                          {:else}
                            Subject
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Source
                          {:else}
                            Source
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Description
                          {:else}
                            Descripción
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Lenguage
                          {:else}
                            Lenguaje
                          {/if}
                        </td>
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          {#if $t['lang'] === 'en'}
                            Link
                          {:else}
                            Link
                          {/if}
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Life cycle assessment method for environmental impact
                          evaluation and certification systems for textiles and
                          clothing
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Eryuruk, S. H. (2012)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Life cycle assessment and environmental impacts of
                          textiles and clothing - Environmentally sustainable
                          design - Relationship between LCA, standards and
                          eco-labelling certification
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://www.sciencedirect.com/science/article/pii/B9780081001691000071"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://www.sciencedirect<br
                            />.com/science/article/pii/<br
                            />B9780081001691000071</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          A life cycle thinking approach to analyse
                          sustainability in the textile industry: A literature
                          review
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Luján-Ornelas, C., et al. (2020)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Progress done in the transition towards sustainable
                          production and consumption patterns - Literature
                          review of the life cycle stages of textile products
                          was carried out to identify initiatives and actions
                          improving the performance
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.3390/su122310193"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.3390/<br />su122310193</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Enhancing environmental management in the textile
                          sector: An Organisational-Life Cycle Assessment
                          approach
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Resta, B., ete al. (2016)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Provides a management system able to support
                          companies in monitoring and evaluating environmental
                          performances with a dynamic perspective
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1016/j.jclepro.2016.06.135"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1016/<br
                            />j.jclepro.2016.06.135</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          A life cycle assessment (LCA)-based approach to
                          guiding an industry sector towards sustainability: the
                          case of the Swedish apparel sector
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Roos, S. et al. (2016)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - What is the current sustainability performance of
                          the sector? - What is an acceptable sustainability
                          performance for the sector? - Are proposed
                          interventions enough to reach an acceptable
                          sustainability performance?
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.1016/j.jclepro.2016.05.146"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.1016/<br
                            />j.jclepro.2016.05.146</a
                          >
                        </td>
                      </tr>
                      <tr class="bg-white border">
                        <td
                          class="px-6 py-4 text font-medium text-gray-900 border-r border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            class="w-6 h-6"
                            ><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path
                              d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
                            /></svg
                          >
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Sustainability Performance of an Italian Textile
                          Product
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          Lenzo, P., et al. (2018)
                        </td>
                        <td class="text text-gray-900 font-light px-6 py-4">
                          - Application of Life Cycle Assessment (LCA) and
                          Social LCA (S-LCA) to a textile product
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          EN
                        </td>
                        <td
                          class="text text-gray-900 font-light px-6 py-4 border-r border-l"
                        >
                          <a
                            href="https://doi.org/10.3390/economies6010017"
                            target="_blank"
                            class="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out"
                            >https://doi.org/10.3390<br />/economies6010017</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h4 id="life-cycle">Life Cycle Assessment</h4>
            According to ISO 14040, Life Cycle Assessment (LCA) is defined as a methodology
            for determining the environmental effects associated with a product or
            service. By compiling the inventory of inputs and outputs of the system,
            its potential environmental impact is assessed at each stage of the product's
            life. LCA is a systematic and detailed study facilitating the identification
            of improvement areas in the production process. LCA studies the potential
            impact of producing goods and services throughout the product's life;
            it includes the extraction and production of raw materials, transport,
            product manufacturing, distribution, use stage, and final disposal. To
            assess the environmental impact of the company's production process,
            SATIN quantifies the inputs and outputs of raw material manufacturing
            and activities carried out within the company. This assessment includes:
            i) the fibres production, yarns and fabrics, including the inputs and
            outputs of each of the processes, ii) the transport of raw materials
            to the company, iii) the chemicals, electricity, fuels, water and packaging
            used in the company's production process, iv) the generation of solid
            waste, wastewater and emissions, and v) the impact of product transport.
            The quantification of the resulting environmental impact is classified
            into five impact categories: Climate Change, fossil resource depletion,
            human toxicity, water ecotoxicity and water depletion. To quantify the
            environmental impact, SATIN use ReCiPe Midpoint.
          {:else}
            <h4 id="life-cycle">Análisis de Ciclo de Vida</h4>
            De acuerdo a la Norma ISO 14040, Análisis de Ciclo de Vida (ACV) se define
            como una metodología para determinar los efectos ambientales asociados
            a un producto o servicio. Mediante la compilación del inventario de entradas
            y salidas del sistema se evalúa su potencial impacto ambiental en función
            a cada etapa de vida del producto. ACV es un estudio sistemático y detallado
            que facilita la identificación de áreas de mejora en el proceso de producción.
            ACV estudia el impacto potencial de la producción de bienes y servicios
            a lo largo de toda la vida del producto; incluye desde la extracción
            y producción de materias primas, transporte, manufactura de producto,
            distribución, etapa de uso, hasta su disposición final como residuo.
            Para la evaluación del impacto ambiental asociado al proceso productivo
            de la empresa, SATIN cuantifica las entradas y salidas de la manufactura
            de materias primas y de las actividades realizadas dentro de la empresa.
            Esta evaluación incluye: i) la producción de fibras, hilos y tejidos
            incluyendo las entradas y salidas de cada uno de los procesos ii) el
            transporte de materias primas a la empresa, iii) las sustancias químicas,
            electricidad, combustibles, agua y empaques empleados en el proceso de
            producción de la empresa, iv) generación de residuos sólidos, aguas residuales
            y emisiones e v) impacto por transporte de productos. La cuantificación
            del impacto ambiental resultante se clasifica en cinco categorías de
            impacto: Cambio climático, agotamiento de recursos fósiles, toxicidad
            humana, ecotoxicidad de agua y agotamiento de agua. Para la cuantificación
            del impacto ambiental, SATIN utiliza el método ReCiPe Midpoint (H) V1.13
            no LT.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Environmental impact categories</h5>
            The environmental impact categories reflect major issues that correspond
            to international concerns and the main problems faced by the global textile
            industry. SATIN offers an analysis of the company's activities according
            to the following impact categories.
          {:else}
            <h5>Categorías de Impacto ambiental</h5>
            Las categorías de impacto ambiental reflejan cuestiones medioambientales
            de gran importancia y corresponden a problemas de interés internacional,
            así como a las principales problemáticas presentadas por la industria
            textil a nivel global. SATIN ofrece el análisis del impacto ambiental
            derivado de las actividades de la empresa segun las siguientes categorías
            de impacto.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Climate Change</h5>
            The Climate Change (CC) category quantifies kg of CO2 eq generated in
            products' manufacturing and transport processes. This category is defined
            as the potential increase in the Earth's surface temperature resulting
            from a rise in the concentration of Greenhouse Gases (GHG), such as carbon
            dioxide (CO2), methane (CH4), nitrous oxide (N2O), ozone (O3) and CFCs.
            The impact quantification is derived from the ability of GHGs to absorb
            heat and their permanence in the atmosphere. These properties are compared
            with the properties of carbon dioxide and converted into CO2 equivalents
            (CO2 eq), which allows the quantification of different GHGs. Some of
            the activities that contribute significantly to the Climate Change category
            are electricity consumption, transport of products, production of polymeric
            fibres, burning of fuels and any process that generates GHG emissions.
          {:else}
            <h5>Cambio climático</h5>
            En la categoria de Cambio Climático (CC) se cuantifican los kg de CO2
            eq generados en los procesos de manufactura y transporte de los productos.
            Esta categoría se define como el potencial incremento en la temperatura
            de la superficie de la Tierra que se da por el aumento de la concentración
            de Gases de Efecto Invernadero (GEI), como el dióxido de carbono (CO2),
            el metano (CH4), el óxido nitroso (N2O), el ozono (O3) y CFCs. La cuantificación
            del impacto se deriva de la habilidad de los GEI para absorber el calor
            y de su permanencia en la atmósfera. Estas propiedades se comparan con
            las propiedades del dióxido de carbono y son convertidas en CO2 equivalentes
            (CO2 eq), lo cual permite sumar los equivalentes de diferentes GEI para
            obtener un indicador. Algunas de las actividades que contribuyen de manera
            significativa a la categoría de Cambio Climático son: consumo de energía
            eléctrica, transporte de productos, producción de fibras poliméricas,
            quema de combustibles y cualquier proceso que genere emisiones de GEI.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Fossil resource depletion</h5>
            The term fossil fuel refers to a group of resources containing hydrocarbons.
            This group includes volatile materials such as methane, liquid gasoline
            and non- volatile materials. Over time and with resource extraction,
            conventional sources have been depleted, driving the emergence of unconventional
            sources. The assessment of this category is therefore based on the projected
            change in resource extraction from traditional to unconventional sources,
            based on the kg of fuels used in the system. Extraction from unconventional
            sources is generally energy-intensive and cost-intensive, which means
            that the use of unconventional sources is feasible, provided that the
            price of fuels is high enough to cover production costs. Some of the
            activities contributing to this category are polyester and polymer production,
            electricity and fuel use, and product transportation.
          {:else}
            <h5>Agotamiento de recursos fósiles</h5>
            El término combustible fósil se refiere a un grupo de recursos que contienen
            hidrocarburos. Este grupo incluye los materiales volátiles como el metano,
            la gasolina líquida y los materiales no volátiles. Con el paso del tiempo
            y la extracción de recursos, las fuentes convencionales se han ido agotando,
            lo cual impulsa el surgimiento de fuentes no convencionales. Es por esto
            que la evaluación de esta categoría se basa en el cambio proyectado de
            la extracción de recursos de fuentes convencionales a fuentes no convencionales,
            en función de los kg de combustibles utilizados en el sistema. La extracción
            de fuentes no convencionales generalmente requiere energía intensiva
            y mayores costos, lo cual significa que el uso de fuentes no convencionales
            es viable, siempre y cuando el precio de los combustibles sea lo suficientemente
            alto como para cubrir los costos de producción.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Human toxicity</h5>
            Toxicity is the inherent ability of some chemicals to cause systemic
            damage to living organisms or ecosystems, affecting human health, the
            natural environment and natural resources. The potential harm caused
            depends on concentration, receptor conditions, and type and time of exposure.
            The human toxicity impact category assesses the effects of toxic substances
            on human health. Identifying impacts involves using empirical toxicological
            data to develop toxicity indicators for about 200 substances and predict
            their environmental effect. The model combines regional, continental
            and global scales with geographical temperatures and physicochemical
            properties based on a substance to describe its soil, water or air dispersion.
            This information makes it possible to estimate an impact index related
            to the reference substance 1,4-dichlorobenzene/kg emission (INE, 2009).
            Some of the activities contributing to this category are pesticides and
            fertilisers in cotton cultivation, pre-treatment chemicals, dyeing and
            textile finishing, and waste generation.
          {:else}
            <h5>Toxicidad humana</h5>
            La toxicidad es la habilidad inherente de algunos químicos de causar
            daños sistémicos a los organismos vivos o a los ecosistemas, afecta a
            las áreas de protección de la salud humana, entorno natural y recursos
            naturales. El posible daño ocasionado depende de factores como la concentración,
            condiciones del receptor, así como el tipo y tiempo de exposición. La
            categoría de impacto en toxicidad humana evalúa los efectos de las sustancias
            tóxicas presentes en el ambiente sobre los seres humanos. La identificación
            de los impactos implica utilizar datos empíricos toxicológicos estándar
            para elaborar indicadores de toxicidad para cerca de 200 sustancias y
            prever su respuesta ambiental. El modelo combina la escala regional,
            continental y global con las temperaturas geográficas y las propiedades
            físico químicas de una sustancia para describir su dispersión en el suelo,
            agua o aire y a partir de esto estimar un índice de impacto, que se relaciona
            al de la sustancia de referencia 1,4- diclorobenceno/kg de emisión (INE,
            2009). Algunas de las actividades que contribuyen en esta categoría son
            el uso de pesticidas y fertilizantes en el cultivo de algodón, uso de
            sustancias químicas en pretratamiento, teñido y acabados textiles, así
            como la generación de residuos.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Water depletion</h5>
            Water is a scarce resource in many parts of the world but abundant elsewhere.
            Unlike other natural resources, there is no global market to ensure worldwide
            distribution. It is important to take this impact category into account,
            as water abstraction in a region where water is scarce can cause significant
            damage to ecosystems and human health. For this reason, the water use
            impact category is structured to capture meaningful water use in areas
            of low water availability in terms of cubic metres of water used. Some
            of the activities contributing to this category are cotton cultivation,
            fibre production and textile dyeing.
          {:else}
            <h5>Agotamiento de agua</h5>
            El agua es un recurso escaso en muchas partes del mundo, pero abundante
            en otros lugares. A diferencia de otros recursos naturales no existe
            un mercado global que asegure su distribución mundial. Es importante
            tomar en cuenta esta categoría de impacto ya que la extracción de agua
            en una región donde existe escasez de este recurso puede causar un daño
            importante a los ecosistemas y a la salud humana. Por esta razón, la
            categoría de impacto de uso de agua, está estructurada para capturar
            el uso significativo del agua en las zonas de baja disponibilidad en
            función a los metros cúbicos de agua utilizada. Algunas de las actividades
            que contribuyen en esta categoría son el cultivo de algodón, la producción
            de fibras y los procesos de teñido textil.
          {/if}
        </div>
        <div class="mt-5">
          {#if $t['lang'] === 'en'}
            <h5>Water ecotoxicity</h5>
            This category evaluates the effect of toxicity on aquatic ecosystems.
            Some activities that contribute to this category are using fertilisers
            and pesticides used in cotton cultivation and using chemical substances
            in pre-treatment, dyeing and textile finishing. In this sense, it should
            be noted that the contaminant does not remain in the medium it has been
            deposited but rather moves and reaches other systems. As in the human
            toxicity category, the model analyses the properties of various chemical
            substances and estimates the potential impact on aquatic ecosystems,
            using 1,4-dichloro-benzene/kg of emission as a reference substance.
          {:else}
            <h5>Ecotoxicidad de agua</h5>
            El agua es un recurso escaso en muchas partes del mundo, pero abundante
            en otros lugares. A diferencia de otros recursos naturales no existe
            un mercado global que asegure su distribución mundial. Es importante
            tomar en cuenta esta categoría de impacto ya que la extracción de agua
            en una región donde existe escasez de este recurso puede causar un daño
            importante a los ecosistemas y a la salud humana. Por esta razón, la
            categoría de impacto de uso de agua, está estructurada para capturar
            el uso significativo del agua en las zonas de baja disponibilidad en
            función a los metros cúbicos de agua utilizada. Algunas de las actividades
            que contribuyen en esta categoría son el cultivo de algodón, la producción
            de fibras y los procesos de teñido textil.
          {/if}
        </div>
      </div>
    </section>
  </div>
  <ScrollButton />
</div>
