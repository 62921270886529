<script>
  import Sidebar from '../components/Sidebar.svelte';
  import Navbar from '../components/Navbar.svelte';
  import { t } from '../stores/messages';
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar />
  <div class="w-full">
    <section
      class="mx-auto w-8/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white shadow-xl"
    >
      <div class="container py-12">
        <div class="flex flex-wrap w-full mb-20 flex-col">
          <div class="flex flex-row items-center mb-8 mr-16">
            <h1 class="text-black border-b w-full flex mb-0 mt-0">
              {#if $t['lang'] === 'en'}
                About us
              {:else}
                Nosotros
              {/if}
            </h1>
          </div>
          <div class="mb-8">
            <h3 class="text-black">
              {#if $t['lang'] === 'en'}
                Hi, Welcome!
              {:else}
                ¡Hola, Bienvenido!
              {/if}
            </h3>
            <div>
              <img
                class="profile-photo"
                src={'/images/profile-photo.png'}
                alt="pdca"
              />
            </div>
            <div class="mb-8 text-black">
              {#if $t['lang'] === 'en'}
                My name is Cristina Luján Ornelas, and I am the author of SATIN.
                One of my passions is production processes and strategies to
                support the transition towards sustainability. That is why as
                part of my PhD project, I developed a methodology to help
                companies integrate sustainable production patterns into their
                activities. Therefore, SATIN is a non-profit academic project
                that connects industry and academic research.
              {:else}
                Mi nombre es Cristina Luján Ornelas y soy la creadora de SATIN.
                Una de mis pasiones son los procesos productivos y la generación
                de estrategias que apoyen la transición hacia la
                sustentabilidad. Es por esto que como parte de mi proyecto de
                doctorado desarrollé una metodología para ayudar a las empresas
                a integrar patrones de producción sustentable dentro de sus
                actividades. Por lo tanto, SATIN es un proyecto académico sin
                fines de lucro que busca generar una conexión entre la industria
                y la investigación académica.
              {/if}
            </div>
            <div class="mb-8 text-black">
              {#if $t['lang'] === 'en'}
                I am a Chemical Engineer from ITCH with a master’s in
                Environmental Engineering from UNAM, Mexico. Recently I
                completed a PhD in Sustainable Consumption and Production at the
                Governance and Technology for Sustainability (CSTM) research
                group, belonging to the Behavioural Management and Social
                Sciences (BMS) faculty at the University of Twente (UT) in The
                Netherlands. I am an expert in industrial sustainability and
                environmental management of organizations and products.
              {:else}
                Soy Ingeniero químico por el Instituto Tecnológico de Chihuahua
                con una maestría en Ingeniería Ambiental de la Universidad
                Nacional Autónoma de México (UNAM). Realicé mis estudios de
                Doctorado en Consumo y Producción Sostenible en el grupo de
                investigación Governance and Techonology for Sustainability
                (CSTM), perteneciente a la facultad Behavioural Management and
                Social Sciences (BMS) en la Universidad de Twente (UT) en Países
                Bajos. Soy especialista en sustentabilidad industrial y en
                gestión ambiental de organizaciones y productos.
              {/if}
            </div>
            <div class="mb-8 text-black">
              {#if $t['lang'] === 'en'}
                I hope SATIN can contribute to improving the sustainable
                performance of your company.
              {:else}
                Espero que SATIN pueda contribuir a mejorar el desempeño
                sostenible de tu empresa.
              {/if}
            </div>
            <div class="mb-12">
              <a
                target="_blank"
                href="https://people.utwente.nl/c.c.lujanornelas"
                >UT Profile |</a
              >
              <a
                target="_blank"
                href="https://www.researchgate.net/profile/Cristina-Lujan-Ornelas"
                >ResearhGate |</a
              >
              <a
                target="_blank"
                href="https://www.linkedin.com/in/cristina-lujan-1744789a/"
                >Linkedin</a
              >
            </div>
            <div class="mt-24">
              <img
                class="university-photo"
                src={'/images/university.png'}
                alt="pdca"
              />
            </div>
            <div class="mb-8 mt-24 text-black">
              {#if $t['lang'] === 'en'}
                At the University of Twente (UT), we are pioneers in merging
                technology, science, and engineering with the social sciences to
                impact the world. Our goal is to contribute to developing a
                digital, fair, and sustainable society by 2030. In this pursuit,
                the entrepreneurial mindset and global awareness of our many
                talented scientists, educators, and students lead us to move
                beyond differences, disciplines, and borders. Moreover, our
                multidisciplinary and multicultural working style opens up a
                wide range of possibilities, generating new ideas, new energy,
                and new ways forward.
              {:else}
                En la Universidad de Twente (UT) somos pioneros en fusionar
                tecnología, ciencia e ingeniería con las ciencias sociales para
                generar grandes cambios. Nuestra meta es contribuir al
                desarrollo de una sociedad digital, justa y sostenible de aquí
                al 2030. Es así que, a través de una visión emprendedora y la
                participación de talentosos científicos, profesores y
                estudiantes, la UT va más allá de las diferencias entre
                disciplinas, culturas y fronteras. Nuestro estilo de trabajo
                multidisciplinar y multicultural abre una amplia gama de
                posibilidades, generando nuevas ideas, nueva energía y nuevas
                formas de avanzar.
              {/if}
            </div>
            <div class="mb-8 text-black">
              {#if $t['lang'] === 'en'}
                Based in the Netherlands, UT believes collaboration is
                fundamental as it cooperates closely with universities,
                companies, and governmental organizations worldwide, creating
                synergies to respond to societal needs. Our passion is to
                understand our planet and improve everyone's life, strengthening
                the links between humanity and technology, knowing that neither
                could exist, let alone flourish, without the other. For the
                fourth time, UT was awarded the title of Most Entrepreneurial
                University in the Netherlands in 2020 and is positioned as the
                Best Technical University in the country. It is also ranked
                among the top 250 universities worldwide by the Times Higher
                Education (THE).
              {:else}
                En la UT la colaboración es fundamental, estamos ubicados en
                Países Bajos, sin embargo, contamos con un campo de acción
                internacional. La Universidad de Twente coopera muy de cerca con
                universidades, empresas y organizaciones gubernamentales de todo
                el mundo, creando sinergias para responder a las necesidades
                sociales. Es nuestra pasión comprender nuestro planeta y mejorar
                la vida de todos en él, fortaleciendo los vínculos entre la
                humanidad y la tecnología, sabiendo que ninguna de las dos
                podría existir y mucho menos florecer sin la otra.
              {/if}
            </div>
            <div class="text-black">
              {#if $t['lang'] === 'en'}
                The University of Twente is committed to enhancing its
                relationship with the business community through a special
                Strategic Business Development unit. We collaborate with the
                corporate sector to develop broader programmes in which
                business, technology, education, and research are present. The
                UT's fundamental task is to create value for companies and
                generate strategies for more sustainable production. We develop
                technical knowledge and expertise and work on the combination of
                new business models, design, and practical applications. We are
                creating systematic solutions with an engineering approach to
                respond to social challenges.
              {:else}
                En el 2020 la UT recibió por cuarta vez el título de la
                Universidad más emprendedora de Países Bajos y está posicionada
                como la mejor universidad técnica del país. Así mismo, se
                encuentra entre las 250 mejores universidades del mundo según el
                Times Higher Education (THE). La Universidad de Twente se
                compromete a mejorar su relación con la comunidad empresarial
                mediante una unidad de Desarrollo Comercial Estratégico.
                Colaboramos con el sector empresarial en el desarrollo de
                programas, en los que se encuentran presentes tanto el negocio
                como la tecnología y tanto la educación como la investigación.
                La UT se plantea como tarea fundamental la creación de valor
                para las empresas y la generación de estrategias hacia una
                producción más sostenible. No solo desarrollamos conocimientos
                técnicos y experiencia, sino que también trabajamos en el
                desarrollo de nuevos modelos de negocio, diseño y aplicaciones
                prácticas. Creando soluciones sistemáticas con un enfoque de
                ingeniería para dar respuesta a los desafíos sociales.
              {/if}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<style>
  .profile-photo {
    height: 377px;
    float: left;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .university-photo {
    height: 300px;
    float: right;
    padding-left: 15px;
    padding-bottom: 15px;
  }
</style>
