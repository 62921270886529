<script>
  import { answers } from '../../stores/answers';
  import { saveAnswer } from '../../services/utils';
  export let question;

  let date = new Date();
  answers.subscribe((a) => {
    date = a[question.code]?.user_value;
  });

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
</script>

<div class="flex flex-row items-center">
  <div class="mb-5 w-64 mt-5 ml-5">
    <input
      on:input={async (e) => {
        date = new Date(e.target.value);
        saveAnswer(question, date);
      }}
      type="date"
      id="start"
      pattern="\d{4}-\d{2}-\d{2}"
      value={formatDate(date)}
    />
  </div>
</div>
