<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { auth } from '../stores/auth';
  import { t } from '../stores/messages';
  import { dimensions, checkValidStoredObject } from '../stores/dimensions';
  import { answers, companyProcesses } from '../stores/answers';
  import ScrollButton from '../components/ScrollButton.svelte';
  import Tabs from '../components/Tabs.svelte';
  import Navbar from '../components/Navbar.svelte';
  import Sidebar from '../components/Sidebar.svelte';
  import { getAnswers, getDimensions, deleteAnswers } from '../services/satin';
  import Results from '../components/Icons/Results.svelte';

  export let currentRoute;

  let company = '';
  let reportPeriod = '';
  let fillingDate = '';

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  auth.subscribe((a) => {
    company = a.me?.company?.name;
  });

  let activeTab = 0;
  const getActiveTabFromURL = () => {
    let currentTab = currentRoute.hash;
    switch (currentTab) {
      case '#charts':
        activeTab = 0;
        break;
      case '#tips':
        activeTab = 1;
        break;
      default:
        activeTab = 0;
        break;
    }
  };

  getActiveTabFromURL();

  $: {
    getActiveTabFromURL();
  }

  const processesQuestionId = 'D421A';

  onMount(async () => {
    if (checkValidStoredObject(get(dimensions))) {
      const d = await getDimensions();
      d.createdAt = new Date().getTime();
      dimensions.set(d);
    }
    const ans = (await getAnswers()) || [];
    const rA = Object.fromEntries(
      ans.map((a) => {
        if (a.question_id === processesQuestionId) {
          companyProcesses.set(a.user_value.split(','));
        }
        return [a.question_id, a];
      })
    );
    rA.createdAt = new Date().getTime();
    answers.set(rA);
    const a = get(answers);
    const reportPeriodsObj = JSON.parse(a['D311A']?.user_value || []);
    const reportStarts = formatDate(reportPeriodsObj[0]);
    const reportEnds = formatDate(reportPeriodsObj[1]);

    reportPeriod = reportStarts + ' - ' + reportEnds;
    fillingDate = formatDate(a['D211A']?.user_value);
  });

  async function myAlert() {
    let message;
    if (get(t)['lang'] === 'es') {
      message = '¿Está seguro de querer eliminar todas las respuestas?';
    } else {
      message = 'Are you sure you want to delete all the answers?';
    }
    let accepted = confirm(message);
    if (accepted) {
      const response = await deleteAnswers();
      if (response.error === undefined) {
        answers.set({});
      }
    }
  }
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar dimension="results" />
  <div class="w-full">
    <div class="mx-auto w-11/12 p-4 flex items-center">
      <div class="mr-2.5">
        <Results />
      </div>
      <h1>{$t['results']}</h1>
      <div class="mx-auto w-11/12 p-4 grid justify-items-end">
        <div
          class="flex items-center rounded-md bg-white shadow-md text-black noprint"
        />
      </div>
    </div>

    <div class="flex ml-10">
      <div class="flex-column mr-16 ml-16">
        <h5>
          {#if $t['lang'] === 'en'}
            Company: {company}
          {:else}
            Empresa: {company}
          {/if}
        </h5>
      </div>
      <div class="flex-column mr-16 ml-16">
        <h5>
          {#if $t['lang'] === 'en'}
            Reporting Period: {reportPeriod}
          {:else}
            Periodo de Reporte: {reportPeriod}
          {/if}
        </h5>
      </div>
      <div class="flex-column mr-16 ml-16">
        <h5>
          {#if $t['lang'] === 'en'}
            Filling Date: {fillingDate}
          {:else}
            Fecha de Llenado: {fillingDate}
          {/if}
        </h5>
      </div>
    </div>
    <section
      class="mx-auto w-11/12 p-4 text-gray-600 body-font bg-white shadow-xl rounded-lg fullwidth"
    >
      <div class="flex-column mr-16 mt-5 ml-10">
        <Tabs {activeTab} />
      </div>
      <hr class="mx-auto w-11/12 mt-24" />
      <div
        class="mx-auto w-11/12 flex flex-row justify-center bg-white mb-5 noprint"
      >
        <p class="mr-5">
          {#if $t['lang'] === 'en'}
            If you want to delete all the answers press the button
          {:else}
            Si desea eliminar todas las respuestas presione el botón
          {/if}
        </p>
        <button
          on:click={() => {
            myAlert();
          }}
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
        >
          Reset
        </button>
      </div>
    </section>
  </div>
  <ScrollButton class="noprint" />
</div>

<style>
  .result-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #111827;
  }
</style>
