<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2605_2486)">
    <path
      d="M13.3332 7.5V15.8333H6.6665V7.5H13.3332ZM12.0832 2.5H7.9165L7.08317 3.33333H4.1665V5H15.8332V3.33333H12.9165L12.0832 2.5ZM14.9998 5.83333H4.99984V15.8333C4.99984 16.75 5.74984 17.5 6.6665 17.5H13.3332C14.2498 17.5 14.9998 16.75 14.9998 15.8333V5.83333Z"
      fill="#9CA3AF"
    />
  </g>
  <defs>
    <clipPath id="clip0_2605_2486">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>
