<script>
  import { t } from '../stores/messages';
  import { selectedDepartment, companyProcesses } from '../stores/answers';

  // Answers
  import D2 from '../components/answers/D2.svelte';
  import D3 from '../components/answers/D3.svelte';
  import D4 from '../components/answers/D4.svelte';
  import R2 from '../components/answers/R2.svelte';
  import R3 from '../components/answers/R3.svelte';
  import R4 from '../components/answers/R4.svelte';
  import R6 from '../components/answers/R6.svelte';
  import R7 from '../components/answers/R7.svelte';
  import R71 from '../components/answers/R71.svelte';
  import R8 from '../components/answers/R8.svelte';
  import R81 from '../components/answers/R81.svelte';
  import R9 from '../components/answers/R9.svelte';
  import R91 from '../components/answers/R91.svelte';
  import R10 from '../components/answers/R10.svelte';
  import R11 from '../components/answers/R11.svelte';
  import R12 from '../components/answers/R12.svelte';
  import R13 from '../components/answers/R13.svelte';
  import R14 from '../components/answers/R14.svelte';
  import R15 from '../components/answers/R15.svelte';
  import R16 from '../components/answers/R16.svelte';
  import R17 from '../components/answers/R17.svelte';
  import R18 from '../components/answers/R18.svelte';
  import R19 from '../components/answers/R19.svelte';
  import R5 from '../components/answers/R5.svelte';
  import R20 from '../components/answers/R20.svelte';
  import R21 from '../components/answers/R21.svelte';
  import R23 from '../components/answers/R23.svelte';
  import R24 from '../components/answers/R24.svelte';
  import R25 from '../components/answers/R25.svelte';
  import K1 from '../components/answers/K1.svelte';
  import K3 from '../components/answers/K3.svelte';
  import K4 from '../components/answers/K4.svelte';
  import K5 from '../components/answers/K5.svelte';
  import K6 from '../components/answers/K6.svelte';
  import V2 from '../components/answers/V2.svelte';
  import V21 from '../components/answers/V21.svelte';
  import V3 from '../components/answers/V3.svelte';
  import K2 from '../components/answers/K2.svelte';
  import V4 from '../components/answers/V4.svelte';
  import V41 from '../components/answers/V41.svelte';
  import V5 from '../components/answers/V5.svelte';
  import V6 from '../components/answers/V6.svelte';
  import V10 from '../components/answers/V10.svelte';
  import V11 from '../components/answers/V11.svelte';
  import V12 from '../components/answers/V12.svelte';
  import V13 from '../components/answers/V13.svelte';
  import V9 from '../components/answers/V9.svelte';
  import Default from '../components/answers/Default.svelte';

  const answerTypes = [
    { answer: 'D2', component: D2 },
    { answer: 'D3', component: D3 },
    { answer: 'D4', component: D4 },
    { answer: 'R2', component: R2 },
    { answer: 'R3', component: R3 },
    { answer: 'R4', component: R4 },
    { answer: 'R6', component: R6 },
    { answer: 'R7', component: R7 },
    { answer: 'R71', component: R71 },
    { answer: 'R8', component: R8 },
    { answer: 'R81', component: R81 },
    { answer: 'R9', component: R9 },
    { answer: 'R91', component: R91 },
    { answer: 'R10', component: R10 },
    { answer: 'R11', component: R11 },
    { answer: 'R12', component: R12 },
    { answer: 'R13', component: R13 },
    { answer: 'R14', component: R14 },
    { answer: 'R15', component: R15 },
    { answer: 'R16', component: R16 },
    { answer: 'R17', component: R17 },
    { answer: 'R18', component: R18 },
    { answer: 'R19', component: R19 },
    { answer: 'R5', component: R5 },
    { answer: 'R20', component: R20 },
    { answer: 'R21', component: R21 },
    { answer: 'R23', component: R23 },
    { answer: 'R24', component: R24 },
    { answer: 'R25', component: R25 },
    { answer: 'K1', component: K1 },
    { answer: 'K2', component: K2 },
    { answer: 'K3', component: K3 },
    { answer: 'K4', component: K4 },
    { answer: 'K5', component: K5 },
    { answer: 'K6', component: K6 },
    { answer: 'V2', component: V2 },
    { answer: 'V21', component: V21 },
    { answer: 'V3', component: V3 },
    { answer: 'V4', component: V4 },
    { answer: 'V41', component: V41 },
    { answer: 'V5', component: V5 },
    { answer: 'V6', component: V6 },
    { answer: 'V10', component: V10 },
    { answer: 'V11', component: V11 },
    { answer: 'V9', component: V9 },
  ];

  export let indicator;

  let questions = indicator.questions?.data || [];
  $: {
    questions = indicator.questions?.data || [];
  }
</script>

{#if ['A110'].includes(indicator?.code) && $companyProcesses.some((p) => 'M6,M7'.indexOf(p) >= 0)}
  <div class="indicator mt-12 pr-5 w-full">
    <strong><h5 class="text-center">{indicator[$t['lang']]}</h5></strong>
  </div>
{/if}

{#if ['A111'].includes(indicator?.code) && $companyProcesses.some((p) => 'M1'.indexOf(p) >= 0)}
  <div class="indicator mt-12 pr-5 w-full">
    <strong><h5 class="text-center">{indicator[$t['lang']]}</h5></strong>
  </div>
{/if}

{#if ['A112'].includes(indicator?.code) && $companyProcesses.some((p) => 'M2,M3,M4,M5'.indexOf(p) >= 0)}
  <div class="indicator mt-12 pr-5 w-full">
    <strong><h5 class="text-center">{indicator[$t['lang']]}</h5></strong>
  </div>
{/if}

{#if ['A113'].includes(indicator?.code) && $companyProcesses.some((p) => 'M3,M4'.indexOf(p) >= 0)}
  <div class="indicator mt-12 pr-5 w-full">
    <strong><h5 class="text-center">{indicator[$t['lang']]}</h5></strong>
  </div>
{/if}

{#if ['A114'].includes(indicator?.code)}
  <div class=" mt-12 pr-5 w-full">
    <strong><h5 class="text-center">{indicator[$t['lang']]}</h5></strong>
  </div>
{/if}

{#if ['A115', 'A116', 'A117', 'A118', 'A119'].includes(indicator?.code)}
  <div class=" mt-12 pr-5 w-full">
    <strong><h5 class="text-center">{indicator[$t['lang']]}</h5></strong>
  </div>
{/if}

{#if !['A110', 'A111', 'A112', 'A113'].includes(indicator?.code)}
  {#each questions as question}
    {#if (question.production.length === 0 || $companyProcesses.some((p) => question.production.indexOf(p) >= 0)) && ($selectedDepartment === '' || question.department.indexOf($selectedDepartment) >= 0)}
      <div class="flex-column border-t mr-16 mt-5">
        <div class="mt-5">
          {question[$t['lang']]}
        </div>
        <div>
          <svelte:component
            this={(
              answerTypes.find(
                (type) => type.answer === question['answerType']
              ) || { component: Default }
            ).component}
            {question}
          />
        </div>
      </div>
    {/if}
  {/each}
{/if}
{#if indicator?.code === 'A110' && $companyProcesses.some((p) => 'M6,M7'.indexOf(p) >= 0) && ($selectedDepartment === '' || ['PURCHASING', 'PRODUCTION'].indexOf($selectedDepartment) >= 0)}
  <V10 />
{/if}
{#if indicator?.code === 'A111' && $companyProcesses.some((p) => 'M1'.indexOf(p) >= 0) && ($selectedDepartment === '' || ['PURCHASING', 'PRODUCTION'].indexOf($selectedDepartment) >= 0)}
  <V11 />
{/if}
{#if indicator?.code === 'A112' && $companyProcesses.some((p) => 'M2,M3,M4,M5'.indexOf(p) >= 0) && ($selectedDepartment === '' || ['PURCHASING', 'PRODUCTION'].indexOf($selectedDepartment) >= 0)}
  <V12 />
{/if}
{#if indicator?.code === 'A113' && ($selectedDepartment === '' || ['PURCHASING', 'PRODUCTION'].indexOf($selectedDepartment) >= 0)}
  <V13 />
{/if}

<style>
  .indicator {
    border-bottom-width: 1px;
  }

  h5 {
    align-items: center;
  }
  p {
    align-items: center;
  }
</style>
