import Home from "./pages/Home.svelte";
import Logout from "./pages/Logout.svelte";
import Profile from "./pages/Profile.svelte";
import About from "./pages/About.svelte";
import Main from "./pages/Main.svelte";
import Results from "./pages/Results.svelte";
import TipsEnvironmental from "./pages/TipsEnvironmental.svelte";
import TipsSocial from "./pages/TipsSocial.svelte";
import TipsManagement from "./pages/TipsManagement.svelte";
import TipsEconomic from "./pages/TipsEconomic.svelte";
import Transition from "./pages/Transition.svelte";
import Terms from "./pages/Terms.svelte";
import Us from "./pages/Us.svelte";
import Contact from "./pages/Contact.svelte";
import Colaborators from "./pages/Colaborators.svelte";

const routes = [
  {
    name: "/",
    component: Home,
  },
  {
    name: "/logout",
    component: Logout,
  },
  {
    name: "/app/register",
    component: Profile
  },
  {
    name: "/app/profile",
    component: Profile,
  },
  {
    name: "/app/about",
    component: About,
  },
  {
    name: "/app/transition",
    component: Transition,
  },
  {
    name: "/app/main/:dimension/:categoryId?",
    component: Main,
  },
  {
    name: "/app/results",
    component: Results,
  },
  {
    name: "/app/tipsenvironmental",
    component: TipsEnvironmental,
  },
  {
    name: "/app/tipssocial",
    component: TipsSocial,
  },
  {
    name: "/app/tipseconomic",
    component: TipsEconomic,
  },
  {
    name: "/app/tipsmanagement",
    component: TipsManagement,
  },
  {
    name: "/app/terms",
    component: Terms,
  },
  {
    name: "/app/us",
    component: Us,
  },
  {
    name: "/app/contact",
    component: Contact,
  },
  {
    name: "/app/colaborators",
    component: Colaborators,
  },
];

export { routes };
