<script>
  import { t } from '../stores/messages';
  export let dimension;
</script>

<div
  class="banner w-full bg-white p-6 flex flex-row justify-evenly shadow-xl rounded-lg"
>
  <div class="flex justify-center items-center p-2">
    {#if dimension}
      <img
        src={`/images/${dimension?.toLowerCase()}.png`}
        alt={dimension?.toLowerCase()}
        style="min-width: 200px;"
      />
    {/if}
  </div>
  <div class="flex flex-col right-0 p-2">
    <h4 class="mt-4 font-bold">{$t[dimension?.toLowerCase()]}</h4>
    {#if dimension === 'General Data'}
      <p class="text-justify">{$t['dimension-banner-content']}</p>
    {:else if dimension === 'Environmental'}
      <p class="text-justify">{$t['dimension-banner-environmental']}</p>
    {:else if dimension === 'Social'}
      <p class="text-justify">{$t['dimension-banner-social']}</p>
    {:else if dimension === 'Economic'}
      <p class="text-justify">{$t['dimension-banner-economic']}</p>
    {:else if dimension === 'Management'}
      <p class="text-justify">{$t['dimension-banner-management']}</p>
    {/if}
  </div>
</div>

<style>
  .banner {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
</style>
