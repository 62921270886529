<script>
  import { t } from '../stores/messages';
  import { auth } from '../stores/auth';
  import { deleteInvitation, postRegistration } from '../services/satin';
  import Navbar from '../components/Navbar.svelte';
  import Sidebar from '../components/Sidebar.svelte';
  import CheckCircle from '../components/CheckCircle.svelte';
  import CollaboratorCard from '../components/ColaboratorCard.svelte';
  import { getInvitations, getUsers, postInvitation } from '../services/satin';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import Modal, { bind } from '../components/Modal.svelte';
  import Popup from '../components/Popup.svelte';
  const modal = writable(null);

  let pendingInvitations = [];
  let users = [];

  let updateInvitations = async () => {
    const invitations = await getInvitations();
    const allUsers = await getUsers();
    users = allUsers;
    pendingInvitations = invitations
      .filter((invitation) => invitation.accepter_id === null)
      .filter(
        (invitation) => !allUsers.map((u) => u.email).includes(invitation.email)
      );
  };

  onMount(async () => {
    await updateInvitations();
  });

  const showModal = () =>
    modal.set(
      bind(Popup, {
        onSend: async (email) => {
          const invitation = {
            id: 0,
            email,
            token: window.crypto.randomUUID(),
            company_id: $auth.me?.company?.id || 0,
            inviter_id: 0,
            accepter_id: null,
          };

          await postInvitation(invitation);
          const invitations = await getInvitations();
          const allUsers = await getUsers();
          users = allUsers;
          pendingInvitations = invitations
            .filter((invitation) => invitation.accepter_id === null)
            .filter(
              (invitation) =>
                !allUsers.map((u) => u.email).includes(invitation.email)
            );
          modal.set(null);
        },
      })
    );
</script>

<Navbar />
<div class="mt-7 flex flex-row">
  <Sidebar />
  <div class="w-full">
    <section
      class="mx-auto w-8/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white shadow-xl"
    >
      <div class="container py-12">
        <div class="flex flex-wrap w-full mb-20 flex-col">
          <div class="flex flex-row items-center justify-between border-b">
            <div class="flex items-center">
              <h1 class="mb-0 mt-0 text-black">
                {#if $t['lang'] === 'en'}
                  Collaboration Network
                {:else}
                  Colaboradores
                {/if}
              </h1>
              <div class="mt-2 ml-2">
                <CheckCircle />
              </div>
            </div>
            <Modal
              show={$modal}
              unstyled={true}
              classBg="fixed top-0 left-0 w-screen h-screen flex flex-col justify-center bg-orange-100/[.9]"
              classWindowWrap="relative m-2 max-h-full"
              classWindow="relative w-40 max-w-full max-h-full my-2 mx-auto  rounded  w-full"
              classContent="relative p-2 overflow-auto"
              closeButton={false}
            >
              <button
                class=" bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-sm flex items-center"
                on:click={showModal}
              >
                <div class="mr-2">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z"
                      fill="#F9FAFB"
                    />
                  </svg>
                </div>

                {#if $t['lang'] === 'en'}
                  Invite
                {:else}
                  Invitar
                {/if}
              </button>
            </Modal>
          </div>
          <div class="mt-12">
            {#each users as user}
              <CollaboratorCard
                name={user.name}
                email={user.email}
                trash={false}
                department={true}
                defaultDepartment={user.department}
                onSelectDepartment={async (department) => {
                  let u = user;
                  u.department = department;
                  await postRegistration(u);
                }}
              />
            {/each}
            {#each pendingInvitations as invitation}
              <CollaboratorCard
                name={$t['lang'] === 'en' ? 'Invite' : 'Invitación'}
                email={invitation.email}
                trash={true}
                department={false}
                onDelete={async () => {
                  await deleteInvitation(invitation.email);
                  await updateInvitations();
                }}
              />
            {/each}
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
