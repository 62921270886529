<script>
  export let progress;
</script>

<div
  id={`progress-circle-${String(progress).replace('.', '')}`}
  style={`background: radial-gradient(white 50%, transparent 51%),
    conic-gradient(transparent 0deg ${360 * progress}deg, gainsboro ${
    360 * progress
  }deg 360deg),
    conic-gradient(orange 0deg, yellow 90deg, lightgreen 180deg, green); border-radius: 50%; width: 100px; height: 100px`}
/>
