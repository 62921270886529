import { performanceLevel } from './performanceLevel';

export const dimensionNames = {
  S: { en: 'Social', es: 'Social' },
  A: { en: 'Environmental', es: 'Ambiental' },
  E: { en: 'Economic', es: 'Económico' },
  G: { en: 'Management', es: 'Gestión' },
};

export const categoriesByDimension = {
  S: ['S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8'],
  A: ['A1', 'A2', 'A3', 'A5', 'A6', 'A7'],
  E: ['E1', 'E2', 'E3', 'E4', 'E5', 'E6'],
  G: ['G0', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8', 'G9'],
};
export const categoriesNames = {
  S1: { en: 'Working conditions', es: 'Condiciones laborales' },
  S2: { en: 'Human and labour rights', es: 'Derechos humanos y laborales' },
  S3: { en: 'Social security', es: 'Seguridad Social' },
  S4: { en: 'Wage and opportunities', es: 'Sueldos y oportunidades' },
  S5: {
    en: 'Occupational safety and health',
    es: 'Seguridad y Salud Ocupacional',
  },
  S6: { en: 'Suppliers', es: 'Proveedores' },
  S7: { en: 'Community', es: 'Comunidad' },
  S8: { en: 'Customers', es: 'Clientes' },
  A1: { en: 'Resources', es: 'Recursos' },
  A2: { en: 'Emissions and waste', es: 'Emisiones y Residuos' },
  A3: {
    en: 'Environmental assessment of suppliers',
    es: 'Evaluación ambiental de proveedores',
  },
  // A4: { en: 'Transport of products', es: 'Transporte de productos' },
  A5: {
    en: 'Environmental risk',
    es: 'Riesgo ambiental',
  },
  A6: { en: 'Use of chemical substances', es: 'Uso de sustancias químicas' },
  A7: {
    en: 'Research for environmental improvement',
    es: 'Investigación para la mejora ambiental',
  },
  E1: { en: 'Business plan', es: 'Plan de negocios' },
  E2: { en: 'Production cost', es: 'Costo de producción' },
  E3: { en: 'Financial performance', es: 'Desempeño financiero' },
  E4: { en: 'Collaboration', es: 'Colaboración' },
  E5: {
    en: 'Vulnerability',
    es: 'Vulnerabilidad',
  },
  E6: { en: 'Financial indicators', es: 'Indicadores Financieros' },
  G0: { en: 'Company profile', es: 'Perfil de la empresa' },
  G1: { en: 'Automation', es: 'Automatización' },
  G2: { en: 'Manufacturing management', es: 'Gestión y manufactura' },
  G3: { en: 'Business management', es: 'Gestión administrativa' },
  G4: { en: 'Security', es: 'Seguridad' },
  G5: {
    en: 'Sustainability strategies',
    es: 'Estrategias de sustentabilidad',
  },
  G6: { en: 'Applicable legislation', es: 'Legislación y regulación' },
  G7: { en: 'Corruption prevention', es: 'Prevención de corrupción' },
  G8: {
    en: 'Transparency and communication',
    es: 'Transparencia y comunicación',
  },
  G9: { en: 'Market', es: 'Mercado' },
};

export const allScoresPerCategory = (a) => {
  const categoriesScores = {};
  const dimensions = ['S', 'A', 'E', 'G'];
  dimensions.forEach((dimension) => {
    categoriesByDimension[dimension].forEach((category) => {
      let categoryAnswers = [];
      let categoryScore = 0;

      for (var key in a) {
        if (key === 'createdAt') {
          continue;
        }
        if (
          a[key].question_id.startsWith(category) &&
          a[key].answer_type.startsWith('R')
        ) {
          categoryAnswers.push(a[key]);
        }
      }
      // Quitar las preguntas que el answer_value sea NA
      categoryAnswers = categoryAnswers.filter(
        (answ) => answ.answer_value !== 'NA'
      );
      // Sumar todos los answer_values que quedan
      categoryAnswers.forEach((answ) => {
        categoryScore += Number(answ.answer_value);
      });

      const level = performanceLevel(categoryAnswers.length, categoryScore);

      switch (level) {
        case 'low':
          categoriesScores[category] = 1;
          break;
        case 'mid':
          categoriesScores[category] = 2;
          break;
        case 'high':
          categoriesScores[category] = 3;
          break;
        case 'ideal':
          categoriesScores[category] = 4;
          break;
      }
    });
  });
  return categoriesScores;
};

export function totalQuestionsPerCategoryCalculable(categoryCode, dimensions) {
  let dimensionCode = categoryCode[0];
  let dimension = dimensions[dimensionCode];
  let category = dimension.categories.data.find((c) => {
    return c.code == categoryCode;
  });
  if (category) {
    let quantity = 0;
    category.subCategories.data.forEach((sc) => {
      sc.indicators.data.forEach((i) => {
        i.questions.data.forEach((q) => {
          if (
            q.answerType.startsWith('R') &&
            q.code.startsWith(category.code)
          ) {
            quantity++;
          }
        });
      });
    });
    return quantity;
  } else {
    return 0;
  }
}

export function totalQuestionsPerCategory(categoryCode, dimensions) {
  let dimensionCode = categoryCode[0];
  let dimension = dimensions[dimensionCode];
  let category = dimension.categories.data.find((c) => {
    return c.code == categoryCode;
  });
  if (category) {
    let quantity = 0;
    category.subCategories.data.forEach((sc) => {
      sc.indicators.data.forEach((i) => {
        i.questions.data.forEach(() => {
          quantity++;
        });
      });
    });
    return quantity;
  } else {
    return 0;
  }
}

export function totalQuestionsPerSubcategory(subCategory) {
  let quantity = 0;
  subCategory.indicators.data.forEach((i) => {
    quantity += i.questions.data.length;
  });
  return quantity;
}

export function totalAnsweredPerCategory(categoryCode, answers) {
  let quantity = 0;
  for (var key in answers) {
    if (key === 'createdAt') {
      continue;
    }
    if (answers[key].question_id.startsWith(categoryCode)) {
      quantity++;
    }
  }
  return quantity;
}
