<svg
  width="16"
  height="19"
  viewBox="0 0 16 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0 4.22222H16V6.33333H0V4.22222ZM0 10.5556H16V8.44444H0V10.5556ZM0 14.7778H7V12.6667H0V14.7778ZM0 19H7V16.8889H0V19ZM11.41 16.0128L10 14.5139L8.59 16.0022L11.41 19L16 14.1656L14.58 12.6667L11.41 16.0128ZM0 0V2.11111H16V0H0Z"
    fill="#111827"
  />
</svg>
