<script>
  import { t } from '../stores/messages';
  export let onSend;
  let email = '';
</script>

<div class="mt-7 flex flex-row">
  <div class="w-full">
    <section
      class="mx-auto w-6/12 rounded-lg mb-4 p-4 text-gray-600 body-font bg-white border-2"
    >
      <div class="container py-12">
        <div class="flex flex-wrap w-full mb-20 flex-col">
          <form
            on:keydown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                return false;
              }
            }}
          >
            <div class="flex items-center">
              <div class="container mx-auto">
                <div class="mr-16">
                  <div
                    class="flex flex-row mb-8 font-bold ml-8 mt-6 text-black"
                  >
                    {#if $t['lang'] === 'en'}
                      Email
                    {:else}
                      Correo
                    {/if}
                  </div>
                </div>
                <div class="mx-auto my-10 bg-white rounded-md">
                  <div class="m-7">
                    <div class="mb-6">
                      <div class="block p-2.5 w-full">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          bind:value={email}
                          on:keydown={(e) => e.key !== 'Enter'}
                          on:keyup={(e) => e.key !== 'Enter'}
                          required
                          class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                          placeholder={$t['lang'] === 'en'
                            ? 'Email...'
                            : 'Correo...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="mx-auto w-6/12 bg-white flex flex-row justify-evenly">
            <button
              class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm"
              disabled={!email}
              on:click={() => onSend(email)}
            >
              {#if $t['lang'] === 'en'}
                Send
              {:else}
                Enviar
              {/if}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
