<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';
  export let question;
  let litters = 0;
  let notes = '';

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      const ans = parseAnswer(a[question.code].user_value);
      litters = ans.litters === '' ? 0 : ans.litters;
    }
  });

  function save() {
    saveAnswer(
      question,
      JSON.stringify({
        litters: litters,
      }),
      notes
    );
  }
</script>

<div class="answer mt-5 pr-5">
  <div class="col-left">
    <div class="quantity">
      <div class="form-outline">
        <input
          type="number"
          id="typeNumber"
          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          min="0"
          size="0.5"
          step=".001"
          bind:value={litters}
          on:change={() => {
            save();
          }}
        />
      </div>
      <div>
        <label class="form-label ml-5" for="typeNumber">
          {#if $t['lang'] === 'en'}
            l/year
          {:else}
            l/año
          {/if}
        </label>
      </div>
    </div>
  </div>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(
        question,
        JSON.stringify({
          litters: litters,
        }),
        notes
      );
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>

<style>
  .answer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .quantity {
    align-items: center;
    display: flex;
  }
</style>
