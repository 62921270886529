<script>
  import { onMount } from 'svelte';
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';

  const questionCode = 'A110';

  let materials = [];

  function addMaterial() {
    materials = [
      ...materials,
      {
        fabric: '',
        quantity: 0,
        firstTransport: '',
        firstTransportQty: 0,
        secondTransport: '',
        secondTransportQty: 0,
      },
    ];
  }

  onMount(async () => {
    if (materials.length === 0) {
      addMaterial();
      addMaterial();
      addMaterial();
    }
  });

  function saveMaterials() {
    const a = JSON.stringify(materials);
    saveAnswer({ code: questionCode, answerType: 'V10' }, a);
  }

  answers.subscribe((a) => {
    if (a[questionCode] !== undefined) {
      let ans = parseAnswer(a[questionCode].user_value);
      materials = ans;
    }
  });
</script>

<!-- Fabric 100% -->
<div class="yarn pr-6">
  <div class="mr-4 yarn-info">
    <div class="info w-full">
      <h5 class="text-center">
        <b>
          {#if $t['lang'] === 'en'}
            Purchased raw materials
          {:else}
            Materias primas adquiridas
          {/if}
        </b>
      </h5>
      <p>
        {#if $t['lang'] === 'en'}
          Enter the amount of raw materials purchased annually
        {:else}
          Ingrese la cantidad de materias primas adquiridas anualmente
        {/if}
      </p>
    </div>
    <div class="inputs flex mt-5">
      <div class="flex-column w-full">
        <div class="row">
          {#each materials as material}
            <div class="flex flex-wrap flex-row w-full">
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="select">
                    <select
                      bind:value={material.fabric}
                      on:change={() => saveMaterials()}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="A110A">
                        {#if $t['lang'] === 'en'}
                          Polyethylene terephthalate (PET), granulated
                        {:else}
                          Polietileno tereftalato granulado (PET)
                        {/if}
                      </option>
                      <option value="A110B">
                        {#if $t['lang'] === 'en'}
                          Polyethylene terephthalate (PET), recycled granulated
                        {:else}
                          Polietileno tereftalato reciclado granulado (PET)
                        {/if}
                      </option>
                      <option value="A110C">
                        {#if $t['lang'] === 'en'}
                          Nylon
                        {:else}
                          Nylon
                        {/if}
                      </option>
                      <option value="A110D">
                        {#if $t['lang'] === 'en'}
                          Polyurethane
                        {:else}
                          Poliuretano
                        {/if}
                      </option>
                      <option value="A110E">
                        {#if $t['lang'] === 'en'}
                          Acrylic
                        {:else}
                          Acrílico
                        {/if}
                      </option>
                      <option value="A110F">
                        {#if $t['lang'] === 'en'}
                          Polypropylene, granulated
                        {:else}
                          Polipropileno granulado
                        {/if}
                      </option>
                      <option value="A110G">
                        {#if $t['lang'] === 'en'}
                          Polyester fiber
                        {:else}
                          Fibra de poliéster
                        {/if}
                      </option>
                      <option value="A110H">
                        {#if $t['lang'] === 'en'}
                          Recycled polyester fiber
                        {:else}
                          Fibra de poliéster reciclado
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={material.quantity}
                        on:change={() => saveMaterials()}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        {#if $t['lang'] === 'en'}
                          kg/year
                        {:else}
                          kg/año
                        {/if}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>

  <div class="mr-4 yarn-transport">
    <div class="info w-full">
      <br />
      <h5 class="text-center">
        <b>
          {#if $t['lang'] === 'en'}
            Origin or Provenance
          {:else}
            Origen o procedencia
          {/if}
        </b>
      </h5>
      <p>
        {#if $t['lang'] === 'en'}
          Select the transport mean and the kilometres travelled (approximately)
          from the material production place to your company
        {:else}
          Selecciona el medio de transporte y kilómetros recorridos
          (aproximadamente) desde el lugar de producción del material hasta tu
          empresa
        {/if}
      </p>
    </div>
    <div class="inputs flex mt-5">
      <div class="flex-column w-full">
        <div class="row">
          {#each materials as material}
            <div class="flex flex-wrap flex-row w-full">
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="select mb-5">
                    <select
                      bind:value={material.firstTransport}
                      on:change={() => saveMaterials()}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Train
                        {:else}
                          Tren
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Lorry
                        {:else}
                          Camión
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Commercial vehicle
                        {:else}
                          Vehículo comercial
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Ship
                        {:else}
                          Barco
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Aircraft
                        {:else}
                          Avión
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={material.firstTransportQty}
                        on:change={() => saveMaterials()}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        km
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-column basis-1 flex-1">
                <div class="col-right">
                  <div class="select mb-5">
                    <select
                      bind:value={material.secondTransport}
                      on:change={() => saveMaterials()}
                      class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                    >
                      <option value="">
                        {#if $t['lang'] === 'en'}
                          Select
                        {:else}
                          Seleccionar
                        {/if}
                      </option>
                      <option value="1">
                        {#if $t['lang'] === 'en'}
                          Train
                        {:else}
                          Tren
                        {/if}
                      </option>
                      <option value="2">
                        {#if $t['lang'] === 'en'}
                          Lorry
                        {:else}
                          Camión
                        {/if}
                      </option>
                      <option value="3">
                        {#if $t['lang'] === 'en'}
                          Commercial vehicle
                        {:else}
                          Vehículo comercial
                        {/if}
                      </option>
                      <option value="4">
                        {#if $t['lang'] === 'en'}
                          Ship
                        {:else}
                          Barco
                        {/if}
                      </option>
                      <option value="5">
                        {#if $t['lang'] === 'en'}
                          Aircraft
                        {:else}
                          Avión
                        {/if}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-left">
                  <div class="quantity mb-5">
                    <div class="form-outline">
                      <input
                        bind:value={material.secondTransportQty}
                        on:change={() => saveMaterials()}
                        type="number"
                        id="typeNumber"
                        class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        min="0"
                        step=".001"
                      />
                    </div>
                    <div>
                      <label class="form-label ml-5" for="typeNumber">
                        km
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <button class="button" type="button" on:click={addMaterial}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke="#111827"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>

<style>
  .button {
    background-color: white;
  }
  .row {
    margin-left: 5px;
  }
  .info {
    flex-direction: row;
  }
  h5 {
    align-items: center;
  }
  p {
    align-items: center;
  }
  .yarn {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .quantity {
    align-items: center;
    display: flex;
  }
  .form-outline {
    width: 60%;
  }

  .select {
    align-items: center;
    display: flex;
    justify-items: center;
  }
  .yarn-info {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
  }
  .yarn-transport {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
    margin-top: -54px;
  }

  .inputs {
    align-items: center;
    align-content: center;
    justify-content: right;
  }
</style>
