<script>
  import SubCategoryTabs from '../components/SubCategoryTabs.svelte';
  import Indicator from './Indicator.svelte';

  export let category;
  let subCategories = category.subCategories?.data || [];
  let subCategoryId = subCategories[0]?.code;
  $: {
    subCategories = category.subCategories?.data || [];
    subCategoryId = subCategories[0]?.code;
  }
</script>

<SubCategoryTabs
  {category}
  setSubCategoryId={(subCatId) => {
    subCategoryId = subCatId;
  }}
>
  {#each subCategories as subCategory}
    {#if subCategoryId === subCategory.code}
      {#each subCategory.indicators?.data || [] as indicator}
        <Indicator {indicator} />
      {/each}
    {/if}
  {/each}
</SubCategoryTabs>
