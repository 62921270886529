<script>
  import { t } from '../stores/messages';
  import { auth } from '../stores/auth';
  import Menu from './Menu.svelte';
  import HeaderLogo from './HeaderLogo.svelte';
  import UserLogo from './UserLogo.svelte';
  import LanguageSwitch from './LanguageSwitch.svelte';
</script>

{#if $auth.loggedIn}
  <header class="navbar shadow bg-white">
    <div class="w-auto flex flex-row justify-between">
      <a class="flex items-center" href="/">
        <HeaderLogo />
      </a>
      <div class="flex items-center noprint">
        <div class="flex mr-10 mt-5">
          <div class="switch mr-2">ES</div>
          <div class="menu-icon">
            <LanguageSwitch checked={$t['lang'] === 'en'} />
          </div>
          <div class="switch ml-2">EN</div>
        </div>
        <Menu>
          <UserLogo />
          <div class="mx-5 flex flex-col justify-start">
            <span class="company-name text-orange-500 text-opacity-60"
              >{$auth.me?.company?.name || ''}</span
            >
            <div class="flex flex-row justify-between">
              <p class="email text-gray-400">
                {$auth.me?.name || $auth.me?.email}
              </p>
              <!-- solid/chevron-down -->
              <div class="chevron-down">
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#9CA3AF"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Menu>
      </div>
    </div>
  </header>
{/if}

<style>
  .navbar {
    height: 71px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .company-name {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #f59e0b;
  }
  .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9ca3af;
  }
  a {
    margin-top: 14px;
    margin-left: 32px;
  }
  .chevron-down {
    display: flex;
    align-items: center;
  }
</style>
