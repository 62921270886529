<script>
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';
  export let question;

  let country = { name: '', value: 0 };
  let notes = '';

  function save() {
    const a = JSON.stringify({
      country: country,
    });
    saveAnswer(question, a, notes);
  }

  answers.subscribe((a) => {
    if (a[question.code] !== undefined) {
      let ans = parseAnswer(a[question.code].user_value);
      country.name = ans.country.name;
      country.value = ans.country.value;
    }
  });
</script>

<div class="answer mt-5 pr-5">
  <div class="flex mr-4">
    <div class="col-right">
      <div class="select">
        <select
          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
          bind:value={country['name']}
          on:change={() => {
            save();
          }}
        >
          <option value="" selected>
            {#if $t['lang'] === 'en'}
              Select
            {:else}
              Selecciona
            {/if}
          </option>
          <option value="181"> Europe </option>
          <option value="183"> Belgium </option>
          <option value="185"> Canada </option>
          <option value="186"> China </option>
          <option value="197"> Croatia </option>
          <option value="188"> Czech Republic </option>
          <option value="190"> Denmark </option>
          <option value="192"> Finland </option>
          <option value="193"> France </option>
          <option value="189"> Germany </option>
          <option value="198"> Ireland </option>
          <option value="201"> Italy </option>
          <option value="202"> Mexico </option>
          <option value="203"> Netherlands </option>
          <option value="204"> Norway </option>
          <option value="205"> Poland </option>
          <option value="206"> Portugal </option>
          <option value="207"> Romania </option>
          <option value="209"> Slovakia </option>
          <option value="191"> Spain </option>
          <option value="208"> Sweden </option>
          <option value="187"> Switzerland </option>
          <option value="194"> United Kingdom </option>
          <option value="195"> United States </option>
          <option value="180"> Other </option>
        </select>
      </div>
    </div>
    <div class="col-left">
      <div class="quantity">
        <div class="form-outline">
          <input
            type="number"
            id="typeNumber"
            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
            min="0"
            step=".001"
            bind:value={country['value']}
            on:change={() => {
              save();
            }}
          />
        </div>
        <div>
          <label class="form-label ml-5" for="typeNumber">
            {#if $t['lang'] === 'en'}
              kg/year
            {:else}
              kg/año
            {/if}
          </label>
        </div>
      </div>
    </div>
  </div>
  <textarea
    on:keyup={async (e) => {
      notes = e.target.value;
      await saveAnswer(
        question,
        JSON.stringify({
          country: country,
        }),
        notes
      );
    }}
    value={notes}
    placeholder={$t['lang'] === 'en'
      ? 'Notes and following steps...'
      : 'Notas/acción de seguimiento...'}
    class="block p-2.5 w-96 h-24"
  />
</div>

<style>
  .answer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .quantity {
    align-items: center;
    display: flex;
  }
  .select {
    align-items: center;
    display: flex;
    justify-items: center;
  }
</style>
