<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_256_120)">
    <path
      d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z"
      fill="#111827"
      fill-opacity="0.9"
    />
  </g>
  <defs>
    <clipPath id="clip0_256_120">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
