<script>
  import { onMount } from 'svelte';
  import { t } from '../../stores/messages';
  import { answers } from '../../stores/answers';
  import { saveAnswer, parseAnswer } from '../../services/utils';

  const questionCode = 'A112';

  let materials = [];

  function addMaterial() {
    materials = [
      ...materials,
      {
        fabric: '',
        quantity: 0,
        firstTransport: '',
        firstTransportQty: 0,
        secondTransport: '',
        secondTransportQty: 0,
      },
    ];
  }

  let yarn = {
    quantity: 0,
    firstComponent: '',
    secondComponent: '',
    thirdComponent: '',
    firstComponentQty: 0,
    secondComponentQty: 0,
    thirdComponentQty: 0,
    firstTransport: '',
    firstTransportQty: 0,
    secondTransport: '',
    secondTransportQty: 0,
  };

  let yarns = [yarn];

  function addYarn() {
    yarns = [...yarns, yarn];
  }

  onMount(async () => {
    if (materials.length === 0) {
      addMaterial();
      addMaterial();
      addMaterial();
    }
    if (yarns.length === 0) {
      addYarn();
      addYarn();
      addYarn();
    }
  });

  function saveMaterialsAndYarns(materials, yarns) {
    const a = JSON.stringify({ materials: materials, yarns: yarns });
    saveAnswer({ code: questionCode, answerType: 'V12' }, a);
  }

  answers.subscribe((a) => {
    if (a[questionCode] !== undefined) {
      let ans = parseAnswer(a[questionCode].user_value);
      materials = ans.materials;
      yarns = ans.yarns;
    }
  });

  $: {
    saveMaterialsAndYarns(materials, yarns);
  }
</script>

<main>
  <!-- yarn 100% -->
  <div>
    <div class="col yarn pr-6">
      <div class="mr-4 yarn-info">
        <div class="info w-full">
          {#if $t['lang'] === 'en'}
            <h5>Yarn 100%</h5>
          {:else}
            <h5>Hilo 100%</h5>
          {/if}

          <h5 class="text-center">
            <b>
              {#if $t['lang'] === 'en'}
                Purchased raw materials
              {:else}
                Materias primas adquiridas
              {/if}
            </b>
          </h5>
          <p>
            {#if $t['lang'] === 'en'}
              Select the material and the amount of yarn purchased annually
            {:else}
              Seleccione el material y la cantidad de hilo adquirida anualmente
            {/if}
          </p>
        </div>
        <div class="inputs flex mt-5">
          <div class="flex-column w-full">
            <div class="row">
              {#each materials as material}
                <div class="flex flex-wrap flex-row w-full">
                  <div class="flex flex-column basis-1 flex-1">
                    <div class="col-right">
                      <div class="select">
                        <select
                          bind:value={material['fabric']}
                          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        >
                          <option value="">
                            {#if $t['lang'] === 'en'}
                              Select
                            {:else}
                              Seleccionar
                            {/if}
                          </option>
                          <option value="A112A">
                            {#if $t['lang'] === 'en'}
                              Conventional cotton yarn
                            {:else}
                              Hilo de algodon convencional
                            {/if}
                          </option>
                          <option value="A112B">
                            {#if $t['lang'] === 'en'}
                              Organic cotton yarn
                            {:else}
                              Hilo de algodon organico
                            {/if}
                          </option>
                          <option value="A112C">
                            {#if $t['lang'] === 'en'}
                              Wool yarn
                            {:else}
                              Hilo de lana
                            {/if}
                          </option>
                          <option value="A112D">
                            {#if $t['lang'] === 'en'}
                              Cellulosic fiber yarn
                            {:else}
                              Hilo de fibras celulosicas
                            {/if}
                          </option>
                          <option value="A112E">
                            {#if $t['lang'] === 'en'}
                              Polyester yarn
                            {:else}
                              Hilo de poliester
                            {/if}
                          </option>
                          <option value="A112F">
                            {#if $t['lang'] === 'en'}
                              Recycled polyester yarn
                            {:else}
                              Hilo de poliester reciclado
                            {/if}
                          </option>
                          <option value="A112G">
                            {#if $t['lang'] === 'en'}
                              Nylon yarn
                            {:else}
                              Hilo de nylon
                            {/if}
                          </option>
                          <option value="A112H">
                            {#if $t['lang'] === 'en'}
                              Elastane yarn
                            {:else}
                              Hilo de elastano
                            {/if}
                          </option>
                          <option value="A112I">
                            {#if $t['lang'] === 'en'}
                              Acrylic yarn
                            {:else}
                              Hilo de acrilico
                            {/if}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-left">
                      <div class="quantity mb-5">
                        <div class="form-outline">
                          <input
                            bind:value={material['quantity']}
                            type="number"
                            id="typeNumber"
                            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                            min="0"
                            step=".001"
                          />
                        </div>
                        <div>
                          <label class="form-label ml-5" for="typeNumber">
                            {#if $t['lang'] === 'en'}
                              kg/year
                            {:else}
                              kg/año
                            {/if}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>

      <div class="col mr-4 yarn-transport">
        <br />
        <div class="info w-full">
          <br />
          <h5 class="text-center">
            <b>
              {#if $t['lang'] === 'en'}
                Origin or Provenance
              {:else}
                Origen o procedencia
              {/if}
            </b>
          </h5>
          <p>
            {#if $t['lang'] === 'en'}
              Select the transport mean and the kilometres travelled
              (approximately) from the material production place to your company
            {:else}
              Selecciona el medio de transporte y kilómetros recorridos
              (aproximadamente) desde el lugar de producción del material hasta
              tu empresa
            {/if}
          </p>
        </div>
        <div class="inputs flex mt-5">
          <div class="flex-column w-full">
            <div class="row">
              {#each materials as material}
                <div class="flex flex-wrap flex-row w-full">
                  <div class="flex flex-column basis-1 flex-1">
                    <div class="col-right">
                      <div class="select mb-5">
                        <select
                          bind:value={material['firstTransport']}
                          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        >
                          <option value="">
                            {#if $t['lang'] === 'en'}
                              Select
                            {:else}
                              Seleccionar
                            {/if}
                          </option>
                          <option value="1">
                            {#if $t['lang'] === 'en'}
                              Train
                            {:else}
                              Tren
                            {/if}
                          </option>
                          <option value="2">
                            {#if $t['lang'] === 'en'}
                              Lorry
                            {:else}
                              Camión
                            {/if}
                          </option>
                          <option value="3">
                            {#if $t['lang'] === 'en'}
                              Commercial vehicle
                            {:else}
                              Vehículo comercial
                            {/if}
                          </option>
                          <option value="4">
                            {#if $t['lang'] === 'en'}
                              Ship
                            {:else}
                              Barco
                            {/if}
                          </option>
                          <option value="5">
                            {#if $t['lang'] === 'en'}
                              Aircraft
                            {:else}
                              Avión
                            {/if}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-left">
                      <div class="quantity mb-5">
                        <div class="form-outline">
                          <input
                            bind:value={material['firstTransportQty']}
                            type="number"
                            id="typeNumber"
                            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                            min="0"
                            step=".001"
                          />
                        </div>
                        <div>
                          <label class="form-label ml-5" for="typeNumber">
                            km
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-column basis-1 flex-1">
                    <div class="col-right">
                      <div class="select mb-5">
                        <select
                          bind:value={material['secondTransport']}
                          class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                        >
                          <option value="">
                            {#if $t['lang'] === 'en'}
                              Select
                            {:else}
                              Seleccionar
                            {/if}
                          </option>
                          <option value="1">
                            {#if $t['lang'] === 'en'}
                              Train
                            {:else}
                              Tren
                            {/if}
                          </option>
                          <option value="2">
                            {#if $t['lang'] === 'en'}
                              Lorry
                            {:else}
                              Camión
                            {/if}
                          </option>
                          <option value="3">
                            {#if $t['lang'] === 'en'}
                              Commercial vehicle
                            {:else}
                              Vehículo comercial
                            {/if}
                          </option>
                          <option value="4">
                            {#if $t['lang'] === 'en'}
                              Ship
                            {:else}
                              Barco
                            {/if}
                          </option>
                          <option value="5">
                            {#if $t['lang'] === 'en'}
                              Aircraft
                            {:else}
                              Avión
                            {/if}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-left">
                      <div class="quantity mb-5">
                        <div class="form-outline">
                          <input
                            bind:value={material['secondTransportQty']}
                            type="number"
                            id="typeNumber"
                            class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                            min="0"
                            step=".001"
                          />
                        </div>
                        <div>
                          <label class="form-label ml-5" for="typeNumber">
                            km
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button class="button" type="button" on:click={addMaterial}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
            stroke="#111827"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>

  <!-- Mix yarn -->
  <div class="col mix-yarn mt-5 pr-6">
    <div class="mr-4 yarn-info">
      <div class="info w-full">
        {#if $t['lang'] === 'en'}
          <h5>Mixed Yarn</h5>
        {:else}
          <h5>Hilo Mixto</h5>
        {/if}
        <h5 class="text-center">
          <b>
            {#if $t['lang'] === 'en'}
              Purchased raw materials
            {:else}
              Materias primas adquiridas
            {/if}
          </b>
        </h5>
        <p>
          {#if $t['lang'] === 'en'}
            In this section, you can customize the purchased yarn according to
            its composition. <br />1) Enter the amount of yarn purchased
            annually
            <br />2) Select its components and the percentage
          {:else}
            En esta sección puedes personalizar el hilo adquirido según su
            composición. 1) Ingresa la cantidad de hilo adquirido anualmente 2)
            Selecciona sus componentes y el porcentaje
          {/if}
        </p>
      </div>
      <div class="inputs w-full flex mt-5">
        <div class="flex-column w-full">
          <div class="row">
            {#each yarns as yarn, index}
              <div class="flex flex-wrap flex-row w-full">
                <div class="flex flex-column basis-1 flex-1">
                  <div class="col-right">
                    <div class="items-center text-center mb-12">
                      <label class="form-label ml-5" for="typeNumber">
                        {#if $t['lang'] === 'en'}
                          Yarn {index + 1}
                        {:else}
                          Hilo {index + 1}
                        {/if}
                      </label>
                    </div>
                    <div class="select mb-5">
                      <select
                        bind:value={yarn['firstComponent']}
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1">
                          {#if $t['lang'] === 'en'}
                            Conventional cotton
                          {:else}
                            Algodón convencional
                          {/if}
                        </option>
                        <option value="2">
                          {#if $t['lang'] === 'en'}
                            Organic cotton
                          {:else}
                            Algodón orgánico
                          {/if}
                        </option>
                        <option value="3">
                          {#if $t['lang'] === 'en'}
                            Wool
                          {:else}
                            Lana
                          {/if}
                        </option>
                        <option value="4">
                          {#if $t['lang'] === 'en'}
                            Cellulosic fiber
                          {:else}
                            Fibras celulosicas
                          {/if}
                        </option>
                        <option value="5">
                          {#if $t['lang'] === 'en'}
                            Polyester
                          {:else}
                            Poliéster
                          {/if}
                        </option>
                        <option value="6">
                          {#if $t['lang'] === 'en'}
                            Recycled polyester
                          {:else}
                            Poliéster reciclado
                          {/if}
                        </option>
                        <option value="7">
                          {#if $t['lang'] === 'en'}
                            Nylon
                          {:else}
                            Nylon
                          {/if}
                        </option>
                        <option value="8">
                          {#if $t['lang'] === 'en'}
                            Elastane
                          {:else}
                            Elastano
                          {/if}
                        </option>
                        <option value="9">
                          {#if $t['lang'] === 'en'}
                            Acrylic
                          {:else}
                            Acrílico
                          {/if}
                        </option>
                      </select>
                    </div>
                    <div class="select mb-5">
                      <select
                        bind:value={yarn['secondComponent']}
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1">
                          {#if $t['lang'] === 'en'}
                            Conventional cotton
                          {:else}
                            Algodón convencional
                          {/if}
                        </option>
                        <option value="2">
                          {#if $t['lang'] === 'en'}
                            Organic cotton
                          {:else}
                            Algodón orgánico
                          {/if}
                        </option>
                        <option value="3">
                          {#if $t['lang'] === 'en'}
                            Wool
                          {:else}
                            Lana
                          {/if}
                        </option>
                        <option value="4">
                          {#if $t['lang'] === 'en'}
                            Cellulosic fiber
                          {:else}
                            Fibras celulosicas
                          {/if}
                        </option>
                        <option value="5">
                          {#if $t['lang'] === 'en'}
                            Polyester
                          {:else}
                            Poliéster
                          {/if}
                        </option>
                        <option value="6">
                          {#if $t['lang'] === 'en'}
                            Recycled polyester
                          {:else}
                            Poliéster reciclado
                          {/if}
                        </option>
                        <option value="7">
                          {#if $t['lang'] === 'en'}
                            Nylon
                          {:else}
                            Nylon
                          {/if}
                        </option>
                        <option value="8">
                          {#if $t['lang'] === 'en'}
                            Elastane
                          {:else}
                            Elastano
                          {/if}
                        </option>
                        <option value="9">
                          {#if $t['lang'] === 'en'}
                            Acrylic
                          {:else}
                            Acrílico
                          {/if}
                        </option>
                      </select>
                    </div>
                    <div class="select mb-5">
                      <select
                        bind:value={yarn['thirdComponent']}
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1">
                          {#if $t['lang'] === 'en'}
                            Conventional cotton
                          {:else}
                            Algodón convencional
                          {/if}
                        </option>
                        <option value="2">
                          {#if $t['lang'] === 'en'}
                            Organic cotton
                          {:else}
                            Algodón orgánico
                          {/if}
                        </option>
                        <option value="3">
                          {#if $t['lang'] === 'en'}
                            Wool
                          {:else}
                            Lana
                          {/if}
                        </option>
                        <option value="4">
                          {#if $t['lang'] === 'en'}
                            Cellulosic fiber
                          {:else}
                            Fibras celulosicas
                          {/if}
                        </option>
                        <option value="5">
                          {#if $t['lang'] === 'en'}
                            Polyester
                          {:else}
                            Poliéster
                          {/if}
                        </option>
                        <option value="6">
                          {#if $t['lang'] === 'en'}
                            Recycled polyester
                          {:else}
                            Poliéster reciclado
                          {/if}
                        </option>
                        <option value="7">
                          {#if $t['lang'] === 'en'}
                            Nylon
                          {:else}
                            Nylon
                          {/if}
                        </option>
                        <option value="8">
                          {#if $t['lang'] === 'en'}
                            Elastane
                          {:else}
                            Elastano
                          {/if}
                        </option>
                        <option value="9">
                          {#if $t['lang'] === 'en'}
                            Acrylic
                          {:else}
                            Acrílico
                          {/if}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-left">
                    <div class="quantity mb-5">
                      <div class="form-outline">
                        <input
                          bind:value={yarn['quantity']}
                          type="number"
                          id="typeNumber"
                          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                          min="0"
                          step=".001"
                        />
                      </div>
                      <div>
                        <label class="form-label ml-5" for="typeNumber">
                          {#if $t['lang'] === 'en'}
                            kg/year
                          {:else}
                            kg/año
                          {/if}
                        </label>
                      </div>
                    </div>
                    <div class="quantity mb-5">
                      <div class="form-outline">
                        <input
                          bind:value={yarn['firstComponentQty']}
                          type="number"
                          id="typeNumber"
                          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                          min="0"
                          step=".001"
                        />
                      </div>
                      <div>
                        <label class="form-label ml-5" for="typeNumber">
                          %
                        </label>
                      </div>
                    </div>
                    <div class="quantity mb-5">
                      <div class="form-outline">
                        <input
                          bind:value={yarn['secondComponentQty']}
                          type="number"
                          id="typeNumber"
                          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                          min="0"
                          step=".001"
                        />
                      </div>
                      <div>
                        <label class="form-label ml-5" for="typeNumber">
                          %
                        </label>
                      </div>
                    </div>
                    <div class="quantity mb-5">
                      <div class="form-outline">
                        <input
                          bind:value={yarn['thirdComponentQty']}
                          type="number"
                          id="typeNumber"
                          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                          min="0"
                          step=".001"
                        />
                      </div>
                      <div>
                        <label class="form-label ml-5" for="typeNumber">
                          %
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
    <div class="mr-4 mixed-yarn-transport">
      <div class="info w-full">
        <br />
        <h5 class="text-center">
          <b>
            {#if $t['lang'] === 'en'}
              Origin or Provenance
            {:else}
              Origen o procedencia
            {/if}
          </b>
        </h5>
        <p>
          {#if $t['lang'] === 'en'}
            Select the transport mean and the kilometres travelled
            (approximately) from the material production place to your company
          {:else}
            Selecciona el medio de transporte y kilómetros recorridos
            (aproximadamente) desde el lugar de producción del material hasta tu
            empresa
          {/if}
        </p>
      </div>
      <div class="inputs-transport w-full flex mt-5">
        <div class="flex-column w-full">
          <div class="row">
            {#each yarns as yarn, index}
              <div class="flex flex-wrap flex-row w-full">
                <div class="flex flex-column basis-1 flex-1">
                  <div class="col-right">
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="select mb-5">
                      <select
                        bind:value={yarn['firstTransport']}
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1">
                          {#if $t['lang'] === 'en'}
                            Train
                          {:else}
                            Tren
                          {/if}
                        </option>
                        <option value="2">
                          {#if $t['lang'] === 'en'}
                            Lorry
                          {:else}
                            Camión
                          {/if}
                        </option>
                        <option value="3">
                          {#if $t['lang'] === 'en'}
                            Commercial vehicle
                          {:else}
                            Vehículo comercial
                          {/if}
                        </option>
                        <option value="4">
                          {#if $t['lang'] === 'en'}
                            Ship
                          {:else}
                            Barco
                          {/if}
                        </option>
                        <option value="5">
                          {#if $t['lang'] === 'en'}
                            Aircraft
                          {:else}
                            Avión
                          {/if}
                        </option>
                      </select>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                  </div>
                  <div class="col-left">
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="quantity mb-5">
                      <div class="form-outline">
                        <input
                          bind:value={yarn['firstTransportQty']}
                          type="number"
                          id="typeNumber"
                          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                          min="0"
                          step=".001"
                        />
                      </div>
                      <div>
                        <label class="form-label ml-5" for="typeNumber">
                          km
                        </label>
                      </div>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column basis-1 flex-1">
                  <div class="col-right">
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="select mb-5">
                      <select
                        bind:value={yarn['secondTransport']}
                        class="form-select leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                      >
                        <option value="">
                          {#if $t['lang'] === 'en'}
                            Select
                          {:else}
                            Seleccionar
                          {/if}
                        </option>
                        <option value="1">
                          {#if $t['lang'] === 'en'}
                            Train
                          {:else}
                            Tren
                          {/if}
                        </option>
                        <option value="2">
                          {#if $t['lang'] === 'en'}
                            Lorry
                          {:else}
                            Camión
                          {/if}
                        </option>
                        <option value="3">
                          {#if $t['lang'] === 'en'}
                            Commercial vehicle
                          {:else}
                            Vehículo comercial
                          {/if}
                        </option>
                        <option value="4">
                          {#if $t['lang'] === 'en'}
                            Ship
                          {:else}
                            Barco
                          {/if}
                        </option>
                        <option value="5">
                          {#if $t['lang'] === 'en'}
                            Aircraft
                          {:else}
                            Avión
                          {/if}
                        </option>
                      </select>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                  </div>
                  <div class="col-left">
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="quantity mb-5">
                      <div class="form-outline">
                        <input
                          bind:value={yarn['secondTransportQty']}
                          type="number"
                          id="typeNumber"
                          class="form-control leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline border border-secondary"
                          min="0"
                          step=".001"
                        />
                      </div>
                      <div>
                        <label class="form-label ml-5" for="typeNumber">
                          km
                        </label>
                      </div>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                    <div class="false items-center text-center mb-12">
                      <label
                        class="form-label ml-5 text-white"
                        for="typeNumber"
                      >
                        {#if $t['lang'] === 'en'}
                          .
                        {:else}
                          .
                        {/if}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="button" type="button" on:click={addYarn}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
          stroke="#111827"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</main>

<style>
  .col {
    flex: 1;
  }

  .row {
    margin-left: 5px;
    display: flex;
  }
  p {
    align-items: center;
  }
  .yarn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mix-yarn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .quantity {
    align-items: center;
    display: flex;
  }
  .form-outline {
    width: 65%;
  }
  .select {
    align-items: center;
    display: flex;
    justify-items: center;
  }
  .yarn-info {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
  }
  .yarn-transport {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
    margin-top: -42px;
  }
  .mixed-yarn-transport {
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 50%;
    /* margin-top: -126px; */
  }
  .inputs {
    align-items: center;
    align-content: center;
    justify-content: right;
  }

  .inputs-transport {
    margin-top: 6px;
  }
  .info {
    flex-direction: row;
  }
  .button {
    background-color: white;
  }
</style>
