<svg
  width="20"
  height="13"
  viewBox="0 0 20 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5 3H0V1H5V3ZM5 6H0V8H5V6ZM18.59 13L14.76 9.17C13.96 9.69 13.02 10 12 10C9.24 10 7 7.76 7 5C7 2.24 9.24 0 12 0C14.76 0 17 2.24 17 5C17 6.02 16.69 6.96 16.17 7.75L20 11.59L18.59 13ZM15 5C15 3.35 13.65 2 12 2C10.35 2 9 3.35 9 5C9 6.65 10.35 8 12 8C13.65 8 15 6.65 15 5ZM0 13H10V11H0V13Z"
    fill="#111827"
  />
</svg>
